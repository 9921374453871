import React, { FC, useState, useMemo, useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-date-picker';
import { gql, useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { MdExpandMore } from 'react-icons/md';
import moment from 'moment';
import map from 'lodash/map';
import GMButtonGreen from 'components/GMButtonGreen';
import GMContainer from 'components/GMContainer';
import GMModalInput from 'components/GMModalInput';
import GMSelect from 'components/GMSelect';
import GMSwitcher from 'components/GMSwitcher';
import GMTag from 'components/GMTag';
import useBreakpoint from 'hooks/useBreakpoint';
import { useBtnSize } from 'hooks/useProfileLayout';
import { useProfile } from 'context/profile';
import { useTagSize } from 'hooks/useSearchPageLayout';
import customApolloClient from 'apollo';
import { localizedMotors } from 'constants/motors';
import { numbersPattern } from 'constants/patterns';

const SAVE_ENTERPRISE_VEHICLE = gql`
  mutation SaveEnterpriseVehicle($vehicleData: EnterpriseVehicleInput!) {
    saveEnterpriseVehicle(vehicleData: $vehicleData) {
      id
      name
      model
      licensePlate
      engineType
      registrationDate
      co2Emissions
      kmDistance
    }
  }
`;

const MobilityEnterpriseAddNew: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { token, isEnterprise } = useProfile();
  if (!isEnterprise) return <Redirect to="/mobility/" />;

  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  const history = useHistory();

  const engineTypes = ['HYDROGEN', 'ELECTRIC', 'METHANE', 'HYBRID', 'DIESEL', 'PETROL'];

  const [isNew, setIsNew] = useState(false);
  const [registrationDate, setRegistrationDate] = useState<Date | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [engineType, setEngineType] = useState<string>(engineTypes[0]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>();

  const { handleSubmit, control, register, errors, getValues } = useForm({ reValidateMode: 'onSubmit' });

  const client = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  const redirectToHomeMobility = useCallback((): void => {
    const redirectPath = '/mobility/enterprise';
    history?.location?.pathname !== redirectPath && history.push({ pathname: redirectPath });
  }, []);

  const [SaveVehicleData] = useMutation(SAVE_ENTERPRISE_VEHICLE, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      setLoading(false);
      setError(null);
      redirectToHomeMobility();
    },
    onError(error) {
      setLoading(false);
      setError(error?.message);
    },
    client,
  });

  const updateVehicle = useCallback(async (vehicleData) => {
    setLoading(true);

    return await SaveVehicleData({
      variables: {
        vehicleData,
      },
    });
  }, []);

  const onSubmit = useCallback(
    (values): void => {
      const vehicleData = {
        ...values,
        co2Emissions: Number(values?.co2Emissions),
        currentKmDistance: Number(values?.currentKmDistance),
        startingKmDistance: values.startingKmDistance ? Number(values.startingKmDistance) : 0,
        engineType,
        registrationDate,
      };
      updateVehicle(vehicleData);
    },
    [engineType, registrationDate],
  );

  const validateMoreThanStarting = useCallback((value: number): string | true => {
    const startingKmDistance = getValues('startingKmDistance') ?? 0;
    const error = t('common:errors:cannotBeLessThan', {
      field1: t('mobility:enterprise:addNewVehicle:currentKmDistance'),
      field2: t('mobility:enterprise:addNewVehicle:startingKmDistance'),
    });
    return Number(value) > Number(startingKmDistance) ? true : error;
  }, []);

  return (
    <GMContainer>
      <Helmet>
        <title>{t('mobility:pageTitle')}</title>
        <meta name="mobility" content={t('mobility:pageTitle')} />
      </Helmet>

      <Flex align="flex-end" mb={{ base: '16px', lg: '24px', xl: '32px' }} pt="32px" px={{ lg: '56px' }}>
        <Box maxW={{ base: '536px', lg: '450px' }} w="100%" mx="auto">
          <Box
            bg="SUPER_LIGHT_GRAY"
            border="1px solid"
            borderColor="LIGHT"
            color="PAGE.SECONDARY_TEXT"
            fontSize={xl ? 16 : 14}
            p={{ base: '16px', lg: '32px' }}
          >
            <Heading pb="24px" color="PRIMARY" fontSize={{ base: '20px', lg: '28px', xl: '32px' }}>
              {t('mobility:enterprise:addNewVehicle:title')}
            </Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={{ base: '24px', lg: '32px' }}>
                <GMModalInput
                  register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
                  name="name"
                  label={t('mobility:enterprise:addNewVehicle:id')}
                  errors={errors}
                  req
                />
                <GMModalInput
                  register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
                  name="model"
                  label={t('mobility:enterprise:addNewVehicle:model')}
                  errors={errors}
                  req
                />
                <GMModalInput
                  register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
                  name="licensePlate"
                  label={t('mobility:enterprise:addNewVehicle:licensePlate')}
                  errors={errors}
                  req
                />
                <Box maxW="100%" my={{ base: '16px', lg: '24px' }}>
                  <Flex>
                    <Box mr="8px" flexGrow={1} w="100%">
                      <GMSelect
                        size={tagSize}
                        onSelectOption={(event) => setEngineType(event.target.value)}
                        value={engineType}
                      >
                        {map(engineTypes, (engine) => (
                          <option key={engine} value={engine}>
                            {engine && t(localizedMotors[engine])}
                          </option>
                        ))}
                      </GMSelect>
                    </Box>
                    <Flex flexGrow={1} w="100%" color="TEXT.PRIMARY" direction="column" zIndex={2}>
                      <GMTag
                        active={true}
                        size={tagSize}
                        onClick={() => setShowDatePicker(!showDatePicker)}
                        tagName={
                          registrationDate
                            ? moment(registrationDate).format('DD/MM/YY')
                            : t('mobility:enterprise:addNewVehicle:registrationDate')
                        }
                        icon={
                          <Box position="absolute" right="9px" top="50%" transform="translateY(-50%)">
                            <MdExpandMore fontWeight="bold" size={20} />
                          </Box>
                        }
                      />

                      <Controller
                        as={
                          <DatePicker
                            className="date-picker-mobility-add-new"
                            isOpen={showDatePicker}
                            onCalendarClose={() => setShowDatePicker(false)}
                            locale={currentLocale}
                            format="dd/MM/yyyy"
                            calendarIcon={null}
                            clearIcon={null}
                          />
                        }
                        onChange={(value) => {
                          setRegistrationDate(value[0] as Date);
                          return value;
                        }}
                        control={control}
                        name="registrationDate"
                        rules={{
                          required: {
                            value: true,
                            message: t('common:errors:requiredFieldNamed', {
                              name: t('mobility:enterprise:addNewVehicle:registrationDate'),
                            }),
                          },
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Box color="ERROR" fontSize="14px">
                    {errors && errors.registrationDate?.message}
                  </Box>
                </Box>

                <GMModalInput
                  register={register({
                    pattern: { value: numbersPattern, message: t('common:errors:onlyNumbers') },
                    required: { value: true, message: t('common:errors:requiredField') },
                  })}
                  name="co2Emissions"
                  label={t('mobility:enterprise:addNewVehicle:emissions')}
                  errors={errors}
                  req
                />
                <Box mb={{ base: '16px', lg: '14px' }}>
                  <Box fontSize={{ base: '14px', lg: '16px', xl: '18px' }} pb="16px">
                    {t('mobility:enterprise:addNewVehicle:isVehicleNew')}
                  </Box>
                  <GMSwitcher
                    options={[
                      t('mobility:enterprise:addNewVehicle:vehicleIsNew'),
                      t('mobility:enterprise:addNewVehicle:vehicleIsNotNew'),
                    ]}
                    value={
                      isNew
                        ? t('mobility:enterprise:addNewVehicle:vehicleIsNew')
                        : t('mobility:enterprise:addNewVehicle:vehicleIsNotNew')
                    }
                    onChange={(name) => setIsNew(name === t('mobility:enterprise:addNewVehicle:vehicleIsNew'))}
                  />
                </Box>
                {!isNew && (
                  <GMModalInput
                    register={register({
                      pattern: { value: numbersPattern, message: t('common:errors:onlyNumbers') },
                      required: { value: true, message: t('common:errors:requiredField') },
                    })}
                    name="startingKmDistance"
                    label={t('mobility:enterprise:addNewVehicle:startingKmDistance')}
                    errors={errors}
                    req
                  />
                )}

                <GMModalInput
                  register={register({
                    pattern: { value: numbersPattern, message: t('common:errors:onlyNumbers') },
                    maxLength: { value: 9, message: t('common:errors:maxSymbols', { max: '9' }) },
                    validate: {
                      validateMoreThanStarting: (value) => validateMoreThanStarting(value),
                    },
                    required: { value: true, message: t('common:errors:requiredField') },
                  })}
                  name="currentKmDistance"
                  label={t('mobility:enterprise:addNewVehicle:currentKmDistance')}
                  errors={errors}
                  req
                />
              </Box>
              <GMButtonGreen size={btnSize} type="submit" loading={loading}>
                {t('mobility:enterprise:addNewVehicle:createBtn')}
              </GMButtonGreen>
              {error && (
                <Box color="ERROR" fontSize="14px">
                  {error}
                </Box>
              )}
            </form>
          </Box>
        </Box>
      </Flex>
    </GMContainer>
  );
};

export default MobilityEnterpriseAddNew;
