import React, { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/core';

interface GMContainerProps {
  children: ReactNode;
  px?: string | number;
}

const GMContainer: FC<GMContainerProps> = ({ children, px }) => {
  return (
    <Box minHeight="100%" mx="auto" px={px} pt={{ base: '56px', lg: '142px' }}>
      {children}
    </Box>
  );
};

export default GMContainer;
