import React, { FC } from 'react';
import { palette } from 'theme/theme';

interface GreenKmProps {
  variant?: string;
  size?: number;
}

const colors = {
  green: palette.MEDIUM_GREEN,
  darkGrey: palette.DARK_GREY,
  mediumGrey: palette.MEDIUM_GREY,
  lightGrey: palette.LIGHT_GREY,
  darkGreen: palette.DARK_GREEN,
};

const GreenKm: FC<GreenKmProps> = ({ variant, size = 36 }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="prefix__Livello_2"
      width={size}
      height={size}
      data-name="Livello 2"
      viewBox="0 0 36 36"
    >
      <g id="prefix__icon_km_green" data-name="icon km green" transform="translate(5.645 5.402)">
        <path
          id="prefix__Subtraction_25"
          fill={(variant && colors[variant]) || '#fff'}
          d="M19.31 31.2a2.7 2.7 0 0 1-1.908-.792l-9.9-9.9a2.7 2.7 0 0 1 0-3.816l9.9-9.9a2.7 2.7 0 0 1 3.816 0l9.9 9.9a2.7 2.7 0 0 1 0 3.816l-9.9 9.9a2.7 2.7 0 0 1-1.908.792zm-1.539-11.808h.6a1.476 1.476 0 0 1 .558.081.711.711 0 0 1 .333.3l2.448 3.753a1.656 1.656 0 0 0 .153.2.738.738 0 0 0 .2.144.9.9 0 0 0 .261.09 1.71 1.71 0 0 0 .369 0h2.277l-3.27-5.027a1.8 1.8 0 0 0-.423-.486 2.412 2.412 0 0 0-.513-.288 2.061 2.061 0 0 0 .369-.252 1.863 1.863 0 0 0 .36-.4L24.7 13.2h-2.177a1.656 1.656 0 0 0-.63.1.981.981 0 0 0-.459.4L19 17.223a.9.9 0 0 1-.8.351h-.432V13.2H15.3V24h2.475z"
          data-name="Subtraction 25"
          transform="translate(-6.713 -6.003)"
        />
      </g>
      <path id="prefix__Rectangle_575" fill="none" d="M0 0H36V36H0z" data-name="Rectangle 575" />
    </svg>
  );
};

export default GreenKm;
