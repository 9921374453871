import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Box, Heading, Flex, Link, useDisclosure } from '@chakra-ui/core';
import { renderers } from 'renderers';
import useBreakpoint from 'hooks/useBreakpoint';
// import { useAnalytics } from 'use-analytics';
import { useProfile } from 'context/profile';
import { UseActivityValues, EventCategory } from 'constants/analytics';
import GMPhoto from 'components/GMPhoto';
import GMButtonGreen from 'components/GMButtonGreen';
import GMSinglePromoContentDescription from 'components/GMSinglePromoContentDescription';
import GMModal from 'components/GMModal';
import Drop from 'assets/svg-components/drop';
import GreenKm from 'assets/svg-components/greenKm';
import { urlify, parseUnderline } from 'utils';
import keycloak from 'keycloak';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

interface IGMPromoContent {
  categoryName: string;
  id?: string;
  thumbnailImageUrl?: string;
  imageUrl?: string;
  isSoldOut?: boolean;
  partnerName?: string;
  partnerLink?: string;
  price?: number;
  abstract?: string;
  description?: string;
  title?: string;
  slug?: string;
  details?: string;
  instructions?: string;
  context?: 'promo' | 'donation';
  buyPromoHandler?: () => void;
}

interface IBuyButton {
  size: 'small' | 'medium' | 'big';
  price?: number;
  context?: 'promo' | 'donation';
  onClick?: () => void;
  isSoldOut?: boolean;
  onOpen?: () => void;
}

interface IModal {
  isOpen: boolean;
  onClose: () => void;
  login: () => void;
  register?: () => void;
}

const Modal: FC<IModal> = ({ isOpen, onClose, login, register }) => {
  const { t } = useTranslation();

  return (
    <GMModal isOpen={isOpen} onClose={onClose}>
      <Heading color="PRIMARY" fontSize={{ base: '20px', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('promos:singlePromo:warningMessageTitle')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }}>{t('promos:singlePromo:warningMessageContent')}</Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" variant="transparent" onClick={register}>
            {t('common:register')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" onClick={login}>
            {t('common:login')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </GMModal>
  );
};

const BuyButton: FC<IBuyButton> = ({ size, price, context = 'promo', onClick, isSoldOut }) => {
  const { t } = useTranslation();
  const { userBalance } = useProfile();

  const insufficientFunds = {
    promo: !!(price && price > userBalance?.km),
    donation: !!(price && price > userBalance?.coins),
  };

  const disabled = insufficientFunds[context] || isSoldOut;

  const onClickHandler = (): void => {
    if (disabled) return;
    if (onClick) onClick();
  };

  const buyButtonText = context === 'promo' ? 'promos:singlePromo:buyButton' : 'donations:singleDonation:buyButton';
  const disabledText = {
    promo: {
      insufficientFunds: 'promos:singlePromo:insufficientFunds',
      soldOut: 'promos:singlePromo:soldOut',
    },
    donation: {
      insufficientFunds: 'donations:singleDonation:insufficientFunds',
      soldOut: 'donations:singleDonation:soldOut',
    },
  };

  return (
    <GMButtonGreen size={size} disabled={disabled} onClick={onClickHandler}>
      <Flex minW={{ lg: '262px', xl: '360px' }} justify="space-between" align="center" px="4px">
        {disabled ? (
          <Box>{t(isSoldOut ? disabledText[context]?.soldOut : disabledText[context]?.insufficientFunds)}</Box>
        ) : (
          <>
            <Box>{t(buyButtonText)}</Box>
            <Flex align="center">
              {price} {context === 'promo' && <GreenKm />}
              {context === 'donation' && <Drop />}
            </Flex>
          </>
        )}
      </Flex>
    </GMButtonGreen>
  );
};

const GMPromoContent: FC<IGMPromoContent> = ({
  abstract,
  categoryName,
  context = 'promo',
  description,
  details,
  isSoldOut,
  imageUrl = '',
  partnerLink,
  price,
  thumbnailImageUrl = '',
  title,
  buyPromoHandler,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { userInfo } = useProfile();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const breakpoint = useBreakpoint();
  // const { track } = useAnalytics();
  const sendDataToGTM = useGTMDispatch();
  const xl = breakpoint === 'xl';
  const lg = breakpoint === 'lg';
  const renderingMarkdown = renderers;

  const login = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuLogin, value: EventCategory.UserActivity });

    keycloak.login({ locale: currentLanguage });
  }, [keycloak]);

  const register = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuLogin, value: EventCategory.UserActivity });

    keycloak.register({ locale: currentLanguage });
  }, [keycloak]);

  return (
    <>
      <Box mb={{ base: '24px', lg: '32px', xl: '48px' }}>
        <Box w="100%" h={{ base: '178px', lg: '342px', xl: '342px' }} position="relative">
          <GMPhoto text="" w="100%" h="100%" imageUrl={imageUrl} thumbnailImageUrl={thumbnailImageUrl} />
          <Flex
            position="absolute"
            w="100%"
            bottom="0"
            pb={{ base: '16px', lg: '32px' }}
            px="32px"
            align="flex-end"
            justify="space-between"
          >
            <Box color="TITLE_LINK.LIGHT_TEXT">
              <Heading as="h1" fontSize={{ base: '28px', lg: '32px', xl: '40px' }} textShadow="outline">
                {title}
              </Heading>
              {categoryName && (
                <Box fontSize="xs" display={{ lg: 'none' }} textShadow="outline">
                  {categoryName}
                </Box>
              )}
            </Box>

            {userInfo ? (
              <Box display={{ base: 'none', lg: 'block' }} ml="18px">
                <BuyButton size="big" price={price} context={context} onClick={buyPromoHandler} isSoldOut={isSoldOut} />
              </Box>
            ) : (
              <Box display={{ base: 'none', lg: 'block' }} ml="18px">
                <BuyButton size="big" onClick={onOpen} isSoldOut={isSoldOut} />
              </Box>
            )}
          </Flex>
        </Box>
      </Box>

      <Box
        p={{ base: '0 32px 24px', lg: '0 0 32px' }}
        fontSize={{ base: 'md', lg: 'lg' }}
        lineHeight={{ base: '27px', xl: '32px' }}
        color="TEXT.POST_CONTENT"
        className="markdown"
      >
        {userInfo ? (
          <Box my={{ base: '24px' }} display={{ lg: 'none' }}>
            <BuyButton
              size={xl || lg ? 'medium' : 'small'}
              price={price}
              context={context}
              onClick={buyPromoHandler}
              isSoldOut={isSoldOut}
            />
          </Box>
        ) : (
          <Box my={{ base: '24px' }} display={{ lg: 'none' }}>
            <BuyButton
              size={xl || lg ? 'medium' : 'small'}
              price={price}
              context={context}
              onClick={onOpen}
              isSoldOut={isSoldOut}
            />
          </Box>
        )}

        <Box fontWeight="bold" fontSize={{ base: 'lg', lg: 'xl' }} pb="24px">
          {abstract}
        </Box>

        {context === 'promo' && (
          <>
            <Box pb="32px">
              <ReactMarkdown escapeHtml={false} source={parseUnderline(description)} renderers={renderingMarkdown} />
            </Box>

            <GMSinglePromoContentDescription title={t('promos:singlePromo:detailsTitle')}>
              <ReactMarkdown escapeHtml={false} source={parseUnderline(details)} renderers={renderingMarkdown} />
            </GMSinglePromoContentDescription>

            {/* <GMSinglePromoContentDescription title={t('promos:singlePromo:instructionsTitle')}>
              <ReactMarkdown escapeHtml={false} source={parseUnderline(instructions)} renderers={renderingMarkdown} />
            </GMSinglePromoContentDescription> */}
          </>
        )}

        {context === 'donation' && (
          <>
            <GMSinglePromoContentDescription title={t('donations:singleDonation:aboutPartnerTitle', { name: title })}>
              <ReactMarkdown escapeHtml={false} source={parseUnderline(description)} renderers={renderingMarkdown} />
            </GMSinglePromoContentDescription>

            {partnerLink && (
              <GMSinglePromoContentDescription title={t('donations:singleDonation:seeMoreTitle')}>
                <Link isExternal href={partnerLink} color="PRIMARY" fontWeight="bold">
                  {urlify(partnerLink)}
                </Link>
              </GMSinglePromoContentDescription>
            )}
          </>
        )}
      </Box>

      {isOpen && <Modal login={login} register={register} isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export default GMPromoContent;
