import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import GMSelectOptions from 'components/GMSelectOptions';
import { companyCategories } from 'constants/companyCategories';

interface IGMSelectCompanyCategory {
  onSelect?: (value) => void;
  value?: string;
}

const GMSelectCompanyCategory: FC<IGMSelectCompanyCategory> = ({ onSelect, value }) => {
  const { t } = useTranslation();

  const categoriesOptionsMapped = map(companyCategories, (category) => ({
    value: category.label,
    label: t(`common:companyCategories:${category.label}`),
  }));

  return (
    <GMSelectOptions
      value={value}
      options={categoriesOptionsMapped}
      onSelect={onSelect}
      noValue={t('profile:account:modal:noCompanyCategorySelected')}
      backgroundColor="transparent"
    />
  );
};

export default GMSelectCompanyCategory;
