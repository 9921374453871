import React, { FC } from 'react';
import { Box, Divider } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import {
  GMTransactionsFilterState,
  GMTransactionsFilterCurrency,
  GMTransactionsFilterMovement,
  GMTransactionsFilterDateInterval,
} from 'components/GMTransactionsFilters';

interface IGMTransactionsSidebar {
  activeFilters?: IFilters;
  onChangeCurrency?: (newValue: string) => void;
  onChangeState?: (newValue: string) => void;
  onChangeMovement?: (newValue: string) => void;
  onChangeDateInterval?: (startDate: Date, endDate: Date) => void;
}

interface IFilters {
  byCurrency?: string;
  byState?: string;
  byMovement?: string;
  byDateInterval?: { startDate: Date; endDate: Date };
}

const GMTransactionsSidebar: FC<IGMTransactionsSidebar> = ({
  activeFilters,
  onChangeCurrency,
  onChangeState,
  onChangeMovement,
  onChangeDateInterval,
}) => {
  const { t } = useTranslation();

  const onSelectState = (newValue: string): void => {
    if (onChangeState) onChangeState(newValue);
  };

  const onSelectCurrency = (newValue: string): void => {
    if (onChangeCurrency) onChangeCurrency(newValue);
  };

  const onSelectMovement = (newValue: string): void => {
    if (onChangeMovement) onChangeMovement(newValue);
  };

  const onSelectDates = (startDate, endDate): void => {
    if (onChangeDateInterval) onChangeDateInterval(startDate, endDate);
  };

  return (
    <Box position="relative" w={{ lg: '234px', xl: '312px' }} pl={{ lg: '32px', xl: '24px' }}>
      <Box position="sticky" top="5">
        <Box
          fontSize={{ lg: 'md', xl: 'lg' }}
          lineHeight={{ base: '19px', xl: '22px' }}
          overflow="hidden"
          color="PAGE.SECONDARY_TEXT"
        >
          {t('search:filterTitle')}
        </Box>

        <Divider my="16px" />

        <GMTransactionsFilterMovement activeFilter={activeFilters?.byMovement} onChangeMovement={onSelectMovement} />

        <Divider my="16px" />

        <GMTransactionsFilterState activeFilter={activeFilters?.byState} onChangeState={onSelectState} />

        <Divider my="16px" />

        <GMTransactionsFilterCurrency activeFilter={activeFilters?.byCurrency} onChangeCurrency={onSelectCurrency} />

        <Divider my="16px" />

        <GMTransactionsFilterDateInterval
          activeFilter={activeFilters?.byDateInterval}
          onChangeDateInterval={onSelectDates}
        />
      </Box>
    </Box>
  );
};

export default GMTransactionsSidebar;
