import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, SimpleGrid, Heading, Flex, Text } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import GMHorizontalScrollSection from 'components/GMHorizontalScrollSection';
import GMPhoto from 'components/GMPhoto';
import GMSectionContainer from 'components/GMSectionContainer';
import { useCategoryPhotoTextSize } from 'hooks/useShoppingPageLayout';
import ScrollContainer from 'react-indiana-drag-scroll';

interface IGMCategoriesList {
  categories?: Category[];
  context: 'store' | 'donation' | 'promo';
  description?: ReactNode;
  search?: ReactNode;
}

interface Category {
  id: string;
  landscapeBannerId: string;
  portraitBannerId?: string;
  name: string;
  slug: string;
}

const GMCategoriesList: FC<IGMCategoriesList> = ({ context, categories = [], description, search }) => {
  const { t } = useTranslation();

  const getPhotoTextSize: Function = useCategoryPhotoTextSize();
  const photoTextSize = getPhotoTextSize() || 'small';

  const layoutColums = !isEmpty(categories) ? Math.round(categories.length / 2) : 4;
  const layoutRows = !isEmpty(categories) && categories.length > 4 ? 2 : 1;

  const urlSlug = {
    promo: 'promos',
    store: 'partners',
    donation: 'donations',
  };

  return (
    <Box py={{ base: '16px', lg: '0', xl: '16px' }}>
      <GMSectionContainer>
        {(description || search) && (
          <Flex align="flex-end" mb={{ base: '16px', lg: '24px', xl: '32px' }}>
            <Flex maxW={{ base: '536px', lg: '1224px' }} w="100%">
              <Box
                color="PAGE.SECONDARY_TEXT"
                fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                lineHeight={{ base: '17px', lg: '19px', xl: '22px' }}
                w="100%"
              >
                {description}
              </Box>
              <Box
                minW={{ lg: '216px', xl: '204px' }}
                ml={{ lg: '96px', xl: '232px' }}
                display={{ base: 'none', lg: 'block' }}
              >
                {search}
              </Box>
            </Flex>
          </Flex>
        )}
        <Box>
          <Heading
            as="h3"
            color="PRIMARY"
            fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
            lineHeight={{ base: '24px', lg: '34px', xl: '39px' }}
            pb="24px"
          >
            {t('shopping:home:categoriesTitle')}
          </Heading>
          {!isEmpty(categories) ? (
            <Box h={{ base: layoutRows > 1 ? '256px' : '128px', md: 'auto' }}>
              <GMHorizontalScrollSection>
                <ScrollContainer className="scroll-container" horizontal nativeMobileScroll>
                  <SimpleGrid
                    columns={{ base: layoutColums > 4 ? layoutColums : 4, md: 4 }}
                    spacing={{ base: '24px', md: '18px', lg: '24px' }}
                  >
                    {map(categories, (category, index) => {
                      return (
                        <Box
                          key={category.id || Number(index)}
                          w={{ base: '120px', lg: 'auto' }}
                          h={{ base: '120px', lg: '108px', xl: '144px' }}
                        >
                          <Link
                            to={{
                              pathname: `search/${urlSlug[context]}`,
                              state: { category: category.slug },
                            }}
                          >
                            <GMPhoto
                              h="100%"
                              w="100%"
                              textSize={photoTextSize}
                              text={category.name}
                              thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${category.landscapeBannerId}`}
                              imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${category.landscapeBannerId}`}
                            />
                          </Link>
                        </Box>
                      );
                    })}
                  </SimpleGrid>
                </ScrollContainer>
              </GMHorizontalScrollSection>
            </Box>
          ) : (
            <Text>{t('shopping:home:noCategoriesFound')}</Text>
          )}
        </Box>
      </GMSectionContainer>
    </Box>
  );
};

export default GMCategoriesList;
