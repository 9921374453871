import React, { FC } from 'react';
import { Flex } from '@chakra-ui/core';
import GMLoader from 'components/GMLoader';

const GMLoading: FC = () => {
  return (
    <Flex
      alignItems="center"
      minHeight="100%"
      w="100%"
      justify="center"
      pt={{ base: '200px', md: '400px' }}
      pb={{ base: '150px', md: '250px' }}
    >
      <GMLoader size={40} />
    </Flex>
  );
};

export default GMLoading;
