import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import { gql, useMutation } from '@apollo/client';
import GMPhoto from 'components/GMPhoto';
import { useKeycloak } from '@react-keycloak/web';
import useBreakpoint from 'hooks/useBreakpoint';
import { useTimeout } from 'hooks/useTimeout';
import { localizedCategories } from 'constants/categories';
import { averageTimeToReadArticle, parseUnderline } from 'utils';
import { renderers } from 'renderers';

interface SingleArticleContentProps {
  id: string;
  title?: string;
  content?: string;
  date?: string;
  abstract?: string;
  tags?: string[];
  imageUrl?: string;
  thumbnailImageUrl?: string;
}

const SET_ARTICLE_READ = gql`
  mutation SetArticleRead($articleId: GUID!) {
    setPostRead(id: $articleId)
  }
`;

interface TagsProps {
  currentLocale: string;
  tags: string[];
  t: (tag: string) => string;
}

const Tags: FC<TagsProps> = ({ currentLocale, tags, t }) => (
  <Text
    fontSize={{ base: 'xs', lg: 'md', xl: 'lg' }}
    lineHeight={{ base: '15px', lg: '19px', xl: '22px' }}
    color="SINGLE_ARTICLE.TAGS_TEXT"
  >
    {tags.map((tag, index) => (
      <>
        <Link key={Number(index)} to={`/${currentLocale}/category/${tag}`} style={{ textDecoration: 'underline' }}>
          {`${t(localizedCategories[tag])}`}
        </Link>
        {index + 1 < tags.length ? ' ⋅ ' : ''}
      </>
    ))}
  </Text>
);

const GMSingleArticleContent: FC<SingleArticleContentProps> = ({
  id,
  thumbnailImageUrl = '',
  imageUrl = '',
  title,
  tags = [],
  date,
  abstract,
  content,
}) => {
  const { keycloak } = useKeycloak();
  const breakpoint = useBreakpoint();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const renderingMarkdown = renderers;

  const [SetArticleRead] = useMutation(SET_ARTICLE_READ, {
    variables: {
      articleId: id,
    },
  });

  const readMutation = useCallback(() => {
    if (keycloak?.authenticated) {
      SetArticleRead();
    }
  }, [SetArticleRead]);

  const [startTimeout, cancelTimeout] = useTimeout(readMutation);

  useEffect(() => {
    const timing = averageTimeToReadArticle(content);
    startTimeout(timing / 2, timing); // testing put 10000 = 10secs

    return cancelTimeout;
  });

  return (
    <>
      <Box w="100%" h={{ base: '250px', lg: '342px', xl: '456px' }} fontFamily="content">
        <GMPhoto
          noGradient={breakpoint !== 'lg'}
          text={breakpoint === 'lg' ? title : ''}
          w="100%"
          h="100%"
          imageUrl={imageUrl}
          thumbnailImageUrl={thumbnailImageUrl}
          textSize="big"
        />
      </Box>

      <Text
        color="SINGLE_ARTICLE.TITLE"
        as="h1"
        p={{ base: '16px 32px', xl: '16px 0 0 0' }}
        fontSize={{ base: '28px', xl: '40px' }}
        lineHeight={{ base: '35px', xl: '50px' }}
        fontFamily="content"
        fontWeight="bold"
        bg={{ base: 'SINGLE_ARTICLE.FOOTER_BACKGROUND', lg: 'transparent' }}
        display={{ base: 'block', lg: 'none', xl: 'block' }}
      >
        {title}
      </Text>

      <Box p={{ base: '10px 32px 24px 32px', lg: '10px 0 32px 0', xl: '8px 0 32px 0' }} className="tags">
        <Text
          fontSize={{ base: 'xs', xl: 'sm' }}
          lineHeight={{ base: '15px', xl: '17px' }}
          fontWeight="light"
          color="TEXT.DATE"
          pb={{ base: '4px', lg: '0' }}
        >
          {t('singleArticle:publishedAt')} {date}
        </Text>
        <Tags tags={tags} t={t} currentLocale={currentLocale} />
      </Box>

      <Text
        as="h2"
        p={{ base: '16px 32px', xl: '16px' }}
        mb="32px"
        bg="TEXT.ABSTRACT_BACKGROUND"
        fontSize={{ base: 'xl', lg: '22px', xl: '2xl' }}
        fontWeight="bold"
        lineHeight={{ base: '24px', lg: '27px', xl: '29px' }}
        color="TEXT.ABSTRACT"
      >
        {abstract}
      </Text>

      <Box
        p={{ base: '0 32px 24px', lg: '0 0 32px' }}
        fontSize={{ base: 'xl', xl: '22px' }}
        lineHeight={{ base: '27px', xl: '32px' }}
        color="TEXT.POST_CONTENT"
        fontFamily="content"
        className="markdown"
      >
        <ReactMarkdown escapeHtml={false} source={parseUnderline(content)} renderers={renderingMarkdown} />
      </Box>
    </>
  );
};

export default GMSingleArticleContent;
