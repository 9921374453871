import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import GMContainer from 'components/GMContainer';
import GMCategoriesList from 'components/GMCategoriesList';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMPromoList from 'components/GMPromoList';
import GMSearchBar from 'components/GMSearchBar';
import { useProfile } from 'context/profile';
import { customApolloClient } from 'apollo';

const GET_PROMOS = gql`
  query allPromos($filter: FilterInput!) {
    promos(filter: $filter) {
      totalCount
      edges {
        node {
          id
          landscapeBannerId
          name
          price
          public
          slug
          expirationDate
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query categories($languageCode: String!, $entityType: EntityType) {
    categories(languageCode: $languageCode, entityType: $entityType) {
      id
      landscapeBannerId
      name
      slug
    }
  }
`;

const promosClient = customApolloClient({ clientType: 'promosClient' });
const categoriesClient = customApolloClient({ clientType: 'categoriesClient' });

const Promo: FC = () => {
  const { userInfo } = useProfile();
  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;

  const { loading, error, data: dataPromos } = useQuery(GET_PROMOS, {
    variables: {
      filter: { languageCode: currentLocale, limit: 6, countries: userInfo?.myData?.preferredCountry || undefined },
    },
    client: promosClient,
    fetchPolicy: 'no-cache',
  });

  const { data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      languageCode: currentLocale,
      entityType: 'PROMO',
    },
    client: categoriesClient,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <GMLoading />;
  if (error || !dataPromos) return <GMError />;

  const promos = dataPromos?.promos?.edges;
  const categories = dataCategories?.categories;

  return (
    <GMContainer>
      <Helmet>
        <title>{t('promos:home:pageTitle')}</title>
        <meta name="description" content={t('promos:home:pageTitle')} />
      </Helmet>
      <GMSearchBar path="/search/promos" />

      <GMPromoList src={promos} />
      {!isEmpty(categories) && <GMCategoriesList context="promo" categories={categories} />}
    </GMContainer>
  );
};

export default Promo;
