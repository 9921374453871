import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, Flex, VisuallyHidden, Input } from '@chakra-ui/core';
import DatePicker from 'react-date-picker';
import { MdExpandMore } from 'react-icons/md';
import moment from 'moment';
import { isEmpty } from 'lodash';
import GMCheckbox from 'components/GMCheckbox';
import GMModalInput from 'components/GMModalInput';
import GMTag from 'components/GMTag';
import { useTagSize } from 'hooks/useSearchPageLayout';
import { numbersPattern } from 'constants/patterns';

interface IGMVehicleCard {
  vehicle?: {
    id?: string;
    name?: string;
    kmDistance?: number;
    kmDistanceMeasuredAt?: Date;
    model?: string;
    licensePlate: string;
  };
  index: number;
}

const GMVehicleCard: FC<IGMVehicleCard> = ({ vehicle = {}, index }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activeDateChange, setActiveDateChange] = useState(false);
  const [measuredDate, setMeasuredDate] = useState<Date | null>(null);

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const { id, name, model, kmDistance, kmDistanceMeasuredAt, licensePlate } = vehicle;
  const { register, errors } = useFormContext();

  const onClickDatePickerHandler = useCallback((): void => {
    if (activeDateChange) setShowDatePicker(!showDatePicker);
  }, [activeDateChange]);

  const onCheckboxClickHandler = useCallback((value): void => {
    setActiveDateChange(value);
    if (!value) setMeasuredDate(null);
  }, []);

  const validateMoreThanStarting = useCallback(
    (value: number): string | true => {
      const error = t('common:errors:cannotBeLessThan', {
        field1: t('mobility:enterprise:updateDistances:newDistance'),
        field2: t('mobility:enterprise:updateDistances:currentDistance'),
      });
      return isEmpty(value) || Number(value) > Number(kmDistance) ? true : error;
    },
    [kmDistance],
  );

  return (
    <Box
      bg="SUPER_LIGHT_GRAY"
      border="1px solid"
      borderColor="LIGHT"
      color="PAGE.SECONDARY_TEXT"
      p={{ base: '16px', lg: '32px' }}
      fontSize={{ base: '14px', lg: '16px', xl: '18px' }}
      mb="16px"
      maxW={{ base: '536px', lg: '100%' }}
      mx="auto"
    >
      <Flex wrap="wrap">
        <Flex w={{ base: '100%', lg: '200px' }} wrap="wrap">
          <Box w={{ base: '50%', lg: '100%' }}>
            <Box fontWeight="bold" color="TEXT.PRIMARY">
              {name}
            </Box>
            <Box pb="24px">
              {model} - {licensePlate}
            </Box>
          </Box>
          <Box w={{ base: '50%', lg: '100%' }}>
            <Box maxW="120px">
              {t('mobility:enterprise:updateDistances:updatedAt', {
                date: moment(kmDistanceMeasuredAt).format('DD/MM/YY'),
              })}
            </Box>
            <Box color="TEXT.PRIMARY">
              {t('mobility:enterprise:updateDistances:kmDistance', {
                km: kmDistance,
              })}
            </Box>
          </Box>
        </Flex>
        <Box pl={{ lg: '16px' }} w={{ base: '100%', lg: 'calc(100% - 200px)' }}>
          <VisuallyHidden>
            <Input value={id} ref={register} name={`data[${index}].id`} isDisabled />
          </VisuallyHidden>
          <GMModalInput
            register={register({
              pattern: { value: numbersPattern, message: t('common:errors:onlyNumbers') },
              validate: {
                validateMoreThanStarting: (value) => validateMoreThanStarting(value),
              },
            })}
            req
            errors={errors}
            name={`data[${index}].kmDistance`}
            label={t('mobility:enterprise:updateDistances:currentDistance')}
          />
          <Flex fontSize={{ base: '14px', xl: '16px' }} pb={{ base: '16px', lg: '24px' }}>
            <GMCheckbox onChange={(value) => onCheckboxClickHandler(value)} size="20px">
              {t('mobility:enterprise:updateDistances:measuredDateLabel')}
            </GMCheckbox>
          </Flex>
          <Flex w="200px" direction="column" color="TEXT.PRIMARY">
            <GMTag
              dense
              size={tagSize}
              active={activeDateChange}
              onClick={onClickDatePickerHandler}
              tagName={
                measuredDate
                  ? moment(measuredDate).format('DD/MM/YY')
                  : t('mobility:enterprise:updateDistances:measuredDate')
              }
              icon={
                <Box position="absolute" right="9px" top="50%" transform="translateY(-50%)">
                  <MdExpandMore fontWeight="bold" size={20} />
                </Box>
              }
            />
            {activeDateChange && (
              <Controller
                as={
                  <DatePicker
                    className="date-picker-mobility-add-new"
                    isOpen={showDatePicker}
                    onCalendarClose={() => setShowDatePicker(false)}
                    locale={currentLocale}
                    format="dd/MM/yyyy"
                    calendarIcon={null}
                    clearIcon={null}
                  />
                }
                onChange={(value) => {
                  setMeasuredDate(value[0] as Date);
                  return value;
                }}
                name={`data[${index}].kmDistanceMeasuredAt`}
              />
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default GMVehicleCard;
