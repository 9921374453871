import React, { FC, useEffect, useState, useContext } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { isEmpty, uniqBy, filter, keys, get } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import InView from 'react-intersection-observer';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMSearchBar from 'components/GMSearchBar';
import GMSectionContainer from 'components/GMSectionContainer';
import GMPromoCategoryMesh from 'components/GMPromoCategoryMesh';
import GMPromoCategorySidebar from 'components/GMPromoCategorySidebar';
import GMLoader from 'components/GMLoader';
import GMLoading from 'components/GMLoading';
import GMWalletBadge from 'components/GMWalletBadge';
import { AppContext } from 'context';
import { customApolloClient } from 'apollo';
import { itemsPerPage } from 'constants/index';
import { useProfile } from 'context/profile';
import { navigateByLocale } from 'utils';

interface IEdges {
  id: string;
  categoryName: string;
  node: {
    expirationDate: Date;
    id: string;
    portraitBannerId: string;
    partnerName: string;
    name: string;
    price: number;
    public: boolean;
    slug: string;
  };
}

const GET_PROMOS_IN_CATEGORY = gql`
  query promos($filter: FilterInput!) {
    promos(filter: $filter) {
      totalCount
      edges {
        node {
          expirationDate
          id
          portraitBannerId
          partnerName
          price
          public
          name
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;
const GET_CATEGORIES = gql`
  query categories($languageCode: String!, $entityType: EntityType) {
    categories(languageCode: $languageCode, entityType: $entityType) {
      id
      slug
    }
  }
`;
const client = customApolloClient({ clientType: 'promosClient' });
const categoriesClient = customApolloClient({ clientType: 'categoriesClient' });

const Promos: FC = () => {
  const { loadingWallet, userInfo, userBalance } = useProfile();
  const history = useHistory();

  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;

  const { slug } = useParams();
  const { state } = useLocation();
  const [categoryId, setCategoryId] = useState('');

  const { data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      languageCode: currentLocale,
      entityType: 'PROMO',
    },
    client: categoriesClient,
    fetchPolicy: 'network-only',
  });
  const categories = dataCategories?.categories;

  /**
   * On first load we take category id
   */
  useEffect(() => {
    if (!isEmpty(dataCategories) && !categoryId) {
      const findCategory = categories.find((item) => item.slug === slug);
      findCategory && setCategoryId(findCategory.id);
    }
    if (!isEmpty(dataCategories) && categoryId) {
      const findCategory = categories.find((item) => item.id === categoryId);
      findCategory && navigateByLocale(history, currentLocale, 'promos', findCategory.slug);
    }
  }, [dataCategories]);

  useEffect(() => {
    !slug && navigateByLocale(history, currentLocale, 'promos');
  }, [currentLocale, slug]);

  const categoryName = get(state, 'categoryName');

  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const [promosEdge, setPromosEdge] = useState([{}] as IEdges[]);
  const { isDivInView, setIsDivInView } = useContext(AppContext);

  const promosFilter = {
    languageCode: currentLocale,
    after: '',
    limit: itemsPerPage,
    categorySlugs: slug ? [slug] : undefined,
  };

  const { loading, error, data, networkStatus, fetchMore } = useQuery(GET_PROMOS_IN_CATEGORY, {
    variables: {
      filter: promosFilter,
    },
    fetchPolicy: 'no-cache',
    client,
  });

  const { promos } = data || {};

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');

    return () => addClass;
  });

  const pageInfo = promos?.pageInfo;
  const edges = promos?.edges;

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setPromosEdge(edges);
    }
  }, [data, edges, pageInfo]);

  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: {
          filter: {
            ...promosFilter,
            after: pageInfoState.endCursor,
          },
        },
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'promos.edges', []);
          const newPageInfo = get(fetchMoreResult, 'promos.pageInfo', {});

          setPageInfo(newPageInfo);
          setPromosEdge(uniqBy([...promosEdge, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const cleanedPromos = filter(promosEdge, (val) => keys(val).length !== 0);

  return (
    <GMContainer>
      <Helmet>
        <title>{categoryName || t('promos:category:pageTitle')}</title>
        <meta name="description" content={categoryName || t('promos:category:pageTitle')} />
      </Helmet>
      <GMSearchBar path="/search/promos" category={slug} />

      <GMSectionContainer>
        <Flex>
          <Box maxW={{ base: '536px', lg: 'calc(100% - 312px)' }} mx="auto" w="100%">
            <Heading
              pb={{ base: '24px', xl: '48px' }}
              color="PRIMARY"
              fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
            >
              {slug ? categoryName : t('promos:category:allPromosTitle')}
            </Heading>

            <InfiniteScroll
              dataLength={cleanedPromos.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState?.hasNext && (
                  <Box margin="10px auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0.5}
              style={{ overflowY: 'hidden' }}
            >
              {userInfo && (
                <Box mb="24px" display={{ lg: 'none' }}>
                  <GMWalletBadge loading={loadingWallet} size="big" centered amount={userBalance?.km} />
                </Box>
              )}
              <GMPromoCategoryMesh categorySlug={slug} categoryName={categoryName} src={cleanedPromos} />
              <InView as="div" onChange={(inView) => setIsDivInView({ type: 'setDivInView', payload: inView })}>
                <></>
              </InView>
            </InfiniteScroll>
          </Box>
          <GMPromoCategorySidebar promoCategorySlug={slug} />
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Promos;
