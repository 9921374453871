import React, { FC } from 'react';

interface FlagProps {
  width?: number | string;
  height?: number | string;
}

const FlagDe: FC<FlagProps> = ({ width = 21, height = 15 }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 15">
      <defs>
        <clipPath id="prefix__clip-path">
          <rect
            id="prefix__Rectangle_645"
            width={width}
            height={height}
            fill="#fff"
            stroke="#707070"
            data-name="Rectangle 645"
            rx="2"
            transform="translate(708 197)"
          />
        </clipPath>
      </defs>
      <g id="prefix__flag_ger" clipPath="url(#prefix__clip-path)" transform="translate(-708 -197)">
        <g id="prefix__Group_862" data-name="Group 862" transform="rotate(90 694 234)">
          <path id="prefix__Rectangle_642" d="M0 0H5V21H0z" data-name="Rectangle 642" transform="translate(657 199)" />
          <path
            id="prefix__Rectangle_643"
            fill="#fecf2f"
            d="M0 0H5V21H0z"
            data-name="Rectangle 643"
            transform="translate(667 199)"
          />
          <path
            id="prefix__Rectangle_644"
            fill="#de0a16"
            d="M0 0H5V21H0z"
            data-name="Rectangle 644"
            transform="translate(662 199)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FlagDe;
