/* eslint-disable no-useless-escape */
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/min/locales';
import isEmpty from 'lodash/isEmpty';
import { Helmet } from 'react-helmet';
import { decode } from 'html-entities';
import { Box, Flex } from '@chakra-ui/core';
import { gql, useQuery } from '@apollo/client';
import GMContainer from 'components/GMContainer';
import GMSingleArticleContent from 'components/GMSingleArticleContent';
import GMSingleArticleFooter from 'components/GMSingleArticleFooter';
import GMSingleArticleSidebar from 'components/GMSingleArticleSidebar';
import GMLoading from 'components/GMLoading';
import GMError from 'components/GMError';
import { navigateByLocale, parseMarkdown } from 'utils';

const SINGLE_ARTICLE_BY_SLUG = gql`
  query postBySlug($slug: String) {
    postBySlug(slug: $slug) {
      id
      portraitBannerId
      landscapeBannerId
      publishedAt
      categoryIds
      title
      abstract
      content
      relatedPosts {
        id
        slug
        title
        landscapeBannerId
      }
      metaDescription
      metaKeywords
    }
  }
`;

const SINGLE_ARTICLE_BY_ID = gql`
  query postById($id: GUID!, $languageCode: String!) {
    post(id: $id, languageCode: $languageCode) {
      slug
    }
  }
`;

const Article: FC = () => {
  const { t, i18n } = useTranslation();
  const { slug } = useParams();
  const currentLocale = i18n.language;

  const history = useHistory();

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const { loading, error, data: dataPostBySlug } = useQuery(SINGLE_ARTICLE_BY_SLUG, {
    variables: { slug },
    fetchPolicy: 'no-cache',
  });

  const { postBySlug } = dataPostBySlug || {};
  const {
    id,
    landscapeBannerId,
    publishedAt,
    title,
    abstract,
    content,
    categoryIds,
    relatedPosts,
    metaDescription,
    metaKeywords,
  } = postBySlug || {};

  const { loading: postByIdLoading, error: postByIdError, data: dataPostById } = useQuery(SINGLE_ARTICLE_BY_ID, {
    variables: { id, languageCode: currentLocale },
    fetchPolicy: 'no-cache',
    skip: isEmpty(id),
  });

  const { post: postById } = dataPostById || {};
  const { slug: newLanguageSlug } = postById || {};

  useEffect(() => {
    navigateByLocale(history, currentLocale, 'article', newLanguageSlug || slug);
  }, [newLanguageSlug, currentLocale]);

  if (loading || postByIdLoading) return <GMLoading />;
  if (error || !dataPostBySlug || postByIdError || !postById) return <GMError error={t('common:errors:noContent')} />;

  const publishedDataString = moment(publishedAt).format('DD MMM YYYY');

  // const newContent = content?.replace(/\[\[ embed url=([\s\S]*?) \]\]/gi, (_el, url, index) => {
  //   return `<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" id="youtubeplayer-${index}" type="text/html" width="100%" height="0" src="${url}" frameborder="0" /></div>`;
  // });

  const newContent = parseMarkdown(`<div class="new-content">${decode(content ?? '')}</div>`); //item?.content

  return (
    <GMContainer>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription || abstract} />
        <meta id="og-title" property="og:title" content={title} />
        <meta
          id="og-image"
          property="og:image"
          content={landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''}
        />
        <meta property="og:description" content={metaDescription || abstract} />
        <meta property="og:locale" content={currentLocale} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={metaDescription || abstract} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:site" content="Gremobo" />
        <meta
          name="twitter:image"
          content={landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''}
        />

        <meta name="keywords" content={metaKeywords || ''} />
      </Helmet>

      <Flex px={{ md: '52px' }} py={{ md: '32px' }} maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
        <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
          <GMSingleArticleContent
            id={id}
            title={title}
            tags={categoryIds}
            abstract={abstract}
            content={newContent}
            date={publishedDataString}
            imageUrl={
              landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''
            }
            thumbnailImageUrl={
              landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}` : ''
            }
          />
          <GMSingleArticleFooter relatedArticles={relatedPosts} />
        </Box>
        <GMSingleArticleSidebar title={title} abstract={abstract} relatedArticles={relatedPosts} slug={slug} />
      </Flex>
    </GMContainer>
  );
};

export default Article;
