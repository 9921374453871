import React, { createContext, FC, useEffect, useContext, ReactNode, useCallback, useMemo } from 'react';
import { gql, useMutation, ApolloError, useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import get from 'lodash/get';
import customApolloClient from 'apollo';
import { useKeycloak } from '@react-keycloak/web';

interface IVehicle {
  id: string;
  owned: boolean;
}

interface IProfile {
  inviteCode: string;
  address: string;
  ageGroup: string;
  categoryIds: string[];
  createdAt: Date;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  preferredCountry: string;
  storeIds: string[];
  vehicles: IVehicle[];
}

interface IBalance {
  coins: number;
  km: number;
}

interface IUserContext {
  currentUser?: IProfile | {};
  userInfo: IProfile;
  setUserInfo: (info: any) => void;
  myData?: IProfile;
  errorInfo?: ApolloError | undefined;
  isEnterprise?: boolean;
  loadingInfo: boolean;
  loadingWallet?: boolean;
  userBalance?: IBalance | {};
  refetchWallet: () => void;
  token?: string;
}

const GET_USER = gql`
  query userData {
    myData {
      ... on UserDataDto {
        inviteCode
        id
        isEnterprise
        address
        ageGroup
        categoryIds
        createdAt
        email
        firstName
        lastName
        phoneNumber
        preferredCountry
        vehicles {
          id
          owned
        }
      }
      ... on EnterpriseUserDataDto {
        address
        ageGroup
        createdAt
        categoryIds
        email
        firstName
        id
        isEnterprise
        lastName
        phoneNumber
        preferredCountry
        country
        companyName
        VAT
        companyCategory
        updatedAt
      }
    }
  }
`;

const SAVE_USER_DATA = gql`
  mutation SaveUserData($userData: UserDataInput!) {
    saveUserData(userData: $userData) {
      id
    }
  }
`;

const SAVE_ENTERPRISE_USER_DATA = gql`
  mutation SaveEnterpriseUserData($enterpriseUserData: EnterpriseUserDataInput!) {
    saveEnterpriseUserData(enterpriseUserData: $enterpriseUserData) {
      id
    }
  }
`;

const GET_WALLET = gql`
  query wallet {
    wallet {
      id
      userId
      netBalance
      availableBalance
      currency {
        code
      }
      createdAt
      updatedAt
    }
  }
`;

const UserContext = createContext<IUserContext>({} as IUserContext);

const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [keycloak] = useKeycloak();
  const token = keycloak?.token;
  const isAuthenticated = keycloak?.authenticated;

  const walletClient = useMemo(() => customApolloClient({ clientType: 'walletClient', token }), [token]);
  const usersClient = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  const [GetUserData, { data, error, loading }] = useLazyQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    client: usersClient,
  });

  const [GetWalletData, { data: dataWallet, loading: loadingWallet }] = useLazyQuery(GET_WALLET, {
    fetchPolicy: 'no-cache',
    client: walletClient,
  });

  const isEnterprise = data?.myData?.isEnterprise;

  const walletCoin = find(dataWallet?.wallet, { currency: { code: 'GCN' } });
  const userCoinBalance = get(walletCoin, 'netBalance', 0);
  const positiveUserCoinBalance = userCoinBalance !== undefined && Math.sign(userCoinBalance) > 0 ? userCoinBalance : 0;

  const walletKm = find(dataWallet?.wallet, { currency: { code: 'GKM' } });
  const userKmBalance = get(walletKm, 'availableBalance', 0);

  const userBalance = {
    coins: positiveUserCoinBalance,
    km: userKmBalance,
  };

  const [SaveUserData] = useMutation(SAVE_USER_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      GetUserData();
    },
    client: usersClient,
  });

  const [SaveEnterpriseUserData] = useMutation(SAVE_ENTERPRISE_USER_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      GetUserData();
    },
    client: usersClient,
  });

  const refetchData = useCallback(() => {
    GetUserData();
  }, []);

  const refetchWallet = useCallback(() => {
    GetWalletData();
  }, []);

  const updateUserData = useCallback(
    async (userData) => {
      if (isEnterprise)
        return await SaveEnterpriseUserData({
          variables: {
            enterpriseUserData: userData,
          },
        });

      return await SaveUserData({
        variables: {
          userData,
        },
      });
    },
    [isEnterprise],
  );

  useEffect(() => {
    if (isAuthenticated) {
      refetchData();
    }
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        userInfo: data,
        setUserInfo: updateUserData,
        errorInfo: error,
        isEnterprise: isEnterprise,
        loadingInfo: loading,
        loadingWallet,
        userBalance: userBalance,
        refetchWallet: refetchWallet,
        token,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useProfile = (): any => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useProfile must be used within an UserProvider.');
  }

  return context;
};

export { UserContext, UserProvider, useProfile };
