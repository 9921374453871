import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

interface IGMFooterLinkItem {
  to: string | { pathname: string };
  children?: ReactNode;
}

const GMFooterLinkItem: FC<IGMFooterLinkItem> = ({ to, children }) => {
  return (
    <Box py="8px">
      <Link to={to} target={typeof to !== 'string' ? '_blank' : undefined}>
        {children}
      </Link>
    </Box>
  );
};

export default GMFooterLinkItem;
