import React, { FC } from 'react';
import { BarChart, Bar, XAxis, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { Box } from '@chakra-ui/core';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import get from 'lodash/get';
import { palette } from 'theme/theme';
import { useTranslation } from 'react-i18next';

interface IGMChartBar {
  data?: { id: string; x: string; y: number }[];
  containerH?: number;
  loading?: boolean;
}

interface ICustomTooltip {
  payload?: { value: number }[];
  unit?: string;
}

const CustomTooltip: FC<ICustomTooltip> = ({ payload, unit }) => {
  return (
    <Box bg={palette.WHITE} border="1px solid" borderColor={palette.LIGHT_GREY} p="10px">
      {payload && payload[0]?.value} {unit}
    </Box>
  );
};

const GMChartBar: FC<IGMChartBar> = ({ containerH = 168, data = [], loading }) => {
  const { t } = useTranslation();

  const minDay = minBy(data, 'y');
  const maxDay = maxBy(data, 'y');

  const minDataId = get(minDay, 'id');
  const maxDataId = get(maxDay, 'id');

  return (
    <ResponsiveContainer width="100%" height={containerH}>
      <BarChart data={data} margin={{ top: 15, right: 5, bottom: 5, left: 5 }}>
        <Bar dataKey="y" radius={10} barSize={10} minPointSize={5}>
          {map(data, (entry, index) => {
            const highlight = entry?.id === minDataId || entry?.id === maxDataId;
            return (
              <Cell key={`cell-${entry.x}-${index}`} fill={highlight ? palette.BELIZE_HOLE : palette.MEDIUM_GREEN} />
            );
          })}
        </Bar>
        <Tooltip cursor={{ fill: 'none' }} content={<CustomTooltip unit={t('mobility:charts:unit')} />} />
        <XAxis
          interval={0}
          dataKey="x"
          fontSize="12px"
          axisLine={false}
          tickLine={false}
          stroke={loading ? 'none' : palette.MEDIUM_GREY}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GMChartBar;
