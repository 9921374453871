import React, { FC } from 'react';
import { css } from '@emotion/core';
import MoonLoader from 'react-spinners/MoonLoader';
import { palette } from 'theme/theme';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

interface GMLoader {
  color?: string;
  size?: number;
}

const GMLoader: FC<GMLoader> = ({ color = palette.MEDIUM_GREEN, size = 50 }) => {
  return <MoonLoader css={override} size={size} color={color} loading />;
};

export default GMLoader;
