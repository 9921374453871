import React, { FC } from 'react';

interface FlagProps {
  width?: number | string;
  height?: number | string;
}

const FlagIt: FC<FlagProps> = ({ width = 21, height = 15 }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15">
      <defs>
        <clipPath id="prefix__clip-path">
          <rect
            id="prefix__Rectangle_641"
            width={width}
            height={height}
            fill="#fff"
            stroke="#707070"
            data-name="Rectangle 641"
            rx="2"
            transform="translate(708 197)"
          />
        </clipPath>
      </defs>
      <g id="prefix__flag_ita" clipPath="url(#prefix__clip-path)" transform="translate(-708 -197)">
        <g id="prefix__Group_861" data-name="Group 861" transform="translate(51 -2)">
          <path
            id="prefix__Rectangle_638"
            fill="#008c45"
            d="M0 0H7V15H0z"
            data-name="Rectangle 638"
            transform="translate(657 199)"
          />
          <path
            id="prefix__Rectangle_639"
            fill="#cd212a"
            d="M0 0H7V15H0z"
            data-name="Rectangle 639"
            transform="translate(671 199)"
          />
          <path
            id="prefix__Rectangle_640"
            fill="#fff"
            d="M0 0H7V15H0z"
            data-name="Rectangle 640"
            transform="translate(664 199)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FlagIt;
