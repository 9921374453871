import React, { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Divider, useDisclosure } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMSectionContainer from 'components/GMSectionContainer';
import GMLoading from 'components/GMLoading';
import GMModal from 'components/GMModal';
import GMModalEditData from 'components/GMModalEditData';
import GMModalPreferences from 'components/GMModalPreferences';
import GMModalRedeemCoupon from 'components/GMModalRedeemCoupon';
import GMModalSendCoins from 'components/GMModalSendCoins';
import GMModalGeneratedCoupon from 'components/GMModalGeneratedCoupon';
import GMModalDeleteAccount from 'components/GMModalDeleteAccount';
import GMUserAccount from 'components/GMUserAccount';
import GMUserPreferences from 'components/GMUserPreferences';
import GMUserWallet from 'components/GMUserWallet';
import { useProfile } from 'context/profile';
import customApolloClient from 'apollo';

const GET_TRANSACTIONS = gql`
  query transactions($filter: FilterInput!) {
    transactions(filter: $filter) {
      edges {
        node {
          id
          amount
          lastEventDate
          currency {
            code
          }
          motive
          sender
          transactionType
          state
        }
      }
    }
  }
`;

const GENERATE_COUPON = gql`
  mutation createTransactionCoupon($amount: Float!, $currency: String!) {
    createTransactionCoupon(amount: $amount, currency: $currency) {
      coupon
      transaction {
        id
        sender
        amount
      }
      createdAt
    }
  }
`;

const REDEEM_COUPON = gql`
  mutation redeemCoupon($coupon: String!) {
    redeemCoupon(coupon: $coupon) {
      id
      sender
      amount
    }
  }
`;

const Profile: FC = () => {
  const { t } = useTranslation();
  const { userInfo, setUserInfo, errorInfo, loadingInfo, token, refetchWallet } = useProfile();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modal, setModal] = useState('');
  const [coupon, setCoupon] = useState('');
  const [error, setError] = useState('');

  const [userData, setUserData] = useState({});

  const client = useMemo(() => customApolloClient({ clientType: 'walletClient', token }), [token]);

  const { data: dataTransactions, refetch: refetchTransactions } = useQuery(GET_TRANSACTIONS, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: { after: '', limit: 6 },
    },
  });

  const [GenerateCoupon, { loading: generateCouponLoading }] = useMutation(GENERATE_COUPON, {
    fetchPolicy: 'no-cache',
    client,
    onCompleted(data) {
      setCoupon(data?.createTransactionCoupon?.coupon);
      setModal('generatedCoupon');
      refetchWallet();
      refetchTransactions();
    },
    onError(error) {
      setError(t(`common:errors:${camelCase(error?.message)}`));
    },
  });

  const [RedeemCoupon, { loading: redeemCouponLoading }] = useMutation(REDEEM_COUPON, {
    fetchPolicy: 'no-cache',
    client,
    onCompleted() {
      refetchWallet();
      refetchTransactions();
      onClose();
    },
    onError(error) {
      setError(t(`common:errors:${camelCase(error?.message)}`));
    },
  });

  const openModal = (modal: string): void => {
    setError('');
    setModal(modal);
    onOpen();
  };

  const profileCompleted = userInfo?.myData?.createdAt;
  const transactions = dataTransactions?.transactions?.edges;

  useEffect(() => {
    if (!profileCompleted) openModal('account');
  }, []);

  const setUserDataHandler = useCallback(
    async (data) => {
      await setUserData(data);
      onClose();
    },
    [userData],
  );

  useEffect(() => {
    if (!isEmpty(userData)) setUserInfo(userData);
  }, [userData]);

  const onCloseModalHandler = (): void => {
    if (!profileCompleted) return;
    onClose();
  };

  const onGenerateCoupon = (value: number): void => {
    GenerateCoupon({ variables: { amount: value, currency: 'GCN' } });
  };

  const onRedeemCouponHandler = (coupon: string): void => {
    RedeemCoupon({ variables: { coupon } });
  };

  if (loadingInfo) return <GMLoading />;
  if (errorInfo || !userInfo) return <GMError />;

  const { myData } = userInfo;

  const modalContents = {
    account: <GMModalEditData onClose={onClose} setUserData={setUserDataHandler} />,
    deleteAccount: <GMModalDeleteAccount onClose={onClose} />,
    preferences: <GMModalPreferences onClose={onClose} setUserData={setUserDataHandler} />,
    redeemCoupon: (
      <GMModalRedeemCoupon
        onClose={onClose}
        onRedeemCoupon={onRedeemCouponHandler}
        loading={redeemCouponLoading}
        error={error}
      />
    ),
    generatedCoupon: <GMModalGeneratedCoupon onClose={onClose} coupon={coupon} />,
    sendCoins: (
      <GMModalSendCoins
        onClose={onClose}
        onGenerateCoupon={onGenerateCoupon}
        loading={generateCouponLoading}
        error={error}
      />
    ),
  };

  return (
    <GMContainer>
      <Helmet>
        <title>{t('profile:account:title')}</title>
        <meta name="description" content="Account" />
      </Helmet>
      <GMSectionContainer>
        <Flex direction={{ base: 'column', lg: 'row' }}>
          <Box mr={{ lg: '96px', xl: '128px' }} w={{ base: '100%', lg: '65%' }}>
            <GMUserAccount account={myData} openModal={openModal} />
            <Divider my="30px" display={{ lg: 'none' }} />
            <GMUserPreferences account={myData} openModal={openModal} />
          </Box>

          <Divider mb="25px" display={{ lg: 'none' }} />

          <Box w={{ base: '100%', lg: '35%' }}>
            <GMUserWallet openModal={openModal} transactions={transactions} />
          </Box>
        </Flex>
      </GMSectionContainer>
      {isOpen && (
        <GMModal isOpen={isOpen} onClose={onCloseModalHandler}>
          {modalContents[modal]}
        </GMModal>
      )}
    </GMContainer>
  );
};

export default Profile;
