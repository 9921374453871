import useBreakpoint from 'hooks/useBreakpoint';

const useMeshLayout = (): Function => {
  const breakpoint = useBreakpoint();
  return (articlesQty: number) => {
    switch (`${breakpoint}-${articlesQty}`) {
      // XL
      case 'xl-5':
        return {
          templateArea: `
            "main main other1 other2"
            "main main other3 other4"`,
          templateRows: `
            156px 156px`,
          templateColumns: `
            1fr 1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo', 'photo', 'photo'],
          textSize: ['big', 'small', 'small', 'small', 'small'],
        };
      case 'xl-4':
        return {
          templateArea: `
            "main other1 other2 other3"`,
          templateRows: `
            336px`,
          templateColumns: `
            1fr 1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo', 'photo'],
          textSize: ['medium', 'medium', 'medium', 'medium'],
        };
      case 'xl-3':
        return {
          templateArea: `
            "main main other1 other2"`,
          templateRows: `
            336px`,
          templateColumns: `
            1fr 1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo'],
          textSize: ['big', 'medium', 'medium'],
        };
      case 'xl-2':
        return {
          templateArea: `
            "main other1"`,
          templateRows: `
            336px`,
          templateColumns: `
            1fr 1fr`,
          cardType: ['photo', 'photo'],
          textSize: ['big', 'big'],
        };
      case 'xl-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            448px`,
          templateColumns: `
            1fr`,
          cardType: ['photo'],
          textSize: ['big'],
        };

      // LG
      case 'lg-5':
        return {
          templateArea: `
            "main main other1"
            "main main other2"
            "other3 other3 other3"
            "other4 other4 other4"`,
          templateRows: `
            156px 156px auto auto`,
          templateColumns: `
            1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo', 'title', 'title'],
          textSize: ['big', 'small', 'small', 'big', 'big'],
        };
      case 'lg-4':
        return {
          templateArea: `
            "main main other1"
            "main main other2"
            "other3 other3 other3"`,
          templateRows: `
            156px 156px auto`,
          templateColumns: `
            1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo', 'title'],
          textSize: ['big', 'small', 'small', 'big'],
        };
      case 'lg-3':
        return {
          templateArea: `
            "main main other1"
            "main main other2"`,
          templateRows: `
            150px 150px`,
          templateColumns: `
            1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo'],
          textSize: ['big', 'small', 'small'],
        };
      case 'lg-2':
        return {
          templateArea: `
            "main main other1"`,
          templateRows: `
            336px`,
          templateColumns: `
            1fr 1fr 1fr`,
          cardType: ['photo', 'photo'],
          textSize: ['big', 'medium'],
        };
      case 'lg-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            336px`,
          templateColumns: `
            1fr`,
          cardType: ['photo'],
          textSize: ['big'],
        };

      // MD
      case 'md-5':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"
            "other3"
            "other4"`,
          templateRows: `
            298px auto auto auto auto`,
          cardType: ['photo', 'title', 'title', 'title', 'title'],
          textSize: ['medium', 'small', 'small', 'small', 'small'],
        };
      case 'md-4':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"
            "other3"`,
          templateRows: `
            298px auto auto auto`,
          cardType: ['photo', 'title', 'title', 'title'],
          textSize: ['medium', 'small', 'small', 'small'],
        };
      case 'md-3':
        return {
          templateArea: `
          "main"
          "other1"
          "other2"`,
          templateRows: `
            298px auto auto`,
          cardType: ['photo', 'title', 'title'],
          textSize: ['medium', 'small', 'small'],
        };
      case 'md-2':
        return {
          templateArea: `
            "main"
            "other1"`,
          templateRows: `
            298px auto`,
          cardType: ['photo', 'title'],
          textSize: ['medium', 'small'],
        };
      case 'md-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            298px`,
          cardType: ['photo'],
          textSize: ['medium'],
        };

      // SM
      case 'sm-5':
        return {
          templateArea: `
              "main"
              "other1"
              "other2"
              "other3"
              "other4"`,
          templateRows: `
              160px auto auto auto auto`,
          cardType: ['photo', 'title', 'title', 'title', 'title'],
          textSize: ['medium', 'small', 'small', 'small', 'small'],
        };
      case 'sm-4':
        return {
          templateArea: `
              "main"
              "other1"
              "other2"
              "other3"`,
          templateRows: `
              160px auto auto auto`,
          cardType: ['photo', 'title', 'title', 'title'],
          textSize: ['medium', 'small', 'small', 'small'],
        };
      case 'sm-3':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"`,
          templateRows: `
              160px auto auto`,
          cardType: ['photo', 'title', 'title'],
          textSize: ['medium', 'small', 'small'],
        };
      case 'sm-2':
        return {
          templateArea: `
              "main"
              "other1"`,
          templateRows: `
              160px auto`,
          cardType: ['photo', 'title'],
          textSize: ['medium', 'small'],
        };
      case 'sm-1':
        return {
          templateArea: `
              "main"`,
          templateRows: `
              160px`,
          cardType: ['photo'],
          textSize: ['medium'],
        };
    }
  };
};

export default useMeshLayout;
