import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/core';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from 'context';
import { useProfile } from 'context/profile';
import GMContainer from 'components/GMContainer';
import GMSectionContainer from 'components/GMSectionContainer';
import GMSearchResults from 'components/GMSearchResults';
import GMSearchPageSidebar from 'components/GMSearchPageSidebar';
import GMSearchPageHeader from 'components/GMSearchPageHeader';
import GMLoader from 'components/GMLoader';
import { itemsPerPage } from 'constants/index';
import useBreakpoint from 'hooks/useBreakpoint';
import usePrevious from 'hooks/usePrevious';
import { customApolloClient } from 'apollo';
import { fetchCountries } from 'api';

interface EdgesProps {
  id: string;
  landscapeBannerId: string;
  isGreenDelivery: boolean;
  greenKmReward: number;
  name: string;
  tagline: string;
  abstract: string;
  description: string;
  metaDescription: string;
  metaKeywords: string;
  slug: string;
  node: {
    id: string;
    landscapeBannerId: string;
    isGreenDelivery: boolean;
    greenKmReward: number;
    name: string;
    slug: string;
    tagline: string;
    abstract: string;
    description: string;
    metaDescription: string;
    metaKeywords: string;
  };
}

const SEARCH_STORES = gql`
  query searchStores($after: String, $limit: Int, $filter: SearchStoresFilterInput!) {
    searchStores(limit: $limit, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          landscapeBannerId
          isGreenDelivery
          name
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
      categoryName
    }
  }
`;

const client = customApolloClient({ clientType: 'shopClient' });

const Search: FC = () => {
  const context = 'partners';
  const { isDivInView } = useContext(AppContext);
  const [stores, setStores] = useState([{}] as EdgesProps[]);
  const [countries, setCountries] = useState([]);
  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const history = useHistory();
  const { state } = history?.location;
  const category = get(state, 'category', null);
  const query = get(state, 'query', null);
  const { userInfo } = useProfile();
  const previousLocale = usePrevious(currentLocale);

  useEffect(() => {
    history.replace({ pathname: history.location.pathname, state: null });
  }, []);

  const filters = {
    byCategory: category ? [category] : undefined,
    byGreenDelivery: undefined,
    byCountry: userInfo?.myData?.preferredCountry || undefined,
    byQuery: query || undefined,
  };

  const [activeFilters, setActiveFilters] = useState(filters);

  const storesSearchFilter = {
    languageCode: currentLocale,
    hideAppOnly: true,
    categorySlugs: activeFilters.byCategory,
    countries: activeFilters.byCountry,
    greenDelivery: activeFilters.byGreenDelivery,
    term: activeFilters.byQuery,
  };

  const { loading, data, networkStatus, fetchMore } = useQuery(SEARCH_STORES, {
    variables: {
      filter: storesSearchFilter,
      limit: itemsPerPage,
      after: undefined,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const setFilter = ({ filter, value }): void => {
    const filters = { ...activeFilters };
    filters[filter] = value;
    setActiveFilters(filters);
  };

  const startSearchAfterMs = 1000;
  const startSearch = debounce((newQuery: string) => {
    if (newQuery.length > 0 && newQuery.length < 3) return;
    setFilter({ filter: 'byQuery', value: newQuery });
  }, startSearchAfterMs);

  const onChangeQueryHandler = (newQuery: string): void => {
    startSearch(newQuery);
  };

  const onResetQueryHandler = (): void => {
    setFilter({ filter: 'byQuery', value: undefined });
  };

  const onChangeCategoryHandler = (slug: string): void => {
    const selectedCategories: string[] = [...(activeFilters.byCategory || [])];
    const addCategoryToArray = ({ slug }): void => {
      selectedCategories.push(slug);
    };

    const removeCategoryFromArray = ({ slug }): void => {
      remove(selectedCategories, (catSlug) => catSlug === slug);
    };

    selectedCategories.includes(slug) ? removeCategoryFromArray({ slug }) : addCategoryToArray({ slug });
    setFilter({ filter: 'byCategory', value: selectedCategories.length === 0 ? undefined : selectedCategories });
  };

  const onChangeDeliveryHandler = (newValue: string): void => {
    setFilter({ filter: 'byGreenDelivery', value: newValue === 'greenDeliveryOnly' ? true : undefined });
  };

  const onChangeCountryHandler = (newValue: string): void => {
    setFilter({ filter: 'byCountry', value: newValue || undefined });
  };

  const onResetAllFiltersHandler = (): void => {
    setActiveFilters({
      byCategory: undefined,
      byGreenDelivery: undefined,
      byCountry: undefined,
      byQuery: undefined,
    });
  };

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');
    return () => addClass;
  });

  const pageInfo = data?.searchStores?.pageInfo;
  const edges = data?.searchStores?.edges;

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setStores(edges);
    }
  }, [data, edges, pageInfo]);

  const fetchVars = {
    filter: storesSearchFilter,
    after: pageInfoState.endCursor,
  };
  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: fetchVars,
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'searchStores.edges', []);
          const newPageInfo = get(fetchMoreResult, 'searchStores.pageInfo', {});

          setPageInfo(newPageInfo);
          setStores(uniqBy([...stores, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  const cleanedSrc = filter(stores, (val) => keys(val).length !== 0);

  useEffect(() => {
    fetchCountries()
      .then((res) => res.json())
      .then((result) => {
        setCountries(result);
      })
      .catch(() => {
        setCountries([]);
      });
  }, []);

  useEffect(() => {
    if (previousLocale && previousLocale !== currentLocale) {
      setFilter({ filter: 'byCategory', value: undefined });
    }
  }, [currentLocale]);

  return (
    <GMContainer>
      <Helmet htmlAttributes>
        <title>{t('search:partnersSearchTitle')}</title>
        <meta name="description" content={t('search:partnersSearchDescription')} />
      </Helmet>

      <GMSectionContainer>
        <Flex>
          <Box w="100%" maxW={{ base: '100%', lg: `calc(100% - 234px)`, xl: `calc(100% - 312px)` }} mx="auto">
            <GMSearchPageHeader
              onChangeCategory={onChangeCategoryHandler}
              onChangeCountry={onChangeCountryHandler}
              onChangeQuery={onChangeQueryHandler}
              onResetQuery={onResetQueryHandler}
              onChangeDelivery={onChangeDeliveryHandler}
              activeFilters={activeFilters}
              context={context}
              countries={countries}
            />

            <InfiniteScroll
              dataLength={cleanedSrc.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState?.hasNext && (
                  <Box margin="10px auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0}
              style={{ overflowY: 'hidden' }}
            >
              <GMSearchResults
                context={context}
                loading={loading}
                partners={cleanedSrc}
                onResetAllFilters={onResetAllFiltersHandler}
              />
            </InfiniteScroll>
          </Box>
          {(lg || xl) && (
            <GMSearchPageSidebar
              onChangeDeliveryFilter={onChangeDeliveryHandler}
              onChangeCountry={onChangeCountryHandler}
              activeFilters={activeFilters}
              context={context}
              countries={countries}
            />
          )}
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Search;
