import React, { FC } from 'react';

interface FlagProps {
  width?: number | string;
  height?: number | string;
}

const FlagEs: FC<FlagProps> = ({ width = 21, height = 15 }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <defs>
        <clipPath id="prefix__clip-path">
          <rect
            id="prefix__Rectangle_666"
            width={width}
            height={height}
            fill="#fff"
            stroke="#707070"
            data-name="Rectangle 666"
            rx="2"
            transform="translate(708 197)"
          />
        </clipPath>
      </defs>
      <g id="prefix__flag_esp" clipPath="url(#prefix__clip-path)" transform="translate(-708 -197)">
        <g id="prefix__Group_864" data-name="Group 864" transform="rotate(90 694 234)">
          <path
            id="prefix__Rectangle_663"
            d="M0 0H5V21H0z"
            fill="#c60b1e"
            data-name="Rectangle 663"
            transform="translate(657 199)"
          />
          <path
            id="prefix__Rectangle_664"
            d="M0 0H5V21H0z"
            fill="#c60b1e"
            data-name="Rectangle 664"
            transform="translate(667 199)"
          />
          <path
            id="prefix__Rectangle_665"
            fill="#fec42d"
            d="M0 0H7.5V21H0z"
            data-name="Rectangle 665"
            transform="translate(660.75 199)"
          />
        </g>
      </g>
    </svg>
  );
};

export default FlagEs;
