import React, { FC, ReactNode } from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useBreakpoint from 'hooks/useBreakpoint';
import GMReadMoreLink from 'components/GMReadMoreLink';
import GMSectionContainer from 'components/GMSectionContainer';
import GMNewsMesh from 'components/GMNewsMesh';
import Post from 'interfaces/Post';

interface GMNewsProps {
  articles?: Post[];
  readMore?: boolean;
  title: string;
  onlyHightlight?: boolean;
  search?: ReactNode;
}

const GMNews: FC<GMNewsProps> = ({ title = '', articles = [], readMore = false, onlyHightlight = false, search }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  return (
    <GMSectionContainer>
      <Flex align="flex-end" mb={{ base: '16px', lg: '24px', xl: '32px' }}>
        <Text
          color="PAGE.TITLE_DARK"
          fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
          lineHeight={{ base: '24px', lg: '34px', xl: '39px' }}
          fontWeight="bold"
          w="100%"
        >
          {title}
        </Text>
        {search}
      </Flex>

      <GMNewsMesh articles={articles.slice(0, 5)} />
      {readMore && (
        <>
          <Box paddingTop="24px">
            <Link to={`${currentLocale}/category`}>
              <GMReadMoreLink
                text={t('lifestyle:news:read_all')}
                color="PAGE.TITLE_DARK"
                size={lg || xl ? 'big' : 'small'}
              />
            </Link>
          </Box>
        </>
      )}
      {onlyHightlight && <Box borderBottom="1px" borderColor="READ_ALL.DEFAULT_COLOR" w="100%" pt="30px"></Box>}
    </GMSectionContainer>
  );
};

export default GMNews;
