import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Flex, Text } from '@chakra-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import GMChartCircle from 'components/GMChartCircle';
import GMChartBar from 'components/GMChartBar';
import GMMobilityDateFilter from 'components/GMMobilityFilter';
import useBreakpoint from 'hooks/useBreakpoint';
import { useProfile } from 'context/profile';
import customApolloClient from 'apollo';
import { formatXAxisDate } from 'utils';
import { prevProgressDefault } from 'constants/charts';

interface IBox {
  children?: ReactNode;
  w?: {};
  title?: string;
  mr?: {};
}

const GET_TOTAL_KM = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      totalKm {
        x
        y
      }
    }
  }
`;

// const GET_TOTAL_KM_SUM = gql`
//   query mobilityStats($filter: FilterInput) {
//     mobilityStats(filter: $filter) {
//       totalKmSum
//     }
//   }
// `;

const GET_SHARING = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      sharingMobilityKm {
        x
        y
      }
    }
  }
`;

const GET_DELIVERY = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      deliveryMobilityKm {
        x
        y
      }
    }
  }
`;

const GET_PERSONAL = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      personalMobilityKm {
        x
        y
      }
    }
  }
`;

const GET_OVERALL_PROGRESS = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      overallProgress {
        x
        y
      }
    }
  }
`;

const GET_PREVIOUS_DAY = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      totalKmSum
    }
  }
`;

const ChartBox: FC<IBox> = ({ children, w = '100%', title, mr = '0' }) => {
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  return (
    <Box display="inline-block" w={w} mb="16px" pr={mr} fontSize={{ base: '14px', lg: '16px', xl: '18px' }}>
      {title && (
        <Box color="PAGE.SECONDARY_TEXT" pb="8px" px={{ base: '16px', lg: '0' }}>
          {title}
        </Box>
      )}
      <Box
        bg="SUPER_LIGHT_GRAY"
        border="1px solid"
        borderColor="LIGHT"
        color="PAGE.SECONDARY_TEXT"
        fontSize={xl ? 16 : 14}
      >
        {children}
      </Box>
    </Box>
  );
};

const GMMobilityAuth: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { userInfo, token } = useProfile();

  useEffect(() => {
    moment.locale(currentLocale);
  }, [currentLocale]);

  const filterValueDefault = 'week';
  const filterInputDefault = {
    dateInterval: {
      startDate: moment().subtract(1, 'week').add(1, 'day').toDate(),
      endDate: moment().toDate(),
    },
  };
  const filterPreviousDayInputDefault = {
    dateInterval: {
      startDate: moment().subtract(1, 'days').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
    },
  };

  const [activeDateFilter, setDateFilter] = useState<string>(filterValueDefault);
  const [dateFilterInput, setDateFilterInput] = useState<
    { dateInterval: { startDate: Date; endDate: Date } } | undefined
  >(filterInputDefault);
  const [dateFilterPreviousDayInput] = useState<{ dateInterval: { startDate: Date; endDate: Date } } | undefined>(
    filterPreviousDayInputDefault,
  );

  const client = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  const { data: totalKmData /*, loading: totalKmLoading */ } = useQuery(GET_TOTAL_KM, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  // const { data: totalKmSumData, loading: totalKmSumLoading } = useQuery(GET_TOTAL_KM_SUM, {
  //   fetchPolicy: 'no-cache',
  //   client,
  //   variables: {
  //     filter: dateFilterInput,
  //   },
  // });

  const { data: sharingMobilityKmData /* , loading: sharingMobilityKmLoading */ } = useQuery(GET_SHARING, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: deliveryMobilityKmData /* , loading: deliveryMobilityKmLoading */ } = useQuery(GET_DELIVERY, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: personalMobilityKmData /* , loading: personalMobilityKmLoading */ } = useQuery(GET_PERSONAL, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: overallProgressData, loading: overallProgressLoading } = useQuery(GET_OVERALL_PROGRESS, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: previousDayData } = useQuery(GET_PREVIOUS_DAY, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterPreviousDayInput,
    },
  });

  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  const containerH = xl ? 192 : 168;
  const pieSize = xl ? 130 : 110;

  const totalKm = totalKmData?.mobilityStats?.totalKm;
  // const totalKmSum = totalKmSumData?.mobilityStats?.totalKmSum;
  const deliveryMobilityKm = deliveryMobilityKmData?.mobilityStats?.deliveryMobilityKm;
  const sharingMobilityKm = sharingMobilityKmData?.mobilityStats?.sharingMobilityKm;
  const personalMobilityKm = personalMobilityKmData?.mobilityStats?.personalMobilityKm;
  const overallProgress = overallProgressData?.mobilityStats?.overallProgress;

  const prevProgress = previousDayData?.mobilityStats?.totalKmSum || prevProgressDefault;
  const totalSum = sumBy(totalKm, (item: any) => item?.y);
  const progressPercent = (totalSum * 100) / prevProgress;
  const progressPercentModified = progressPercent > 100 ? 100 : Math.round(progressPercent);

  const overallProgressModified = map(overallProgress, (day) => {
    return {
      id: day?.x,
      x: formatXAxisDate(day?.x, activeDateFilter, currentLocale),
      y: day?.y / 1000, //the data is sent back multiplied by 1000
    };
  });

  const dateFilters = [
    {
      value: 'week',
      filterNameTag: 'mobility:filters:lastWeekTag',
      filterNameSelect: 'mobility:filters:lastWeekSelect',
    },
    {
      value: 'month',
      filterNameTag: 'mobility:filters:lastMonthTag',
      filterNameSelect: 'mobility:filters:lastMonthSelect',
    },
    {
      value: 'year',
      filterNameTag: 'mobility:filters:lastYearTag',
      filterNameSelect: 'mobility:filters:lastYearSelect',
    },
  ];

  return (
    <>
      <Flex>
        <Box pb="32px">
          <Text
            fontSize={{ base: 'md', xl: 'lg' }}
            lineHeight={{ base: '19px', xl: '22px' }}
            color="PAGE.SECONDARY_TEXT"
            px={{ base: '16px', lg: '0' }}
          >
            <Trans
              i18nKey={userInfo ? 'mobility:descriptionAuth' : 'mobility:description'}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green" />]}
            />
          </Text>
        </Box>

        <Box
          minW={{ lg: 'calc(216px + 96px)', xl: 'calc(216px + 96px)' }}
          ml={{ lg: '96px', xl: '96px' }}
          display={{ base: 'none', lg: 'block' }}
        >
          <GMMobilityDateFilter
            type="select"
            onSelectValue={(value) => setDateFilterInput(value)}
            dateFilters={dateFilters}
            setDateFilter={(value) => setDateFilter(value)}
            activeDateFilter={activeDateFilter}
          />
        </Box>
      </Flex>

      <Box display={{ lg: 'none' }}>
        <GMMobilityDateFilter
          type="tags"
          onSelectValue={(value) => setDateFilterInput(value)}
          dateFilters={dateFilters}
          setDateFilter={(value) => setDateFilter(value)}
          activeDateFilter={activeDateFilter}
        />
      </Box>

      <Box>
        <ChartBox
          w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
          title={t('mobility:charts:total:title')}
          mr={{ lg: '16px' }}
        >
          <GMChartCircle
            data={totalKm}
            percent={progressPercentModified}
            labelAmount="km"
            size={pieSize}
            containerH={containerH}
          />
        </ChartBox>

        <ChartBox w={{ base: '100%', lg: 'calc(100% / 3 * 2)' }} title={t('mobility:charts:progress:title')}>
          <GMChartBar data={overallProgressModified} containerH={containerH} loading={overallProgressLoading} />
        </ChartBox>

        <ChartBox
          w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
          title={t('mobility:charts:personal:title')}
          mr={{ lg: '16px' }}
        >
          <GMChartCircle data={personalMobilityKm} size={pieSize} labelAmount="km" containerH={containerH} />
        </ChartBox>

        <ChartBox
          w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
          title={t('mobility:charts:sharing:title')}
          mr={{ lg: '16px' }}
        >
          <GMChartCircle data={sharingMobilityKm} size={pieSize} labelAmount="km" containerH={containerH} />
        </ChartBox>

        <ChartBox w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }} title={t('mobility:charts:delivery:title')}>
          <GMChartCircle data={deliveryMobilityKm} size={pieSize} labelAmount="km" containerH={containerH} />
        </ChartBox>
      </Box>
    </>
  );
};

export default GMMobilityAuth;
