import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, SimpleGrid, Flex } from '@chakra-ui/core';
import moment from 'moment';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import Drop from 'assets/svg-components/drop';
import GreenKm from 'assets/svg-components/greenKm';
import GMPhoto from 'components/GMPhoto';
import GMReadMoreLink from 'components/GMReadMoreLink';
import GMSectionContainer from 'components/GMSectionContainer';
import GMPriceTag from 'components/GMPriceTag';
import GMWalletBadge from 'components/GMWalletBadge';
import { useProfile } from 'context/profile';
import { usePartnersSeeMoreSize } from 'hooks/useShoppingPageLayout';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import theme from 'theme/theme';

interface IPromo {
  node: {
    expirationDate?: Date;
    id: string;
    landscapeBannerId: string;
    name: string;
    price: number;
    slug: string;
  };
}

interface IGMPromoList {
  src?: IPromo[];
  context?: 'promo' | 'donation';
}

const GMPromoList: FC<IGMPromoList> = ({ src, context = 'promo' }) => {
  const { loadingWallet, userInfo, userBalance } = useProfile();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const getReadMoreSize: Function = usePartnersSeeMoreSize();
  const readMoreSize = getReadMoreSize() || 'small';

  const urlSlug = {
    donation: 'donations',
    promo: 'promos',
  };

  const descriptionObj = {
    promo: userInfo ? 'promos:greenKmDescriptionAuth' : 'promos:greenKmDescription',
    donation: userInfo ? 'donations:greenCoinDescriptionAuth' : 'donations:greenCoinDescription',
  };

  const descriptionMobileObj = {
    promo: userInfo ? 'promos:greenKmDescriptionAuthMobile' : 'promos:greenKmDescription',
    donation: userInfo ? 'donations:greenCoinDescriptionAuthMobile' : 'donations:greenCoinDescription',
  };

  const placeholder = {
    promo: 'promos:search',
    donation: 'donations:search',
  };

  const readMoreLinkTextObj = {
    promo: 'promos:home:allPromos',
    donation: 'donations:home:allDonations',
  };

  return (
    <GMSectionContainer>
      <Flex mb={{ lg: '24px', xl: '32px' }}>
        <Box
          color="PAGE.SECONDARY_TEXT"
          fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
          lineHeight={{ base: '17px', lg: '19px', xl: '22px' }}
          pb={{ base: '24px', lg: '26px', xl: '32px' }}
          w="100%"
        >
          <Box display={{ base: 'none', lg: 'block' }}>
            <Trans
              i18nKey={descriptionObj[context]}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green"></strong>]}
            />
          </Box>
          <Box display={{ lg: 'none' }}>
            <Trans
              i18nKey={descriptionMobileObj[context]}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green"></strong>]}
            />
          </Box>
        </Box>
        <Box
          minW={{ lg: '216px', xl: '204px' }}
          ml={{ lg: '96px', xl: '232px' }}
          display={{ base: 'none', lg: 'block' }}
        >
          <GMInputSearchHandler
            path={`/search/${urlSlug[context]}`}
            placeholder={t(placeholder[context])}
            border="2px solid"
            borderColor={theme.colors.PRIMARY}
            iconColor={theme.colors.PRIMARY}
            cursor="pointer"
            shouldRedirectOnClick
            readOnly
          />
        </Box>
      </Flex>
      {!isEmpty(src) ? (
        <Box mb={{ xl: '16px' }}>
          {userInfo && (
            <Box mb="24px" display={{ lg: 'none' }}>
              <GMWalletBadge
                loading={loadingWallet}
                size="big"
                centered
                valute={context === 'promo' ? 'km' : 'coin'}
                amount={context === 'promo' ? userBalance?.km : userBalance?.coins}
              />
            </Box>
          )}

          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: '17px', lg: '18px', xl: '24px' }} mb="24px">
            {map(src, (item, index) => {
              const { price, slug, name, landscapeBannerId, expirationDate } = item?.node;

              const disabled = {
                promo: price > userBalance?.km,
                donation: price > userBalance?.coins,
              };

              const isSoldOut = moment().isAfter(expirationDate);

              return (
                <Box h={{ base: '160px', lg: '156px', xl: '196px' }} key={Number(index)} position="relative">
                  <Link to={`${currentLocale}/${context}/${slug}`}>
                    <GMPhoto
                      h="100%"
                      textSize="medium"
                      text={name}
                      thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}`}
                      imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}`}
                    />
                  </Link>
                  <GMPriceTag variant={disabled[context] || isSoldOut ? 'grey' : 'gradient'} absolute rounded>
                    {price} {context === 'promo' && <GreenKm size={22} />}{' '}
                    {context === 'donation' && <Drop size={22} />}
                  </GMPriceTag>
                </Box>
              );
            })}
          </SimpleGrid>
          <Link to={`search/${urlSlug[context]}`}>
            <GMReadMoreLink text={t(readMoreLinkTextObj[context])} size={readMoreSize} />
          </Link>
        </Box>
      ) : (
        <Link to={`search/${urlSlug[context]}`}>
          <GMReadMoreLink text={t(readMoreLinkTextObj[context])} size={readMoreSize} />
        </Link>
      )}
    </GMSectionContainer>
  );
};

export default GMPromoList;
