import useBreakpoint from 'hooks/useBreakpoint';

export const useBtnSize = (): Function => {
  const breakpoint = useBreakpoint();
  return () => {
    switch (`${breakpoint}`) {
      case 'base':
      case 'sm':
      case 'md':
      default:
        return 'small';
      case 'lg':
        return 'medium';
      case 'xl':
        return 'big';
    }
  };
};

export const useTagSize = (): Function => {
  const breakpoint = useBreakpoint();
  return () => {
    switch (`${breakpoint}`) {
      case 'base':
      case 'sm':
      case 'md':
      default:
        return 'small';
      case 'lg':
        return 'medium';
      case 'xl':
        return 'big';
    }
  };
};
