import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  realm: 'gremobo',
  // eslint-disable-next-line no-undef
  url: process.env.REACT_APP_KEYCLOAK_URL,
  clientId: 'web',
});

export default keycloak;
