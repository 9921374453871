import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Divider, Flex } from '@chakra-ui/core';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import GMGreenDeliveryIcon from 'components/GMGreenDeliveryIcon';
import { useProfile } from 'context/profile';

interface GMShopCategorySidebarProps {
  storeCategorySlug?: string;
}

const GMShopCategorySidebar: FC<GMShopCategorySidebarProps> = ({ storeCategorySlug }) => {
  const { t } = useTranslation();
  const { userInfo } = useProfile();

  return (
    <Box
      position="relative"
      w={{ lg: '312px' }}
      pl={{ lg: '96px', xl: '24px' }}
      display={{ base: 'none', lg: 'block' }}
    >
      <Box position="sticky" top="5">
        <Box mb={{ lg: '24px', xl: '48px' }}>
          <GMInputSearchHandler path="/search/partners" category={storeCategorySlug} />
        </Box>

        <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
          <Trans
            i18nKey={userInfo ? 'shopping:greenCoinDescriptionAuth' : 'shopping:greenCoinDescription'}
            // eslint-disable-next-line react/jsx-key
            components={[<strong className="green" />]}
          />
        </Box>
        <Divider my="24px" />
        <Flex>
          <Box
            color="PRIMARY"
            pr="16px"
            fontSize={{ lg: 'xl', xl: '22px' }}
            lineHeight={{ lg: '24px', xl: '27px' }}
            fontWeight="bold"
            pb="8px"
          >
            {t('shopping:greenDeliveryTitle')}
          </Box>
          <Box
            overflow="hidden"
            borderRadius={{ lg: '20px', xl: '24px' }}
            minW={{ base: '56px', xl: '64px' }}
            w={{ base: '56px', xl: '64px' }}
            h={{ base: '40px', xl: '48px' }}
          >
            <GMGreenDeliveryIcon padding="8px 16px" />
          </Box>
        </Flex>
        <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
          <Trans
            i18nKey={'shopping:greenDeliveryDescription'}
            // eslint-disable-next-line react/jsx-key
            components={[<strong className="green" />]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GMShopCategorySidebar;
