import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Flex, Divider } from '@chakra-ui/core';
import ISOCountries from 'i18n-iso-countries';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import GMSelect from 'components/GMSelect';
import GMGreenDeliveryIcon from 'components/GMGreenDeliveryIcon';
import { useTagSize } from 'hooks/useSearchPageLayout';
import { userInfo } from 'os';

interface SidebarSearchStoresProps {
  activeFilters?: Filters;
  onChangeCountry?: (newValue: string) => void;
  onChangeDeliveryFilter?: (newValue: string) => void;
  countries?: { id: string; name: string }[];
}

interface Filters {
  byGreenDelivery?: boolean;
  byCategory?: string[];
  byCountry?: string;
  byQuery?: string;
}

const SidebarSearchStores: FC<SidebarSearchStoresProps> = ({
  activeFilters,
  onChangeDeliveryFilter,
  onChangeCountry,
  countries,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onSelectDeliveryOptionHandler = (newValue: string): void => {
    if (onChangeDeliveryFilter) onChangeDeliveryFilter(newValue);
  };

  const onSelectOptionCountryHandler = (newValue: string): void => {
    if (onChangeCountry) onChangeCountry(newValue);
  };

  const sortedCountries = sortBy(
    map(countries, (country) => ({
      id: country.id,
      name: ISOCountries.getName(country.id, currentLocale),
    })),
    (o) => o.name,
  );

  return (
    <>
      <Box flexShrink={0} flexGrow={1}>
        <Box mb="8px">
          <GMSelect
            size={tagSize}
            value={activeFilters?.byGreenDelivery ? 'greenDeliveryOnly' : 'allDeliveries'}
            onSelectOption={(event) => onSelectDeliveryOptionHandler(event.target.value)}
          >
            <option value="allDeliveries">{t('search:allDeliveries')}</option>
            <option value="greenDeliveryOnly">{t('search:greenDeliveryOnly')}</option>
          </GMSelect>
        </Box>
      </Box>

      <Divider my="16px" />

      {!isEmpty(countries) && (
        <Box>
          <Flex
            wrap="nowrap"
            maxW="100%"
            overflowX="scroll"
            mb={{ base: '16px', lg: '24px' }}
            style={{ overflowX: 'hidden' }}
          >
            <Box flexShrink={0} flexGrow={1}>
              <GMSelect
                size={tagSize}
                value={activeFilters?.byCountry}
                onSelectOption={(event) => onSelectOptionCountryHandler(event.target.value)}
              >
                <option value="">{t('search:allCountries')}</option>
                {map(sortedCountries, (country, index) => (
                  <option key={index} value={country.id}>
                    {country.name ?? ''}
                  </option>
                ))}
              </GMSelect>
            </Box>
          </Flex>
        </Box>
      )}

      {!userInfo && (
        <>
          <Divider my="16px" />

          <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
            <Trans
              i18nKey={userInfo ? 'shopping:greenCoinDescriptionAuth' : 'shopping:greenCoinDescription'}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green" />]}
            />
          </Box>
          <Divider my="24px" />
          <Flex>
            <Box
              color="PRIMARY"
              pr="16px"
              fontSize={{ lg: 'xl', xl: '22px' }}
              lineHeight={{ lg: '24px', xl: '27px' }}
              fontWeight="bold"
              pb="8px"
            >
              {t('shopping:greenDeliveryTitle')}
            </Box>
            <Box
              overflow="hidden"
              borderRadius={{ lg: '20px', xl: '24px' }}
              minW={{ base: '56px', xl: '64px' }}
              w={{ base: '56px', xl: '64px' }}
              h={{ base: '40px', xl: '48px' }}
            >
              <GMGreenDeliveryIcon padding="8px 16px" />
            </Box>
          </Flex>
          <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
            <Trans
              i18nKey={'shopping:greenDeliveryDescription'}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green" />]}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default SidebarSearchStores;
