import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/core';
import DatePicker from '@wojtekmaj/react-daterange-picker';
import { MdExpandMore } from 'react-icons/md';
import GMTag from 'components/GMTag';
import GMSelect from 'components/GMSelect';
import { useTagSize } from 'hooks/useSearchPageLayout';

interface IGMTransactionsFilterState {
  activeFilter?: string;
  onChangeState?: (newValue: string) => void;
}

interface IGMTransactionsFilterCurrency {
  activeFilter?: string;
  onChangeCurrency?: (newValue: string) => void;
}

interface IGMTransactionsFilterMovement {
  activeFilter?: string;
  onChangeMovement?: (newValue: string) => void;
}

interface IGMTransactionsFilterDateInterval {
  activeFilter?: { startDate: Date; endDate: Date };
  onChangeDateInterval?: (startDate: Date, endDate: Date) => void;
}

export const GMTransactionsFilterState: FC<IGMTransactionsFilterState> = ({ activeFilter, onChangeState }) => {
  const { t } = useTranslation();
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onSelectState = (newValue: string): void => {
    if (onChangeState) onChangeState(newValue);
  };

  return (
    <GMSelect
      size={tagSize}
      value={activeFilter?.toLowerCase()}
      onSelectOption={(event) => onSelectState(event?.target?.value)}
    >
      <option value="">{t('profile:transactions:filters:byState:all')}</option>
      <option value="confirmed">{t('profile:transactions:filters:byState:completed')}</option>
      <option value="pending">{t('profile:transactions:filters:byState:pending')}</option>
      <option value="void">{t('profile:transactions:filters:byState:canceled')}</option>
    </GMSelect>
  );
};

export const GMTransactionsFilterCurrency: FC<IGMTransactionsFilterCurrency> = ({ activeFilter, onChangeCurrency }) => {
  const { t } = useTranslation();
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onSelectCurrency = (newValue: string): void => {
    if (onChangeCurrency) onChangeCurrency(newValue);
  };

  return (
    <GMSelect
      size={tagSize}
      value={activeFilter?.toLowerCase()}
      onSelectOption={(event) => onSelectCurrency(event?.target?.value)}
    >
      <option value="">{t('profile:transactions:filters:byCurrency:all')}</option>
      <option value="gkm">{t('profile:transactions:filters:byCurrency:gkm')}</option>
      <option value="gcn">{t('profile:transactions:filters:byCurrency:gcn')}</option>
    </GMSelect>
  );
};

export const GMTransactionsFilterMovement: FC<IGMTransactionsFilterMovement> = ({ activeFilter, onChangeMovement }) => {
  const { t } = useTranslation();
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onSelectMovement = (newValue: string): void => {
    if (onChangeMovement) onChangeMovement(newValue);
  };

  return (
    <GMSelect
      size={tagSize}
      value={activeFilter?.toLowerCase()}
      onSelectOption={(event) => onSelectMovement(event?.target?.value)}
    >
      <option value="">{t('profile:transactions:filters:byMovement:all')}</option>
      <option value="incoming">{t('profile:transactions:filters:byMovement:incoming')}</option>
      <option value="outgoing">{t('profile:transactions:filters:byMovement:outgoing')}</option>
    </GMSelect>
  );
};

export const GMTransactionsFilterDateInterval: FC<IGMTransactionsFilterDateInterval> = ({
  activeFilter,
  onChangeDateInterval,
}) => {
  const { t, i18n } = useTranslation();
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';
  const [show, setShow] = useState(false);

  const currentLocale = i18n.language;

  const onSelectDates = (dates): void => {
    const startDate = dates ? dates[0] : undefined;
    const endDate = dates ? dates[1] : undefined;
    if (onChangeDateInterval) onChangeDateInterval(startDate, endDate);
  };

  const isRangeSelected = activeFilter?.startDate && activeFilter?.endDate;

  return (
    <Flex>
      <GMTag
        size={tagSize}
        active={true}
        tagName={
          isRangeSelected
            ? t('profile:transactions:filters:byDateInterval:dateIntervalSelected')
            : t('profile:transactions:filters:byDateInterval:allDates')
        }
        onClick={() => setShow(!show)}
        icon={
          <Box position="absolute" right="9px" top="50%" transform="translateY(-50%)">
            <MdExpandMore fontWeight="bold" size={20} />
          </Box>
        }
      />

      <DatePicker
        isOpen={show}
        onCalendarClose={() => setShow(false)}
        onChange={(dates) => onSelectDates(dates as Date[])}
        locale={currentLocale}
        format="dd/MM/yyyy"
        calendarIcon={null}
        clearIcon={null}
        value={[activeFilter?.startDate, activeFilter?.endDate]}
      />
    </Flex>
  );
};
