import useBreakpoint from 'hooks/useBreakpoint';

const useMeshLayout = (): Function => {
  const breakpoint = useBreakpoint();
  return (articlesQty: number) => {
    switch (`${breakpoint}-${articlesQty}`) {
      // XL
      case 'xl-4':
        return {
          templateArea: `
            "main other1 other2 other3"`,
          templateRows: `
            291px`,
          templateColumns: `
            1fr 1fr 1fr 1fr`,
          cardType: ['photo-text', 'photo-text', 'photo-text', 'photo-text'],
          textSize: ['medium', 'medium', 'medium', 'medium'],
        };
      case 'xl-3':
        return {
          templateArea: `
            "main other1 other2"`,
          templateRows: `
            360px`,
          templateColumns: `
            1fr 1fr 1fr`,
          cardType: ['photo-text', 'photo-text', 'photo-text'],
          textSize: ['medium', 'medium', 'medium'],
        };
      case 'xl-2':
        return {
          templateArea: `
            "main other1"`,
          templateRows: `
            auto`,
          templateColumns: `
            1fr 1fr`,
          cardType: ['alternative', 'alternative'],
        };
      case 'xl-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            auto`,
          cardType: ['alternative'],
        };

      // LG
      case 'lg-4':
        return {
          templateArea: `
            "main other1 other2 other3"`,
          templateRows: `
            256px`,
          templateColumns: `
            1fr 1fr 1fr 1fr`,
          cardType: ['photo', 'photo', 'photo', 'photo'],
          textSize: ['small', 'small', 'small', 'small'],
        };
      case 'lg-3':
        return {
          templateArea: `
            "main other1 other2"`,
          templateRows: `
            256px`,
          cardType: ['photo', 'photo', 'photo'],
          textSize: ['small', 'small', 'small'],
        };
      case 'lg-2':
        return {
          templateArea: `
            "main other1"`,
          templateRows: `
            256px`,
          templateColumns: `
            1fr 1fr`,
          cardType: ['photo', 'photo'],
          textSize: ['medium', 'medium'],
        };
      case 'lg-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            auto`,
          templateColumns: `
            1fr`,
          cardType: ['alternative'],
        };

      // MD
      case 'md-4':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"
            "other3"`,
          templateRows: `
            373px auto auto auto`,
          cardType: ['photo-text', 'title', 'title', 'title'],
          textSize: ['small', 'small', 'small', 'small'],
        };
      case 'md-3':
        return {
          templateArea: `
          "main"
          "other1"
          "other2"`,
          templateRows: `
            373px auto auto`,
          cardType: ['photo-text', 'title', 'title'],
        };
      case 'md-2':
        return {
          templateArea: `
            "main"
            "other1"`,
          templateRows: `
            373px auto`,
          cardType: ['photo-text', 'title'],
        };
      case 'md-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            373px`,
          cardType: ['photo-text'],
        };

      // SM
      case 'sm-4':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"
            "other3"`,
          templateRows: `
            235px auto auto auto`,
          cardType: ['photo-text', 'title', 'title', 'title'],
        };
      case 'sm-3':
        return {
          templateArea: `
          "main"
          "other1"
          "other2"`,
          templateRows: `
            235px auto auto`,
          cardType: ['photo-text', 'title', 'title'],
        };
      case 'sm-2':
        return {
          templateArea: `
            "main"
            "other1"`,
          templateRows: `
            235px auto`,
          cardType: ['photo-text', 'title'],
        };
      case 'sm-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            235px`,
          cardType: ['photo-text'],
        };
    }
  };
};

export default useMeshLayout;
