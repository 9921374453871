import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import GMLink from 'components/GMLink';
import useBreakpoint from 'hooks/useBreakpoint';
import { useProfile } from 'context/profile';

interface GMPartnerSidebarProps {
  categoryName?: string;
  categorySlug?: string;
}

const GMPartnerSidebar: FC<GMPartnerSidebarProps> = ({ categoryName, categorySlug }) => {
  const { t, i18n } = useTranslation();
  const breakpoint = useBreakpoint();
  const { userInfo } = useProfile();
  const xl = breakpoint === 'xl';
  const currentLocale = i18n.language;

  return (
    <Box position="relative" w={{ lg: '312px' }} pl="24px" display={{ base: 'none', lg: 'block' }}>
      <Box position="sticky" top="5px">
        <Box pb={{ base: '34px', xl: '32px' }}>
          <Text
            fontSize={{ base: 'md', xl: 'lg' }}
            lineHeight={{ base: '19px', xl: '22px' }}
            mb="16px"
            color="PAGE.SECONDARY_TEXT"
          >
            <Trans
              i18nKey={userInfo ? 'shopping:greenCoinDescriptionAuth' : 'shopping:greenCoinDescription'}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green" />]}
            />
          </Text>
        </Box>
        {categoryName && categorySlug && (
          <Box pb={{ lg: '34px', xl: '32px' }}>
            <Link to={`/${currentLocale}/partners/${categorySlug}`}>
              <GMLink
                text={`${t('shopping:singleShop:backToCategoryLink')} ${categoryName}`}
                size={xl ? 'medium' : 'small'}
              />
            </Link>
          </Box>
        )}
        <Link to={`/${currentLocale}/partners`}>
          <GMLink text={t('shopping:singleShop:allShopsLink')} size={xl ? 'medium' : 'small'} />
        </Link>
      </Box>
    </Box>
  );
};

export default GMPartnerSidebar;
