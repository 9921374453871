import React, { FC, ReactNode } from 'react';
import { Box, Flex } from '@chakra-ui/core';

interface ContainerProps {
  children?: ReactNode;
  backgroundImage?: string;
}

const GMSectionContainer: FC<ContainerProps> = ({ children, backgroundImage }) => {
  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      p={{ base: '16px', lg: '32px 53px' }}
      backgroundImage={backgroundImage}
    >
      <Box maxW={{ sm: '536px', lg: '1224px' }} w="100%" h="100%">
        {children}
      </Box>
    </Flex>
  );
};

export default GMSectionContainer;
