import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import GreenKm from 'assets/svg-components/greenKm';
import Drop from 'assets/svg-components/drop';
import GMLoader from 'components/GMLoader';
import { walletBadge as dimensions } from 'constants/dimensions';
import { palette } from 'theme/theme';

export interface IWalletBadge {
  valute?: 'coin' | 'km';
  amount?: number;
  size?: 'big' | 'small' | 'xsmall';
  centered?: boolean;
  variant?: 'light';
  loading?: boolean;
}

const GMWalletBadge: FC<IWalletBadge> = ({
  loading,
  amount,
  valute = 'km',
  size = 'big',
  centered = false,
  variant,
}) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <Box
      bg={variant === 'light' ? 'WHITE' : 'PRIMARY'}
      borderRadius={dimensions[size]?.borderRadius}
      border="2px solid"
      borderColor="PRIMARY"
      pl="16px"
      pr="8px"
      color={variant === 'light' ? 'PRIMARY' : 'WHITE'}
      fontSize={dimensions[size]?.fontSize}
      fontWeight="bold"
      h={dimensions[size]?.h}
    >
      <Flex
        align="center"
        justify={centered ? 'center' : 'space-between'}
        h="100%"
        lineHeight={dimensions[size]?.lineHeight}
        position="relative"
        minWidth="50px"
      >
        {loading ? (
          <GMLoader size={15} color={valute === 'km' ? palette.MEDIUM_GREEN : palette.BELIZE_HOLE} />
        ) : (
          <Box>{amount?.toLocaleString(currentLocale) ?? 0}</Box>
        )}
        <Box position={centered ? 'absolute' : 'relative'} right="0">
          {valute === 'km' && (
            <GreenKm size={dimensions[size]?.iconSize} variant={variant === 'light' ? 'green' : ''} />
          )}
          {valute === 'coin' && <Drop size={dimensions[size]?.iconSize} variant={variant === 'light' ? 'blue' : ''} />}
        </Box>
      </Flex>
    </Box>
  );
};

export default GMWalletBadge;
