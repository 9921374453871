import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';

interface IGMUserDataItem {
  children?: React.ReactNode;
  label?: string;
}

const GMUserDataItem: FC<IGMUserDataItem> = ({ children, label = '' }) => {
  return (
    <>
      <Box
        color="PAGE.SECONDARY_TEXT"
        fontSize={{ base: 'xs', lg: 'md', xl: 'lg' }}
        pb={{ base: '2px', lg: '8px' }}
        lineHeight={{ base: '15px', lg: '19px', xl: '22px' }}
      >
        {label}
      </Box>
      <Box mb="16px" lineHeight={{ base: '22px', lg: '24px', xl: '27px' }}>
        {children}
      </Box>
    </>
  );
};

export default GMUserDataItem;
