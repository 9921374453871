import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';

interface GMTagProfileProps {
  tagName?: string;
}

const GMTagProfile: FC<GMTagProfileProps> = ({ tagName = '' }) => {
  return (
    <Box
      border="2px solid"
      borderColor="PRIMARY"
      borderRadius="4px"
      p="6px 12px"
      mr="8px"
      mb="8px"
      fontSize={{ base: 'xs', lg: 'md', xl: 'lg' }}
      lineHeight={{ base: '15px', lg: '19px', xl: '22px' }}
      fontWeight="bold"
      color="PRIMARY"
    >
      {tagName}
    </Box>
  );
};

export default GMTagProfile;
