import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
import Select from 'react-select';
import { palette } from 'theme/theme';

interface IGMSelectOptions {
  onSelect?: (value) => void;
  value?: string;
  noValue?: string;
  options: { value: string; label: string }[];
  backgroundColor?: string;
  noEmptyValue?: boolean;
}

const GMSelectOptions: FC<IGMSelectOptions> = ({
  onSelect,
  value,
  noValue,
  options,
  backgroundColor = palette.LIGHT_GREY,
  noEmptyValue = false,
}) => {
  const { t } = useTranslation();

  const onSelectHandler = (option): void => {
    if (onSelect) onSelect(option?.value);
  };
  const selectedOptions = !noEmptyValue
    ? [{ value: '', label: noValue || t('common:select') }, ...options]
    : [...options];

  return (
    <Select
      placeholder={t('common:select')}
      options={selectedOptions}
      styles={{
        control: (base) => ({
          ...base,
          fontSize: '20px',
          border: 'none',
          backgroundColor: backgroundColor,
          boxShadow: 'none',
          padding: 0,
        }),
        option: (base) => ({ ...base, fontSize: '20px' }),
        valueContainer: (base) => ({
          ...base,
          padding: 0,
        }),
      }}
      autoFocus={false}
      value={filter(selectedOptions, (option) => option.value === value)}
      onChange={onSelectHandler}
    />
  );
};

export default GMSelectOptions;
