import React, { FC, ReactNode } from 'react';
import { Button, Flex, Box } from '@chakra-ui/core';
import customTheme from 'theme/theme';

export interface ButtonProps {
  children: ReactNode;
  dense?: boolean;
  uppercase?: boolean;
  variant?: 'gradient' | 'transparent' | 'grey' | 'link';
  onClick?: () => void;
  iconType?: string;
  fontWeight?: string;
  cp?: string;
}

const GMButton: FC<ButtonProps> = ({
  children,
  iconType,
  onClick,
  dense,
  uppercase,
  fontWeight,
  variant = 'gradient',
  cp,
}) => {
  const h = dense ? '32px' : '56px';
  const w = dense ? null : '100%';
  const border = '0px';
  const rounded = '28px';
  const padding = dense && !cp ? '0 16px' : cp || '0 24px';
  const gradient = `linear-gradient(to right, ${customTheme.colors.BUTTON.ENABLED.GRADIENT1} 0%, ${customTheme.colors.BUTTON.ENABLED.GRADIENT2} 100%)`;
  const shadow = dense ? null : `0 6px 10px 0 rgba(0,0,0,.2)`;
  const fontSize = dense ? '16px' : customTheme.fontSizes['2xl'];

  const variants = {
    transparent: {
      fontWeight: 'regular',
      color: 'BUTTON.NOBACKGROUND.TEXT',
      backgroundColor: 'transparent',
      backgroundImage: null,
      boxShadow: null,
    },
    gradient: {
      fontWeight: 'bold',
      color: 'BUTTON.ENABLED.TEXT',
      backgroundColor: null,
      backgroundImage: gradient,
      boxShadow: shadow,
    },
    grey: {
      fontWeight: 'bold',
      color: 'BUTTON.GREY.TEXT',
      backgroundColor: 'BUTTON.GREY.BACKGROUND',
      backgroundImage: null,
      boxShadow: shadow,
    },
    link: {
      fontWeight: 'regular',
      color: 'BUTTON.GREY.TEXT',
      backgroundColor: 'transparent',
      backgroundImage: null,
      boxShadow: null,
    },
  };

  return (
    <Button
      w={w}
      h={h}
      rounded={rounded}
      p={padding}
      color={variants[variant]?.color}
      border={border}
      backgroundColor={variants[variant]?.backgroundColor}
      backgroundImage={variants[variant]?.backgroundImage}
      lineHeight={1}
      fontSize={fontSize}
      fontFamily="body"
      fontWeight={fontWeight || variants[variant]?.fontWeight}
      boxShadow={variants[variant]?.boxShadow}
      onClick={onClick}
      _hover={{
        backgroundColor: variants[variant]?.backgroundColor,
        backgroundImage: variants[variant]?.backgroundImage,
      }}
      _active={{
        backgroundColor: variants[variant]?.backgroundColor,
        backgroundImage: variants[variant]?.backgroundImage,
      }}
    >
      {/* @TODO two icons together */}
      <Flex align={dense ? 'flex-end' : 'center'} justify={iconType ? `space-between` : `center`} w="100%">
        <Box textTransform={uppercase ? 'uppercase' : null}>{children}</Box>
      </Flex>
    </Button>
  );
};

export default GMButton;
