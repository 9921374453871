import { useState, useEffect } from 'react';
import fromPairs from 'lodash/fromPairs';
import throttle from 'lodash/throttle';
import { breakpoints } from 'theme/theme';

const getDeviceConfig = (width: number): string | undefined => {
  const numericBreakpoints = fromPairs(
    Object.entries(breakpoints).map(([key, value]: [any, any]) => [key, parseInt(value.replace('px', ''))]),
  );

  if (width < numericBreakpoints.md) {
    return 'sm';
  } else if (width < numericBreakpoints.lg) {
    return 'md';
  } else if (width < numericBreakpoints.xl) {
    return 'lg';
  } else return 'xl';
};

const useBreakpoint = (): string | undefined => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle((): void => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
