export const tag = {
  small: {
    borderRadius: '16px',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 'bold',
    padding: '7px 12px',
    height: '28px',
  },
  medium: {
    borderRadius: '16px',
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 'bold',
    padding: '8px 12px',
    height: '32px',
  },
  big: {
    borderRadius: '20px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    padding: '10px 13px',
    height: '40px',
  },
};

export const input = {
  small: {
    borderRadius: '20px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 'bold',
    paddingY: '8px',
    paddingX: '16px',
    height: '36px',
  },
  big: {
    borderRadius: '20px',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'normal',
    paddingY: '8px',
    paddingX: '24px',
    height: '40px',
  },
};

export const greenButton = {
  small: {
    fontSize: 'lg',
    height: '48px',
  },
  medium: {
    fontSize: 'xl',
    height: '56px',
  },
  big: {
    fontSize: '22px',
    height: '64px',
  },
};

export const readMoreLink = {
  small: {
    fontSize: 'md',
    lineHeight: '19px',
    paddingBottom: '8px',
  },
  medium: {
    fontSize: 'lg',
    lineHeight: '22px',
    paddingBottom: '8px',
  },
  big: {
    fontSize: 'xl',
    lineHeight: '24px',
    paddingBottom: '16px',
  },
};

export const walletBadge = {
  big: {
    borderRadius: '24px',
    fontSize: '2xl',
    h: '48px',
    lineHeight: '29px',
    iconSize: 44,
  },
  small: {
    borderRadius: '20px',
    fontSize: 'xl',
    h: '36px',
    lineHeight: '24px',
    iconSize: 32,
  },
  xsmall: {
    borderRadius: '16px',
    fontSize: 'md',
    h: '32px',
    lineHeight: '19px',
    iconSize: 30,
  },
};
