import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { MdPersonOutline } from 'react-icons/md';
// import { useAnalytics } from 'use-analytics';
import { Box, Flex } from '@chakra-ui/core';
import { Link as CLink } from '@chakra-ui/core';
import { EventCategory, UseActivityValues } from 'constants/analytics';
import { useProfile } from 'context/profile';
import GMButton from 'components/GMButton';
import GMMenu from 'components/GMMenu';
import GMLanguageSwitcher from 'components/GMLanguageSwitcher';
import GMWalletBadge from 'components/GMWalletBadge';
import GremoboLogo from 'assets/svg-components/logo';
import {
  GoogleIt,
  GoogleEn,
  GoogleDe,
  GoogleEs,
  GoogleFr,
  AppleDe,
  AppleEn,
  AppleEs,
  AppleFr,
  AppleIt,
} from 'assets/svg-components/mobile-badges';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const googleBadges = {
  it: <GoogleIt width="130" height="56" />,
  en: <GoogleEn width="130" height="56" />,
  es: <GoogleEs width="130" height="56" />,
  de: <GoogleDe width="130" height="56" />,
  fr: <GoogleFr width="130" height="56" />,
};

const appleBadges = {
  it: <AppleIt width="115" height="56" />,
  en: <AppleEn width="115" height="56" />,
  es: <AppleEs width="115" height="56" />,
  de: <AppleDe width="115" height="56" />,
  fr: <AppleFr width="115" height="56" />,
};

const GMHeader: FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [keycloak] = useKeycloak();
  // const { track } = useAnalytics();
  const sendDataToGTM = useGTMDispatch();
  const { loadingWallet, userInfo, userBalance } = useProfile();
  const currentLanguage = i18n.language;

  const login = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuLogin, value: EventCategory.UserActivity });

    keycloak.login({ locale: currentLanguage });
  }, [keycloak, currentLanguage]);

  const register = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuRegister, value: EventCategory.UserActivity });

    keycloak.register({ locale: currentLanguage });
  }, [keycloak, currentLanguage]);

  const firstName =
    (userInfo?.myData?.isEnterprise ? userInfo?.myData?.companyName : userInfo?.myData?.firstName) ?? '';

  const getBadgeLang = (): { google: object; apple: object } => {
    return { google: googleBadges[i18n.language], apple: appleBadges[i18n.language] };
  };

  return (
    <Box
      shadow="0 10px 10px 0px rgba(0, 0, 0, 0.15)"
      position={{ base: 'fixed' }}
      w="100%"
      zIndex={{ base: 9999, lg: 99 }}
    >
      <Flex as="nav" align="center" justify="center" wrap="wrap" pt={{ lg: '30px' }} bg="HEADER.BACKGROUND" w="100%">
        <Flex
          w="100%"
          maxW={{ lg: 'calc(1224px + 106px)' }}
          justify="space-between"
          align="flex-end"
          minH="56px"
          p={{ base: '18px 16px', lg: '0px 53px' }}
          mx="auto"
        >
          <Flex
            justify="space-between"
            w={{ base: 120, lg: 200, xl: 220 }}
            transition="width 0.4s"
            pb={{ lg: '16px' }}
            mr={{ lg: '70px', xl: '92px' }}
          >
            <Link to="/">
              <GremoboLogo width="100%" height="100%" />
            </Link>
          </Flex>

          <Box cursor="pointer" color="PRIMARY" mr="40px" display={{ lg: 'none' }}>
            {userInfo ? (
              <Link to="/profile">
                <MdPersonOutline size="20" />
              </Link>
            ) : (
              <MdPersonOutline size="20" onClick={login} />
            )}
          </Box>

          <Box display={{ base: 'none', lg: 'flex' }} alignSelf="flex-end" flexGrow={1}>
            <GMMenu />
          </Box>

          <Box display={{ base: 'none', lg: 'block' }} pb="18px" w="150px">
            <GMLanguageSwitcher />
          </Box>
        </Flex>
      </Flex>

      <Flex
        wrap="wrap"
        bg="LIGHT"
        h={{ sm: '48px', lg: '56px' }}
        shadow="0 10px 10px 0px rgba(0, 0, 0, 0.15)"
        transition="height 0.4s"
        display={{ base: 'none', lg: 'block' }}
      >
        <Flex
          maxW={{ lg: 'calc(1224px + 106px)' }}
          w="100%"
          h="100%"
          justify="space-between"
          align="center"
          p={{ base: '0px 16px', lg: '0px 53px' }}
          mx="auto"
        >
          <Box height="56px">
            <Box display="inline-block" height="56px">
              <CLink
                boxShadow="none"
                href={`https://apps.apple.com/${currentLanguage}/app/gremobo/id1540994263`}
                display="inline-block"
                isExternal
              >
                {getBadgeLang().apple}
              </CLink>
              <CLink
                boxShadow="none"
                href="https://play.google.com/store/apps/details?id=com.gremobo"
                display="inline-block"
                isExternal
              >
                {getBadgeLang().google}
              </CLink>
            </Box>
          </Box>
          <Flex align="center">
            {!userInfo ? (
              <>
                <Box mr="10px">
                  <GMButton variant="transparent" dense onClick={login}>
                    {t('common:login')}
                  </GMButton>
                </Box>
                <Box>
                  <GMButton variant="grey" dense onClick={register}>
                    {t('common:register')}
                  </GMButton>
                </Box>
              </>
            ) : (
              <Flex>
                <Box mr="16px">
                  <GMWalletBadge
                    loading={loadingWallet}
                    amount={userBalance?.km}
                    valute="km"
                    size="xsmall"
                    variant="light"
                  />
                </Box>
                <Box mr="16px">
                  <GMWalletBadge
                    loading={loadingWallet}
                    amount={userBalance?.coins}
                    valute="coin"
                    size="xsmall"
                    variant="light"
                  />
                </Box>
                <GMButton onClick={() => history.push('/profile')} dense>
                  <Flex align="center">
                    <Box pr="5px">
                      <MdPersonOutline size="20" />
                    </Box>
                    <Box pr="5px">{firstName}</Box>
                  </Flex>
                </GMButton>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default GMHeader;
