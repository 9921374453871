import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@chakra-ui/core';
import map from 'lodash/map';
import Mailto from 'react-protected-mailto';
import GMContainer from 'components/GMContainer';

const contacts = [
  {
    description: 'contacts:mobilityDescription',
    email: 'contacts:mobilityEmail',
  },
  {
    description: 'contacts:partnerDescription',
    email: 'contacts:partnerEmail',
  },
  {
    description: 'contacts:donationDescription',
    email: 'contacts:donationEmail',
  },
  {
    description: 'contacts:jobDescription',
    email: 'contacts:jobEmail',
  },
  {
    description: 'contacts:otherDescription',
    email: 'contacts:otherEmail',
  },
];

const Contacts: FC = () => {
  const { t } = useTranslation();

  return (
    <GMContainer>
      <Box maxW="500px" mx="auto" py={50}>
        <Box textAlign="center" fontSize={{ base: 'lg', lg: 'xl' }}>
          {map(contacts, (contact, index) => (
            <Box key={Number(index)}>
              <Box px="16px">
                {t(contact?.description)}
                <Box color="PRIMARY" fontSize={{ base: 'xl', lg: '2xl' }}>
                  <Mailto email={t(contact?.email)} />
                </Box>
              </Box>

              {index + 1 < contacts.length && <Divider my="40px" />}
            </Box>
          ))}
        </Box>
      </Box>
    </GMContainer>
  );
};

export default Contacts;
