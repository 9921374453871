import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@chakra-ui/core';
import SidebarSearchArticles from './articles';
import SidebarSearchStores from './stores';
import SidebarSearchPromos from './promos';

interface IGMSearchSidebar {
  children?: ReactNode;
  activeFilters?: IFilters;
  onSelectDateFilter?: (newValue: string) => void;
  onChangeReadedHandler?: (newValue: string) => void;
  onChangeDeliveryFilter?: (newValue: string) => void;
  onChangeCountry?: (newValue: string) => void;
  onChangePriceFilter?: (newValue: string) => void;
  context?: 'partners' | 'articles' | 'promos' | 'donations';
  countries?: { name: string; id: string }[];
}

interface IFilters {
  byRead?: boolean;
  byDate?: string;
  byCategory?: string[];
  byQuery?: string;
  byGreenDelivery?: boolean;
  byCountry?: string;
  byPrice?: number;
}

const GMSearchSidebar: FC<IGMSearchSidebar> = ({
  activeFilters,
  onSelectDateFilter,
  onChangeCountry,
  onChangeReadedHandler,
  onChangeDeliveryFilter,
  onChangePriceFilter,
  context,
  countries,
}) => {
  const { t } = useTranslation();
  return (
    <Box position="relative" w={{ lg: '234px', xl: '312px' }} pl={{ lg: '32px', xl: '24px' }}>
      <Box position="sticky" top="5">
        <Box
          fontSize={{ lg: 'md', xl: 'lg' }}
          lineHeight={{ base: '19px', xl: '22px' }}
          overflow="hidden"
          color="PAGE.SECONDARY_TEXT"
        >
          {t('search:filterTitle')}
        </Box>

        <Divider my="16px" />

        {context === 'articles' && (
          <SidebarSearchArticles
            activeFilters={activeFilters}
            onChangeReadedHandler={onChangeReadedHandler}
            onSelectDateFilter={onSelectDateFilter}
          />
        )}

        {context === 'partners' && (
          <SidebarSearchStores
            countries={countries}
            activeFilters={activeFilters}
            onChangeDeliveryFilter={onChangeDeliveryFilter}
            onChangeCountry={onChangeCountry}
          />
        )}

        {(context === 'promos' || context === 'donations') && (
          <SidebarSearchPromos
            activeFilters={activeFilters}
            onChangePriceFilter={onChangePriceFilter}
            context={context}
            onChangeCountry={onChangeCountry}
            countries={countries}
          />
        )}
      </Box>
    </Box>
  );
};

export default GMSearchSidebar;
