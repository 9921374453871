import * as React from 'react';
import { FC, memo } from 'react';
// import { useLocation } from 'react-router-dom';
import GMPDFViewer from 'components/GMPDFViewer';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

const LegalPage: FC<{
  filename: React.ReactNode;
}> = ({ filename }) => {
  // const location = useLocation();
  // const pathname = location?.pathname;

  return <GMPDFViewer pdf={filename} />;

  // switch (true) {
  //   case pathname.includes('/it/terms-and-conditions-private'):
  //     return <GMPDFViewer pdf={TSPrivateIT} />;
  //   case pathname.includes('/it/terms-and-conditions-enterprise'):
  //     return <GMPDFViewer pdf={TSEnterpriseIT} />;
  //   case pathname.includes('/it/privacy-policy'):
  //     return <GMPDFViewer pdf={PrivacyPolicyIT} />;
  //   case pathname.includes('/it/cookie-policy'):
  //     return <GMPDFViewer pdf={CookiePolicyIT} />;
  //   default:
  //     return <></>;
  // }
};

export default memo(LegalPage);
