import React, { FC, ReactNode } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/core';

interface GMModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const GMModal: FC<GMModalProps> = ({ children, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', md: 'md', lg: 'lg' }}>
      <ModalOverlay />
      <ModalContent
        px={{ base: '0px', md: '32px' }}
        py="32px"
        mt={{ base: '3.5rem', md: '3.75rem' }}
        mb={{ base: '0', lg: '3.75rem' }}
      >
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GMModal;
