import React, { FC, Suspense, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
import { ThemeProvider, CSSReset, Flex } from '@chakra-ui/core';
import { I18nextProvider } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from 'i18n';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ApolloProvider } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import AppChildren from 'AppChildren';
import { customApolloClient } from 'apollo';
import { AppContextProvider } from 'context';
import { UserProvider } from 'context/profile';
import GMLoader from 'components/GMLoader';
import customTheme from 'theme/theme';

import ISOCountries from 'i18n-iso-countries';
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/de.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/es.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/it.json'));

const App: FC = () => {
  const [keycloak, initialized] = useKeycloak();
  const font = new FontFaceObserver('Lato');
  const html = document.documentElement;
  // const { init, UseGTMHookProvider } = useGTM();
  const gtmParams = { id: 'GTM-PR2P3KJ' };

  useEffect(() => {
    html.classList.add('fonts-loading');

    font
      .load()
      .then(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-loaded');
      })
      .catch(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-failed');
      });
  });

  const client = customApolloClient({ token: keycloak?.token });

  if (!initialized) {
    return (
      <Flex alignItems="center" h="100%">
        <GMLoader size={40} />
      </Flex>
    );
  }

  return (
    <GTMProvider state={gtmParams}>
      <Suspense fallback={null}>
        <ThemeProvider theme={customTheme}>
          <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
              <AppContextProvider>
                <Helmet titleTemplate={`Gremobo | %s`} defaultTitle="Gremobo" />
                <CSSReset />
                <UserProvider>
                  <AppChildren />
                </UserProvider>
              </AppContextProvider>
            </I18nextProvider>
          </ApolloProvider>
        </ThemeProvider>
      </Suspense>
    </GTMProvider>
  );
};

export default App;
