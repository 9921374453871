import React, { FC, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/core';
import { useProfile } from 'context/profile';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { PrivateRoute } from 'routes/privateRoute';
import GMHeader from 'components/GMHeader';
import GMHamburgerMenu from 'components/GMHamburgerMenu';
import GMFooter from 'components/GMFooter';
import GMLoader from 'components/GMLoader';
import GMUpButtonWrapper from 'components/GMUpButtonWrapper';
import Article from 'pages/Article';
import DonationHome from 'pages/DonationHome';
import DonationCategory from 'pages/DonationCategory';
import Category from 'pages/Category';
import Contacts from 'pages/Contacts';
import InfoPage from 'pages/Info';
import Home from 'pages/Homepage';
import Lifestyle from 'pages/Lifestyle';
import Mobility from 'pages/Mobility';
import MobilityEnterprise from 'pages/MobilityEnterprise';
import MobilityEnterpriseAddNew from 'pages/MobilityEnterpriseAddNew';
import MobilityEnterpriseUpdateStats from 'pages/MobilityEnterpriseUpdateStats';
import Partners from 'pages/Partners';
import Partner from 'pages/Partner';
import PromoHome from 'pages/PromoHome';
import PromoCategory from 'pages/PromoCategory';
import Profile from 'pages/Profile';
import SearchPosts from 'pages/Search/articles';
import SearchStores from 'pages/Search/stores';
import SearchPromos from 'pages/Search/promos';
import SearchDonations from 'pages/Search/donations';
import Shopping from 'pages/Shopping';
import SinglePromo from 'pages/SinglePromo';
import SingleDonation from 'pages/SingleDonation';
import Transactions from 'pages/Transactions';
import TransactionSummaryPromo from 'pages/TransactionSummaryPromo';
import TransactionSummaryDonation from 'pages/TransactionSummaryDonation';
import FAQPage from 'pages/FAQ';
import LegalPage from 'pages/Legal';
import { trackPage } from 'utils/analytics';
import { palette } from 'theme/theme';

import CookiePolicyIT from 'assets/pdfs/it/GREMOBO_Cookie_Policy_IT.pdf';
import PrivacyPolicyIT from 'assets/pdfs/it/GREMOBO_Privacy_Policy_IT.pdf';
import TSEnterpriseIT from 'assets/pdfs/it/GREMOBO_Termini_condizioni_enterprise_IT.pdf';
import TSPrivateIT from 'assets/pdfs/it/GREMOBO_Termini_condizioni_private_IT.pdf';

import CookiePolicyEN from 'assets/pdfs/en/GREMOBO_Cookie_Policy_EN.pdf';
import PrivacyPolicyEN from 'assets/pdfs/en/GREMOBO_Privacy_Policy_EN.pdf';
import TSEnterpriseEN from 'assets/pdfs/en/GREMOBO_Enterprise_EN.pdf';
import TSPrivateEN from 'assets/pdfs/en/GREMOBO_Private_Users_EN.pdf';

import CookiePolicyDE from 'assets/pdfs/en/GREMOBO_Cookie_Policy_EN.pdf';
import PrivacyPolicyDE from 'assets/pdfs/en/GREMOBO_Privacy_Policy_EN.pdf';
import TSEnterpriseDE from 'assets/pdfs/en/GREMOBO_Enterprise_EN.pdf';
import TSPrivateDE from 'assets/pdfs/en/GREMOBO_Private_Users_EN.pdf';

import CookiePolicyES from 'assets/pdfs/en/GREMOBO_Cookie_Policy_EN.pdf';
import PrivacyPolicyES from 'assets/pdfs/en/GREMOBO_Privacy_Policy_EN.pdf';
import TSEnterpriseES from 'assets/pdfs/en/GREMOBO_Enterprise_EN.pdf';
import TSPrivateES from 'assets/pdfs/en/GREMOBO_Private_Users_EN.pdf';

import CookiePolicyFR from 'assets/pdfs/en/GREMOBO_Cookie_Policy_EN.pdf';
import PrivacyPolicyFR from 'assets/pdfs/en/GREMOBO_Privacy_Policy_EN.pdf';
import TSEnterpriseFR from 'assets/pdfs/en/GREMOBO_Enterprise_EN.pdf';
import TSPrivateFR from 'assets/pdfs/en/GREMOBO_Private_Users_EN.pdf';

const legalDocs = {
  cookiePolicy: {
    de: CookiePolicyDE,
    en: CookiePolicyEN,
    es: CookiePolicyES,
    fr: CookiePolicyFR,
    it: CookiePolicyIT,
  },
  privacyPolicy: {
    de: PrivacyPolicyDE,
    en: PrivacyPolicyEN,
    es: PrivacyPolicyES,
    fr: PrivacyPolicyFR,
    it: PrivacyPolicyIT,
  },
  tsEnterprise: {
    de: TSEnterpriseDE,
    en: TSEnterpriseEN,
    es: TSEnterpriseES,
    fr: TSEnterpriseFR,
    it: TSEnterpriseIT,
  },
  tsPrivate: {
    de: TSPrivateDE,
    en: TSPrivateEN,
    es: TSPrivateES,
    fr: TSPrivateFR,
    it: TSPrivateIT,
  },
};

const AppChildren: FC = () => {
  trackPage();

  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { userInfo, loadingInfo, refetchWallet } = useProfile();
  const { pathname, hash } = useLocation();
  const { keycloak } = useKeycloak();
  const currentLanguage = i18n.language;
  const isAuthenticated = keycloak?.authenticated;

  const redirectToProfile = (): void => {
    const redirectPath = '/profile';
    history?.location?.pathname !== redirectPath && history.push({ pathname: redirectPath });
  };

  useEffect(() => {
    const profileCompleted = userInfo?.myData?.createdAt;
    if (userInfo && !profileCompleted) redirectToProfile();
  }, [userInfo, history.location]);

  useEffect(() => {
    // if no hash found scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        console.log('id: ', id);

        const element = document.getElementById(id);
        console.log('element: ', element);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); // 🌈🌈🌈 magic scroll
        }
      }, 0);
    }
  }, [pathname]); // do this on route change

  useEffect(() => {
    if (isAuthenticated && pathname) {
      refetchWallet();
    }
  }, [isAuthenticated, pathname]);

  if (loadingInfo) {
    return (
      <Flex alignItems="center" h="100%">
        <GMLoader size={40} />
      </Flex>
    );
  }

  return (
    <>
      <GMHeader />
      <GMHamburgerMenu />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/lifestyle" component={Lifestyle} />
        <Route path="/:lang/article/:slug" component={Article} />
        <Route path="/:lang/category/:id?" component={Category} />
        <Route path="/search/articles" component={SearchPosts} />
        <Route path="/search/partners" component={SearchStores} />
        <Route path="/search/promos" component={SearchPromos} />
        <Route path="/search/donations" component={SearchDonations} />
        <Route path="/shopping" component={Shopping} />
        <Route path="/:lang/partners/:slug?" component={Partners} />
        <Route path="/:lang/partner/:slug" component={Partner} />
        <Route exact path="/mobility" component={Mobility} />
        <PrivateRoute exact path="/mobility/enterprise" component={MobilityEnterprise} />
        <PrivateRoute exact path="/mobility/enterprise/add-new-vehicle" component={MobilityEnterpriseAddNew} />
        <PrivateRoute exact path="/mobility/enterprise/update-statistics" component={MobilityEnterpriseUpdateStats} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/profile/transactions" component={Transactions} />
        <PrivateRoute path="/profile/transactions/promo/:id" component={TransactionSummaryPromo} />
        <PrivateRoute path="/profile/transactions/donation/:id" component={TransactionSummaryDonation} />
        <Route path="/promos" component={PromoHome} />
        <Route path="/donations" component={DonationHome} />
        <Route path="/:lang/donations/:slug?" component={DonationCategory} />
        <Route path="/:lang/donation/:slug?" component={SingleDonation} />
        <Route path="/:lang/promos/:slug?" component={PromoCategory} />
        <Route path="/:lang/promo/:slug?" component={SinglePromo} />
        <Route path="/contacts" component={Contacts} />
        <Route
          path="/about-us"
          render={() =>
            (window.location.href = `https://www.gremove.com/team?lang=${currentLanguage === 'it' ? 'it' : 'en'}`)
          }
        />
        <Route path="/faq" component={FAQPage} />
        <Route
          path="/terms-and-conditions-private"
          component={() => <LegalPage filename={legalDocs.tsPrivate[currentLanguage]} />}
        />
        <Route
          path="/terms-and-conditions-enterprise"
          component={() => <LegalPage filename={legalDocs.tsEnterprise[currentLanguage]} />}
        />
        <Route
          path="/privacy-policy"
          component={() => <LegalPage filename={legalDocs.privacyPolicy[currentLanguage]} />}
        />
        <Route
          path="/cookie-policy"
          component={() => <LegalPage filename={legalDocs.cookiePolicy[currentLanguage]} />}
        />
        {/* <Route path="/:lang/privacy-policy" component={LegalPage} />
        <Route path="/:lang/cookie-policy" component={LegalPage} /> */}
        <PrivateRoute path="/info" component={InfoPage} />
        <Route path="/*" component={Home} />
      </Switch>

      <GMUpButtonWrapper />

      <CookieConsent
        location="bottom"
        buttonText={t('cookie:Understand')}
        cookieName="generalCookieAdvertise"
        style={{ background: '#2B373B' }}
        buttonStyle={{ backgroundColor: palette.MEDIUM_GREEN, color: palette.WHITE, fontSize: '13px' }}
        expires={150}
      >
        {t('cookie:generalCookieDescription')}
      </CookieConsent>
      <GMFooter />
    </>
  );
};

export default AppChildren;
