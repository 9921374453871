import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/core';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  EmailIcon,
} from 'react-share';
import isEmpty from 'lodash/isEmpty';
import GMPhoto from 'components/GMPhoto';
import GMTitleLink from 'components/GMTitleLink';
import useBreakpoint from 'hooks/useBreakpoint';
import Post from 'interfaces/Post';

interface IGMSingleArticleSideBar {
  abstract?: string;
  relatedArticles: Post[];
  title: string;
  slug: string;
}
interface IRelatedArticles {
  relatedArticles: Post[];
}

const RelatedArticles: FC<IRelatedArticles> = ({ relatedArticles }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <>
      <Text
        fontSize={{ base: 'md', xl: 'lg' }}
        lineHeight={{ base: '19px', xl: '22px' }}
        mb="16px"
        color="PAGE.SECONDARY_TEXT"
      >
        {t('singleArticle:relatedArticles')}
      </Text>
      {relatedArticles.map((relatedArticle) => {
        const titleSlug = relatedArticle.slug;
        return (
          <Box pb="24px" key={relatedArticle.id}>
            <Link to={`/${currentLocale}/article/${titleSlug}`}>
              <Box display={{ lg: 'block', xl: 'none' }}>
                <GMTitleLink
                  text={relatedArticle.title}
                  borderColor="SINGLE_ARTICLE.RELATED_ARTICLE_BORDER"
                  fontWeight="bold"
                />
              </Box>
              <Box display={{ lg: 'none', xl: 'block' }}>
                <GMPhoto
                  w="288px"
                  h="144px"
                  imageUrl={
                    relatedArticle?.landscapeBannerId
                      ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${relatedArticle.landscapeBannerId}`
                      : ''
                  }
                  thumbnailImageUrl={
                    relatedArticle?.landscapeBannerId
                      ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${relatedArticle.landscapeBannerId}`
                      : ''
                  }
                  text={relatedArticle.title}
                  textSize="xsmall"
                />
              </Box>
            </Link>
          </Box>
        );
      })}
    </>
  );
};
const GMSingleArticleSideBar: FC<IGMSingleArticleSideBar> = ({ relatedArticles, title, slug }) => {
  const { t, i18n } = useTranslation();
  const breakpoint = useBreakpoint();

  const currentLocale = i18n.language;
  const shareUrl = `${process.env.REACT_APP_BASE_WEBSITE}/${currentLocale}/article/${slug}`;
  const socialButtonWH = breakpoint === 'lg' ? '32px' : '40px';
  const socialButtonSize = breakpoint === 'lg' ? 32 : 40;

  return (
    <Box position="relative" w={{ lg: '312px' }} pl="24px" display={{ base: 'none', lg: 'block' }}>
      <Box position="sticky" top="5">
        <Box pb={{ base: '24px', xl: '40px' }}>
          <Text
            fontSize={{ base: 'md', xl: 'lg' }}
            lineHeight={{ base: '19px', xl: '22px' }}
            mb="16px"
            color="PAGE.SECONDARY_TEXT"
          >
            {t('singleArticle:share')}
          </Text>
          <Flex>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={socialButtonSize} round />
              </FacebookShareButton>
            </Box>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={socialButtonSize} round />
              </TwitterShareButton>
            </Box>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={socialButtonSize} round />
              </TelegramShareButton>
            </Box>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                <WhatsappIcon size={socialButtonSize} round />
              </WhatsappShareButton>
            </Box>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <LinkedinShareButton url={shareUrl}>
                <LinkedinIcon size={socialButtonSize} round />
              </LinkedinShareButton>
            </Box>
            <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" mr="9px">
              {/*
              // @ts-ignore: https://github.com/nygardk/react-share/issues/277 */}
              <EmailShareButton url={shareUrl} subject={title} body="body">
                <EmailIcon size={socialButtonSize} round />
              </EmailShareButton>
            </Box>
          </Flex>
        </Box>
        {!isEmpty(relatedArticles) ? (
          <Box pb="40px">
            <RelatedArticles relatedArticles={relatedArticles} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default GMSingleArticleSideBar;
