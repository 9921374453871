import { theme } from '@chakra-ui/core';

// Use https://openchakra.app/ to make experiments with ChakraUI components and props

interface BreakpointsProps {
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  0: string;
  1: string;
  2: string;
  3: string;
}

// ['360px', '768px', '1024px', '1366px'];

// Custom breakpoints...Remember to check both arrays for key: value
// Any is unavoidable 🤯
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const breakpoints: any = ['360px', '768px', '1024px', '1366px'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const palette = {
  // ZEPLIN COLORS
  BLACK: '#000',
  DARK_GREEN: '#4c5541',
  DARK_GREY: '#666666',
  BELIZE_HOLE: '#2980b9',
  LIGHTEST_GRADIENT_GREY: '#fefefe',
  LIGHTEST_GREY: 'rgb(240, 240, 246)',
  LIGHT_GRADIENT_GREY: '#f5f5f5',
  LIGHT_GRADIENT_WHITE: '#fff',
  LIGHT_GREEN: '#d5d012',
  LIGHT_GREY: '#e2ebef',
  MEDIUM_GREEN: '#679136',
  MEDIUM_GREY: '#a4b3ae',
  RED: '#e83e00',
  SHADOW_OPACITY: 'rgba(0, 0, 0, 0.4)',
  SHADOW_OPACITY_WHITE: 'rgba(255, 255, 255, 0.7)',
  SMOKED_GREY: '#95adb8',
  SUPER_LIGHT_GRAY: '#f9f9f9',
  TRANSPARENT: '#00000000',
  TEXT_SHADOW: '#4c5540',
  VERMILLION: '#E83E1A',
  WHITE: '#fff',
  YELLOW: '#f8cb2e',

  // Categories colors
  SDG1: '#e7000e',
  SDG2: '#d8a700',
  SDG3: '#009735',
  SDG4: '#c90822',
  SDG5: '#e93600',
  SDG6: '#00a5db',
  SDG7: '#fabe00',
  SDG8: '#98052f',
  SDG9: '#ef6a00',
  SDG10: '#df007a',
  SDG11: '#f6a200',
  SDG12: '#d49200',
  SDG13: '#3f7a30',
  SDG14: '#0074bd',
  SDG15: '#1aa931',
  SDG16: '#004a89',
  SDG17: '#002e69',
};

export const gradients = {
  PHOTO_GRADIENT: 'linear-gradient(180deg, #00000000 0%, #00000000 50%, #000000b3 100%);',
  GREEN_GRADIENT: `linear-gradient(125deg, ${palette.MEDIUM_GREEN} 0%, ${palette.LIGHT_GREEN} 100%)`,
  OVERFLOW_SHADOW_LEFT: `linear-gradient(to left,#0000 0%,rgba(112, 112, 112, 0.15) 100%)`,
  OVERFLOW_SHADOW_RIGHT: `linear-gradient(to right,#0000 0%,rgba(112, 112, 112, 0.15) 100%)`,
};

// Let's say you want to add custom colors

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,

    PRIMARY: palette.MEDIUM_GREEN,
    SECONDARY: palette.DARK_GREEN,
    CONTRAST: palette.DARK_GREY,
    LIGHT: palette.LIGHT_GREY,
    SUPER_LIGHT_GRAY: palette.SUPER_LIGHT_GRAY,
    BAR_COLOR: palette.DARK_GREY,
    ERROR: palette.RED,
    PROFILE: {
      TRANSACTION: {
        CANCELED_LABEL: palette.RED,
        PENDING_LABEL: palette.YELLOW,
        CONFIRMED_LABEL: palette.MEDIUM_GREEN,
      },
    },
    HEADER: {
      BACKGROUND: palette.WHITE,
      HEADER_TEXT: palette.DARK_GREEN,
      BACK_TEXT: palette.DARK_GREY,
      SHADOW: palette.SHADOW_OPACITY,
      ITEM_NOT_SELECTED: palette.MEDIUM_GREY,
      ITEM_SELECTED: palette.MEDIUM_GREEN,
      BUTTON: {
        GRADIENT1: palette.LIGHT_GREY,
        GRADIENT2: palette.LIGHT_GRADIENT_WHITE,
        GRADIENT3: palette.LIGHTEST_GRADIENT_GREY,
      },
    },
    FOOTER: {
      BAR: palette.LIGHT_GREEN,
    },
    BOTTOM_BAR: {
      ICON_COLOR_NOT_ACTIVE: palette.SMOKED_GREY,
      ICON_COLOR_ACTIVE: palette.WHITE,
      BIG_ICON_COLOR_NOT_ACTIVE: palette.SMOKED_GREY,
      BIG_ICON_COLOR_ACTIVE: palette.WHITE,
    },
    TEXT: {
      PRIMARY: palette.DARK_GREEN,
      SECONDARY: palette.MEDIUM_GREEN,
      SHADOW: palette.SHADOW_OPACITY,
      DATE: palette.DARK_GREEN,
      TAG: palette.MEDIUM_GREEN,
      ABSTRACT_BACKGROUND: palette.LIGHT_GREY,
      ABSTRACT: palette.DARK_GREEN,
      POST_CONTENT: palette.DARK_GREEN,
      ARTICLE_TITLE_BACKGROUND: palette.LIGHT_GREY,
    },
    PAGE: {
      TITLE_DARK: palette.MEDIUM_GREEN,
      TITLE_LIGHT: palette.WHITE,
      BACKGROUND: palette.LIGHTEST_GREY,
      TITLE_BACKGROUND: palette.SUPER_LIGHT_GRAY,
      SECONDARY_TEXT: palette.MEDIUM_GREY,
      ABSTRACT_BORDER: palette.LIGHT_GREY,
    },
    SEARCH: {
      PHOTO_BORDER: palette.MEDIUM_GREEN,
      DEFAULT_CATEGORY_COLOR: palette.MEDIUM_GREEN,
    },
    SINGLE_ARTICLE: {
      TITLE: palette.DARK_GREEN,
      RELATED_POST_BACKGROUND: palette.SUPER_LIGHT_GRAY,
      FOOTER_BACKGROUND: palette.SUPER_LIGHT_GRAY,
      TAGS_TEXT: palette.MEDIUM_GREEN,
      RELATED_ARTICLE_TITLE: palette.DARK_GREEN,
      RELATED_ARTICLE_BORDER: palette.MEDIUM_GREEN,
    },
    ARTICLE_CARD: {
      DATE: palette.DARK_GREEN,
      DATE_LIGHT: palette.MEDIUM_GREY,
      CATEGORY: palette.MEDIUM_GREEN,
      ABSTRACT: palette.DARK_GREY,
      TITLE: palette.DARK_GREEN,
      READ_MORE: palette.MEDIUM_GREEN,
      ONE_ARTICLE_TITLE: palette.DARK_GREEN,
      SECONDARY_TEXT: palette.MEDIUM_GREY,
      ONE_ARTICLE_HEAD_BACKGROUD: palette.LIGHT_GREY,
      ONE_ARTICLE_HEAD_TITLE: palette.DARK_GREEN,
    },
    CATEGORY: {
      DEFAULT_COLOR: palette.MEDIUM_GREEN,
    },
    BUTTON: {
      DISABLED: {
        BACKGROUND: palette.LIGHT_GREY,
        TEXT: palette.MEDIUM_GREY,
        BORDER: palette.MEDIUM_GREY,
      },
      ENABLED: {
        GRADIENT1: palette.MEDIUM_GREEN,
        GRADIENT2: palette.LIGHT_GREEN,
        TEXT: palette.WHITE,
      },
      NOBACKGROUND: {
        TEXT: palette.MEDIUM_GREY,
      },
      GREY: {
        BACKGROUND: palette.MEDIUM_GREY,
        TEXT: palette.WHITE,
      },
      UP: {
        BACKGROUND: palette.LIGHT_GREEN,
      },
      GREEN: {
        BORDER: {
          COLOR: palette.MEDIUM_GREEN,
        },
        BACKGROUND: palette.MEDIUM_GREEN,
      },
      GREEN_OUTLINED: {
        BACKGROUND: palette.TRANSPARENT,
        BORDER: {
          COLOR: palette.WHITE,
        },
        COLOR: palette.WHITE,
      },
      RED_OUTLINED: {
        BACKGROUND: palette.TRANSPARENT,
        BORDER: {
          COLOR: palette.RED,
        },
        COLOR: palette.RED,
      },
    },
    READ_ALL: {
      DEFAULT_COLOR: palette.MEDIUM_GREEN,
    },
    TITLE_LINK: {
      DARK_TEXT: palette.DARK_GREEN,
      LIGHT_TEXT: palette.WHITE,
      DEFAULT_BORDER_COLOR: palette.MEDIUM_GREEN,
    },
    TAG: {
      BACKGROUND: palette.WHITE,
      BACKGROUND_ACTIVE: palette.MEDIUM_GREEN,
      BORDER: palette.MEDIUM_GREY,
      BORDER_ACTIVE: palette.MEDIUM_GREEN,
      TEXT: palette.MEDIUM_GREY,
      TEXT_ACTIVE: palette.WHITE,
    },
    INPUT: {
      STANDARD: {
        TEXT: palette.DARK_GREEN,
        BORDER: palette.MEDIUM_GREY,
        ICON: palette.DARK_GREEN,
      },
      LIGHT: {
        PLACEHOLDER: palette.MEDIUM_GREY,
        ICON: palette.MEDIUM_GREY,
      },
    },
    ICON: {
      GREEN_DELIVERY: {
        BACKGROUND: palette.MEDIUM_GREEN,
        CONTENT: palette.WHITE,
      },
      DELETE_DARK: {
        BACKGROUND: palette.MEDIUM_GREY,
        COLOR: palette.WHITE,
      },
      DELETE_LIGHT: {
        BACKGROUND: palette.LIGHT_GREY,
        COLOR: palette.MEDIUM_GREY,
      },
    },
  },
  breakpoints,
  fonts: {
    heading: 'Lato, sans-serif',
    body: 'Lato, sans-serif',
    mono: 'Menlo, monospace',
    content: 'Source Serif Pro, sans-serif',
  },
  fontWeights: {
    ...theme.fontWeights,
    thin: 100,
    light: 300,
    normal: 400,
    bold: 700,
    black: 900,
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
  },
  shadows: {
    sm: `none`,
    md: `none`,
    lg: `none`,
    xl: `none`,
    '2xl': `none`,
    outline: `0px 1px 0px ${palette.TEXT_SHADOW}`,
    inner: `none`,
    none: `none`,
  },
};

export { breakpoints };
export default customTheme;
