import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

interface IGMTransactionStateLabel {
  state: string;
}

const GMTransactionStateLabel: FC<IGMTransactionStateLabel> = ({ state }) => {
  const { t } = useTranslation();

  const label = {
    PENDING: {
      color: 'PROFILE.TRANSACTION.PENDING_LABEL',
      label: 'profile:transactions:pending',
    },
    VOID: {
      color: 'PROFILE.TRANSACTION.CANCELED_LABEL',
      label: 'profile:transactions:canceled',
    },
    CONFIRMED: {
      color: 'PROFILE.TRANSACTION.CONFIRMED_LABEL',
      label: 'profile:transactions:completed',
    },
  };
  return (
    <Box
      fontSize={{ base: '10px', lg: 'xs', xl: 'sm' }}
      lineHeight={{ base: '12px', lg: '15px', xl: '17px' }}
      fontWeight="bold"
      bg={label[state]?.color}
      p="4px 10px"
      color="white"
      whiteSpace="nowrap"
      textTransform="uppercase"
      borderRadius="4px"
      textAlign="center"
    >
      {t(label[state]?.label)}
    </Box>
  );
};

export default GMTransactionStateLabel;
