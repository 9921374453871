import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Flex } from '@chakra-ui/core';
import { KeycloakProvider } from '@react-keycloak/web';
import GMLoader from 'components/GMLoader';
import ScrollToTop from 'components/ScrollToTopOnMount';
import App from './App';
import * as serviceWorker from './serviceWorker';
import keycloak from './keycloak';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <KeycloakProvider
      LoadingComponent={
        <Flex alignItems="center" h="100%">
          <GMLoader size={40} />
        </Flex>
      }
      keycloak={keycloak}
      autoRefreshToken
    >
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </KeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
