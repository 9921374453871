import React, { FC, useEffect } from 'react';
import { Box, Button, Flex } from '@chakra-ui/core';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { MdArrowForward } from 'react-icons/md';
import GMSinglePromoContentDescription from 'components/GMSinglePromoContentDescription';
import GMTransactionStateLabel from 'components/GMTransactionStateLabel';
import GreenKm from 'assets/svg-components/greenKm';
import { renderers } from 'renderers';
import { convertToLink, parseUnderline } from 'utils';
import { palette } from 'theme/theme';

interface IGMPromoSummary {
  name?: string;
  subscribedOn?: string;
  price?: number;
  instructions?: string;
  details?: string;
  promoCode: string;
  promoCodeType?: string;
  state?: string;
  variant?: 'modal';
}

interface IBadge {
  promoCodeType?: string;
  promoCode?: string;
  state?: string;
}

const PromoAction: FC<IBadge> = ({ promoCodeType, promoCode }) => {
  const codeLabel = {
    link: 'promos:singlePromo:summary:link',
    code: 'promos:singlePromo:summary:couponCode',
    codes: 'promos:singlePromo:summary:couponCode',
  };

  const { t } = useTranslation();

  return (
    <Box bg="LIGHT" borderRadius="4px" color="SECONDARY" padding="10px 20px" mt="16px">
      <Flex align="center" justify="space-between">
        <Box>
          <Box fontSize="22px" fontWeight="bold" lineHeight="27px">
            {promoCodeType === 'link' ? (
              <>
                {promoCode && (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      // window.location.href = convertToLink(promoCode);
                      window.open(convertToLink(promoCode), '_blank');
                    }}
                    backgroundColor={palette.LIGHT_GREY}
                    _hover={{
                      backgroundColor: palette.LIGHT_GREY,
                    }}
                  >
                    {t('promos:singlePromo:YourCouponToActivate')}
                    {/* {`${promoCode?.slice(0, 30)}.....`} */}
                  </Button>
                )}
              </>
            ) : (
              <>
                <Box fontSize="md" lineHeight="19px">
                  {promoCodeType && t(codeLabel[promoCodeType])}
                </Box>
                {promoCode}
              </>
            )}
          </Box>
        </Box>
        {promoCodeType === 'link' && <MdArrowForward size={30} />}
      </Flex>
    </Box>
  );
};

const GMPromoSummary: FC<IGMPromoSummary> = ({
  name,
  subscribedOn,
  price,
  instructions,
  // details,
  promoCode,
  promoCodeType,
  state,
  variant,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  useEffect(() => {
    moment.locale(currentLocale);
  });

  return (
    <>
      <Box>
        <Box
          bg="SUPER_LIGHT_GRAY"
          border="1px solid"
          borderColor="PAGE.ABSTRACT_BORDER"
          p="16px"
          mb={{ base: '24px', lg: '32px' }}
        >
          <Flex align="flex-start">
            <Box fontSize={{ base: 'xl', lg: '2xl', xl: '28px' }} fontWeight="bold" color="SECONDARY" mr="auto">
              {name}
            </Box>
            <Box ml="10px">
              {state && <GMTransactionStateLabel state={state} />}
              <Box
                borderRadius="4px"
                fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                lineHeight={{ base: '16px', lg: '16px', xl: '22px' }}
                bg="LIGHT"
                color="SECONDARY"
                fontWeight="bold"
                px="10px"
                py={{ base: '0px', lg: '2px', xl: '2px' }}
                mt="8px"
              >
                <Flex align="center" justify="center">
                  <Box>-{price}</Box> <GreenKm size={20} variant="darkGreen" />
                </Flex>
              </Box>
            </Box>
          </Flex>
          <Box fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }} color="PAGE.SECONDARY_TEXT">
            {t('promos:singlePromo:summary:activatedOn')} {moment(subscribedOn).format('DD MMM YYYY')}
          </Box>

          {promoCodeType === 'link' ? (
            // <a href={promoCode} style={{ wordBreak: 'break-all' }} target="_blank" rel="noreferrer">
            <PromoAction promoCode={promoCode} promoCodeType={promoCodeType} />
          ) : (
            // </a>
            <PromoAction promoCode={promoCode} promoCodeType={promoCodeType} />
          )}
        </Box>
      </Box>

      <Box px={{ base: variant === 'modal' ? '0px' : '16px', lg: '0' }}>
        <GMSinglePromoContentDescription title={t('promos:singlePromo:instructionsTitle')}>
          <ReactMarkdown escapeHtml={false} source={parseUnderline(instructions)} renderers={renderers} />
        </GMSinglePromoContentDescription>

        {/* <GMSinglePromoContentDescription title={t('promos:singlePromo:detailsTitle')}>
          <ReactMarkdown escapeHtml={false} source={parseUnderline(details)} renderers={renderers} />
        </GMSinglePromoContentDescription> */}
      </Box>
    </>
  );
};

export default GMPromoSummary;
