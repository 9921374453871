import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import GMSearchInput from 'components/GMSearchInput';
import GMSearchPageHeaderArticles from './articles';
import GMSearchPageHeaderStores from './stores';
import GMSearchPageHeaderPromos from './promos';

interface GMSearchPageHeaderProps {
  activeFilters?: IFilters;
  onChangeQuery?: (newQuery: string) => void;
  onChangeCategory?: (newCategory: string) => void;
  onChangeDelivery?: (newValue: string) => void;
  onSelectDateFilter?: (newValue: string) => void;
  onChangeReadedHandler?: (newValue: string) => void;
  onResetQuery?: () => void;
  onChangeCountry?: (newValue: string) => void;
  onChangePriceOption?: (newValue: string) => void;
  context?: 'articles' | 'partners' | 'promos' | 'donations';
  countries?: { name: string; id: string }[];
}

interface IFilters {
  byRead?: boolean;
  byDate?: string;
  byCategory?: string[];
  byQuery?: string;
  byPrice?: number;
  byCountry?: string;
  byGreenDelivery?: boolean;
}

const GMSearchPageHeader: FC<GMSearchPageHeaderProps> = ({
  activeFilters,
  onSelectDateFilter,
  onChangeReadedHandler,
  onChangeQuery,
  onChangeCategory,
  onChangeCountry,
  onChangePriceOption,
  onResetQuery,
  onChangeDelivery,
  context,
  countries,
}) => {
  return (
    <Box mb={{ base: '24px', lg: '32px', xl: '40px' }}>
      <GMSearchInput onChangeQuery={onChangeQuery} onResetQuery={onResetQuery} query={activeFilters?.byQuery} />
      {context === 'articles' && (
        <GMSearchPageHeaderArticles
          activeFilters={activeFilters}
          onSelectDateFilter={onSelectDateFilter}
          onChangeReadedHandler={onChangeReadedHandler}
          onChangeCategory={onChangeCategory}
        />
      )}
      {context === 'partners' && (
        <GMSearchPageHeaderStores
          countries={countries}
          activeFilters={activeFilters}
          onChangeDelivery={onChangeDelivery}
          onChangeCategory={onChangeCategory}
          onChangeCountry={onChangeCountry}
        />
      )}
      {(context === 'promos' || context === 'donations') && (
        <GMSearchPageHeaderPromos
          activeFilters={activeFilters}
          onChangePriceOption={onChangePriceOption}
          onChangeCategory={onChangeCategory}
          context={context}
          countries={countries}
          onChangeCountry={onChangeCountry}
        />
      )}
    </Box>
  );
};

export default GMSearchPageHeader;
