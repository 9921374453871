import React from 'react';
import { Link, Text, List } from '@chakra-ui/core';

const Heading = (props): any => {
  const { level } = props;

  switch (level) {
    case 1:
    case 2:
    case 3:
      return (
        <Text as="h2" fontSize="2xl" fontWeight="black" lineHeight="27px">
          {props.children}
        </Text>
      );
    default:
      return <Text>{props.children}</Text>;
  }
};

const Links = (props): any => {
  const targetBlank = props.href && props.href.includes(process.env.REACT_APP_BASE_WEBSITE) ? '_self' : '_blank';

  return (
    <Link color="TITLE_LINK.DEFAULT_BORDER_COLOR" href={props.href} fontWeight="bold" target={targetBlank}>
      {props.children}
    </Link>
  );
};

const Lists = ({ ordered, children }): any => {
  return (
    <List pl="20px" as={ordered ? 'ol' : 'ul'} styleType={ordered ? 'decimal' : 'disc'} stylePos="inherit">
      {children}
    </List>
  );
};

export const renderers = {
  heading: Heading,
  link: Links,
  list: Lists,
};
