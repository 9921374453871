import React, { FC, ReactNode, useMemo, useCallback, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Box, Flex, Heading, Text, Divider } from '@chakra-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import customApolloClient from 'apollo';
import { gql, useQuery, useMutation } from '@apollo/client';
import isNaN from 'lodash/isNaN';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import find from 'lodash/find';
import GMButtonGreen from 'components/GMButtonGreen';
import GMContainer from 'components/GMContainer';
import GMChartCircle from 'components/GMChartCircle';
import GMMobilityDateFilter from 'components/GMMobilityFilter';
import GMMobilityFleetTable from 'components/GMMobilityFleetTable';
import { useProfile } from 'context/profile';
import useBreakpoint from 'hooks/useBreakpoint';
import { useBtnSize } from 'hooks/useProfileLayout';
import { palette } from 'theme/theme';

interface IBox {
  children?: ReactNode;
  w?: {};
  title?: string;
  mr?: {};
}

interface ISectionHeader {
  title?: string;
  description?: string;
  btn?: ReactNode;
}

const GET_TOTAL_KM = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      totalKm {
        x
        y
      }
    }
  }
`;

const GET_SHARING = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      sharingMobilityKm {
        x
        y
      }
    }
  }
`;

const GET_DELIVERY = gql`
  query mobilityStats($filter: FilterInput) {
    mobilityStats(filter: $filter) {
      deliveryMobilityKm {
        x
        y
      }
    }
  }
`;

const GET_FLEET_STATS = gql`
  query fleetStats($filter: FilterInput) {
    fleetStats(filter: $filter) {
      vehiclesByEngineType {
        x
        y
      }
      travelTypeKm {
        x
        y
      }
      avoidedEmissionsKg
      totalEmissions
    }
  }
`;

const GET_FLEET = gql`
  query myFleet {
    myFleet {
      id
      name
      model
      licensePlate
      engineType
      registrationDate
      co2Emissions
      kmDistance
      kmDistanceMeasuredAt
    }
  }
`;

const DELETE_ENTERPRISE_VEHICLE = gql`
  mutation DeleteEnterpriseVehicle($id: String!) {
    deleteEnterpriseVehicle(id: $id)
  }
`;

const ChartBox: FC<IBox> = ({ children, w = '100%', title, mr = '0' }) => {
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  return (
    <Box display="inline-block" verticalAlign="bottom" w={w} mb="16px" pr={mr}>
      {title && (
        <Box
          color="PAGE.SECONDARY_TEXT"
          pb="8px"
          px={{ base: '16px', lg: '0' }}
          fontSize={{ base: '14px', lg: '16px', xl: '18px' }}
        >
          {title}
        </Box>
      )}
      <Box
        bg="SUPER_LIGHT_GRAY"
        border="1px solid"
        borderColor="LIGHT"
        color="PAGE.SECONDARY_TEXT"
        fontSize={xl ? 16 : 14}
      >
        {children}
      </Box>
    </Box>
  );
};

const SectionHeader: FC<ISectionHeader> = ({ title, description, btn }) => {
  return (
    <Box mb="32px">
      {title && (
        <Heading
          pb="24px"
          px={{ base: '16px', lg: '0' }}
          color="PRIMARY"
          fontSize={{ base: '20px', lg: '28px', xl: '32px' }}
        >
          {title}
        </Heading>
      )}

      <Flex px={{ base: '16px', lg: '0' }} justify="space-between">
        {description && (
          <Box
            fontSize={{ base: 'md', xl: 'lg' }}
            lineHeight={{ base: '19px', xl: '22px' }}
            color="PAGE.SECONDARY_TEXT"
          >
            <Trans
              i18nKey={description}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green" />]}
              style={{}}
            />
          </Box>
        )}

        <Box
          display={{ base: 'none', lg: 'block' }}
          minW={{ lg: '294px', xl: '392px' }}
          ml={{ lg: '18px', xl: '24px' }}
        >
          {btn}
        </Box>
      </Flex>
    </Box>
  );
};

const UpdateStatsButton: FC = () => {
  const { t } = useTranslation();
  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  return (
    <Link to="/mobility/enterprise/update-statistics">
      <GMButtonGreen size={btnSize}>{t('mobility:enterprise:fleetStats:updateStatsButton')}</GMButtonGreen>
    </Link>
  );
};

const AddNewVehicleButton: FC = () => {
  const { t } = useTranslation();
  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  return (
    <Link to="/mobility/enterprise/add-new-vehicle">
      <GMButtonGreen size={btnSize}>{t('mobility:enterprise:myFleet:addNewVehicleButton')}</GMButtonGreen>
    </Link>
  );
};

const MobilityEnterprise: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { userInfo, token, isEnterprise } = useProfile();

  if (!isEnterprise) return <Redirect to="/mobility/" />;

  const client = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  useEffect(() => {
    moment.locale(currentLocale);
  }, [currentLocale]);

  const filterValueDefault = 'week';
  const filterInputDefault = {
    dateInterval: {
      startDate: moment().subtract(1, 'week').add(1, 'day').toDate(),
      endDate: moment().toDate(),
    },
  };

  const [activeDateFilter, setDateFilter] = useState<string>(filterValueDefault);
  const [dateFilterInput, setDateFilterInput] = useState<
    { dateInterval: { startDate: Date; endDate: Date } } | undefined
  >(filterInputDefault);

  const { data: totalKmData /* , loading: totalKmLoading */ } = useQuery(GET_TOTAL_KM, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: sharingMobilityKmData /* , loading: sharingMobilityKmLoading */ } = useQuery(GET_SHARING, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: deliveryMobilityKmData /* , loading: deliveryMobilityKmLoading */ } = useQuery(GET_DELIVERY, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: fleetStatsData, refetch: refetchFleetStats } = useQuery(GET_FLEET_STATS, {
    fetchPolicy: 'no-cache',
    client,
    variables: {
      filter: dateFilterInput,
    },
  });

  const { data: myFleetData, refetch: refetchFleet } = useQuery(GET_FLEET, {
    fetchPolicy: 'no-cache',
    client,
  });

  const [DeleteVehicle] = useMutation(DELETE_ENTERPRISE_VEHICLE, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      refetchFleet();
      refetchFleetStats();
    },
    client,
  });

  const deleteVehicle = useCallback(async (id: string | undefined) => {
    return await DeleteVehicle({
      variables: {
        id,
      },
    });
  }, []);

  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  const containerH = xl ? 192 : 168;
  const pieSize = xl ? 130 : 110;

  const totalKm = totalKmData?.mobilityStats?.totalKm;
  const deliveryMobilityKm = deliveryMobilityKmData?.mobilityStats?.deliveryMobilityKm;
  const sharingMobilityKm = sharingMobilityKmData?.mobilityStats?.sharingMobilityKm;

  const fleetStats = fleetStatsData?.fleetStats;
  const { vehiclesByEngineType, travelTypeKm, avoidedEmissionsKg, totalEmissions } = fleetStats || {};

  const totalTravelKm = sumBy(travelTypeKm, (item: { x?: string; y: number }) => item?.y);
  const sustainableTravelObj = find(travelTypeKm, { x: 'SUSTAINABLE' });
  const sustainableTravelKm = sustainableTravelObj?.y;

  const percentSustainableTravelKm = Math.round((sustainableTravelKm * 100) / totalTravelKm) || 0;

  const { myFleet } = myFleetData || {};

  const dateFilters = [
    {
      value: 'week',
      filterNameTag: 'mobility:enterprise:filters:lastWeekTag',
      filterNameSelect: 'mobility:enterprise:filters:lastWeekSelect',
    },
    {
      value: 'month',
      filterNameTag: 'mobility:enterprise:filters:lastMonthTag',
      filterNameSelect: 'mobility:enterprise:filters:lastMonthSelect',
    },
    {
      value: 'year',
      filterNameTag: 'mobility:enterprise:filters:lastYearTag',
      filterNameSelect: 'mobility:enterprise:filters:lastYearSelect',
    },
  ];

  const renderLegend = ({ payload }): ReactNode => {
    return (
      <Box>
        {map(payload, (entry, index) => (
          <Box mb="16px" key={`item-${index}`}>
            <Box
              color={entry.color}
              fontSize={
                entry.id === 'SUSTAINABLE'
                  ? { base: '26px', sm: '38px', lg: '26px', xl: '38px' }
                  : { base: '20px', sm: '24px', lg: '20px', xl: '24px' }
              }
              fontWeight="bold"
              mb="3px"
              lineHeight={1}
            >
              {entry.id === 'SUSTAINABLE' ? entry.amount : totalTravelKm}
            </Box>
            <Box lineHeight="19px">
              {entry.id === 'SUSTAINABLE' ? entry.value : t('mobility:enterprise:charts:legend:totalKm')}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <GMContainer>
      <Helmet>
        <title>{t('mobility:pageTitle')}</title>
        <meta name="mobility" content={t('mobility:pageTitle')} />
      </Helmet>

      <Flex align="flex-end" mb={{ base: '16px', lg: '24px', xl: '32px' }} pt="32px" px={{ lg: '56px' }}>
        <Box maxW={{ base: '536px', lg: '1224px' }} w="100%" mx="auto">
          <Heading
            display={{ lg: 'none' }}
            pb="24px"
            color="PRIMARY"
            fontSize={{ base: '20px', lg: '28px', xl: '32px' }}
            px="16px"
          >
            {t('mobility:title')}
          </Heading>

          <Flex px={{ base: '16px', lg: '0' }} justify="space-between">
            <Box pb={{ base: '31px', lg: '32px' }}>
              <Text
                fontSize={{ base: 'md', xl: 'lg' }}
                lineHeight={{ base: '19px', xl: '22px' }}
                color="PAGE.SECONDARY_TEXT"
              >
                <Trans
                  i18nKey="mobility:enterprise:description"
                  // eslint-disable-next-line react/jsx-key
                  components={[<strong className="green" />]}
                />
              </Text>
            </Box>

            <Box
              minW={{ lg: 'calc(216px + 96px)', xl: 'calc(216px + 96px)' }}
              ml={{ lg: '96px', xl: '96px' }}
              display={{ base: 'none', lg: 'block' }}
            >
              <GMMobilityDateFilter
                type="select"
                onSelectValue={(value) => setDateFilterInput(value)}
                dateFilters={dateFilters}
                setDateFilter={(value) => setDateFilter(value)}
                activeDateFilter={activeDateFilter}
              />
            </Box>
          </Flex>

          <Box display={{ lg: 'none' }}>
            <GMMobilityDateFilter
              type="tags"
              onSelectValue={(value) => setDateFilterInput(value)}
              dateFilters={dateFilters}
              setDateFilter={(value) => setDateFilter(value)}
              activeDateFilter={activeDateFilter}
            />
          </Box>

          <Box>
            <ChartBox
              w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
              title={t('mobility:enterprise:charts:total:title')}
              mr={{ lg: '16px' }}
            >
              <GMChartCircle data={totalKm} labelAmount="km" size={pieSize} containerH={containerH} legend={false} />
            </ChartBox>

            <ChartBox
              w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
              title={t('mobility:enterprise:charts:sharing:title')}
              mr={{ lg: '16px' }}
            >
              <GMChartCircle data={deliveryMobilityKm} labelAmount="km" size={pieSize} containerH={containerH} />
            </ChartBox>

            <ChartBox
              w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
              title={t('mobility:enterprise:charts:delivery:title')}
            >
              <GMChartCircle data={sharingMobilityKm} labelAmount="km" size={pieSize} containerH={containerH} />
            </ChartBox>
          </Box>

          <Divider borderColor="PRIMARY" mb="32px" mx={{ base: '16px', lg: '0' }} />

          <Box mb="20px">
            <SectionHeader
              title={t('mobility:enterprise:fleetStats:title')}
              description={t('mobility:enterprise:fleetStats:description')}
              btn={<UpdateStatsButton />}
            />

            <Box verticalAlign="middle">
              <ChartBox
                w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
                title={t('mobility:enterprise:charts:fleet:title', { date: moment().format('DD/MM/YY') })}
                mr={{ lg: '16px' }}
              >
                <GMChartCircle
                  data={vehiclesByEngineType}
                  size={pieSize}
                  containerH={253}
                  showAmountInLegend
                  fleetStats
                />
              </ChartBox>

              <ChartBox
                w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
                title={`${t('mobility:enterprise:charts:kmDistance:title')} ${t(
                  'mobility:enterprise:charts:kmDistance:dates',
                  {
                    startDate: moment(userInfo?.myData?.createdAt).format('DD/MM/YY'),
                    endDate: moment().format('DD/MM/YY'),
                  },
                )}`}
                mr={{ lg: '16px' }}
              >
                <GMChartCircle
                  data={travelTypeKm}
                  total={percentSustainableTravelKm}
                  labelAmount="percent"
                  renderLegend={renderLegend}
                  size={pieSize}
                  containerH={253}
                />
              </ChartBox>

              <ChartBox
                w={{ base: '100%', lg: 'calc(100% / 3 * 1)' }}
                title={t('mobility:enterprise:charts:emissions:title')}
              >
                <Flex direction="column" justify="space-between" h={253} padding="26px">
                  <Flex direction="column" align="center">
                    <Text fontSize="50px" color={palette.LIGHT_GREEN} lineHeight="1" fontWeight="bold">
                      {!isNaN(avoidedEmissionsKg / 1000) ? Number(avoidedEmissionsKg / 1000) : 0}
                    </Text>
                    <Box fontSize="22px">
                      <Trans
                        i18nKey="mobility:enterprise:charts:emissions:avoided"
                        // eslint-disable-next-line react/jsx-key
                        components={[<sub />]}
                      />
                    </Box>
                  </Flex>
                  <Flex direction="column" align="center">
                    <Text fontSize="50px" color={palette.DARK_GREEN} lineHeight="1" fontWeight="bold">
                      {!isNaN(totalEmissions / 1000) ? Number(totalEmissions / 1000) : 0}
                    </Text>
                    <Box fontSize="22px">
                      <Trans
                        i18nKey="mobility:enterprise:charts:emissions:produced"
                        // eslint-disable-next-line react/jsx-key
                        components={[<sub />]}
                      />
                    </Box>
                  </Flex>
                </Flex>
              </ChartBox>
            </Box>

            <Box display={{ lg: 'none' }} px="16px">
              <UpdateStatsButton />
            </Box>
          </Box>

          <Divider borderColor="PRIMARY" mb="32px" mx={{ base: '16px', lg: '0' }} />

          <Box mb="20px">
            <SectionHeader
              title={t('mobility:enterprise:myFleet:title')}
              description={t('mobility:enterprise:myFleet:description')}
              btn={<AddNewVehicleButton />}
            />

            <Box display={{ lg: 'none' }} px="16px">
              <AddNewVehicleButton />
            </Box>
          </Box>

          <Box px={{ base: '16px', lg: '0' }}>
            <GMMobilityFleetTable fleet={myFleet} deleteVehicle={deleteVehicle} />
          </Box>
        </Box>
      </Flex>
    </GMContainer>
  );
};

export default MobilityEnterprise;
