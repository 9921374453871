import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, SimpleGrid, Text } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import GMPhoto from 'components/GMPhoto';
import GMReadMoreLink from 'components/GMReadMoreLink';
import GMSectionContainer from 'components/GMSectionContainer';
import { usePartnersSeeMoreSize } from 'hooks/useShoppingPageLayout';

interface GMShopPartnersListProps {
  partners?: Store[];
  readMore?: boolean;
  onlyHightlight?: boolean;
  title?: string;
}

interface Store {
  id: string;
  landscapeBannerId: string;
  isGreenDelivery: boolean;
  greenKmReward: number;
  name: string;
  tagline: string;
  abstract: string;
  description: string;
  metaDescription: string;
  metaKeywords: string;
  slug: string;
}

const GMShopPartnersList: FC<GMShopPartnersListProps> = ({ partners, readMore, onlyHightlight, title }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const getReadMoreSize: Function = usePartnersSeeMoreSize();
  const readMoreSize = getReadMoreSize() || 'small';

  return (
    <GMSectionContainer>
      {title && (
        <Text
          color="PAGE.TITLE_DARK"
          fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
          lineHeight={{ base: '24px', lg: '34px', xl: '39px' }}
          fontWeight="bold"
          mb={{ base: '16px', lg: '24px', xl: '32px' }}
        >
          {title}
        </Text>
      )}
      {!isEmpty(partners) ? (
        <Box mb={{ xl: '16px' }}>
          <SimpleGrid
            columns={{ base: 1, lg: 3 }}
            spacing={{ base: '17px', lg: '18px', xl: '24px' }}
            mb="24px"
            overflowX="hidden"
          >
            {map(partners, (partner, index) => {
              return (
                <Box h={{ base: '160px', lg: '156px', xl: '196px' }} key={partner.id || Number(index)}>
                  <Link to={`${currentLocale}/partner/${partner.slug}`}>
                    <GMPhoto
                      h="100%"
                      textSize="medium"
                      text={partner.name}
                      thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${partner.landscapeBannerId}`}
                      imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${partner.landscapeBannerId}`}
                    />
                  </Link>
                </Box>
              );
            })}
          </SimpleGrid>
          {readMore && (
            <Link to="search/partners">
              <GMReadMoreLink text={t('shopping:home:allPartners')} size={readMoreSize} />
            </Link>
          )}
          {onlyHightlight && <Box borderBottom="1px" borderColor="READ_ALL.DEFAULT_COLOR" w="100%" pt="30px"></Box>}
        </Box>
      ) : (
        readMore && (
          <Link to="search/partners">
            <GMReadMoreLink text={t('shopping:home:allPartners')} size={readMoreSize} />
          </Link>
        )
      )}
    </GMSectionContainer>
  );
};

export default GMShopPartnersList;
