import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@chakra-ui/core';
import GMArticleCardAlternative from 'components/GMArticleCardAlternative';
import GMPhoto from 'components/GMPhoto';
import GMTitleLink from 'components/GMTitleLink';
import Post from 'interfaces/Post';
import useMeshLayout from 'hooks/useNewsMeshLayout';
import useBreakpoint from 'hooks/useBreakpoint';

interface GMArticlesMeshProps {
  articles: Post[];
  children?: React.ReactNode;
  h?: string;
}

const GMArticlesMesh: FC<GMArticlesMeshProps> = ({ articles = [], h }) => {
  const { i18n } = useTranslation();
  const getMeshLayout: Function = useMeshLayout();
  const layout = getMeshLayout(articles.length) || {};
  const isOnlyOneArticle = articles.length === 1;
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';
  const currentLocale = i18n.language;

  return (
    <Grid
      gridTemplateAreas={layout.templateArea ? layout.templateArea : null}
      gridTemplateRows={layout.templateRows ? layout.templateRows : null}
      gridTemplateColumns={layout.templateColumns || null}
      gridGap={{ base: '16px', lg: '18px', xl: '24px' }}
      h={h || 'auto'}
    >
      {articles.map((article, index) => {
        const singleArticle = article?.node;
        const titleSlug = singleArticle.slug;

        return (
          <Box gridArea={index === 0 ? 'main' : `other${index}`} key={index}>
            <Link to={`/${currentLocale}/article/${titleSlug}`}>
              <Box display={layout.cardType && layout.cardType[index] === 'photo' ? 'block' : 'none'} w="100%" h="100%">
                {isOnlyOneArticle && (lg || xl) ? (
                  <GMArticleCardAlternative
                    noCardPadding
                    title={singleArticle.title}
                    desc={lg || xl ? singleArticle.abstract : ''}
                    truncDescAfterLine={lg ? 9 : 10}
                    truncTitleAfterLine={3}
                    bigPicture
                    titleSize="big"
                    h={lg ? '336px' : '448px'}
                    imageUrl={
                      singleArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${singleArticle.landscapeBannerId}`
                        : ''
                    }
                    thumbnailImageUrl={
                      singleArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${singleArticle.landscapeBannerId}`
                        : ''
                    }
                  />
                ) : (
                  <GMPhoto
                    thumbnailImageUrl={
                      singleArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${singleArticle.landscapeBannerId}`
                        : ''
                    }
                    imageUrl={
                      singleArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${singleArticle.landscapeBannerId}`
                        : ''
                    }
                    text={singleArticle.title}
                    textSize={layout.textSize && layout.textSize[index]}
                  />
                )}
              </Box>
              <GMTitleLink
                size={layout.textSize && layout.textSize[index]}
                text={singleArticle.title}
                color="dark"
                display={layout.cardType && layout.cardType[index] === 'title' ? 'block' : 'none'}
              />
            </Link>
          </Box>
        );
      })}
    </Grid>
  );
};

export default GMArticlesMesh;
