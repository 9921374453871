import React, { FC } from 'react';
import { Flex, Grid } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import Post from 'interfaces/Post';
import GMSectionContainer from 'components/GMSectionContainer';
import GMCategoryMesh from 'components/GMCategoryMesh';

interface GMCategoriesProps {
  articles?: Array<{
    id: string;
    posts: {
      edges: Post[];
    };
  }>;
}

const GMCategories: FC<GMCategoriesProps> = ({ articles }) => {
  return (
    <GMSectionContainer>
      <Grid
        gridTemplateColumns={{ base: '100%', lg: 'minmax(0, 1fr) minmax(0, 1fr)' }}
        gridGap={{ base: '16px', lg: '18px', xl: '28px' }}
      >
        {map(articles, (article, index) => {
          if (!isEmpty(article)) {
            return (
              <Flex pb="32px" key={index} direction="column">
                <GMCategoryMesh category={article.id} articles={article?.posts?.edges} />
              </Flex>
            );
          }
        })}
      </Grid>
    </GMSectionContainer>
  );
};

export default GMCategories;
