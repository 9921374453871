import React, { FC, useState, useCallback } from 'react';
import { Heading, Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import map from 'lodash/map';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import GMTag from 'components/GMTag';
import GMButtonGreen from 'components/GMButtonGreen';
import GMModalInput from 'components/GMModalInput';
import GMSelectCountry from 'components/GMSelectCountry';
import GMSelectCompanyCategory from 'components/GMSelectCompanyCategory';
import { useTagSize } from 'hooks/useProfileLayout';
import { useProfile } from 'context/profile';
import keycloak from 'keycloak';
import { phonePattern } from 'constants/patterns';
import GMSelectOptions from 'components/GMSelectOptions';

interface IGMModalEdit {
  setUserData?: (data) => void;
  onClose?: () => void;
}

const GMModalEditData: FC<IGMModalEdit> = ({ onClose, setUserData }) => {
  const { userInfo, isEnterprise } = useProfile();
  const { t } = useTranslation();
  const sendDataToGTM = useGTMDispatch();

  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const { control, handleSubmit, register, errors } = useForm({ reValidateMode: 'onSubmit' });

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const { myData } = userInfo || {};

  const profileCompleted = myData?.createdAt;
  const userId = myData?.id;

  const { address, country, ageGroup, firstName, lastName, phoneNumber, vehicles, companyName, VAT, companyCategory } =
    myData || {};

  const ownedVehicles = filter(vehicles, (vehicle) => vehicle.owned);
  const vehicleIds = map(ownedVehicles, (vehicle) => vehicle.id);

  const [, setAgeGroupDate] = useState<string>();
  const [selectedVehicleIds, setSelectedVehicleIds] = useState<string[]>(vehicleIds || []);
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(country);
  const [selectedCompanyCategory, setSelectedCompanyCategory] = useState<string | undefined>(companyCategory);

  const onSubmit = (values): void => {
    if (setUserData)
      setUserData({
        ...values,
        phoneNumber: values.phoneNumber || null,
        country: selectedCountry,
        companyCategory: selectedCompanyCategory,
        vehicleIds: isEnterprise ? undefined : selectedVehicleIds,
      });

    if (profileCompleted === null && process.env.NODE_ENV === 'production') {
      const eventName = 'af_complete_registration';

      sendDataToGTM({
        event: eventName,
        value: {
          type: isEnterprise ? 'enterprise' : 'private',
          transactionID: userId,
          transactionTotal: 1,
          transactionCurrency: 'EUR',
        },
      });
    }
  };

  const onClickTagHandler = (id: string): void => {
    const vehicles: string[] = [...(selectedVehicleIds || [])];
    const addVehicleToArray = ({ id }): void => {
      vehicles.push(id);
    };

    const removeVehicleFromArray = ({ id }): void => {
      remove(vehicles, (vehicleId) => vehicleId === id);
    };

    vehicles.includes(id) ? removeVehicleFromArray({ id }) : addVehicleToArray({ id });
    setSelectedVehicleIds(vehicles);
  };

  return (
    <>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {profileCompleted ? t('profile:account:modal:title') : t('profile:account:modal:profileHasToBeCompiled')}
      </Heading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={{ base: '24px', lg: '32px' }}>
          <GMModalInput
            register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
            name="firstName"
            label={`${t('profile:account:modal:firstname')}`}
            value={firstName}
            errors={errors}
            req
          />
          <GMModalInput
            register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
            name="lastName"
            label={`${t('profile:account:modal:lastname')}`}
            value={lastName}
            errors={errors}
            req
          />
          <GMModalInput
            register={register({
              pattern: { value: phonePattern, message: t('common:errors:onlyNumbers') },
              minLength: { value: 4, message: t('common:errors:minSymbols', { min: '4' }) },
            })}
            name="phoneNumber"
            label={t('profile:account:modal:phoneNumber')}
            value={phoneNumber}
            errors={errors}
          />
          <Box mb={{ base: '16px', lg: '14px' }}>
            <Box
              fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
              color="PAGE.SECONDARY_TEXT"
              lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
              pb="2px"
            >
              {t('profile:account:modal:age')}
            </Box>
            <Box
              position="relative"
              borderBottom="1px solid"
              borderColor={errors && errors['ageGroup'] ? 'ERROR' : 'SECONDARY'}
              pr="20px"
            >
              <Controller
                as={({ onChange, value }) => (
                  <GMSelectOptions
                    options={[
                      {
                        value: '<24',
                        label: t('profile:account:modal:lessThan24'),
                      },
                      {
                        value: '25-39',
                        label: '25-39',
                      },
                      {
                        value: '40-55',
                        label: '40-55',
                      },
                      {
                        value: '>=56',
                        label: t('profile:account:modal:moreThan56'),
                      },
                    ]}
                    onSelect={(value) => onChange(value)}
                    value={value}
                    backgroundColor="transparent"
                    noEmptyValue
                  />
                )}
                onChange={(values) => {
                  setAgeGroupDate(values[0]);
                  return values[0];
                }}
                defaultValue={ageGroup}
                control={control}
                name="ageGroup"
                rules={{ required: { value: true, message: t('common:errors:requiredField') } }}
              />
              <Box color={errors && errors['ageGroup'] ? 'ERROR' : 'PRIMARY'} position="absolute" right="8px" top="6px">
                &#8226;
              </Box>
            </Box>
            <Box color="ERROR" fontSize="14px">
              {errors && errors['ageGroup']?.message}
            </Box>
          </Box>
          {!isEnterprise && (
            <GMModalInput
              register={register}
              name="address"
              label={t('profile:account:modal:address')}
              value={address}
              errors={errors}
            />
          )}
          {!isEnterprise && (
            <>
              <Box
                fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
                color="PAGE.SECONDARY_TEXT"
                lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
                pb="8px"
              >
                {t('profile:account:modal:ownedVehicles')}
              </Box>

              <Flex wrap="wrap">
                {map(vehicles, (vehicle) => {
                  const { id } = vehicle;
                  return (
                    <Box mr="8px" mb="8px" key={id}>
                      <GMTag
                        onClick={() => onClickTagHandler(id)}
                        size={tagSize}
                        active={selectedVehicleIds.includes(id)}
                        dense
                        tagName={t(`common:vehicles:${id}`)}
                      />
                    </Box>
                  );
                })}
              </Flex>
            </>
          )}

          {isEnterprise && (
            <>
              <Heading
                color="PRIMARY"
                fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
                pb={{ base: '12px', lg: '16px' }}
                pt="16px"
              >
                {t('profile:account:modal:companyData')}
              </Heading>

              <GMModalInput
                register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
                name="companyName"
                label={`${t('profile:account:modal:companyName')}`}
                value={companyName}
                errors={errors}
                req
              />

              <GMModalInput
                register={register({ required: { value: false, message: t('common:errors:requiredField') } })}
                name="address"
                label={`${t('profile:account:modal:legalAddress')}`}
                value={address}
                errors={errors}
              />

              <Box mb={{ base: '16px', lg: '14px' }}>
                <Box
                  fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
                  color="PAGE.SECONDARY_TEXT"
                  lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
                  pb="8px"
                >
                  {t('profile:account:modal:companyNationality')}
                </Box>
                <Box
                  position="relative"
                  borderBottom="1px solid"
                  borderColor={errors && errors['country'] ? 'ERROR' : 'SECONDARY'}
                  pr="20px"
                >
                  <Controller
                    as={({ onChange }) => (
                      <GMSelectCountry onSelect={(value) => onChange(value)} country={selectedCountry} />
                    )}
                    onChange={(values) => {
                      setSelectedCountry(values[0]);
                      return values[0];
                    }}
                    defaultValue={country}
                    control={control}
                    name="country"
                    rules={{
                      required: { value: true, message: t('common:errors:requiredField') },
                    }}
                  />
                  <Box
                    color={errors && errors['country'] ? 'ERROR' : 'PRIMARY'}
                    position="absolute"
                    right="8px"
                    top="6px"
                  >
                    &#8226;
                  </Box>
                </Box>
                <Box color="ERROR" fontSize="14px">
                  {errors && errors['country']?.message}
                </Box>
              </Box>

              <GMModalInput
                register={register({ required: { value: true, message: t('common:errors:requiredField') } })}
                name="VAT"
                label={`${t('profile:account:modal:VAT')}`}
                value={VAT}
                errors={errors}
                req
              />

              <Box mb={{ base: '16px', lg: '14px' }}>
                <Box
                  fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
                  color="PAGE.SECONDARY_TEXT"
                  lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
                  pb="8px"
                >
                  {t('profile:account:modal:companyCategory')}
                </Box>
                <Box
                  position="relative"
                  borderBottom="1px solid"
                  borderColor={errors && errors['companyCategory'] ? 'ERROR' : 'SECONDARY'}
                  pr="20px"
                >
                  <Controller
                    as={({ onChange }) => (
                      <GMSelectCompanyCategory onSelect={(value) => onChange(value)} value={selectedCompanyCategory} />
                    )}
                    onChange={(values) => {
                      setSelectedCompanyCategory(values[0]);
                      return values[0];
                    }}
                    defaultValue={companyCategory}
                    control={control}
                    name="companyCategory"
                    rules={{
                      required: { value: true, message: t('common:errors:requiredField') },
                    }}
                  />
                  <Box
                    color={errors && errors['companyCategory'] ? 'ERROR' : 'PRIMARY'}
                    position="absolute"
                    right="8px"
                    top="6px"
                  >
                    &#8226;
                  </Box>
                </Box>
                <Box color="ERROR" fontSize="14px">
                  {errors && errors['companyCategory']?.message}
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Flex>
          <Box mr="16px" w="50%">
            <GMButtonGreen size="medium" variant="transparent" onClick={profileCompleted ? onClose : logout}>
              {profileCompleted ? t('common:cancel') : t('common:logout')}
            </GMButtonGreen>
          </Box>
          <Box w="50%">
            <GMButtonGreen size="medium" type="submit">
              {t('common:save')}
            </GMButtonGreen>
          </Box>
        </Flex>
      </form>
    </>
  );
};

export default GMModalEditData;
