import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';

interface IGMPromoContentDescription {
  children?: React.ReactNode;
  title?: string;
}

const GMPromoContentDescription: FC<IGMPromoContentDescription> = ({ children, title }) => {
  return (
    <Box pb="24px">
      <Box fontSize={{ base: 'xl', lg: '22px', xl: '2xl' }} pb="16px" fontWeight="bold" color="PAGE.SECONDARY_TEXT">
        {title}
      </Box>
      {children}
    </Box>
  );
};

export default GMPromoContentDescription;
