import React, { FC, useState, useRef } from 'react';
import { Drawer, DrawerOverlay, DrawerContent, DrawerBody, Box, Flex, Divider, useDisclosure } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import styled from '@emotion/styled';
import useBreakpoint from 'hooks/useBreakpoint';
import GMSocials from 'components/GMSocials';
import GMMenu from 'components/GMMenu';
import GMFooterLinkItem from 'components/GMFooterLinkItem';
import GMLanguageSwitcher from 'components/GMLanguageSwitcher';
import { palette } from 'theme/theme';
import { GMLink } from './styled';

interface IBurger {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  innerRef: React.MutableRefObject<null>;
}

interface IStyledBurger {
  isOpen: boolean;
}

interface IMenuFooter {
  onClose: () => void;
}

const MenuFooter: FC<IMenuFooter> = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Box
      bg="PRIMARY"
      p="25px 60px"
      h="100%"
      color="WHITE"
      textAlign="center"
      fontSize="sm"
      lineHeight="17px"
      className="footer"
    >
      <Box maxW="200px" mx="auto">
        <GMLanguageSwitcher outlineColor="white" />
      </Box>

      <Divider my="16px" color="WHITE" />
      <GMFooterLinkItem
        to={{ pathname: `https://www.gremove.com/team?lang=${currentLanguage === 'it' ? 'it' : 'en'}` }}
      >
        {t('footer:people:whoWeAre')}
      </GMFooterLinkItem>
      <GMFooterLinkItem to="/contacts">{t('footer:people:contacts')}</GMFooterLinkItem>
      <Divider my="16px" color="WHITE" />
      <Box py="8px">
        {location && location?.pathname === '/' ? (
          <GMLink to="theVision" hashSpy={true} smooth={true} offset={-140} duration={500} onClick={onClose}>
            {t('footer:project:vision')}
          </GMLink>
        ) : (
          <HashLink to="/#theVision" onClick={onClose}>
            {t('footer:project:vision')}
          </HashLink>
        )}
      </Box>
      <Box py="8px">
        {location && location?.pathname === '/' ? (
          <GMLink to="howItWorks" hashSpy={true} smooth={true} offset={-140} duration={500} onClick={onClose}>
            {t('footer:project:howItWorks')}
          </GMLink>
        ) : (
          <HashLink to="/#howItWorks" onClick={onClose}>
            {t('footer:project:howItWorks')}
          </HashLink>
        )}
      </Box>
      {/* <Box py="8px">{t('footer:project:certifications')}</Box> */}
      <Divider my="16px" color="WHITE" />
      <GMFooterLinkItem to={`/terms-and-conditions-private`}>{t('footer:legal:termsAndConditions')}</GMFooterLinkItem>

      <GMFooterLinkItem to={`/terms-and-conditions-enterprise`}>
        {t('footer:legal:termsAndConditions')} Enterprise
      </GMFooterLinkItem>

      <GMFooterLinkItem to={`/privacy-policy`}>{t('footer:legal:privacy')}</GMFooterLinkItem>

      <GMFooterLinkItem to={`/cookie-policy`}>{t('footer:legal:cookiePolicy')}</GMFooterLinkItem>

      <GMFooterLinkItem to="/faq">{t('footer:legal:FAQ')}</GMFooterLinkItem>
      <Divider my="16px" color="WHITE" />
      <Flex justify="center" align="center" wrap="wrap" maxW="150px" mx="auto">
        <GMSocials />
      </Flex>
    </Box>
  );
};

const StyledBurger = styled.div<IStyledBurger>`
  position: fixed;
  top: 22px;
  right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 18px;
  height: 12px;
  background: transparent;
  transition: all 0.3s linear;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 9999999;

  &:focus {
    outline: none;
  }

  div {
    width: 100%;
    height: 2px;
    background: ${palette.MEDIUM_GREEN};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 18%;

    :first-of-type {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
      transform: ${({ isOpen }) => (isOpen ? 'translateX(5px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const Burger: FC<IBurger> = ({ isOpen, onOpen, onClose, innerRef }) => {
  return (
    <StyledBurger isOpen={isOpen} onClick={isOpen ? onClose : onOpen} ref={innerRef}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const GMHamburgerMenu: FC = () => {
  const breakpoint = useBreakpoint();
  const md = breakpoint === 'md';
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const btnRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollBehavior] = useState<'inside' | 'outside' | undefined>('inside');

  return (
    <>
      {!lg && !xl && (
        <>
          <Burger onOpen={onOpen} onClose={onClose} isOpen={isOpen} innerRef={btnRef} />

          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
            size={md ? 'md' : 'full'}
            scrollBehavior={scrollBehavior}
          >
            <DrawerOverlay />
            <DrawerContent zIndex={99999} backgroundColor="PRIMARY">
              <DrawerBody p="0">
                <Box p="32px 16px" backgroundColor="WHITE">
                  <GMMenu />
                </Box>
                <Box>
                  <MenuFooter onClose={onClose} />
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </>
  );
};

export default GMHamburgerMenu;
