import React, { FC, useState, useCallback } from 'react';
import { PseudoBox, Box, Flex } from '@chakra-ui/core';
import { map } from 'lodash';

interface IGMSwitcher {
  options: string[];
  onChange?: (name: string) => void;
  value?: string;
}

const GMSwitcher: FC<IGMSwitcher> = ({ options, onChange, value }) => {
  const [active, setActive] = useState(value);
  const onClick = useCallback((name): void => {
    setActive(name);
    if (onChange) onChange(name);
  }, []);

  return (
    <Box>
      <Flex>
        {map(options, (name) => {
          const isActive = active === name;
          return (
            <PseudoBox
              key={name}
              cursor="pointer"
              fontSize={{ base: '14px', lg: '16px', xl: '18px' }}
              p={{ base: '8px', lg: '11px', xl: '13px' }}
              w={`calc(100% / ${options.length})`}
              color={isActive ? 'WHITE' : 'BUTTON.DISABLED.TEXT'}
              bg={isActive ? 'PRIMARY' : undefined}
              onClick={() => onClick(name)}
              fontWeight={isActive ? 'bold' : undefined}
              border="1px solid"
              borderColor={isActive ? 'PRIMARY' : 'BUTTON.DISABLED.BORDER'}
              textAlign="center"
              _first={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
              _last={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
            >
              {name}
            </PseudoBox>
          );
        })}
      </Flex>
    </Box>
  );
};

export default GMSwitcher;
