import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Heading, Flex } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import GMButtonGreen from 'components/GMButtonGreen';
import GMReadMoreLink from 'components/GMReadMoreLink';
import GMTransactionsCalendar from 'components/GMTransactionsCalendar';
import GMWalletBadge from 'components/GMWalletBadge';
import { useBtnSize } from 'hooks/useProfileLayout';
import { useProfile } from 'context/profile';
import useBreakpoint from 'hooks/useBreakpoint';

interface IGMUserWallet {
  openModal?: (modal: string) => void;
  transactions?: ITransaction[];
}

interface ITransaction {
  node: {
    id: string;
    amount: number;
    lastEventDate: Date;
    currency: {
      code: string;
    };
    motive: string;
    sender: string;
    transactionType: string;
    state: string;
  };
}

const GMUserWallet: FC<IGMUserWallet> = ({ openModal, transactions }) => {
  const { t } = useTranslation();
  const { loadingWallet, userBalance } = useProfile();

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const openModalHandler = (modal): void => {
    if (openModal) openModal(modal);
  };

  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  return (
    <Box color="SECONDARY" fontSize={{ base: 'lg', lg: 'xl', xl: '22px' }}>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:wallet:title')}
      </Heading>
      <Flex mb="24px" display={{ base: 'flex', lg: 'none' }}>
        <Box w="50%" mr="16px">
          <GMWalletBadge loading={loadingWallet} size="small" amount={userBalance?.km} valute="km" />
        </Box>
        <Box w="50%">
          <GMWalletBadge loading={loadingWallet} size="small" amount={userBalance?.coins} valute="coin" />
        </Box>
      </Flex>
      <Box pb="16px">
        <GMButtonGreen size={btnSize} onClick={() => openModalHandler('sendCoins')}>
          {t('profile:buttons:sendGreenCoins')}
        </GMButtonGreen>
      </Box>
      <Box pb="32px">
        <GMButtonGreen size={btnSize} onClick={() => openModalHandler('redeemCoupon')}>
          {t('profile:buttons:redeemCoupon')}
        </GMButtonGreen>
      </Box>
      <Box>
        <Box fontSize={{ base: '14px', lg: '16px' }} color="PAGE.SECONDARY_TEXT" fontWeight="bold" mb="8px">
          {t('profile:transactions:lastTransactions')}
        </Box>
        <GMTransactionsCalendar transactions={transactions} variant="short" />
        {!isEmpty(transactions) && (
          <Link to="/profile/transactions">
            <GMReadMoreLink size={xl || lg ? 'big' : 'small'} text={t('profile:transactions:seeAll')} />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default GMUserWallet;
