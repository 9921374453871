import React, { FC, useCallback } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import moment from 'moment';
import GMSelect from 'components/GMSelect';
import GMTag from 'components/GMTag';
import { useTagSize } from 'hooks/useSearchPageLayout';

interface IGMMobilityDateFilter {
  dateFilters: IDateFilter[];
  activeDateFilter: string | undefined;
  type: 'tags' | 'select';
  onSelectValue: (value: { dateInterval: { startDate: Date; endDate: Date } } | undefined) => void;
  setDateFilter: (value: string) => void;
}

interface IDateFilter {
  value: string;
  filterNameTag: string;
  filterNameSelect: string;
}

const GMMobilityDateFilter: FC<IGMMobilityDateFilter> = ({
  dateFilters,
  type,
  onSelectValue,
  setDateFilter,
  activeDateFilter,
}) => {
  const { t } = useTranslation();

  const onSelectFilter = useCallback(
    (value: 'week' | 'month' | 'year'): void => {
      if (value === activeDateFilter) return;

      setDateFilter(value);

      const startDate = moment().subtract(1, value).add(1, 'day').toDate();
      const endDate = moment().toDate();

      onSelectValue({ dateInterval: { startDate, endDate } });
    },
    [activeDateFilter],
  );

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  return (
    <>
      {type === 'select' && (
        <GMSelect
          value={activeDateFilter}
          size={tagSize}
          onSelectOption={(event) => onSelectFilter(event?.target?.value as 'week' | 'month' | 'year')}
        >
          {map(dateFilters, (filter) => (
            <option value={filter?.value} key={filter?.value}>
              {t(filter?.filterNameSelect)}
            </option>
          ))}
        </GMSelect>
      )}

      {type === 'tags' && (
        <Flex mb="32px" px="16px" wrap="wrap" justify="space-between">
          {map(dateFilters, (filter) => (
            <Box w={{ base: '100%', sm: 'calc((100% / 3) - 4px)' }} pb="8px" key={filter?.value}>
              <GMTag
                size={tagSize}
                onClick={() => onSelectFilter(filter.value as 'week' | 'month' | 'year')}
                tagName={t(filter.filterNameTag)}
                active={activeDateFilter === filter.value}
              />
            </Box>
          ))}
        </Flex>
      )}
    </>
  );
};

export default GMMobilityDateFilter;
