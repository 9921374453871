import React, { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Flex } from '@chakra-ui/core';
import { useQuery, gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMPartnerContent from 'components/GMPartnerContent';
import GMPartnerSidebar from 'components/GMPartnerSidebar';
import { customApolloClient } from 'apollo';
import { useProfile } from 'context/profile';
import { navigateByLocale, parseMarkdown } from 'utils';
import { decode } from 'html-entities';

const SINGLE_STORE_BY_SLUG = gql`
  query storeBySlug($slug: String!) {
    storeBySlug(slug: $slug) {
      id
      landscapeBannerId
      isGreenDelivery
      greenKmReward
      affiliateLink
      name
      tagline
      abstract
      description
      metaDescription
      metaKeywords
    }
  }
`;

const SINGLE_STORE_BY_ID = gql`
  query store($id: String!, $languageCode: String!) {
    store(id: $id, languageCode: $languageCode) {
      slug
    }
  }
`;

const Partner: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { token } = useProfile();

  const { slug } = useParams();

  const { state } = useLocation();
  const categoryName = get(state, 'categoryName');
  const categorySlug = get(state, 'categorySlug');

  const history = useHistory();

  const client = useMemo(() => customApolloClient({ clientType: 'shopClient', token }), [token]);

  const { loading, error, data: dataStoreBySlug } = useQuery(SINGLE_STORE_BY_SLUG, {
    variables: { slug },
    client,
    fetchPolicy: 'no-cache',
  });

  const { storeBySlug } = dataStoreBySlug || {};
  const { id } = storeBySlug || {};

  const { loading: storeByIdLoading, error: storeByIdError, data: dataStoreById } = useQuery(SINGLE_STORE_BY_ID, {
    variables: { id, languageCode: currentLocale },
    client,
    fetchPolicy: 'no-cache',
    skip: isEmpty(id),
  });

  const { store: storeById } = dataStoreById || {};
  const { slug: newLanguageSlug } = storeById || {};

  useEffect(() => {
    navigateByLocale(history, currentLocale, 'partner', newLanguageSlug || slug);
  }, [newLanguageSlug, currentLocale]);

  if (loading || storeByIdLoading) return <GMLoading />;
  if (error || !storeBySlug || storeByIdError || !storeById) return <GMError error={t('common:errors:noContent')} />;

  const store = dataStoreBySlug?.storeBySlug;
  const {
    affiliateLink,
    name,
    abstract,
    isGreenDelivery,
    greenKmReward,
    description,
    landscapeBannerId,
    title,
    metaDescription,
    metaKeywords,
    tagline,
  } = store;

  const newContent = parseMarkdown(`<div class="new-content">${decode(description ?? '')}</div>`); //item?.content

  return (
    <GMContainer>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription || abstract} />
        <meta id="og-title" property="og:title" content={title} />
        <meta
          id="og-image"
          property="og:image"
          content={landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''}
        />
        <meta property="og:description" content={metaDescription || abstract} />
        <meta property="og:locale" content={currentLocale} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={metaDescription || abstract} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:site" content="Gremobo" />
        <meta
          name="twitter:image"
          content={landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''}
        />

        <meta name="keywords" content={metaKeywords || ''} />
      </Helmet>

      {store && (
        <Flex px={{ md: '52px' }} py={{ md: '32px' }} maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
          <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
            <GMPartnerContent
              affiliateLink={affiliateLink}
              categoryName={categoryName}
              title={name}
              abstract={abstract}
              isGreenDelivery={isGreenDelivery}
              greenKmReward={greenKmReward}
              description={newContent}
              tagline={tagline}
              imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}`}
              thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}`}
            />
          </Box>
          <GMPartnerSidebar categorySlug={categorySlug} categoryName={categoryName} />
        </Flex>
      )}
    </GMContainer>
  );
};

export default Partner;
