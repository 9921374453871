import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import GMContainer from 'components/GMContainer';
import GMMobilityAuth from 'components/GMMobilityAuth';
import GMMobilityNotAuth from 'components/GMMobilityNotAuth';
import { useProfile } from 'context/profile';
import { Redirect } from 'react-router-dom';

const Mobility: FC = () => {
  const { t } = useTranslation();
  const { userInfo, isEnterprise } = useProfile();

  if (isEnterprise) return <Redirect to="/mobility/enterprise" />;

  return (
    <GMContainer>
      <Helmet>
        <title>{t('mobility:pageTitle')}</title>
        <meta name="mobility" content={t('mobility:pageTitle')} />
      </Helmet>

      <Flex align="flex-end" mb={{ base: '16px', lg: '24px', xl: '32px' }} pt="32px" px={{ lg: '56px' }}>
        <Box maxW={{ base: '536px', lg: '1224px' }} w="100%" mx="auto">
          {userInfo ? <GMMobilityAuth /> : <GMMobilityNotAuth />}
        </Box>
      </Flex>
    </GMContainer>
  );
};

export default Mobility;
