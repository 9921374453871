import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Flex, useDisclosure } from '@chakra-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMModal from 'components/GMModal';
import GMModalErrorContent from 'components/GMModalErrorContent';
import GMPromoContent from 'components/GMSinglePromoContent';
import GMSinglePromoModalConfirmContent from 'components/GMSinglePromoModalConfirmContent';
import GMSinglePromoSidebar from 'components/GMSinglePromoSidebar';
import GMSingleDonationModalSummaryContent from 'components/GMSingleDonationModalSummaryContent';
import { customApolloClient } from 'apollo';
import { useProfile } from 'context/profile';
import { navigateByLocale, parseMarkdown } from 'utils';
import { decode } from 'html-entities';

interface ISingleDonation {
  token?: string;
}

const SINGLE_DONATION_BY_SLUG = gql`
  query donationBySlug($slug: String!) {
    donationBySlug(slug: $slug) {
      id
      expirationDate
      landscapeBannerId
      name
      abstract
      description
      slug
      price
      partnerLink
      thankYouNote
    }
  }
`;

const SINGLE_DONATION_BY_ID = gql`
  query donationById($id: String!, $languageCode: String!) {
    donationById(id: $id, languageCode: $languageCode) {
      slug
    }
  }
`;

const SUBSCRIBE_DONATION = gql`
  mutation subscribeDonation($languageCode: String!, $donationId: String!) {
    subscribeDonation(languageCode: $languageCode, donationId: $donationId) {
      subscribedOn
      donation {
        id
      }
    }
  }
`;

const SingleDonation: FC<ISingleDonation> = () => {
  const { refetchWallet, token } = useProfile();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { slug } = useParams();
  const { state } = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalContent, setModalContent] = useState('');
  const [subscribedOn, setSubscribedOn] = useState('');

  const categoryName = get(state, 'categoryName');
  const categorySlug = get(state, 'categorySlug');

  const history = useHistory();

  const client = useMemo(() => customApolloClient({ clientType: 'promosClient', token }), [token]);

  const { loading, error, data: dataDonationBySlug } = useQuery(SINGLE_DONATION_BY_SLUG, {
    variables: { slug },
    client,
    fetchPolicy: 'no-cache',
  });

  const { donationBySlug } = dataDonationBySlug || {};
  const { id } = donationBySlug || {};

  const { loading: donationByIdLoading, error: donationByIdError, data: dataDonationById } = useQuery(
    SINGLE_DONATION_BY_ID,
    {
      variables: { id, languageCode: currentLocale },
      client,
      fetchPolicy: 'no-cache',
      skip: isEmpty(id),
    },
  );

  const { donationById } = dataDonationById || {};
  const { slug: newLanguageSlug } = donationById || {};

  useEffect(() => {
    navigateByLocale(history, currentLocale, 'donation', newLanguageSlug || slug);
  }, [newLanguageSlug, currentLocale]);

  const [subscribeDonation, { loading: subscribeLoading, error: errorSubscribe }] = useMutation(SUBSCRIBE_DONATION, {
    fetchPolicy: 'no-cache',
    client,
    onCompleted(data) {
      const { subscribeDonation } = data || {};
      const { subscribedOn: redeemedSubscribedOn } = subscribeDonation;
      setSubscribedOn(redeemedSubscribedOn);
      setModalContent('summary');
      refetchWallet();
    },
    onError() {
      setModalContent('error');
    },
  });

  if (loading || donationByIdLoading) return <GMLoading />;
  if (error || !dataDonationBySlug || donationByIdError || !dataDonationById) return <GMError />;

  const donation = dataDonationBySlug?.donationBySlug || {};
  const { expirationDate, landscapeBannerId, name, description, abstract, price, partnerLink, thankYouNote } = donation;

  if (isEmpty(newLanguageSlug))
    return (
      <GMContainer>
        <Flex justify="center" align="center" py="32px">
          <Box maxW={{ md: '536px', lg: '912px' }} mx="auto">
            <Box textAlign="center" mb="20px">
              {t('donations:singleDonation:notFound')}
            </Box>
            <Box textAlign="center" color="PRIMARY" fontWeight="bold" cursor="pointer">
              <Link to={`/${currentLocale}/donations`}>{t('donations:singleDonation:backToList')}</Link>
            </Box>
          </Box>
        </Flex>
      </GMContainer>
    );

  const buyPromoHandler = (): void => {
    setModalContent('confirm');
    onOpen();
  };

  const onConfirmHandler = (): void => {
    subscribeDonation({ variables: { languageCode: currentLocale, donationId: id } });
  };

  const isSoldOut = moment().isAfter(expirationDate);
  const newContent = parseMarkdown(`<div class="new-content">${decode(description ?? '')}</div>`); //item?.content

  return (
    <GMContainer>
      <Helmet>
        <title>{name}</title>
        <meta name="description" content={name} />
      </Helmet>

      {donation && (
        <Flex px={{ md: '52px' }} py={{ md: '32px' }} maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
          <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
            <GMPromoContent
              isSoldOut={isSoldOut}
              price={price}
              categoryName={categoryName}
              title={name}
              abstract={abstract}
              description={newContent}
              partnerLink={partnerLink}
              imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}`}
              thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}`}
              context="donation"
              buyPromoHandler={buyPromoHandler}
            />
          </Box>
          <GMSinglePromoSidebar categorySlug={categorySlug} categoryName={categoryName} context="donations" />
        </Flex>
      )}
      {isOpen && (
        <GMModal isOpen={isOpen} onClose={onClose}>
          {modalContent === 'error' && <GMModalErrorContent onClose={onClose} error={errorSubscribe?.message} />}

          {modalContent === 'confirm' && (
            <GMSinglePromoModalConfirmContent
              onConfirm={onConfirmHandler}
              onClose={onClose}
              loading={subscribeLoading}
              context="donation"
            />
          )}

          {modalContent === 'summary' && (
            <GMSingleDonationModalSummaryContent
              onClose={onClose}
              name={name}
              price={price}
              thankYouNote={thankYouNote}
              subscribedOn={subscribedOn}
            />
          )}
        </GMModal>
      )}
    </GMContainer>
  );
};

export default SingleDonation;
