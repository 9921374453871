import React, { FC, useState } from 'react';
import { Heading, Box, Flex, Input } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import GMButtonGreen from 'components/GMButtonGreen';

interface IGMModalRedeemCoupon {
  onClose?: () => void;
  onRedeemCoupon?: (coupon: string) => void;
  loading?: boolean;
  error?: string;
}

const GMModalRedeemCoupon: FC<IGMModalRedeemCoupon> = ({ onRedeemCoupon, onClose, loading, error }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event?.target?.value.toUpperCase());
  };

  const onClickHandler = (): void => {
    if (!value) return;
    if (onRedeemCoupon) onRedeemCoupon(value);
  };

  return (
    <>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:coupon:modal:title')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }}>
        <Box color="PAGE.SECONDARY_TEXT">{t('profile:coupon:modal:inputLabel')}</Box>
        <Input fontSize="20px" variant="flushed" value={value} onChange={onChange} />
        {error && (
          <Box color="ERROR" fontSize="14px">
            {error}
          </Box>
        )}
      </Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" variant="transparent" onClick={onClose} disabled={loading}>
            {t('common:cancel')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" onClick={onClickHandler} disabled={!value} loading={loading}>
            {t('profile:coupon:modal:getBtn')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </>
  );
};

export default GMModalRedeemCoupon;
