import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import { useQuery, gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import GMContainer from 'components/GMContainer';
import GMChosenForYou from 'components/GMChosenForYou';
import GMNews from 'components/GMNews';
import GMCategories from 'components/GMCategories';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import GMSearchBar from 'components/GMSearchBar';
import { useProfile } from 'context/profile';
import { chosenForYouPerPage } from 'constants/index';
import GMLoading from 'components/GMLoading';
import GMError from 'components/GMError';

const GET_HIGHLIGHTED_ARTICLES = gql`
  query allRecentPosts($currentLocale: String!, $limit: Int!, $isHighlight: Boolean) {
    posts(languageCode: $currentLocale, opts: { itemsPerPage: $limit, isHighlight: $isHighlight }) {
      totalCount
      edges {
        node {
          id
          slug
          portraitBannerId
          landscapeBannerId
          publishedAt
          categoryIds
          title
          abstract
          content
          isHighlight
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const GET_ALL_ARTICLES = gql`
  query allPosts($currentLocale: String!, $limit: Int!, $isHighlight: Boolean) {
    posts(languageCode: $currentLocale, opts: { itemsPerPage: $limit, isHighlight: $isHighlight }) {
      totalCount
      edges {
        node {
          id
          slug
          portraitBannerId
          landscapeBannerId
          publishedAt
          categoryIds
          title
          abstract
          content
          isHighlight
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const GET_THREE_ARTICLES_PER_CATEGORY = gql`
  query threeArticlesPerCategory($currentLocale: String!, $limit: Int!) {
    categories(languageCode: $currentLocale) {
      id
      posts(opts: { itemsPerPage: $limit }) {
        totalCount
        edges {
          node {
            id
            slug
            portraitBannerId
            landscapeBannerId
            publishedAt
            categoryIds
            title
            abstract
            content
          }
        }
        pageInfo {
          endCursor
          hasNext
        }
      }
    }
  }
`;

const GET_POSTS_IN_CATEGORY = gql`
  query postsInCategoryList($opts: SearchPostOptions, $currentLocale: String!) {
    posts(languageCode: $currentLocale, opts: $opts) {
      totalCount
      edges {
        node {
          portraitBannerId
          landscapeBannerId
          title
          abstract
          slug
        }
      }
    }
  }
`;

const Lifestyle: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { userInfo } = useProfile();

  const { loading, error, data } = useQuery(GET_HIGHLIGHTED_ARTICLES, {
    variables: {
      currentLocale,
      limit: 5,
      isHighlight: true,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: dataRecentArticle } = useQuery(GET_ALL_ARTICLES, {
    variables: {
      currentLocale,
      limit: 5,
      isHighlight: false,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    // @TODO: Add component for error and loading
    data: dataThreeArticlesPerCategory,
  } = useQuery(GET_THREE_ARTICLES_PER_CATEGORY, {
    variables: { currentLocale, limit: 3 },
    fetchPolicy: 'no-cache',
  });

  const searchOptions = {
    byAnyCategoryIds: userInfo?.myData?.categoryIds,
    itemsPerPage: chosenForYouPerPage,
    isRead: false,
  };

  const { data: dataChosenForYou } = useQuery(GET_POSTS_IN_CATEGORY, {
    variables: {
      currentLocale,
      opts: searchOptions,
      fetchPolicy: 'no-cache',
    },
    skip: isEmpty(userInfo?.myData?.categoryIds),
  });

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const searchInput = (
    <Box minW={{ lg: '216px', xl: '204px' }} ml={{ lg: '96px', xl: '232px' }} display={{ base: 'none', lg: 'block' }}>
      <GMInputSearchHandler path="/search/articles" />
    </Box>
  );

  return (
    <GMContainer>
      <Helmet>
        <title>{t('lifestyle:pageTitle')}</title>
        <meta name="description" content={t('lifestyle:pageTitle')} />
      </Helmet>
      <GMSearchBar path="/search/articles" />

      {!isEmpty(data?.posts?.edges) && (
        <GMNews
          articles={data?.posts?.edges}
          title={t('lifestyle:highlight:title')}
          onlyHightlight={dataRecentArticle?.posts?.edges.length > 0}
          search={searchInput}
        />
      )}
      {!isEmpty(dataRecentArticle?.posts.edges) && (
        <GMNews
          articles={dataRecentArticle?.posts?.edges}
          title={t('lifestyle:news:title')}
          readMore
          search={!data?.posts?.edges.length && searchInput}
        />
      )}
      {!isEmpty(dataChosenForYou?.posts?.edges) && <GMChosenForYou articles={dataChosenForYou?.posts?.edges} />}
      <GMCategories articles={dataThreeArticlesPerCategory?.categories} />
    </GMContainer>
  );
};

export default Lifestyle;
