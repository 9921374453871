import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { MdLocalShipping } from 'react-icons/md';

interface GMGreenDeliveryIconProps {
  padding?: string;
}
const GMGreenDeliveryIcon: FC<GMGreenDeliveryIconProps> = ({ padding = 0 }) => {
  return (
    <Box backgroundColor="ICON.GREEN_DELIVERY.BACKGROUND" w="100%" h="100%" color="ICON.GREEN_DELIVERY.CONTENT">
      <Flex justify="center" align="center" p={padding}>
        <MdLocalShipping size="100%" />
      </Flex>
    </Box>
  );
};

export default GMGreenDeliveryIcon;
