import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import GMInput from 'components/GMInput';
import useBreakpoint from 'hooks/useBreakpoint';

interface IGMInputSearchHandler {
  path?: string;
  category?: string;
  type?: string;
  placeholder?: string;
  borderColor?: string;
  border?: string;
  iconColor?: string;
  cursor?: string;
  shouldRedirectOnClick?: boolean;
  readOnly?: boolean;
}

const GMInputSearchHandler: FC<IGMInputSearchHandler> = ({
  path,
  category,
  type,
  placeholder,
  borderColor,
  border,
  iconColor,
  cursor,
  shouldRedirectOnClick,
  readOnly,
}) => {
  const breakpoint = useBreakpoint();
  const { t } = useTranslation();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';
  const [query, setQuery] = useState('');

  const history = useHistory();

  const redirect = (): void => {
    history.push({ pathname: path, state: { category, query } });
  };

  const onInputHandler = (event): void => {
    setQuery(event?.target?.value);
  };

  const onKeyDownHandler = (event): void => {
    if (event.key === 'Enter') {
      redirect();
    }
  };

  return (
    <GMInput
      onInput={(event) => onInputHandler(event)}
      placeholder={placeholder || t('common:search')}
      cursor={cursor}
      iconType="search"
      type={type}
      size={lg || xl ? 'big' : 'small'}
      onClickIcon={redirect}
      onClick={() => {
        if (shouldRedirectOnClick) redirect();
      }}
      onKeyDown={(event) => onKeyDownHandler(event)}
      border={border}
      borderColor={borderColor}
      iconColor={iconColor}
      readOnly={readOnly}
    />
  );
};

export default GMInputSearchHandler;
