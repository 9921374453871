import React, { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Box, Flex } from '@chakra-ui/core';
import { gql, useMutation } from '@apollo/client';
import { useProfile } from 'context/profile';
import keycloak from 'keycloak';
import customApolloClient from 'apollo';

import GMButtonGreen from 'components/GMButtonGreen';

interface IGMModalDeleteAccount {
  onClose?: () => void;
}
const DELETE_ACCOUNT = gql`
  mutation DeleteMyData {
    deleteMyData
  }
`;
const GMModalDeleteAccount: FC<IGMModalDeleteAccount> = ({ onClose }) => {
  const { t } = useTranslation();
  const [deleteError, setDeleteError] = useState(false);
  const { token } = useProfile();

  const client = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  const [DeleteAccount] = useMutation(DELETE_ACCOUNT, {
    client,
    onCompleted({ deleteMyData }) {
      deleteMyData && keycloak.logout();
    },
    onError() {
      setDeleteError(true);
    },
  });

  const onClickHandler = (): void => {
    DeleteAccount();
    return;
  };

  return !deleteError ? (
    <>
      <Heading color="ERROR" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:buttons:deleteAccount')}
      </Heading>
      <Box pb="20px">{t('profile:delete:deleteAccountModal')}</Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" onClick={onClose}>
            {t('common:cancel')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" variant="outlinedRed" onClick={onClickHandler}>
            {t('profile:delete:confirm')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </>
  ) : (
    <>
      <Box color="ERROR" pb="20px">
        {t('profile:delete:deleteAccountError')}
      </Box>
      <GMButtonGreen size="medium" onClick={onClose}>
        {t('common:close')}
      </GMButtonGreen>
    </>
  );
};

export default GMModalDeleteAccount;
