import { palette } from 'theme/theme';

export const categories = {
  'sdg-1': palette.SDG1,
  'sdg-2': palette.SDG2,
  'sdg-3': palette.SDG3,
  'sdg-4': palette.SDG4,
  'sdg-5': palette.SDG5,
  'sdg-6': palette.SDG6,
  'sdg-7': palette.SDG7,
  'sdg-8': palette.SDG8,
  'sdg-9': palette.SDG9,
  'sdg-10': palette.SDG10,
  'sdg-11': palette.SDG11,
  'sdg-12': palette.SDG12,
  'sdg-13': palette.SDG13,
  'sdg-14': palette.SDG14,
  'sdg-15': palette.SDG15,
  'sdg-16': palette.SDG16,
  'sdg-17': palette.SDG17,
};

export const localizedCategories = {
  'sdg-1': 'common:sdg1',
  'sdg-2': 'common:sdg2',
  'sdg-3': 'common:sdg3',
  'sdg-4': 'common:sdg4',
  'sdg-5': 'common:sdg5',
  'sdg-6': 'common:sdg6',
  'sdg-7': 'common:sdg7',
  'sdg-8': 'common:sdg8',
  'sdg-9': 'common:sdg9',
  'sdg-10': 'common:sdg10',
  'sdg-11': 'common:sdg11',
  'sdg-12': 'common:sdg12',
  'sdg-13': 'common:sdg13',
  'sdg-14': 'common:sdg14',
  'sdg-15': 'common:sdg15',
  'sdg-16': 'common:sdg16',
  'sdg-17': 'common:sdg17',
};
