import React, { FC, useCallback } from 'react';
import Table from 'rc-table';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from '@chakra-ui/core';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import map from 'lodash/map';
import moment from 'moment';
import { palette } from 'theme/theme';
import Bin from 'assets/svg-components/bin';
import useBreakpoint from 'hooks/useBreakpoint';
import { localizedMotors } from 'constants/motors';

interface IGMMobilityFleetTable {
  fleet: {
    id: string;
    name: string;
    model: string;
    licensePlate: string;
    engineType: string;
    registrationDate: Date;
    co2Emissions: number;
    kmDistance: number;
    kmDistanceMeasuredAt: Date;
  }[];
  deleteVehicle?: (id: string | undefined) => void;
}

interface IDeleteBtn {
  deleteVehicle?: (id: string | undefined) => void;
}

interface ILine {
  title?: string;
  value?: string;
}

interface IVerticalTable {
  data: {
    id?: string;
    name?: string;
    model?: string;
    licensePlate?: string;
    engineType?: string;
    registrationDate?: Date;
    co2Emissions?: number;
    updateDate?: Date;
    kmDistance?: number;
    kmDistanceMeasuredAt?: Date;
  }[];
  deleteVehicle?: (id: string | undefined) => void;
}

const DeleteBtn: FC<IDeleteBtn> = ({ deleteVehicle }) => {
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const onClickHandler = useCallback((id): void => {
    if (deleteVehicle) deleteVehicle(id);
  }, []);

  return (
    <Flex
      justify="center"
      align="center"
      bg={{ base: 'ICON.DELETE_DARK.BACKGROUND', lg: 'ICON.DELETE_LIGHT.BACKGROUND' }}
      w="40px"
      h="40px"
      borderRadius="4px"
      mx={{ lg: 'auto' }}
      color={lg || xl ? 'ICON.DELETE_LIGHT.COLOR' : 'ICON.DELETE_DARK.COLOR'}
      onClick={onClickHandler}
      cursor="pointer"
    >
      <MdClose size={24} />
    </Flex>
  );
};

const Style = styled.div`
  color: ${palette.DARK_GREY};
  font-size: 14px;

  table {
    width: 100%;
  }

  td,
  th {
    padding: 0px 12px;
    font-weight: normal;
  }

  thead {
    background-color: #666666;
    color: white;
    text-align: left;
    tr {
      th {
        font-size: 16px;
        :last-of-type {
          padding: 12px;
          border-left: 1px solid white;
        }
      }
    }
  }

  tbody {
    tr {
      :first-of-type {
        backgroud-color: ${palette.LIGHTEST_GREY};
      }
      :nth-of-type(2n) {
        background-color: ${palette.LIGHT_GREY};
      }

      td {
        border-left: 1px solid ${palette.MEDIUM_GREY};
        :first-of-type {
          border-left: none;
        }
        :last-of-type {
          border-left: none;
          background-color: white;
          padding: 4px;
        }
      }
    }
  }
`;

const Line: FC<ILine> = ({ title, value }) => {
  return (
    <Flex w="100%" flexGrow={1}>
      <Box w="50%" color={palette.MEDIUM_GREY}>
        {title}
      </Box>
      <Box w="50%">{value}</Box>
    </Flex>
  );
};

const VerticalTable: FC<IVerticalTable> = ({ data = [], deleteVehicle }) => {
  const { t } = useTranslation();

  const deleteVehicleHandler = (id: string | undefined): void => {
    if (deleteVehicle) deleteVehicle(id);
  };

  return (
    <>
      {map(data, (entry, index) => {
        const {
          id,
          model,
          licensePlate,
          engineType,
          registrationDate,
          co2Emissions,
          kmDistance,
          kmDistanceMeasuredAt,
        } = entry;

        return (
          <Box bg={Number(index) % 2 === 0 ? 'SUPER_LIGHT_GRAY' : 'LIGHT'} p="16px" color="SECONDARY" key={id}>
            <Flex fontSize="14px" lineHeight={1.3}>
              <Box w="60%">
                <Box fontSize="18px" fontWeight="bold">
                  {entry.name}
                </Box>
                <Line title={t('mobility:enterprise:myFleet:table:model')} value={model} />
                <Line title={t('mobility:enterprise:myFleet:table:licensePlate')} value={licensePlate} />
                <Line
                  title={t('mobility:enterprise:myFleet:table:engineType')}
                  value={engineType && t(localizedMotors[engineType])}
                />
                <Line
                  title={t('mobility:enterprise:myFleet:table:registrationDate')}
                  value={moment(registrationDate).format('DD/MM/YY')}
                />
                <Line
                  title={t('mobility:enterprise:myFleet:table:emissions')}
                  value={`${co2Emissions} ${t('mobility:enterprise:myFleet:emissionUnit')}`}
                />
              </Box>
              <Flex direction="column" justify="space-between" align="flex-end" w="40%">
                <DeleteBtn deleteVehicle={() => deleteVehicleHandler(id)} />
                <Box bg="PRIMARY" p="8px" color="WHITE">
                  <Box fontWeight="bold" fontSize="14px">
                    {kmDistance} {t('mobility:enterprise:myFleet:table:km')}
                  </Box>
                  <Box fontSize="12px">
                    {t('mobility:enterprise:myFleet:table:updateDate', {
                      date: moment(kmDistanceMeasuredAt).format('DD/MM/YY'),
                    })}
                  </Box>
                </Box>
              </Flex>
            </Flex>
          </Box>
        );
      })}
    </>
  );
};

const GMMobilityFleetTable: FC<IGMMobilityFleetTable> = ({ fleet, deleteVehicle }) => {
  const { t } = useTranslation();

  const emissionRender = (data): string => {
    return `${data} ${t('mobility:enterprise:myFleet:emissionUnit')}`;
  };

  const deleteBtnRender = ({ id }): JSX.Element => {
    const deleteVehicleHandler = useCallback((id: string | undefined): void => {
      if (deleteVehicle) deleteVehicle(id);
    }, []);
    return <DeleteBtn deleteVehicle={() => deleteVehicleHandler(id)} />;
  };

  const dateRender = (data): string => {
    return moment(data).format('DD/MM/YYYY');
  };

  const motorRender = (data): string => {
    return t(localizedMotors[data]);
  };

  const updateDateRender = (data): JSX.Element => {
    return (
      <Box fontSize="12px" lineHeight={1.1} color={palette.MEDIUM_GREY}>
        {t('mobility:enterprise:myFleet:table:updatedAt', {
          date: moment(data).format('DD/MM/YY'),
        })}
      </Box>
    );
  };

  const columns = [
    {
      title: t('mobility:enterprise:myFleet:table:id'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('mobility:enterprise:myFleet:table:model'),
      dataIndex: 'model',
      key: 'model',
      ellipsis: true,
    },
    {
      title: t('mobility:enterprise:myFleet:table:licensePlate'),
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      ellipsis: true,
    },
    {
      title: t('mobility:enterprise:myFleet:table:engineType'),
      dataIndex: 'engineType',
      key: 'engineType',
      ellipsis: true,
      render: motorRender,
    },
    {
      title: t('mobility:enterprise:myFleet:table:registrationDate'),
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      ellipsis: true,
      render: dateRender,
    },
    {
      title: t('mobility:enterprise:myFleet:table:emissions'),
      dataIndex: 'co2Emissions',
      key: 'co2Emissions',
      ellipsis: true,
      render: emissionRender,
    },
    {
      title: t('mobility:enterprise:myFleet:table:kmDistance'),
      dataIndex: 'kmDistance',
      key: 'kmDistance',
      ellipsis: true,
    },
    {
      title: '',
      dataIndex: 'kmDistanceMeasuredAt',
      key: 'kmDistanceMeasuredAt',
      width: 100,
      render: updateDateRender,
    },
    {
      title: <Bin />,
      dataIndex: '',
      key: 'delete',
      width: 50,
      render: deleteBtnRender,
    },
  ];

  return (
    <>
      <Box display={{ base: 'none', lg: 'block' }}>
        <Style>
          <Table columns={columns} data={fleet} emptyText={t('mobility:enterprise:myFleet:noVehicles')} />
        </Style>
      </Box>
      <Box display={{ lg: 'none' }}>
        <VerticalTable data={fleet} deleteVehicle={deleteVehicle} />
      </Box>
    </>
  );
};

export default GMMobilityFleetTable;
