import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import GMInputSearchHandler from 'components/GMInputSearchHandler';

interface GMSingleCategorySidebarProps {
  category?: string;
}

const GMSingleCategorySidebar: FC<GMSingleCategorySidebarProps> = ({ category }) => {
  return (
    <Box
      position="relative"
      w={{ lg: '312px' }}
      pl={{ lg: '96px', xl: '128px' }}
      display={{ base: 'none', lg: 'block' }}
    >
      <Box position="sticky" top="5">
        <GMInputSearchHandler path="/search/articles" category={category} />
      </Box>
    </Box>
  );
};

export default GMSingleCategorySidebar;
