import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

interface IGMError {
  error?: string;
}

const GMError: FC<IGMError> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Flex
      maxW={{ lg: '1224px' }}
      mx="auto"
      alignItems="center"
      h="150px"
      w="100%"
      justify="center"
      px="16px"
      pt={{ base: '200px', md: '400px' }}
      pb={{ base: '150px', md: '250px' }}
    >
      <Box textAlign="center">{error || t('common:errors:errorOccured')}</Box>
    </Flex>
  );
};

export default GMError;
