import React, { FC, useCallback, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import { Box, Flex, Heading, Divider, Link } from '@chakra-ui/core';
import moment from 'moment';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import GMButtonGreen from 'components/GMButtonGreen';
import GMUserDataItem from 'components/GMUserDataItem';
import GMTagProfile from 'components/GMTagProfile';
import { useBtnSize } from 'hooks/useProfileLayout';
import { getLocalizedCountries } from 'utils';
import { localizedCategories } from 'constants/categories';
import { useProfile } from 'context/profile';

interface IGMUserAccount {
  account: Account;
  openModal: (modal: string) => void;
}

interface Account {
  address: string;
  ageGroup: string;
  categoryIds: [string];
  createdAt: Date;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  country: string;
  preferredCountry: string;
  phoneNumber: string;
  vehicles: [Vehicle];
  inviteCode: string;
  companyName: string;
  VAT: string;
  companyCategory: string;
}

interface Vehicle {
  id: string;
  owned: boolean;
}

const GMUserAccount: FC<IGMUserAccount> = ({ account, openModal }) => {
  const { t, i18n } = useTranslation();
  const { isEnterprise } = useProfile();
  const [resetLink, setResetLink] = useState('');

  const currentLocale = i18n.language;
  const localizedCountries = getLocalizedCountries(currentLocale);

  const [keycloak] = useKeycloak();

  useEffect(() => {
    const originalURL = keycloak?.createLoginUrl();
    const resetPwURL = originalURL.replace('auth?', 'forgot-credentials?');
    setResetLink(resetPwURL);
  }, []);

  const logout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  const openModalHandler = (modal): void => {
    openModal(modal);
  };

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    ageGroup,
    address,
    createdAt,
    vehicles,
    country,
    preferredCountry,
    categoryIds,
    inviteCode,
    companyName,
    VAT,
    companyCategory,
  } = account;

  const hasOwnedVehicle = filter(vehicles, (vehicle) => vehicle.owned).length > 0;
  const literalAgeGroup = {
    '<24': t('profile:account:modal:lessThan24'),
    '25-39': '25-39',
    '40-55': '40-55',
    '>=56': t('profile:account:modal:moreThan56'),
  };

  return (
    <Box color="SECONDARY" fontSize={{ base: 'lg', lg: 'xl', xl: '22px' }}>
      <Flex justify="space-between">
        <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
          {t('profile:account:title')}
        </Heading>
        <Flex display={{ base: 'flex', lg: 'none' }} wrap="nowrap" width="20px" justify="space-between">
          <Box color="PRIMARY" cursor="pointer">
            <MdEdit size="20px" onClick={() => openModalHandler('account')} />
          </Box>
        </Flex>
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Box mr={{ lg: '96px', xl: '128px' }} w={{ base: '100%', lg: '50%' }}>
          <Box pb={{ base: '16px', lg: '24px' }}>
            <Box fontSize={{ base: '22px', lg: '2xl', xl: '28px' }} fontWeight="bold" pb={{ base: '8px', lg: '16px' }}>
              {firstName} {lastName}
            </Box>

            <Box pb={{ base: '8px', lg: '16px' }}>{email}</Box>
            {phoneNumber && <Box pb={{ base: '8px', lg: '16px' }}>{phoneNumber}</Box>}
            {inviteCode && <GMUserDataItem label={t('profile:account:inviteCode')}>{inviteCode}</GMUserDataItem>}
          </Box>
          <Box display={{ base: 'none', lg: 'block' }}>
            <Box pb="16px">
              <GMButtonGreen size={btnSize} onClick={() => openModalHandler('account')}>
                {t('profile:buttons:editData')}
              </GMButtonGreen>
            </Box>

            <Box pb="16px">
              <GMButtonGreen size={btnSize}>
                <Link href={resetLink} className="no-underlined">
                  {t('profile:buttons:editPassword')}
                </Link>
              </GMButtonGreen>
            </Box>

            <Box pb="16px">
              <GMButtonGreen size={btnSize} onClick={() => openModalHandler('preferences')}>
                {t('profile:buttons:preferences')}
              </GMButtonGreen>
            </Box>

            <Box pb="16px">
              <GMButtonGreen size={btnSize} onClick={logout}>
                {t('common:logout')}
              </GMButtonGreen>
            </Box>

            <Box>
              <GMButtonGreen variant="outlinedRed" size={btnSize} onClick={() => openModalHandler('deleteAccount')}>
                {t('profile:buttons:deleteAccount')}
              </GMButtonGreen>
            </Box>
          </Box>
        </Box>
        <Box w={{ base: '100%', lg: '50%' }}>
          {ageGroup && (
            <GMUserDataItem label={t('profile:account:ageGroup')}>{literalAgeGroup[ageGroup]}</GMUserDataItem>
          )}
          {createdAt && (
            <GMUserDataItem label={t('profile:account:createdAt')}>
              {moment(createdAt).format('DD/MM/YYYY')}
            </GMUserDataItem>
          )}

          {!isEnterprise && address && <GMUserDataItem label={t('profile:account:address')}>{address}</GMUserDataItem>}

          {hasOwnedVehicle && (
            <GMUserDataItem label={t('profile:account:ownedVehicles')}>
              <Flex mt="6px" wrap="wrap">
                {map(vehicles, (vehicle) => {
                  return (
                    vehicle?.owned && <GMTagProfile key={vehicle?.id} tagName={t(`common:vehicles:${vehicle?.id}`)} />
                  );
                })}
              </Flex>
            </GMUserDataItem>
          )}

          {isEnterprise && (
            <Heading as="h3" color="PRIMARY" fontSize="lg" pb={{ base: '12px', lg: '16px' }} pt="16px">
              {t('profile:account:companyData')}
            </Heading>
          )}

          {companyName && <GMUserDataItem label={t('profile:account:companyName')}>{companyName}</GMUserDataItem>}

          {isEnterprise && address && (
            <GMUserDataItem label={t('profile:account:legalAddress')}>{address}</GMUserDataItem>
          )}

          {VAT && <GMUserDataItem label={t('profile:account:VAT')}>{VAT}</GMUserDataItem>}

          {companyCategory && (
            <GMUserDataItem label={t('profile:account:companyCategory')}>
              {t(`common:companyCategories:${companyCategory}`)}
            </GMUserDataItem>
          )}

          {country && (
            <GMUserDataItem label={t('profile:account:companyNationality')}>
              {localizedCountries[country]}
            </GMUserDataItem>
          )}

          <Divider my="30px" display={{ base: 'none', lg: 'block' }} />

          <Box display={{ base: 'none', lg: 'block' }}>
            {preferredCountry && (
              <GMUserDataItem label={t('profile:account:nationality')}>
                {localizedCountries[preferredCountry]}
              </GMUserDataItem>
            )}

            {!isEmpty(categoryIds) && (
              <GMUserDataItem label={t('profile:preferences:lifestyleCategories')}>
                <Flex mt="6px" wrap="wrap">
                  {map(
                    categoryIds,
                    (category) =>
                      category && <GMTagProfile key={category} tagName={t(localizedCategories[category])} />,
                  )}
                </Flex>
              </GMUserDataItem>
            )}
          </Box>
        </Box>
        <Box display={{ lg: 'none' }}>
          <Box pb="16px">
            <GMButtonGreen size={btnSize}>
              <Link href={resetLink} className="no-underlined">
                {t('profile:buttons:editPassword')}
              </Link>
            </GMButtonGreen>
          </Box>
          <Box>
            <GMButtonGreen size={btnSize} onClick={logout}>
              {t('common:logout')}
            </GMButtonGreen>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default GMUserAccount;
