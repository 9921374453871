import React, { FC } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { customApolloClient } from 'apollo';

const GET_ALL_STORES = gql`
  query allStores($currentLocale: String!, $limit: Int!, $isHighlight: Boolean!, $hideAppOnly: Boolean) {
    stores(languageCode: $currentLocale, limit: $limit, highlight: $isHighlight, hideAppOnly: $hideAppOnly) {
      id
      landscapeBannerId
      name
    }
  }
`;

const InfoPage: FC = () => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { data } = useQuery(GET_ALL_STORES, {
    variables: {
      currentLocale,
      limit: 6,
      isHighlight: false,
      hideAppOnly: true,
    },
    client: customApolloClient({ clientType: 'shopClient' }),
  });

  return <div>Reserved Page</div>;
};

export default InfoPage;
