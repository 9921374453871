import useBreakpoint from 'hooks/useBreakpoint';

const useFontWeight = (): Function => {
  const breakpoint = useBreakpoint();
  return () => {
    switch (`${breakpoint}`) {
      case 'base':
      case 'sm':
      case 'md':
        return 'bold';
      case 'lg':
      case 'xl':
        return 'black';
    }
  };
};

export default useFontWeight;
