import React, { FC, useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { useQuery, gql, NetworkStatus } from '@apollo/client';
import { isEmpty, uniqBy, filter, keys, get } from 'lodash';
import { AppContext } from 'context';
import InfiniteScroll from 'react-infinite-scroll-component';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMSectionContainer from 'components/GMSectionContainer';
import GMSearchBar from 'components/GMSearchBar';
import GMShopCategoryMesh from 'components/GMShopCategoryMesh';
import GMShopCategorySidebar from 'components/GMShopCategorySidebar';
import GMLoader from 'components/GMLoader';
import GMLoading from 'components/GMLoading';
import { customApolloClient } from 'apollo';
import { itemsPerPage } from 'constants/index';
import InView from 'react-intersection-observer';
import { navigateByLocale } from 'utils';

interface EdgesProps {
  id: string;
  landscapeBannerId: string;
  isGreenDelivery: boolean;
  greenKmReward: number;
  name: string;
  tagline: string;
  abstract: string;
  description: string;
  metaDescription: string;
  metaKeywords: string;
  node: {
    id: string;
    landscapeBannerId: string;
    isGreenDelivery: boolean;
    greenKmReward: number;
    name: string;
    tagline: string;
    abstract: string;
    description: string;
    metaDescription: string;
    metaKeywords: string;
    slug: string;
  };
}

const GET_STORES_IN_CATEGORY = gql`
  query searchStores($after: String, $limit: Int, $filter: SearchStoresFilterInput!) {
    searchStores(limit: $limit, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          landscapeBannerId
          isGreenDelivery
          name
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
      categoryName
    }
  }
`;
const GET_CATEGORIES = gql`
  query categories($languageCode: String!, $entityType: EntityType) {
    categories(languageCode: $languageCode, entityType: $entityType) {
      id
      slug
    }
  }
`;
const client = customApolloClient({ clientType: 'shopClient' });
const categoriesClient = customApolloClient({ clientType: 'categoriesClient' });

const Category: FC = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const currentLocale = i18n.language;

  const { slug } = useParams();

  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const [stores, setStores] = useState([{}] as EdgesProps[]);
  const [categoryId, setCategoryId] = useState('');
  const { isDivInView, setIsDivInView } = useContext(AppContext);
  const { data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      languageCode: currentLocale,
      entityType: 'STORE',
    },
    client: categoriesClient,
    fetchPolicy: 'network-only',
  });
  const categories = dataCategories?.categories;
  /**
   * On first load we take category id
   */
  useEffect(() => {
    if (!isEmpty(dataCategories) && !categoryId) {
      const findCategory = categories.find((item) => item.slug === slug);
      findCategory && setCategoryId(findCategory.id);
    }
    if (!isEmpty(dataCategories) && categoryId) {
      const findCategory = categories.find((item) => item.id === categoryId);
      findCategory && navigateByLocale(history, currentLocale, 'partners', findCategory.slug);
    }
  }, [dataCategories]);

  useEffect(() => {
    !slug && navigateByLocale(history, currentLocale, 'partners');
  }, [currentLocale, slug]);

  const storesSearchFilter = {
    languageCode: currentLocale,
    hideAppOnly: true,
    categorySlugs: slug ? [slug] : undefined,
  };

  const { loading, error, data, networkStatus, fetchMore } = useQuery(GET_STORES_IN_CATEGORY, {
    variables: {
      filter: storesSearchFilter,
      limit: itemsPerPage,
      after: undefined,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  const { searchStores } = data || {};

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');

    return () => addClass;
  });

  const pageInfo = searchStores?.pageInfo;
  const edges = searchStores?.edges;

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setStores(edges);
    }
  }, [data, edges, pageInfo]);

  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: {
          filter: storesSearchFilter,
          after: pageInfoState.endCursor,
        },
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'searchStores.edges', []);
          const newPageInfo = get(fetchMoreResult, 'searchStores.pageInfo', {});

          setPageInfo(newPageInfo);
          setStores(uniqBy([...stores, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const cleanedStores = filter(stores, (val) => keys(val).length !== 0);
  const { categoryName } = searchStores;

  return (
    <GMContainer>
      <Helmet>
        <title>{searchStores?.categoryName || t('shopping:stores:title')}</title>
        <meta name="description" content={searchStores?.categoryName || t('shopping:stores:title')} />
      </Helmet>
      <GMSearchBar path="/search/partners" category={slug} />

      <GMSectionContainer>
        <Flex>
          <Box maxW={{ base: '536px', lg: 'calc(100% - 312px)' }} mx="auto" w="100%">
            <Heading
              pb={{ base: '24px', xl: '48px' }}
              color="PRIMARY"
              fontSize={{ base: '20px', lg: '28px', xl: '32px' }}
            >
              {searchStores?.categoryName || t('partners:allPartnersTitle')}
            </Heading>

            <InfiniteScroll
              dataLength={cleanedStores.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState.hasNext && (
                  <Box margin="10px auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0.5}
              style={{ overflowY: 'hidden' }}
            >
              <GMShopCategoryMesh categorySlug={slug} categoryName={categoryName} partners={cleanedStores} />
              <InView as="div" onChange={(inView) => setIsDivInView({ type: 'setDivInView', payload: inView })}>
                <></>
              </InView>
            </InfiniteScroll>
          </Box>
          <GMShopCategorySidebar storeCategorySlug={slug} />
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Category;
