import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { Box, Divider } from '@chakra-ui/core';
import { GMTag } from 'components/GMTag';
import { useTagSize } from 'hooks/useSearchPageLayout';

interface SidebarSearchArticlesProps {
  children?: ReactNode;
  activeFilters?: Filters;
  onSelectDateFilter?: (newValue: string) => void;
  onChangeReadedHandler?: (newValue: string) => void;
}

interface Filters {
  byRead?: boolean;
  byDate?: string;
  byCategory?: string[];
  byQuery?: string;
}

const SidebarSearchArticles: FC<SidebarSearchArticlesProps> = ({
  activeFilters,
  onSelectDateFilter,
  onChangeReadedHandler,
}) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onClickDateFilterHandler = (newValue: string): void => {
    if (onSelectDateFilter) onSelectDateFilter(newValue);
  };

  const onClickReadedFilterHandler = (newValue: string): void => {
    if (onChangeReadedHandler) onChangeReadedHandler(newValue);
  };

  return (
    <>
      <Box flexShrink={0} flexGrow={1}>
        <Box mb="8px">
          <GMTag
            size={tagSize}
            tagName={t('search:allArticles')}
            onClick={() => onClickReadedFilterHandler('allArticles')}
            active={activeFilters?.byRead === undefined}
          />
        </Box>
        {keycloak?.authenticated && (
          <>
            <Box mb="8px">
              <GMTag
                size={tagSize}
                tagName={t('search:alreadyRead')}
                onClick={() => onClickReadedFilterHandler('alreadyRead')}
                active={activeFilters?.byRead}
              />
            </Box>
            <Box mb="8px">
              <GMTag
                size={tagSize}
                tagName={t('search:notReadYet')}
                onClick={() => onClickReadedFilterHandler('notReadYet')}
                active={!activeFilters?.byRead && activeFilters?.byRead !== undefined}
              />
            </Box>
          </>
        )}
      </Box>

      <Divider my="16px" />

      <Box flexShrink={0} flexGrow={1}>
        <Box mb="8px">
          <GMTag
            size={tagSize}
            tagName={t('search:anyDate')}
            active={!activeFilters?.byDate}
            onClick={() => onClickDateFilterHandler('anyDate')}
          />
        </Box>
        <Box mb="8px">
          <GMTag
            size={tagSize}
            tagName={t('search:lastMonth')}
            active={!!activeFilters?.byDate}
            onClick={() => onClickDateFilterHandler('lastMonth')}
          />
        </Box>
      </Box>
    </>
  );
};

export default SidebarSearchArticles;
