import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/core';
import GMButtonGreen from 'components/GMButtonGreen';

interface IGMModalErrorContent {
  error?: string;
  onClose: () => void;
}

const GMModalErrorContent: FC<IGMModalErrorContent> = ({ error, onClose }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box pb="10px">
        {t('common:errors:errorOccured')}: {error}
      </Box>
      <GMButtonGreen size="medium" variant="transparent" onClick={onClose}>
        {t('common:cancel')}
      </GMButtonGreen>
    </Box>
  );
};

export default GMModalErrorContent;
