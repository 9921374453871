// Import here your languages
import en from './locales/en/translations.json';
import it from './locales/it/translations.json';
import de from './locales/de/translations.json';
import fr from './locales/fr/translations.json';
import es from './locales/es/translations.json';

// Export here your language files import
export const languagesResources = {
  en,
  it,
  de,
  fr,
  es,
};
