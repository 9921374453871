import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import { Box, SimpleGrid } from '@chakra-ui/core';
import GMPhoto from 'components/GMPhoto';
import GMGreenDeliveryIcon from 'components/GMGreenDeliveryIcon';
import useBreakpoint from 'hooks/useBreakpoint';

interface GMCategoryMeshProps {
  partners?: Store[];
  categoryName?: string;
  categorySlug?: string;
}

interface Store {
  node: {
    id: string;
    landscapeBannerId: string;
    isGreenDelivery: boolean;
    greenKmReward: number;
    name: string;
    tagline: string;
    abstract: string;
    description: string;
    metaDescription: string;
    metaKeywords: string;
    slug: string;
  };
}

const GMCategoryMesh: FC<GMCategoryMeshProps> = ({ partners = [], categoryName, categorySlug }) => {
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <>
      <SimpleGrid columns={{ lg: 2, xl: 3 }} spacing={{ base: '17px', lg: '18px', xl: '24px' }}>
        {map(partners, (partner, index) => {
          const singleShop = partner?.node;
          return (
            <Box position="relative" h={{ base: '160px', lg: '156px' }} key={singleShop?.id || Number(index)}>
              {singleShop?.isGreenDelivery && (
                <Box
                  position="absolute"
                  zIndex={1}
                  top="0"
                  right="0"
                  overflow="hidden"
                  borderBottomLeftRadius={{ base: '40px', xl: '50px' }}
                  w={{ base: '48px', xl: '56px' }}
                  h={{ base: '48px', xl: '56px' }}
                >
                  <GMGreenDeliveryIcon padding={xl ? '8px 8px 18px 18px' : '8px 8px 16px 16px'} />
                </Box>
              )}
              <Link
                to={{
                  pathname: `/${currentLocale}/partner/${singleShop?.slug}`,
                  state: { categoryName: categoryName, categorySlug: categorySlug },
                }}
              >
                <GMPhoto
                  h="100%"
                  textSize="medium"
                  text={singleShop?.name}
                  thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${singleShop?.landscapeBannerId}`}
                  imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${singleShop?.landscapeBannerId}`}
                />
              </Link>
            </Box>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default GMCategoryMesh;
