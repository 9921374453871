import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import { useScroll } from 'hooks/useScroll';

interface IGMSearchBar {
  path?: string;
  category?: string;
}

const GMSearchBar: FC<IGMSearchBar> = ({ path, category }) => {
  const scroll = useScroll();

  const isScrollingUp = scroll?.direction === 'up';
  const stickSearchBar = isScrollingUp && scroll?.y > 0;

  return (
    <Box
      display={{ lg: 'none' }}
      bg="LIGHT"
      px="16px"
      position="sticky"
      top={stickSearchBar ? '53px' : '0'}
      zIndex={2}
      transition="top 0.5s ease"
    >
      <Box maxW={{ sm: '536px' }} mx="auto" py="6px">
        <GMInputSearchHandler path={path} category={category} type="transparent" />
      </Box>
    </Box>
  );
};

export default GMSearchBar;
