import React, { FC } from 'react';

interface FlagProps {
  width?: number | string;
  height?: number | string;
}

const FlagGe: FC<FlagProps> = ({ width = 21, height = 15 }): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 15">
      <defs>
        <clipPath id="prefix__clip-path">
          <rect
            id="prefix__Rectangle_653"
            width={width}
            height={height}
            fill="#fff"
            stroke="#707070"
            data-name="Rectangle 653"
            rx="2"
            transform="translate(708 197)"
          />
        </clipPath>
      </defs>
      <g id="prefix__flag_gbr" clipPath="url(#prefix__clip-path)" transform="translate(-708 -197)">
        <path
          id="prefix__Rectangle_662"
          fill="#022169"
          d="M0 0H21V15H0z"
          data-name="Rectangle 662"
          transform="translate(708 197)"
        />
        <path
          id="prefix__Rectangle_654"
          d="M0 0H3V27H0z"
          fill="#fff"
          data-name="Rectangle 654"
          transform="rotate(126 311.096 291.42)"
        />
        <path
          id="prefix__Rectangle_655"
          d="M0 0H1V29H0z"
          fill="#c9102e"
          data-name="Rectangle 655"
          transform="rotate(126 310.84 292.41)"
        />
        <path
          id="prefix__Rectangle_656"
          d="M0 0H2.999V27H0z"
          fill="#fff"
          data-name="Rectangle 656"
          transform="rotate(53.98 172.496 812.401)"
        />
        <path
          id="prefix__Rectangle_657"
          d="M0 0H0.999V29H0z"
          fill="#c9102e"
          data-name="Rectangle 657"
          transform="rotate(53.98 172.514 813.903)"
        />
        <path
          id="prefix__Rectangle_658"
          d="M0 0H4V15H0z"
          fill="#fff"
          data-name="Rectangle 658"
          transform="translate(716.5 197)"
        />
        <path
          id="prefix__Rectangle_659"
          d="M0 0H5V21H0z"
          fill="#fff"
          data-name="Rectangle 659"
          transform="rotate(90 263.5 465.5)"
        />
        <path
          id="prefix__Rectangle_660"
          d="M0 0H3V21H0z"
          fill="#c9102e"
          data-name="Rectangle 660"
          transform="rotate(90 263 466)"
        />
        <path
          id="prefix__Rectangle_661"
          d="M0 0H2V15H0z"
          fill="#c9102e"
          data-name="Rectangle 661"
          transform="translate(717.5 197)"
        />
      </g>
    </svg>
  );
};

export default FlagGe;
