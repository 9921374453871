import React, { FC } from 'react';
import { Box, Text, Grid } from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gradients } from 'theme/theme';
import map from 'lodash/map';
import GMTitleLink from 'components/GMTitleLink';
import GMArticleCard from 'components/GMArticleCard';
import GMArticleCardAlternative from 'components/GMArticleCardAlternative';
import GMSectionContainer from 'components/GMSectionContainer';
import useMeshLayout from 'hooks/useChosenForYouMeshLayout';
import useBreakpoint from 'hooks/useBreakpoint';

interface GMChosenForYouProps {
  props?: {};
  articles: Post[];
}

interface Post {
  abstract?: string;
  id: string;
  landscapeBannerId?: string;
  portraitBannerId?: string;
  slug?: string;
  title: string;
  node: {
    abstract?: string;
    categoryIds?: string[];
    id: string;
    slug?: string;
    title: string;
    landscapeBannerId?: string;
    portraitBannerId?: string;
  };
}

const GMChosenForYou: FC<GMChosenForYouProps> = ({ articles }) => {
  const { t, i18n } = useTranslation();
  const gradient = gradients.GREEN_GRADIENT;

  const onlyOneArticle = articles.length === 1;
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const getMeshLayout: Function = useMeshLayout();
  const layout = getMeshLayout(articles.length) || {};

  const heightForOneArticle = xl ? '341px' : '256px';

  const currentLocale = i18n.language;
  return (
    <GMSectionContainer backgroundImage={gradient}>
      {onlyOneArticle && (lg || xl) ? null : (
        <Text
          color="PAGE.TITLE_LIGHT"
          fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
          fontWeight="bold"
          lineHeight={{ base: '24px', lg: '34px', xl: '39px' }}
          mb={{ base: '16px', lg: '24px', xl: '32px' }}
        >
          {t('lifestyle:chosenForYou:title')}
        </Text>
      )}

      <Grid
        gridTemplateAreas={layout.templateArea ? layout.templateArea : null}
        gridTemplateRows={layout.templateRows ? layout.templateRows : null}
        gridTemplateColumns={layout.templateColumns ? layout.templateColumns : null}
        gridGap={{ base: '16px', lg: '18px', xl: '24px' }}
        h="auto"
      >
        {map(articles, (article, index) => {
          const singleArticle = article?.node;
          const titleSlug = article?.node?.slug;

          const { title, abstract, landscapeBannerId, portraitBannerId } = singleArticle;

          return (
            <Box gridArea={index === 0 ? 'main' : `other${index}`} key={index}>
              <Link to={`/${currentLocale}/article/${titleSlug}`}>
                <Box
                  display={layout.cardType && layout.cardType[index] === 'title' ? 'none' : 'block'}
                  w="100%"
                  h="100%"
                >
                  {layout.cardType && layout.cardType[index] === 'alternative' ? (
                    <GMArticleCardAlternative
                      title={title}
                      desc={abstract}
                      thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}`}
                      imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}`}
                      truncDescAfterLine={xl ? 4 : 3}
                      truncTitleAfterLine={articles.length === 2 ? 3 : 2}
                      h={articles.length === 2 ? '291px' : heightForOneArticle}
                      titleSize={articles.length === 2 ? 'small' : 'big'}
                      label={onlyOneArticle ? t('lifestyle:chosenForYou:oneArticleCardLabel') : ''}
                    />
                  ) : (
                    <GMArticleCard
                      title={title}
                      desc={abstract}
                      thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${portraitBannerId}`}
                      imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/360x432/${portraitBannerId}`}
                      titleSize={layout.textSize && layout.textSize[index]}
                      showDesc={layout.cardType && layout.cardType[index] === 'photo-text'}
                    />
                  )}
                </Box>
                <GMTitleLink
                  text={title}
                  color="light"
                  borderColor="#456c18"
                  display={layout.cardType && layout.cardType[index] === 'title' ? 'block' : 'none'}
                />
              </Link>
            </Box>
          );
        })}
      </Grid>
    </GMSectionContainer>
  );
};

export default GMChosenForYou;
