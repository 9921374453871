import React, { FC, useState, useEffect } from 'react';
import { Input, Box, Flex } from '@chakra-ui/core';
import { MdClose, MdSearch } from 'react-icons/md';
import { input as dimensions } from 'constants/dimensions';

export interface IGMInput {
  initValue?: string;
  placeholder?: string;
  cursor?: string;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickIcon?: () => void;
  onKeyDown?: (event) => void;
  onClick?: () => void;
  iconType?: 'search' | 'close';
  w?: string;
  type?: string;
  size?: 'big' | 'small';
  borderColor?: string;
  iconColor?: string;
  border?: string;
  readOnly?: boolean;
}

const GMInput: FC<IGMInput> = ({
  initValue,
  type = 'standard',
  placeholder = '',
  cursor,
  iconType,
  w = '100%',
  size = 'small',
  onInput,
  onClickIcon,
  onKeyDown,
  onClick,
  borderColor: defaultBorderColor,
  iconColor,
  border,
  readOnly,
}) => {
  const [value, setValue] = useState(initValue || '');
  const borderColor = defaultBorderColor || (type === 'standard' ? 'INPUT.STANDARD.BORDER' : 'transparent');
  const { fontSize, borderRadius, paddingY, paddingX, lineHeight, height } = dimensions[size];

  useEffect(() => {
    setValue(initValue || '');
  }, [initValue]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
    if (onInput) onInput(event);
  };

  const onClickIconHandler = (): void => {
    if (onClickIcon) onClickIcon();
  };

  return (
    <>
      <Box w={w} position="relative">
        <Input
          value={value}
          borderRadius={borderRadius}
          placeholder={placeholder}
          cursor={cursor}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={onClick}
          color="INPUT.STANDARD.TEXT"
          borderColor={borderColor}
          border={border || '1px solid'}
          fontSize={fontSize}
          py={type === 'standard' ? paddingY : '0'}
          pl={type === 'standard' ? paddingX : '0'}
          pr={type === 'standard' ? `calc(${paddingX} + 20px)` : '50px'}
          lineHeight={lineHeight}
          h={height}
          _focus={{
            borderColor: { borderColor },
          }}
          _hover={{
            borderColor: { borderColor },
          }}
          background="transparent"
          zIndex={1}
          isReadOnly={readOnly}
        />
        <Flex
          position="absolute"
          px={type === 'standard' ? paddingX : '0'}
          py={type === 'standard' ? paddingY : '0'}
          top="0"
          left="0"
          bottom="0"
          right="0"
          align="center"
          justify="center"
        >
          <Box />
          <Box
            cursor="pointer"
            ml="auto"
            onClick={onClickIconHandler}
            zIndex={2}
            color={type === 'transparent' ? 'INPUT.STANDARD.TEXT' : 'inherit'}
          >
            {iconType === 'search' ? <MdSearch size="24px" color={iconColor} /> : null}
            {iconType === 'close' ? <MdClose size="24px" color={iconColor} /> : null}
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default GMInput;
