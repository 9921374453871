import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import { categories } from 'constants/categories';

export interface GMTitleLinkProps {
  text?: string;
  color?: 'dark' | 'light' | undefined | null;
  size?: string;
  display?: {} | string;
  margin?: string;
  category?: string;
  borderColor?: string;
  maxH?: string;
  fontWeight?: string;
  truncAfterLine?: number;
}

export const GMTitleLink: FC<GMTitleLinkProps> = ({
  text,
  color = 'dark',
  size = 'small',
  display = 'block',
  margin,
  borderColor,
  category,
  maxH,
  fontWeight = 'black',
  truncAfterLine = 4,
}) => {
  const lineHeight = size === 'big' ? '24px' : '19px';
  const fontSize = size === 'big' ? '20px' : '16px';
  const borderColorDefault = 'TITLE_LINK.DEFAULT_BORDER_COLOR';
  const borderColorCustom = borderColor;
  const getBorderColorFromCategory = (category: string): string => categories[category] || borderColorDefault;
  const borderColorCategory = category ? getBorderColorFromCategory(category) : borderColorDefault;
  const border = borderColorCustom ? borderColorCustom : borderColorCategory;

  return (
    <Box
      borderColor={border}
      borderLeftWidth={border ? 4 : 0}
      borderLeftStyle={border ? 'solid' : 'none'}
      w="100%"
      pointerEvents="none"
      display={display}
      margin={margin}
    >
      <Text
        color={color === 'dark' ? 'TITLE_LINK.DARK_TEXT' : 'TITLE_LINK.LIGHT_TEXT'}
        fontSize={fontSize}
        lineHeight={lineHeight}
        padding="0 0 8px 8px"
        fontWeight={fontWeight}
        textShadow={color === 'light' ? 'outline' : 'none'}
        zIndex={1}
        maxH={maxH ? maxH : null}
        overflow="hidden"
      >
        <TextEllipsis lines={truncAfterLine} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
          {text}
        </TextEllipsis>
      </Text>
    </Box>
  );
};

export default GMTitleLink;
