import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import GMLink from 'components/GMLink';
import useBreakpoint from 'hooks/useBreakpoint';
import { useProfile } from 'context/profile';

interface IGMSinglePromoSidebar {
  categoryName?: string;
  categorySlug?: string;
  context?: 'promos' | 'donations';
}

const GMSinglePromoSidebar: FC<IGMSinglePromoSidebar> = ({ categoryName, categorySlug, context = 'promos' }) => {
  const { userInfo } = useProfile();
  const { t, i18n } = useTranslation();
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';
  const currentLocale = i18n.language;

  const descriptionTextPromos = userInfo ? 'promos:greenKmDescriptionAuth' : 'promos:greenKmDescription';
  const descriptionTextDonations = userInfo ? 'donations:greenCoinDescriptionAuth' : 'donations:greenCoinDescription';
  return (
    <Box position="relative" w={{ lg: '312px' }} pl="24px" display={{ base: 'none', lg: 'block' }}>
      <Box position="sticky" top="5px">
        <Box pb={{ base: '34px', xl: '32px' }}>
          <Text
            fontSize={{ base: 'md', xl: 'lg' }}
            lineHeight={{ base: '19px', xl: '22px' }}
            mb="16px"
            color="PAGE.SECONDARY_TEXT"
          >
            <Trans
              i18nKey={context === 'promos' ? descriptionTextPromos : descriptionTextDonations}
              // eslint-disable-next-line react/jsx-key
              components={[<strong className="green"></strong>]}
            />
          </Text>
        </Box>
        {categoryName && categorySlug && (
          <Box pb={{ lg: '34px', xl: '32px' }}>
            <Link to={`/${currentLocale}/${context}/${categorySlug}`}>
              <GMLink
                text={`${t('promos:singlePromo:backToCategoryLink')} ${categoryName}`}
                size={xl ? 'medium' : 'small'}
              />
            </Link>
          </Box>
        )}
        <Link to={`/${currentLocale}/${context}`}>
          <GMLink
            text={t(context === 'promos' ? 'promos:singlePromo:allShopsLink' : 'donations:singleDonation:allShopsLink')}
            size={xl ? 'medium' : 'small'}
          />
        </Link>
      </Box>
    </Box>
  );
};

export default GMSinglePromoSidebar;
