import React, { FC, ReactNode } from 'react';
import { Button, Flex, Box } from '@chakra-ui/core';
import { greenButton } from 'constants/dimensions';

export interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'big';
  variant?: 'solid' | 'outlined' | 'outlinedRed' | 'transparent';
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  loading?: boolean;
}

const variants = {
  solid: {
    border: '0px',
    borderColor: 'BUTTON.GREEN.BORDER.COLOR',
    color: 'BUTTON.ENABLED.TEXT',
    background: 'BUTTON.GREEN.BACKGROUND',
  },
  outlined: {
    border: '1px',
    borderColor: 'BUTTON.GREEN_OUTLINED.BORDER.COLOR',
    color: 'BUTTON.GREEN_OUTLINED.COLOR',
    background: 'BUTTON.GREEN_OUTLINED.BACKGROUND',
  },
  outlinedRed: {
    border: '1px',
    borderColor: 'BUTTON.RED_OUTLINED.BORDER.COLOR',
    color: 'BUTTON.RED_OUTLINED.COLOR',
    background: 'BUTTON.RED_OUTLINED.BACKGROUND',
  },
  transparent: {
    border: '1px',
    borderColor: 'PRIMARY',
    color: 'PRIMARY',
    background: 'transparent',
  },
  disabled: {
    border: '1px',
    color: 'BUTTON.DISABLED.TEXT',
    background: 'BUTTON.DISABLED.BACKGROUND',
    borderColor: 'BUTTON.DISABLED.BORDER',
  },
};

const GMButtonGreen: FC<ButtonProps> = ({
  children,
  onClick,
  size = 'small',
  variant = 'solid',
  type,
  disabled = false,
  loading = false,
}) => {
  const rounded = '4px';
  const h = greenButton[size]?.height;
  const fontSize = greenButton[size]?.fontSize;

  return (
    <Button
      type={type}
      w="100%"
      h={h}
      rounded={rounded}
      color={variants[disabled ? 'disabled' : variant]?.color}
      cursor={disabled ? 'inherit' : 'pointer'}
      border={variants[disabled ? 'disabled' : variant]?.border}
      borderColor={variants[disabled ? 'disabled' : variant]?.borderColor}
      backgroundColor={variants[disabled ? 'disabled' : variant]?.background}
      fontSize={fontSize}
      fontFamily="body"
      fontWeight="bold"
      onClick={onClick}
      isDisabled={disabled}
      isLoading={loading}
      _active={{
        backgroundColor: variants[disabled ? 'disabled' : variant]?.background,
      }}
      _hover={{
        backgroundColor: variants[disabled ? 'disabled' : variant]?.background,
      }}
    >
      <Flex align="center" justify="center" w="100%">
        <Box w="100%">{children}</Box>
      </Flex>
    </Button>
  );
};

export default GMButtonGreen;
