import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import { Box, Flex } from '@chakra-ui/core';
import FlagEs from 'assets/svg-components/flags/es';
import FlagIt from 'assets/svg-components/flags/it';
import FlagDe from 'assets/svg-components/flags/de';
import FlagGb from 'assets/svg-components/flags/gb';
import FlagFr from 'assets/svg-components/flags/fr';
import { palette } from 'theme/theme';

interface IGMLanguageSwitcher {
  outlineColor?: 'green' | 'white';
}

const GMLanguageSwitcher: FC<IGMLanguageSwitcher> = ({ outlineColor = 'green' }) => {
  const { i18n } = useTranslation();
  const currentLocation = i18n.language;

  const changeLanguage = useCallback((lng) => {
    i18n.changeLanguage(lng);
  }, []);

  const flags = {
    it: <FlagIt />,
    en: <FlagGb />,
    es: <FlagEs />,
    de: <FlagDe />,
    fr: <FlagFr />,
  };

  const shadow = `0px 0px 0em 2px ${outlineColor === 'green' ? palette.MEDIUM_GREEN : palette.WHITE}`;
  const border = `1px solid ${outlineColor === 'green' ? palette.WHITE : palette.MEDIUM_GREEN}`;

  return (
    <Flex justify="space-between" align="center">
      {map(flags, (component, lng) => {
        const isSelected = currentLocation === lng;
        return (
          <Box
            key={lng}
            cursor="pointer"
            borderRadius="2px"
            onClick={() => changeLanguage(lng)}
            border={border}
            boxShadow={isSelected ? shadow : ''}
          >
            {component}
          </Box>
        );
      })}
    </Flex>
  );
};

export default GMLanguageSwitcher;
