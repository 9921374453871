import React, { FC } from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { categories } from 'constants/categories';
import { readMoreLink } from 'constants/dimensions';

export interface GMReadMoreLinkProps {
  text?: string;
  color?: string;
  size?: string;
  category?: string;
  iconType?: 'plus' | 'dash';
}

export const GMReadMoreLink: FC<GMReadMoreLinkProps> = ({
  text,
  color,
  size = 'small',
  category,
  iconType = 'plus',
  ...props
}) => {
  const colorDefault = 'READ_ALL.DEFAULT_COLOR';
  const colorCustom = color;
  const getBorderColorFromCategory = (category: string): string => categories[category] || colorDefault;
  const colorCategory = category ? getBorderColorFromCategory(category) : colorDefault;
  const borderColor = colorCustom ? colorCustom : colorCategory;

  return (
    <Flex
      borderBottom="1px"
      borderColor={borderColor}
      w="100%"
      align="center"
      paddingBottom={readMoreLink[size]?.paddingBottom}
      cursor="pointer"
      {...props}
    >
      <Box pr="6px" fontSize="26px" color={borderColor} lineHeight="0.8">
        {iconType === 'plus' && '+'}
        {iconType === 'dash' && '-'}
      </Box>
      <Text
        color={borderColor}
        fontSize={readMoreLink[size]?.fontSize}
        lineHeight={readMoreLink[size]?.lineHeight}
        fontWeight="bold"
      >
        {text}
      </Text>
    </Flex>
  );
};

export default GMReadMoreLink;
