import React, { FC } from 'react';
import { Box, Flex, Link } from '@chakra-ui/core';
import { FacebookIcon, LinkedinIcon } from 'react-share';
import { FaInstagram } from 'react-icons/fa';

const GMSocials: FC = () => {
  const socialButtonWH = '32px';
  const socialButtonSize = 32;

  const margin = '8px 16px 8px 0';

  return (
    <>
      <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" m={margin}>
        <Link href="https://www.facebook.com/Gremobo" isExternal>
          <FacebookIcon size={socialButtonSize} round />
        </Link>
      </Box>
      {/* <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" m={margin}>
        <TwitterIcon size={socialButtonSize} round />
      </Box> */}
      <Flex
        w={socialButtonWH}
        h={socialButtonWH}
        borderRadius="50%"
        m={margin}
        backgroundColor="#d6249f"
        background="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);"
        justify="center"
        align="center"
      >
        <Link href="https://www.instagram.com/gremobo/?hl=it" isExternal>
          <FaInstagram size={18} />
        </Link>
      </Flex>
      <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" m={margin}>
        <Link href="https://www.linkedin.com/company/gremobo" isExternal>
          <LinkedinIcon size={socialButtonSize} round />
        </Link>
      </Box>
      {/* <Box w={socialButtonWH} h={socialButtonWH} borderRadius="50%" m={margin}>
        <Link to="/contacts">
          <EmailIcon size={socialButtonSize} round />
        </Link>
      </Box> */}
    </>
  );
};

export default GMSocials;
