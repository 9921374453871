import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/core';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import GMSinglePromoContentDescription from 'components/GMSinglePromoContentDescription';
import GMTransactionStateLabel from 'components/GMTransactionStateLabel';
import GMShowMore from 'components/GMShowMore';
import Drop from 'assets/svg-components/drop';
import { renderers } from 'renderers';
import GMButtonGreen from 'components/GMButtonGreen';
import { useBtnSize } from 'hooks/useProfileLayout';
import { parseUnderline } from 'utils';

interface IGMDonationSummary {
  slug?: string;
  name?: string;
  expirationDate?: Date;
  subscribedOn?: string;
  price?: number;
  thankYouNote?: string;
  state?: string;
  variant?: 'modal';
  confirmedOn?: Date;
}

const GMDonationSummary: FC<IGMDonationSummary> = ({
  slug,
  expirationDate,
  name,
  subscribedOn,
  price,
  state,
  thankYouNote,
  variant,
  confirmedOn,
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const getButtonSize: Function = useBtnSize();
  const btnSize = getButtonSize() || 'small';

  const states = {
    PENDING: {
      bg: 'PROFILE.TRANSACTION.PENDING_LABEL',
      color: 'WHITE',
      label: t('profile:transactions:pending'),
    },
    CONFIRMED: {
      bg: 'LIGHT',
      color: 'SECONDARY',
      label: `${t('profile:transactions:completed')} - ${confirmedOn ? moment(confirmedOn).format('DD MMM YYYY') : ''}`,
    },
    VOID: {
      bg: 'PROFILE.TRANSACTION.CANCELED_LABEL',
      color: 'WHITE',
      label: t('profile:transactions:canceled'),
    },
  };

  const isSoldOut = moment().isAfter(expirationDate);

  return (
    <>
      <Box>
        <Box
          bg="SUPER_LIGHT_GRAY"
          border="1px solid"
          borderColor="PAGE.ABSTRACT_BORDER"
          p="16px"
          mb={{ base: '24px', lg: '32px' }}
        >
          <Flex align="flex-start">
            <Box fontSize={{ base: 'xl', lg: '2xl', xl: '28px' }} fontWeight="bold" color="SECONDARY" mr="auto">
              {name}
            </Box>
            <Box ml="10px">
              {state && <GMTransactionStateLabel state={state} />}
              <Box
                borderRadius="4px"
                fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                lineHeight={{ base: '16px', lg: '16px', xl: '22px' }}
                bg="LIGHT"
                color="SECONDARY"
                fontWeight="bold"
                px="10px"
                py={{ base: '0px', lg: '2px', xl: '2px' }}
                mt="8px"
              >
                <Flex align="center" justify="center">
                  <Box>-{price}</Box> <Drop size={20} variant="blue" />
                </Flex>
              </Box>
            </Box>
          </Flex>
          <Box fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }} color="PAGE.SECONDARY_TEXT">
            {t('donations:singleDonation:summary:activatedOn')} {moment(subscribedOn).format('DD MMM YYYY')}
          </Box>

          <Box
            bg={state && states[state]?.bg}
            borderRadius="4px"
            color={state && states[state]?.color}
            padding="10px 20px"
            mt="16px"
          >
            <Flex align="center" justify="space-between">
              <Box>
                <Box fontSize="md" lineHeight="19px">
                  {t('donations:singleDonation:summary:state')}
                </Box>

                <Box fontSize="22px" fontWeight="bold" lineHeight="27px">
                  {state && states[state]?.label}
                </Box>
              </Box>

              {state === 'PENDING' && <Box fontSize="30px">!</Box>}
            </Flex>
          </Box>
        </Box>
      </Box>

      <Box px={{ base: variant === 'modal' ? '0px' : '16px', lg: '0' }}>
        {state === 'PENDING' && (
          <GMSinglePromoContentDescription title={t('donations:singleDonation:summary:whatIsPendingTitle')}>
            <GMShowMore>
              <Box>{t('donations:singleDonation:summary:whatIsPending')}</Box>
            </GMShowMore>
          </GMSinglePromoContentDescription>
        )}
        <GMSinglePromoContentDescription title={t('donations:singleDonation:summary:thankYouNoteTitle')}>
          <GMShowMore>
            <ReactMarkdown escapeHtml={false} source={parseUnderline(thankYouNote)} renderers={renderers} />
          </GMShowMore>
        </GMSinglePromoContentDescription>

        {state === 'CONFIRMED' && !isSoldOut && (
          <Box mb="30px">
            <Link to={`/${currentLocale}/donation/${slug}`}>
              <GMButtonGreen size={btnSize}>{t('donations:singleDonation:summary:donateAgain')}</GMButtonGreen>
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default GMDonationSummary;
