import React, { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/core';
import useScrollInfo from 'react-element-scroll-hook';
import { gradients } from 'theme/theme';

interface GMHorizontalScrollSectionProps {
  children?: ReactNode;
  noEndGradients?: boolean;
}

const GMHorizontalScrollSection: FC<GMHorizontalScrollSectionProps> = ({ children, noEndGradients = false }) => {
  const [scrollInfo, scrollableElement] = useScrollInfo();

  const { value: scrollValue, total: elementTotal } = scrollInfo?.x;
  const rightEndElementReached = scrollValue === elementTotal;
  const leftEndElementReached = scrollValue === 0;

  return (
    <>
      <Box pos="relative" h="100%" overflow="hidden">
        {noEndGradients ? null : (
          <Box
            position="absolute"
            opacity={leftEndElementReached ? 0 : 1}
            left="0"
            top="0"
            h="100%"
            w="10px"
            backgroundImage={gradients.OVERFLOW_SHADOW_LEFT}
          />
        )}
        <Box overflowX="scroll" maxW="100%" h="calc(100% + 20px)" ref={scrollableElement}>
          {children}
        </Box>
        {noEndGradients ? null : (
          <Box
            position="absolute"
            opacity={rightEndElementReached ? 0 : 1}
            right="0"
            top="0"
            h="100%"
            w="10px"
            backgroundImage={gradients.OVERFLOW_SHADOW_RIGHT}
          />
        )}
      </Box>
    </>
  );
};

export default GMHorizontalScrollSection;
