import React, { FC } from 'react';

const Lock: FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="lock-24px" width="35.996" height="35.996" viewBox="0 0 35.996 35.996">
      <g id="Group_995" fill="none" data-name="Group 995">
        <path id="Path_208" d="M0 0h36v36H0z" data-name="Path 208" />
        <path id="Path_209" d="M0 0h36v36H0z" data-name="Path 209" opacity="0.87" />
      </g>
      <path
        id="Path_210"
        fill="#a4b3ae"
        d="M25 11.5h-1.5v-3a7.5 7.5 0 1 0-15 0v3H7a3.008 3.008 0 0 0-3 3v15a3.008 3.008 0 0 0 3 3h18a3.008 3.008 0 0 0 3-3v-15a3.008 3.008 0 0 0-3-3zm-13.5-3a4.5 4.5 0 1 1 9 0v3h-9zm13.5 21H7v-15h18zM16 25a3 3 0 1 0-3-3 3.008 3.008 0 0 0 3 3z"
        data-name="Path 210"
        transform="translate(1.999 .5)"
      />
    </svg>
  );
};

export default Lock;
