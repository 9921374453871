import React, { FC } from 'react';
import { Box, Text, Flex } from '@chakra-ui/core';
import { MdArrowForward } from 'react-icons/md';
import { readMoreLink } from 'constants/dimensions';
import { categories } from 'constants/categories';

export interface GMLinkProps {
  text?: string;
  color?: string;
  size?: string;
  category?: string;
}

export const GMLink: FC<GMLinkProps> = ({ text, color, size = 'small', category, ...props }) => {
  const getBorderColorFromCategory = (category: string): string => category && categories[category];
  const colors = {
    default: 'READ_ALL.DEFAULT_COLOR',
    custom: color,
    category: category && getBorderColorFromCategory(category),
  };
  const borderColor = colors.custom || colors.category || colors.default;
  return (
    <Flex
      borderBottom="1px"
      borderColor={borderColor}
      w="100%"
      pointerEvents="none"
      align="center"
      justify="space-between"
      paddingBottom={readMoreLink[size]?.paddingBottom}
      {...props}
    >
      <Text
        color={borderColor}
        fontSize={readMoreLink[size]?.fontSize}
        lineHeight={readMoreLink[size]?.lineHeight}
        fontWeight="bold"
      >
        {text}
      </Text>
      <Box p="0 8px 0 3px" fontSize="26px" color={borderColor} lineHeight="0.8">
        <MdArrowForward size="16px" />
      </Box>
    </Flex>
  );
};

export default GMLink;
