import useBreakpoint from 'hooks/useBreakpoint';

export const useMeshLayout = (): Function => {
  const breakpoint = useBreakpoint();
  return (articlesQty: number) => {
    switch (`${breakpoint}-${articlesQty}`) {
      case 'base-3':
      case 'sm-3':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"`,
          templateRows: `
            144px auto auto`,
          cardType: ['photo', 'title', 'title'],
        };
      case 'md-3':
        return {
          templateArea: `
            "main"
            "other1"
            "other2"`,
          templateRows: `
            267px auto auto`,
          cardType: ['photo', 'title', 'title'],
        };
      case 'lg-3':
        return {
          templateArea: `
            "main other1"
            "main other2"`,
          templateRows: `
            120px 120px`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo', 'title', 'title'],
        };
      case 'xl-3':
        return {
          templateArea: `
            "main other1"
            "other2 other2"`,
          templateRows: `
            auto auto`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo-title', 'photo-title', 'title'],
        };
      case 'base-2':
      case 'sm-2':
        return {
          templateArea: `
            "main"
            "other1"`,
          templateRows: `
            144px auto auto`,
          cardType: ['photo', 'title', 'title'],
        };
      case 'md-2':
        return {
          templateArea: `
            "main"
            "other1"`,
          templateRows: `
          267px auto`,
          cardType: ['photo', 'title'],
        };
      case 'lg-2':
        return {
          templateArea: `
            "main other1"
            "main other2"`,
          templateRows: `
            120px 120px`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo', 'title'],
        };
      case 'xl-2':
        return {
          templateArea: `
            "main other1"`,
          templateRows: `
            auto`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo-title', 'photo-title'],
        };
      case 'base-1':
      case 'sm-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            144px`,
          cardType: ['photo'],
        };
      case 'md-1':
        return {
          templateArea: `
            "main"`,
          templateRows: `
            267px`,
          cardType: ['photo'],
        };
      case 'lg-1':
        return {
          templateArea: `
            "main other1"
            "main other2"`,
          templateRows: `
            120px 120px`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo'],
        };
      case 'xl-1':
        return {
          templateArea: `
            "main other1"`,
          templateRows: `
            auto`,
          templateColumns: `
            minmax(0, 1fr) minmax(0, 1fr)`,
          cardType: ['photo-title'],
        };
    }
  };
};

export const useReadMoreLinkSize = (): Function => {
  const breakpoint = useBreakpoint();
  return () => {
    switch (`${breakpoint}`) {
      case 'base':
      case 'sm':
      case 'md':
        return 'small';
      case 'lg':
      case 'xl':
        return 'big';
    }
  };
};

export const useTitleLinkSize = (): Function => {
  const breakpoint = useBreakpoint();
  return () => {
    switch (`${breakpoint}`) {
      case 'base':
      case 'sm':
      case 'md':
        return 'small';
      case 'lg':
      case 'xl':
        return 'big';
    }
  };
};
