import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ISOCountries from 'i18n-iso-countries';
import { isArray, map, sortBy } from 'lodash';
import GMSelectOptions from 'components/GMSelectOptions';
import { fetchCountries } from 'api';
import { getLocalizedCountries } from 'utils';

interface IGMSelectCountry {
  onSelect?: (value) => void;
  country?: string;
  fetchedCountries?: boolean;
}

const GMSelectCountry: FC<IGMSelectCountry> = ({ onSelect, country, fetchedCountries }) => {
  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState<{ id: string }[]>();
  const currentLocale = i18n.language;
  const localizedCountries = getLocalizedCountries(currentLocale);

  useEffect(() => {
    if (fetchedCountries) {
      fetchCountries()
        .then((res) => res.json())
        .then((result) => {
          setCountries(result);
        })
        .catch(() => {
          setCountries([]);
        });
    }
  }, []);

  const fetchedCountryOptions = sortBy(
    map(countries, (country) => ({
      value: country.id,
      label: ISOCountries.getName(country.id, currentLocale),
    })),
    (o) => o.label,
  );

  const localizedCountryOptions = sortBy(
    map(localizedCountries, (countryName, code) => ({
      value: code,
      label: isArray(countryName) ? countryName[0] : countryName,
    })),
    (o) => o.label,
  );

  return (
    <GMSelectOptions
      value={country}
      options={fetchedCountries ? fetchedCountryOptions : localizedCountryOptions}
      onSelect={onSelect}
      noValue={t('profile:account:modal:noCountrySelected')}
      backgroundColor="transparent"
    />
  );
};

export default GMSelectCountry;
