import React, { FC, ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Divider } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { includes, some } from 'lodash';
// import { useAnalytics } from 'use-analytics';
import { EventCategory, UseActivityValues } from 'constants/analytics';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

interface MenuItemsProps {
  children: ReactNode;
}

const MenuItems: FC<MenuItemsProps> = ({ children }) => (
  <Box
    textAlign="center"
    pr={{ lg: '8px', xl: '11px' }}
    color="HEADER.ITEM_NOT_SELECTED"
    fontSize="lg"
    lineHeight="22px"
  >
    {children}
  </Box>
);

const activeRoutes = {
  lifestyle: ['/lifestyle', '/article', '/category', '/search/articles'],
  shopping: ['/shopping', '/partner', '/partners', '/search/partners'],
  donations: ['/donations', '/donation', '/search/donations'],
  promos: ['/promos', '/promo', '/search/promos'],
};

const GMMenu: FC = () => {
  const { t } = useTranslation();
  // const { track } = useAnalytics();
  const sendDataToGTM = useGTMDispatch();
  const location = useLocation();

  // best and fast way to check nested active link
  const isActive = (...pathsToInclude: string[]): boolean => {
    const pathname = location?.pathname || '';
    return some(pathsToInclude, (path) => includes(pathname, path));
  };

  return (
    <>
      <MenuItems>
        <NavLink
          to="/lifestyle"
          onClick={() => sendDataToGTM({ event: UseActivityValues.MenuLifestyle, value: EventCategory.UserActivity })}
          isActive={() => isActive(...activeRoutes.lifestyle)}
          activeClassName="navbar__link--active"
          className="navbar__link"
        >
          {t('navigation:lifestyle')}
        </NavLink>
      </MenuItems>

      <Divider display={{ lg: 'none' }} />

      <MenuItems>
        <NavLink
          to="/shopping"
          onClick={() => sendDataToGTM({ event: UseActivityValues.MenuShopping, value: EventCategory.UserActivity })}
          isActive={() => isActive(...activeRoutes.shopping)}
          activeClassName="navbar__link--active"
          className="navbar__link"
        >
          {t('navigation:shopping')}
        </NavLink>
      </MenuItems>

      <Divider display={{ lg: 'none' }} />

      <MenuItems>
        <NavLink
          to="/donations"
          onClick={() => sendDataToGTM({ event: UseActivityValues.MenuDonations, value: EventCategory.UserActivity })}
          isActive={() => isActive(...activeRoutes.donations)}
          activeClassName="navbar__link--active"
          className="navbar__link"
        >
          {t('navigation:donations')}
        </NavLink>
      </MenuItems>

      <Divider display={{ lg: 'none' }} />

      <MenuItems>
        <NavLink
          to="/mobility"
          onClick={() => sendDataToGTM({ event: UseActivityValues.MenuMobility, value: EventCategory.UserActivity })}
          activeClassName="navbar__link--active"
          className="navbar__link"
        >
          {t('navigation:mobility')}
        </NavLink>
      </MenuItems>

      <Divider display={{ lg: 'none' }} />

      <MenuItems>
        <NavLink
          to="/promos"
          onClick={() => sendDataToGTM({ event: UseActivityValues.MenuPromotions, value: EventCategory.UserActivity })}
          isActive={() => isActive(...activeRoutes.promos)}
          activeClassName="navbar__link--active"
          className="navbar__link"
        >
          {t('navigation:promo')}
        </NavLink>
      </MenuItems>
    </>
  );
};

export default GMMenu;
