import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

interface IPrivateRoute {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
  rest?: object;
  exact?: boolean;
}

const PrivateRoute: FC<IPrivateRoute> = ({ component: Component, ...rest }) => {
  const [keycloak] = useKeycloak();

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export { PrivateRoute };
