import React, { createContext, useReducer, FC } from 'react';

interface AppContextProps {
  isDivInView: {
    inView?: boolean | false;
  };
  setIsDivInView: (iiv: object) => void;
}

const AppContext = createContext<AppContextProps>({} as AppContextProps);

const reducer = (state = {}, action): object => {
  switch (action.type) {
    case 'setDivInView':
      return { inView: action.payload };
    case 'resetDivInView':
      return { inView: false };

    default:
      return state;
  }
};

const AppContextProvider: FC = ({ children }) => {
  const [isDivInView, setIsDivInView] = useReducer(reducer, {});

  return <AppContext.Provider value={{ isDivInView, setIsDivInView }}>{children}</AppContext.Provider>;
};

export { AppContext, AppContextProvider };
