import React, { FC, ReactNode } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import customTheme from 'theme/theme';

export interface PriceTagProps {
  children: ReactNode;
  absolute?: boolean;
  rounded?: boolean;
  textAlign?: 'flex-start' | 'center' | 'flex-end';
  variant?: 'gradient' | 'grey';
  onClick?: () => void;
}

const gradient = `linear-gradient(to right, ${customTheme.colors.BUTTON.ENABLED.GRADIENT1} 0%, ${customTheme.colors.BUTTON.ENABLED.GRADIENT2} 100%)`;
const h = '32px';
const padding = '0 13px';
const fontSize = '16px';
const variants = {
  gradient: {
    color: 'BUTTON.ENABLED.TEXT',
    backgroundColor: null,
    backgroundImage: gradient,
  },
  grey: {
    color: 'BUTTON.GREY.TEXT',
    backgroundColor: 'BUTTON.GREY.BACKGROUND',
    backgroundImage: null,
  },
};

const GMPriceTag: FC<PriceTagProps> = ({
  children,
  onClick,
  absolute,
  rounded,
  variant = 'gradient',
  textAlign = 'center',
}) => {
  const minW = absolute ? '100px' : '100%';
  const roundedBottomLeft = rounded ? '20px' : '0';
  const position = absolute ? 'absolute' : null;

  return (
    <Box
      minW={minW}
      h={h}
      roundedBottomLeft={roundedBottomLeft}
      p={padding}
      top="0"
      right="0"
      color={variants[variant]?.color}
      pos={position}
      backgroundColor={variants[variant]?.backgroundColor}
      backgroundImage={variants[variant]?.backgroundImage}
      lineHeight={1}
      fontSize={fontSize}
      fontFamily="body"
      fontWeight="bold"
      onClick={onClick}
    >
      <Flex align="center" justify={textAlign} h="100%">
        {children}
      </Flex>
    </Box>
  );
};

export default GMPriceTag;
