import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/core';
import map from 'lodash/map';
import { gql, useQuery } from '@apollo/client';
import GMTag from 'components/GMTag';
import GMSelect from 'components/GMSelect';
import GMHorizontalScrollSection from 'components/GMHorizontalScrollSection';
import useBreakpoint from 'hooks/useBreakpoint';
import { useTagSize } from 'hooks/useSearchPageLayout';
import { customApolloClient } from 'apollo';
import { useProfile } from 'context/profile';
import ScrollContainer from 'react-indiana-drag-scroll';
import sortBy from 'lodash/sortBy';
import ISOCountries from 'i18n-iso-countries';

interface IGMSearchPageHeaderPromos {
  activeFilters?: IFilters;
  onChangeQuery?: (newQuery: string) => void;
  onChangeCategory?: (newCategory: string) => void;
  onChangePriceOption?: (newValue: string) => void;
  context?: 'promos' | 'donations';
  onChangeCountry?: (newValue: string) => void;
  countries?: { name: string; id: string }[];
}

interface IFilters {
  byCountry?: string;
  byCategory?: string[];
  byQuery?: string;
  byPrice?: number;
}

const GET_CATEGORIES = gql`
  query categories($languageCode: String!, $entityType: EntityType) {
    categories(languageCode: $languageCode, entityType: $entityType) {
      id
      landscapeBannerId
      name
      slug
    }
  }
`;

const categoriesClient = customApolloClient({ clientType: 'categoriesClient' });

const GMSearchPageHeaderPromos: FC<IGMSearchPageHeaderPromos> = ({
  activeFilters,
  onChangeCategory,
  onChangePriceOption,
  context = 'promos',
  onChangeCountry,
  countries,
}) => {
  const { userInfo } = useProfile();

  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const entity = context === 'promos' ? 'PROMO' : 'DONATION';

  const { data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      languageCode: currentLocale,
      entityType: entity,
    },
    client: categoriesClient,
    fetchPolicy: 'no-cache',
  });
  const { categories } = dataCategories || [];

  const onClickCategoryHandler = (slug: string): void => {
    if (onChangeCategory) onChangeCategory(slug);
  };

  const onSelectBuyOption = (newValue: string): void => {
    if (onChangePriceOption) {
      onChangePriceOption(newValue);
    }
  };

  const onSelectOptionCountryHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onChangeCountry) {
      onChangeCountry(event.target.value);
    }
  };

  const sortedCountries = sortBy(
    map(countries, (country) => ({
      id: country.id,
      name: ISOCountries.getName(country.id, currentLocale),
    })),
    (o) => o.name,
  );

  const allPromosOptionText = context === 'promos' ? 'search:allPromos' : 'search:allDonations';

  return (
    <>
      <Box h={{ base: '28px', lg: '32px', xl: '40px' }} mb={{ base: '16px', lg: '24px' }}>
        <GMHorizontalScrollSection>
          <ScrollContainer className="scroll-container" horizontal nativeMobileScroll>
            <Flex wrap="nowrap">
              {map(categories, (category, index) => (
                <Box key={index} flexShrink={0} mr="8px">
                  <GMTag
                    onClick={() => onClickCategoryHandler(category.slug)}
                    tagName={`${t(category.name)}`}
                    dense
                    size={tagSize}
                    active={activeFilters?.byCategory?.includes(category.slug)}
                  />
                </Box>
              ))}
            </Flex>
          </ScrollContainer>
        </GMHorizontalScrollSection>
      </Box>

      {!lg && !xl && (
        <Box>
          <Flex wrap="nowrap" maxW="100%" overflowX="scroll" mb={{ base: '16px', lg: '24px' }}>
            <Box mr="8px" flexGrow={1} w="100%">
              <GMSelect
                size={tagSize}
                value={activeFilters?.byPrice !== undefined ? 'canBePurchased' : 'allPromos'}
                onSelectOption={(event) => onSelectBuyOption(event.target.value)}
              >
                <option value="allPromos">{t(allPromosOptionText)}</option>
                {userInfo && <option value="canBePurchased">{t('search:canBePurchased')}</option>}
              </GMSelect>
            </Box>

            {context === 'promos' && (
              <Box flexGrow={1} w="100%">
                <GMSelect
                  size="small"
                  value={activeFilters?.byCountry}
                  onSelectOption={(event) => onSelectOptionCountryHandler(event)}
                >
                  <option value="">{t('search:allCountries')}</option>
                  {map(sortedCountries, (country) => (
                    // <option value={country.id}>{country.name ? t(localizedCountries[country.name]) : ''}</option>
                    <option key={country.id} value={country.id}>
                      {country.name ?? ''}
                    </option>
                  ))}
                </GMSelect>
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default GMSearchPageHeaderPromos;
