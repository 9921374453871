import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/core';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AppContext } from 'context';
import GMContainer from 'components/GMContainer';
import GMSectionContainer from 'components/GMSectionContainer';
import GMSearchResults from 'components/GMSearchResults';
import GMSearchPageSidebar from 'components/GMSearchPageSidebar';
import GMSearchPageHeader from 'components/GMSearchPageHeader';
import GMLoader from 'components/GMLoader';
import { itemsPerPage } from 'constants/index';
import useBreakpoint from 'hooks/useBreakpoint';

interface EdgesProps {
  id: string;
  slug: string;
  portraitBannerId: string;
  landscapeBannerId: string;
  publishedAt: Date;
  categoryIds: string[];
  title: string;
  abstract: string;
  content: string;
  node: {
    id: string;
    slug: string;
    portraitBannerId: string;
    landscapeBannerId: string;
    publishedAt: Date;
    categoryIds: string[];
    title: string;
    abstract: string;
    content: string;
  };
}

const GET_POSTS_IN_CATEGORY = gql`
  query postsInCategoryList($opts: SearchPostOptions, $currentLocale: String!) {
    posts(languageCode: $currentLocale, opts: $opts) {
      totalCount
      edges {
        node {
          id
          portraitBannerId
          landscapeBannerId
          publishedAt
          categoryIds
          title
          abstract
          content
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const Search: FC = () => {
  const context = 'articles';
  const { isDivInView } = useContext(AppContext);
  const [articles, setArticles] = useState([{}] as EdgesProps[]);
  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const history = useHistory();
  const { state } = history?.location;
  const category = get(state, 'category', null);
  const query = get(state, 'query', null);

  useEffect(() => {
    history.replace({ pathname: history.location.pathname, state: null });
  }, []);

  const filters = {
    byCategory: category ? [category] : undefined,
    byRead: undefined,
    byDate: undefined,
    byQuery: query || undefined,
  };

  const [activeFilters, setActiveFilters] = useState(filters);

  const articlesSearchOptions = {
    query: activeFilters.byQuery,
    byAnyCategoryIds: activeFilters.byCategory,
    isRead: activeFilters.byRead,
    publishedAfter: activeFilters.byDate,
    itemsPerPage: itemsPerPage,
    after: undefined,
  };

  const { loading, data, networkStatus, fetchMore } = useQuery(GET_POSTS_IN_CATEGORY, {
    variables: {
      currentLocale,
      opts: articlesSearchOptions,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  const setFilter = ({ filter, value }): void => {
    const filters = { ...activeFilters };
    filters[filter] = value;
    setActiveFilters(filters);
  };

  const startSearchAfterMs = 1000;
  const startSearch = debounce((newQuery: string) => {
    if (newQuery.length > 0 && newQuery.length < 3) return;
    setFilter({ filter: 'byQuery', value: newQuery });
  }, startSearchAfterMs);

  const onChangeQueryHandler = (newQuery: string): void => {
    startSearch(newQuery);
  };

  const onResetQueryHandler = (): void => {
    setFilter({ filter: 'byQuery', value: undefined });
  };

  const onChangeCategoryHandler = (id: string): void => {
    const selectedCategories: string[] = [...(activeFilters.byCategory || [])];
    const addCategoryToArray = ({ id }): void => {
      selectedCategories.push(id);
    };

    const removeCategoryFromArray = ({ id }): void => {
      remove(selectedCategories, (catId) => catId === id);
    };

    selectedCategories.includes(id) ? removeCategoryFromArray({ id }) : addCategoryToArray({ id });
    setFilter({ filter: 'byCategory', value: selectedCategories.length === 0 ? undefined : selectedCategories });
  };

  const onChangeDateHandler = (newValue: string): void => {
    const dateLastMonth = moment().subtract(1, 'month').toDate();
    setFilter({ filter: 'byDate', value: newValue === 'lastMonth' ? dateLastMonth : undefined });
  };

  const onChangeReadedHandler = (newValue: string): void => {
    const objLit = {
      allArticles: undefined,
      alreadyRead: true,
      notReadYet: false,
    };
    setFilter({
      filter: 'byRead',
      value: objLit[newValue],
    });
  };

  const onResetAllFiltersHandler = (): void => {
    setActiveFilters({
      byCategory: undefined,
      byRead: undefined,
      byDate: undefined,
      byQuery: undefined,
    });
  };

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');
    return () => addClass;
  });

  const pageInfo = data?.posts?.pageInfo;
  const edges = data?.posts?.edges;

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setArticles(edges);
    }
  }, [data, edges, pageInfo]);

  const fetchVars = { opts: { ...articlesSearchOptions, after: pageInfoState.endCursor } };
  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: fetchVars,
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'posts.edges', []);
          const newPageInfo = get(fetchMoreResult, 'posts.pageInfo', {});

          setPageInfo(newPageInfo);
          setArticles(uniqBy([...articles, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  const cleanedSrc = filter(articles, (val) => keys(val).length !== 0);

  return (
    <GMContainer>
      <Helmet htmlAttributes>
        <title>{t('search:articlesSearchTitle')}</title>
        <meta name="description" content={t('search:articlesSearchDescription')} />
      </Helmet>

      <GMSectionContainer>
        <Flex>
          <Box w="100%" maxW={{ base: '100%', lg: `calc(100% - 234px)`, xl: `calc(100% - 312px)` }} mx="auto">
            <GMSearchPageHeader
              onChangeCategory={onChangeCategoryHandler}
              onChangeQuery={onChangeQueryHandler}
              onResetQuery={onResetQueryHandler}
              onSelectDateFilter={onChangeDateHandler}
              onChangeReadedHandler={onChangeReadedHandler}
              activeFilters={activeFilters}
              context={context}
            />

            <InfiniteScroll
              dataLength={cleanedSrc.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState?.hasNext && (
                  <Box margin="10px auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0.1}
              style={{ overflowY: 'hidden' }}
            >
              <GMSearchResults
                context={context}
                loading={loading}
                articles={cleanedSrc}
                onResetAllFilters={onResetAllFiltersHandler}
              />
            </InfiniteScroll>
          </Box>
          {(lg || xl) && (
            <GMSearchPageSidebar
              onSelectDateFilter={onChangeDateHandler}
              onChangeReadedHandler={onChangeReadedHandler}
              activeFilters={activeFilters}
              context={context}
            />
          )}
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Search;
