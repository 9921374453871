import { useCallback, useEffect, useRef } from 'react';

type TimeoutHookResult = [(waitMs: number, ...args: any[]) => void, () => void, () => void];

/**
 * Hook to invoke a function after a timeout
 *
 * @param callbackFn The function to invoke after timeout
 */
export function useTimeout(callbackFn: (...args: any[]) => void): TimeoutHookResult {
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  // Clear running timeout (if any)
  const cancelTimeout = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, []);

  // Start timeout
  const startTimeout = useCallback(
    (timeout: number, ...rest) => {
      // Cancel running timeout (if any)
      cancelTimeout();
      // Start new timeout
      timeoutIdRef.current = setTimeout(callbackFn, timeout, ...rest);
    },
    [callbackFn, cancelTimeout],
  );

  // Clear running timeout and invoke callbackFn
  const skipTimeout = useCallback(() => {
    // Cancel running timeout (if any)
    cancelTimeout();
    // Invoke callbackFn
    callbackFn();
  }, [callbackFn, cancelTimeout]);

  // Clear running timeout (if any) on unmount
  useEffect(() => {
    return cancelTimeout();
  }, []);

  return [startTimeout, cancelTimeout, skipTimeout];
}
