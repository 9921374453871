import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Divider, Flex } from '@chakra-ui/core';
import { GMTag } from 'components/GMTag';
import { useTagSize } from 'hooks/useSearchPageLayout';
import { useProfile } from 'context/profile';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import ISOCountries from 'i18n-iso-countries';
import isEmpty from 'lodash/isEmpty';
import GMSelect from 'components/GMSelect';

interface ISidebarSearchPromos {
  activeFilters?: IFilters;
  onChangePriceFilter?: (newValue: string) => void;
  countries?: { id: string; name: string }[];
  context?: 'promos' | 'donations';
  onChangeCountry?: (newValue: string) => void;
}

interface IFilters {
  byPrice?: number;
  byCategory?: string[];
  byQuery?: string;
  byCountry?: string;
}

const SidebarSearchPromos: FC<ISidebarSearchPromos> = ({
  activeFilters,
  onChangePriceFilter,
  onChangeCountry,
  context = 'promos',
  countries,
}) => {
  const { userInfo } = useProfile();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onSelectBuyOptionHandler = (newValue: string): void => {
    if (onChangePriceFilter) onChangePriceFilter(newValue);
  };

  const onSelectOptionCountryHandler = (newValue: string): void => {
    if (onChangeCountry) onChangeCountry(newValue);
  };

  const sortedCountries = sortBy(
    map(countries, (country) => ({
      id: country.id,
      name: ISOCountries.getName(country.id, currentLocale),
    })),
    (o) => o.name,
  );

  const descriptionObj = {
    promos: userInfo ? 'promos:greenKmDescriptionAuth' : 'promos:greenKmDescription',
    donations: userInfo ? 'donations:greenCoinDescriptionAuth' : 'donations:greenCoinDescription',
  };

  const allPromosOptionText = context === 'promos' ? 'search:allPromos' : 'search:allDonations';

  return (
    <>
      <Box flexShrink={0} flexGrow={1}>
        <Box mb="8px">
          <GMTag
            size={tagSize}
            tagName={t(allPromosOptionText)}
            onClick={() => onSelectBuyOptionHandler('allPromos')}
            active={activeFilters?.byPrice === undefined}
          />
        </Box>
        {userInfo && (
          <Box mb="8px">
            <GMTag
              size={tagSize}
              tagName={t('search:canBePurchased')}
              onClick={() => onSelectBuyOptionHandler('canBePurchased')}
              active={activeFilters?.byPrice !== undefined}
            />
          </Box>
        )}
      </Box>

      <Divider my="16px" />

      {!isEmpty(countries) && (
        <Box>
          <Flex
            wrap="nowrap"
            maxW="100%"
            overflowX="scroll"
            mb={{ base: '16px', lg: '24px' }}
            style={{ overflowX: 'hidden' }}
          >
            <Box flexShrink={0} flexGrow={1}>
              <GMSelect
                size={tagSize}
                value={activeFilters?.byCountry}
                onSelectOption={(event) => onSelectOptionCountryHandler(event.target.value)}
              >
                <option value="">{t('search:allCountries')}</option>
                {map(sortedCountries, (country, index) => (
                  <option key={index} value={country.id}>
                    {country.name ?? ''}
                  </option>
                ))}
              </GMSelect>
            </Box>
          </Flex>
        </Box>
      )}

      <Divider my="16px" />

      <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
        <Trans
          i18nKey={descriptionObj[context]}
          // eslint-disable-next-line react/jsx-key
          components={[<strong className="green"></strong>]}
        />
      </Box>
    </>
  );
};

export default SidebarSearchPromos;
