import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import { Box, Flex, Heading, Grid, Text } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import GMReadMoreLink from 'components/GMReadMoreLink';
import GMPhoto from 'components/GMPhoto';
import GMTitleLink from 'components/GMTitleLink';
import { categories, localizedCategories } from 'constants/categories';
import Post from 'interfaces/Post';
import { useMeshLayout, useReadMoreLinkSize, useTitleLinkSize } from 'hooks/useCategoryMeshLayout';

interface GMCategoryMeshProps {
  category?: string;
  articles?: Post[];
}

interface CardProps {
  article: {
    id?: string;
    slug?: string;
    title?: string;
    landscapeBannerId?: string;
    publishedAt?: Date;
    node?: object;
    abstract?: string;
  };
  index: number;
  category?: string;
}

const Card: FC<CardProps> = ({ article, index, category }) => {
  const title = article.title || '';
  const imageUrl = article?.landscapeBannerId
    ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${article.landscapeBannerId}`
    : '';
  const thumbnailImageUrl = article?.landscapeBannerId
    ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${article.landscapeBannerId}`
    : '';

  const getMeshLayout: Function = useMeshLayout();
  const layout = getMeshLayout(index + 1) || {};

  const getTitleLinkSize: Function = useTitleLinkSize();
  const titleLinkSize = getTitleLinkSize() || '';

  if (layout.cardType[index] === 'photo')
    return (
      <GMPhoto
        h="100%"
        category={category}
        textSize="small"
        imageUrl={imageUrl}
        thumbnailImageUrl={thumbnailImageUrl}
        text={title}
      />
    );
  if (layout.cardType[index] === 'photo-title')
    return (
      <>
        <GMPhoto
          h="144px"
          noGradient
          category={category}
          textSize="small"
          imageUrl={imageUrl}
          thumbnailImageUrl={thumbnailImageUrl}
        />
        <Text
          fontSize="xl"
          fontWeight="black"
          color="TEXT.PRIMARY"
          lineHeight="24px"
          pt="10px"
          maxH="85px"
          overflow="hidden"
        >
          <TextEllipsis lines={3} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
            {article.title}
          </TextEllipsis>
        </Text>
      </>
    );
  // default
  return <GMTitleLink truncAfterLine={4} text={title} category={category} size={titleLinkSize} />;
};

const GMCategoryMesh: FC<GMCategoryMeshProps> = ({ category, articles = [] }) => {
  const colorDefault = 'CATEGORY.DEFAULT_COLOR';
  const { t, i18n } = useTranslation();
  const colorTitle = category ? categories[category] : colorDefault;
  const articlesToShow = articles?.slice(0, 3);
  const currentLocale = i18n.language;
  const getMeshLayout: Function = useMeshLayout();
  const layout = getMeshLayout(articles.length) || {};
  const getLinkSize: Function = useReadMoreLinkSize();
  const linkSize = getLinkSize() || '';

  return (
    <>
      <Heading color={colorTitle} isTruncated pb="16px" fontSize={{ base: 'xl', lg: '24px' }} overflow="hidden">
        {`${category?.toUpperCase()} - ${category && t(localizedCategories[category])}`}
      </Heading>
      <Grid
        gridTemplateAreas={layout.templateArea || null}
        gridTemplateRows={layout.templateRows || null}
        gridTemplateColumns={layout.templateColumns || null}
        gridGap={{ base: '16px', lg: '18px', xl: '24px' }}
        pb={{ base: '16px', lg: '24px' }}
      >
        {map(articlesToShow, (article, index) => {
          const singleArticle = article?.node;
          const titleSlug = singleArticle.slug;

          return (
            <Box
              gridArea={Number(index) === 0 ? 'main' : `other${index}`}
              key={singleArticle.id ? singleArticle.id : index}
            >
              <Link to={`/${currentLocale}/article/${titleSlug}`}>
                <Card category={category} index={Number(index)} article={singleArticle} />
              </Link>
            </Box>
          );
        })}
      </Grid>
      <Flex alignItems="flex-end" flex="1">
        <Link to={`/${currentLocale}/category/${category}`} style={{ width: '100%' }}>
          <GMReadMoreLink text={t('lifestyle:categories:readAll')} category={category} size={linkSize} />
        </Link>
      </Flex>
    </>
  );
};

export default GMCategoryMesh;
