export const companyCategories = [
  {
    id: 0,
    label: 'APPAREL',
  },
  {
    id: 1,
    label: 'FEEDING',
  },
  {
    id: 2,
    label: 'FURNITURE',
  },
  {
    id: 3,
    label: 'TRANSPORT',
  },
  {
    id: 4,
    label: 'BANKFINANCE',
  },
  {
    id: 5,
    label: 'PAPERPRINTING',
  },
  {
    id: 6,
    label: 'CHEMICALPLASTIC',
  },
  {
    id: 7,
    label: 'ECO',
  },
  {
    id: 8,
    label: 'BUILDING',
  },
  {
    id: 9,
    label: 'ELECTRONIC',
  },
  {
    id: 10,
    label: 'PUBLICADMINISTRATION',
  },
  {
    id: 11,
    label: 'OFFICE',
  },
  {
    id: 12,
    label: 'INFO',
  },
  {
    id: 13,
    label: 'MECHANICS',
  },
  {
    id: 14,
    label: 'MEDICINEAESTETICS',
  },
  {
    id: 15,
    label: 'GOLDGIFT',
  },
  {
    id: 16,
    label: 'ADVERTISE',
  },
  {
    id: 17,
    label: 'SCHOOLCULTURE',
  },
  {
    id: 18,
    label: 'SPORTSHOBBIES',
  },
  {
    id: 19,
    label: 'TRAVELTURISM',
  },
];
