import React, { FC } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { MdEdit } from 'react-icons/md';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import GMUserDataItem from 'components/GMUserDataItem';
import GMTagProfile from 'components/GMTagProfile';
import { localizedCategories } from 'constants/categories';
import { getLocalizedCountries } from 'utils';

interface IGMUserPreferences {
  account?: Account;
  openModal: (modal: string) => void;
}

interface Account {
  address: string;
  ageGroup: Date;
  categoryIds: [string];
  createdAt: Date;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  preferredCountry: string;
  vehicles: [Vehicle];
}

interface Vehicle {
  id: string;
  owned: boolean;
}

const GMUserPreferences: FC<IGMUserPreferences> = ({ account, openModal }) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const localizedCountries = getLocalizedCountries(currentLocale);

  const openSettingsClickHandler = (): void => {
    openModal('preferences');
  };

  const { preferredCountry, categoryIds } = account || {};

  return (
    <Box color="SECONDARY" fontSize={{ base: 'lg', lg: 'xl', xl: '22px' }} display={{ lg: 'none' }}>
      <Flex justify="space-between">
        <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
          {t('profile:preferences:title')}
        </Heading>
        <Flex display={{ base: 'flex', lg: 'none' }} wrap="nowrap" width="20px" justify="space-between">
          <Box color="PRIMARY" cursor="pointer">
            <MdEdit size="20px" onClick={openSettingsClickHandler} />
          </Box>
        </Flex>
      </Flex>
      <Flex direction={{ base: 'column', lg: 'row' }}>
        <Box>
          {preferredCountry && (
            <GMUserDataItem label={t('profile:preferences:nation')}>
              {localizedCountries[preferredCountry]}
            </GMUserDataItem>
          )}
          {!isEmpty(categoryIds) && (
            <GMUserDataItem label={t('profile:preferences:lifestyleCategories')}>
              <Flex mt="6px" wrap="wrap">
                {map(
                  categoryIds,
                  (category) => category && <GMTagProfile key={category} tagName={t(localizedCategories[category])} />,
                )}
              </Flex>
            </GMUserDataItem>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default GMUserPreferences;
