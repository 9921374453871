import GMContainer from 'components/GMContainer';
import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Box, Text } from '@chakra-ui/core';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import GMSectionContainer from 'components/GMSectionContainer';
import 'react-accessible-accordion/dist/fancy-example.css';
import { palette } from 'theme/theme';
import { isEmpty } from 'lodash';

const FAQPage: FC = () => {
  const { t } = useTranslation();
  const rulesQuestions = t('Info:Rules', { returnObjects: true }) as Array<{
    question: string;
    answer: string;
  }>;
  const guidesQuestions = t('Info:Guides', { returnObjects: true }) as Array<{
    question: string;
    answer: string;
  }>;
  const faqQuestions = t('Info:FAQ', { returnObjects: true }) as Array<{
    question: string;
    answer: string;
  }>;

  return (
    <GMContainer>
      <Helmet>
        <title>{t('Info:GremoboFAQ')}</title>
        <meta name="description" content={t('Info:GremoboFAQ')} />
      </Helmet>
      <Box py={{ base: '16px', lg: '0', xl: '16px' }}>
        <GMSectionContainer>
          {!isEmpty(rulesQuestions) && (
            <>
              <Text
                as="h2"
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
                p="50px"
                pt="20px"
                color={palette.MEDIUM_GREEN}
              >
                {t('Info:GremoboRules')}
              </Text>

              <Accordion allowZeroExpanded>
                {rulesQuestions.map((item, index) => (
                  <AccordionItem key={Number(index)}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          )}

          {!isEmpty(guidesQuestions) && (
            <>
              <Text as="h2" fontSize="3xl" fontWeight="bold" textAlign="center" p="50px" color={palette.MEDIUM_GREEN}>
                {t('Info:GremoboGuides')}
              </Text>

              <Accordion allowZeroExpanded>
                {guidesQuestions.map((item, index) => (
                  <AccordionItem key={Number(index)}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          )}

          {!isEmpty(faqQuestions) && (
            <>
              <Text as="h2" fontSize="3xl" fontWeight="bold" textAlign="center" p="50px" color={palette.MEDIUM_GREEN}>
                {t('Info:GremoboFAQ')}
              </Text>

              <Accordion allowZeroExpanded>
                {faqQuestions.map((item, index) => (
                  <AccordionItem key={Number(index)}>
                    <AccordionItemHeading>
                      <AccordionItemButton>{item.question}</AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>{item.answer}</AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </>
          )}
        </GMSectionContainer>
      </Box>
    </GMContainer>
  );
};

export default FAQPage;
