import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/core';
import GMInput from 'components/GMInput';
import { useInputSize } from 'hooks/useSearchPageLayout';

interface GMSearchInputProps {
  query?: string;
  onChangeQuery?: (newQuery: string) => void;
  onResetQuery?: () => void;
}

const GMSearchInput: FC<GMSearchInputProps> = ({ query, onChangeQuery, onResetQuery }) => {
  const { t } = useTranslation();
  const getInputSize: Function = useInputSize();
  const inputSize = getInputSize() || 'small';

  const onInputHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChangeQuery) onChangeQuery(event.target.value);
  };

  const onClickSearchIconHandler = (): void => {
    if (query) {
      if (onResetQuery) onResetQuery();
    }
  };

  return (
    <Box mb={{ base: '16px', lg: '24px' }}>
      <GMInput
        initValue={query}
        placeholder={t('common:search')}
        iconType={query ? 'close' : 'search'}
        onInput={onInputHandler}
        size={inputSize}
        onClickIcon={onClickSearchIconHandler}
      />
    </Box>
  );
};

export default GMSearchInput;
