import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import map from 'lodash/map';
import GMTag from 'components/GMTag';
import GMSelect from 'components/GMSelect';
import GMHorizontalScrollSection from 'components/GMHorizontalScrollSection';
import { localizedCategories } from 'constants/categories';
import useBreakpoint from 'hooks/useBreakpoint';
import { useTagSize } from 'hooks/useSearchPageLayout';
import ScrollContainer from 'react-indiana-drag-scroll';

interface GMSearchPageHeaderArticlesProps {
  activeFilters?: Filters;
  onChangeCategory?: (newCategory: string) => void;
  onSelectDateFilter?: (newValue: string) => void;
  onChangeReadedHandler?: (newValue: string) => void;
}

interface Filters {
  byRead?: boolean;
  byDate?: string;
  byCategory?: string[];
  byQuery?: string;
}

const GMSearchPageHeaderArticles: FC<GMSearchPageHeaderArticlesProps> = ({
  activeFilters,
  onSelectDateFilter,
  onChangeReadedHandler,
  onChangeCategory,
}) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const { keycloak } = useKeycloak();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';
  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const onClickCategoryHandler = (id: string): void => {
    if (onChangeCategory) onChangeCategory(id);
  };

  const onSelectDateFilterHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onSelectDateFilter) {
      onSelectDateFilter(event.target.value);
    }
  };

  const onSelectReadedHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onChangeReadedHandler) {
      onChangeReadedHandler(event.target.value);
    }
  };

  const localizedCategoriesToArray = map(localizedCategories, (categoryName, id) => {
    return {
      id,
      name: categoryName,
    };
  });

  return (
    <>
      <Box h={{ base: '28px', lg: '32px', xl: '40px' }} mb={{ base: '16px', lg: '24px' }}>
        <GMHorizontalScrollSection>
          <ScrollContainer className="scroll-container" horizontal nativeMobileScroll>
            <Flex wrap="nowrap">
              {map(localizedCategoriesToArray, (category, index) => (
                <Box key={category.id} flexShrink={0} mr="8px">
                  <GMTag
                    onClick={() => onClickCategoryHandler(category.id)}
                    tagName={`${index + 1} - ${t(category.name)}`}
                    dense
                    size={tagSize}
                    active={activeFilters?.byCategory?.includes(category.id)}
                  />
                </Box>
              ))}
            </Flex>
          </ScrollContainer>
        </GMHorizontalScrollSection>
      </Box>
      {!lg && !xl && (
        <Box>
          <Flex wrap="nowrap" maxW="100%" overflowX="scroll" mb={{ base: '16px', lg: '24px' }}>
            <Box mr="8px" flexShrink={0} flexGrow={1}>
              <GMSelect
                size="small"
                value={activeFilters?.byRead ? 'allArticles' : 'notReadYet'}
                onSelectOption={onSelectReadedHandler}
              >
                <option value="allArticles">{t('search:allArticles')}</option>
                {keycloak?.authenticated && (
                  <>
                    <option value="alreadyRead">{t('search:alreadyRead')}</option>
                    <option value="notReadYet">{t('search:notReadYet')}</option>
                  </>
                )}
              </GMSelect>
            </Box>
            <Box flexShrink={0} flexGrow={1}>
              <GMSelect
                value={activeFilters?.byDate ? 'lastMonth' : 'anyDate'}
                onSelectOption={onSelectDateFilterHandler}
              >
                <option value="anyDate">{t('search:anyDate')}</option>
                <option value="lastMonth">{t('search:lastMonth')}</option>
              </GMSelect>
            </Box>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default GMSearchPageHeaderArticles;
