import { palette } from 'theme/theme';

export const lockedData = [
  { y: 50, x: 'ITEM1' },
  { y: 20, x: 'ITEM2' },
  { y: 20, x: 'ITEM3' },
];

export const prevProgressDefault = 0.3;

export const colors = {
  ITEM1: palette.LIGHT_GREEN,
  ITEM2: palette.MEDIUM_GREEN,
  ITEM3: palette.DARK_GREEN,
  DELIVERY: palette.MEDIUM_GREEN,
  SHARING: palette.LIGHT_GREEN,
  DELIVEROO: palette.LIGHT_GREEN,
  JUST_EAT: palette.MEDIUM_GREEN,
  AMAZON: palette.DARK_GREEN,
  OTHER: palette.SMOKED_GREY,
  DRIVE_NOW: palette.LIGHT_GREEN,
  CAR2GO: palette.MEDIUM_GREEN,
  ENJOY: palette.DARK_GREEN,
  HYDROGEN: palette.MEDIUM_GREY,
  ELECTRIC: palette.DARK_GREEN,
  METHANE: palette.MEDIUM_GREEN,
  HYBRID: palette.LIGHT_GREEN,
  DIESEL: '#ef6a00',
  PETROL: '#fabe00',
  SUSTAINABLE: palette.LIGHT_GREEN,
  NOT_SUSTAINABLE: palette.DARK_GREEN,
  WALKING: palette.LIGHT_GREEN,
  BICYCLE: palette.MEDIUM_GREEN,
  TRAIN: palette.DARK_GREY,
  PERSONAL: palette.LIGHT_GREEN,
  DELIVERY_0: palette.LIGHT_GREEN,
  DELIVERY_1: palette.MEDIUM_GREEN,
  DELIVERY_2: palette.DARK_GREEN,
  DELIVERY_3: palette.MEDIUM_GREY,
};

export const localizedCharts = {
  DELIVERY: 'mobility:charts:legend:delivery',
  SHARING: 'mobility:charts:legend:sharing',
  DELIVEROO: 'mobility:charts:legend:deliveroo',
  JUST_EAT: 'mobility:charts:legend:just_eat',
  AMAZON: 'mobility:charts:legend:amazon',
  OTHER: 'mobility:charts:legend:other',
  DRIVE_NOW: 'mobility:charts:legend:drive_now',
  CAR2GO: 'mobility:charts:legend:car2go',
  ENJOY: 'mobility:charts:legend:enjoy',
  HYDROGEN: 'mobility:enterprise:charts:legend:hydrogen',
  ELECTRIC: 'mobility:enterprise:charts:legend:electric',
  METHANE: 'mobility:enterprise:charts:legend:methane',
  HYBRID: 'mobility:enterprise:charts:legend:hybrid',
  DIESEL: 'mobility:enterprise:charts:legend:diesel',
  PETROL: 'mobility:enterprise:charts:legend:petrol',
  SUSTAINABLE: 'mobility:enterprise:charts:legend:sustainable',
  WALKING: 'mobility:charts:legend:walking',
  BICYCLE: 'mobility:charts:legend:bicycle',
  TRAIN: 'mobility:charts:legend:train',
  PERSONAL: 'mobility:charts:legend:personal',
};
