import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// import { useAnalytics } from 'use-analytics';
import ReactMarkdown from 'react-markdown';
import keycloak from 'keycloak';
import { Box, Text, Heading, Flex, useDisclosure } from '@chakra-ui/core';
import GMPhoto from 'components/GMPhoto';
import GMButtonGreen from 'components/GMButtonGreen';
import GMGreenDeliveryIcon from 'components/GMGreenDeliveryIcon';
import GMModal from 'components/GMModal';
import useBreakpoint from 'hooks/useBreakpoint';
import { renderers } from 'renderers';
import { useProfile } from 'context/profile';
import { UseActivityValues, EventCategory } from 'constants/analytics';
import { parseUnderline } from 'utils';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

interface IGMPartnerContent {
  title?: string;
  description?: string;
  abstract?: string;
  imageUrl?: string;
  thumbnailImageUrl?: string;
  categoryName?: string;
  isGreenDelivery?: boolean;
  greenKmReward?: number;
  affiliateLink?: string;
  tagline?: string;
}

interface IBuyButton {
  size?: 'big' | 'medium' | 'small';
  onOpen: () => void;
  openLink: (link?: string) => void;
}

interface IModal {
  isOpen: boolean;
  onClose: () => void;
  login: () => void;
  openLink: (link?: string) => void;
}

const Modal: FC<IModal> = ({ isOpen, onClose, login, openLink }) => {
  const { t } = useTranslation();

  return (
    <GMModal isOpen={isOpen} onClose={onClose}>
      <Heading color="PRIMARY" fontSize={{ base: '20px', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('shopping:singleShop:warningMessageTitle')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }}>{t('shopping:singleShop:warningMessageContent')}</Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" variant="transparent" onClick={openLink}>
            {t('common:continue')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" onClick={login}>
            {t('common:login')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </GMModal>
  );
};

const BuyButton: FC<IBuyButton> = ({ size = 'small', onOpen, openLink }) => {
  const { t } = useTranslation();

  const { userInfo } = useProfile();

  const openModal = (): void => {
    if (onOpen) onOpen();
  };

  const onClickHandler = (): void => {
    if (userInfo) return openLink();
    openModal();
  };

  return (
    <GMButtonGreen size={size} onClick={onClickHandler}>
      {t('shopping:singleShop:buyButton')}
    </GMButtonGreen>
  );
};

const GMPartnerContent: FC<IGMPartnerContent> = ({
  categoryName,
  thumbnailImageUrl = '',
  imageUrl = '',
  title,
  abstract,
  isGreenDelivery,
  greenKmReward,
  description,
  affiliateLink,
  tagline = '',
}) => {
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';
  const lg = breakpoint === 'lg';
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const renderingMarkdown = renderers;

  const { isOpen, onOpen, onClose } = useDisclosure();
  // const { track } = useAnalytics();
  const sendDataToGTM = useGTMDispatch();

  const login = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuLogin, value: EventCategory.UserActivity });

    keycloak.login({ locale: currentLanguage });
  }, [keycloak]);

  const openLink = (link): void => {
    window.open(link, '_blank');
    onClose();
  };

  return (
    <>
      <Box mb={{ base: '24px', lg: '32px', xl: '48px' }}>
        <Box w="100%" h={{ base: '178px', lg: '342px', xl: '342px' }} position="relative">
          <GMPhoto text="" w="100%" h="100%" imageUrl={imageUrl} thumbnailImageUrl={thumbnailImageUrl} />
          <Flex
            position="absolute"
            w="100%"
            bottom="0"
            pb={{ base: '16px', lg: '32px' }}
            px="32px"
            align="flex-end"
            justify="space-between"
          >
            <Box color="TITLE_LINK.LIGHT_TEXT">
              <Heading as="h1" fontSize={{ base: '28px', lg: '32px', xl: '40px' }} textShadow="outline">
                {title} {tagline && `- ${tagline}`}
              </Heading>
              {categoryName && (
                <Box fontSize="xs" display={{ lg: 'none' }} textShadow="outline">
                  {categoryName}
                </Box>
              )}
            </Box>
            <Box display={{ base: 'none', lg: 'block' }} ml="18px" minW={{ lg: '262px', xl: '360px' }}>
              <BuyButton openLink={() => openLink(affiliateLink)} size="big" onOpen={onOpen} />
            </Box>
          </Flex>
          {isGreenDelivery && (
            <Box
              position="absolute"
              zIndex={1}
              bottom="0"
              right="0"
              w="48px"
              h="48px"
              borderRadius="40px 0 0 0px"
              display={{ lg: 'none' }}
              overflow="hidden"
            >
              <GMGreenDeliveryIcon padding={'16px 8px 8px 16px'} />
            </Box>
          )}
        </Box>

        {isGreenDelivery && (
          <Box bg="TEXT.ABSTRACT_BACKGROUND" p="24px 32px" color="TEXT.ABSTRACT" position="relative">
            <Box lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}>
              <Box color="TEXT.SECONDARY" fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}>
                <Text as="span" fontWeight="bold">
                  {t('shopping:singleShop:greenDeliveryName')} -
                </Text>
                <Text as="span"> {t('shopping:singleShop:greenDelivery', { km: greenKmReward })}</Text>
              </Box>
              <Box fontSize={{ base: '14px', xl: '16px' }} fontStyle="italic">
                {t('shopping:singleShop:greenDeliveryDescription')}
              </Box>
            </Box>
            <Flex
              position="absolute"
              top={{ lg: '19px', xl: '12px' }}
              right="32px"
              justify="center"
              align="center"
              zIndex={1}
              w={{ lg: '48px', xl: '64px' }}
              h={{ lg: '48px', xl: '64px' }}
              borderRadius="50%"
              display={{ base: 'none', lg: 'flex' }}
              ml="auto"
              overflow="hidden"
            >
              <GMGreenDeliveryIcon padding={xl ? '17px' : '12px'} />
            </Flex>
          </Box>
        )}
      </Box>

      <Box
        p={{ base: '0 32px 24px', lg: '0 0 32px' }}
        fontSize={{ base: 'md', lg: 'lg' }}
        lineHeight={{ base: '27px', xl: '32px' }}
        color="TEXT.POST_CONTENT"
        className="markdown"
      >
        <Box my={{ base: '24px' }} display={{ lg: 'none' }}>
          <BuyButton size={xl || lg ? 'medium' : 'small'} openLink={() => openLink(affiliateLink)} onOpen={onOpen} />
        </Box>
        <Box fontWeight="bold" fontSize={{ base: 'lg', lg: 'xl' }} pb="16px">
          {abstract}
        </Box>
        <ReactMarkdown escapeHtml={false} source={parseUnderline(description)} renderers={renderingMarkdown} />
      </Box>

      {isOpen && <Modal login={login} openLink={() => openLink(affiliateLink)} isOpen={isOpen} onClose={onClose} />}
    </>
  );
};

export default GMPartnerContent;
