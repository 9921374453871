import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex } from '@chakra-ui/core';
import GMButtonGreen from 'components/GMButtonGreen';
import { useBtnSize } from 'hooks/useProfileLayout';

interface IGMSinglePromoModalConfirmContent {
  onConfirm: () => void;
  onClose: () => void;
  loading?: boolean;
  context?: 'promo' | 'donation';
}

const GMSinglePromoModalConfirmContent: FC<IGMSinglePromoModalConfirmContent> = ({
  onConfirm,
  onClose,
  loading,
  context = 'promo',
}) => {
  const { t } = useTranslation();

  const getButtonSize: Function = useBtnSize();
  const btnSize = getButtonSize() || 'small';

  const text =
    context === 'promo'
      ? t('promos:singlePromo:confirmModal:content')
      : t('donations:singleDonation:confirmModal:content');

  return (
    <Box>
      <Box textAlign="center" pb="10px" color="SECONDARY">
        {text}
      </Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size={btnSize} variant="transparent" onClick={onClose} disabled={loading}>
            {t('common:cancel')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size={btnSize} onClick={onConfirm} loading={loading}>
            {t('promos:singlePromo:buyButton')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </Box>
  );
};

export default GMSinglePromoModalConfirmContent;
