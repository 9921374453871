import React, { FC, ChangeEvent, ReactNode } from 'react';
import { ControlBox, VisuallyHidden, Box, theme } from '@chakra-ui/core';
import { palette } from 'theme/theme';

interface IGMCheckbox {
  checked?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  isInvalid?: boolean;
  isRequired?: boolean;
  size?: string;
  onChange?: (value: boolean) => void;
  name?: string;
  value?: string;
}

const GMCheckbox: FC<IGMCheckbox> = ({
  children,
  checked = false,
  disabled = false,
  isInvalid = false,
  isRequired = false,
  size = '16px',
  onChange,
  value,
  ...props
}) => (
  <Box
    alignItems="flex-start"
    as="label"
    display="flex"
    opacity={disabled ? 0.8 : 1}
    pointerEvents={disabled ? 'none' : 'all'}
  >
    {/* This is the sibling input, it's visually hidden */}
    <VisuallyHidden
      as="input"
      defaultChecked={checked}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => onChange && onChange(e.target.checked)}
      // Issue of ChakraUI TS Migration: https://github.com/chakra-ui/chakra-ui/issues/205
      {...{
        type: 'checkbox',
        value,
      }}
    />

    {/* This is the control box with a colored box as children */}
    <ControlBox
      _checked={{ color: palette.WHITE, borderColor: palette.MEDIUM_GREEN }}
      _disabled={{ pointerEvents: 'none' }}
      border={`2px solid ${palette.MEDIUM_GREY}`}
      cursor="pointer"
      mt={[0, 1]}
      rounded="sm"
      size={size}
      {...props}
    >
      <Box size="8px" bg={palette.MEDIUM_GREEN} />
    </ControlBox>

    {/* You can pass additional text */}
    <Box
      as="span"
      color={isInvalid ? palette.VERMILLION : palette.MEDIUM_GREY}
      cursor="pointer"
      fontSize={theme.fontSizes.sm}
      verticalAlign="top"
      ml={2}
      display="flex"
      alignContent="flex-start"
    >
      {isRequired && <span>* </span>}
      {children}
    </Box>
  </Box>
);

export default GMCheckbox;
