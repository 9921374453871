import React, { FC, useState } from 'react';
import { Heading, Box, Flex, InputGroup, InputRightElement, NumberInput, NumberInputField } from '@chakra-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import GMButtonGreen from 'components/GMButtonGreen';
import Drop from 'assets/svg-components/drop';

interface IGMModalSendCoins {
  onClose?: () => void;
  onGenerateCoupon?: (value: number) => void;
  loading?: boolean;
  error?: string;
}

const GMModalSendCoins: FC<IGMModalSendCoins> = ({ onClose, onGenerateCoupon, loading, error }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>();

  const onGenerateCouponHandler = (): void => {
    if (!value || !Number(value) || value < 0) return;
    if (onGenerateCoupon) onGenerateCoupon(value);
  };

  const onChange = (value): void => {
    setValue(value);
  };

  return (
    <>
      <Heading color="PRIMARY" fontSize={{ base: '20px', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:sendCoins:modal:title')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }} color="PAGE.SECONDARY_TEXT">
        <Trans
          i18nKey="profile:sendCoins:modal:description"
          // eslint-disable-next-line react/jsx-key
          components={[<br />]}
        />
      </Box>
      <Box mb="32px">
        <Box mb="8px" color="PAGE.SECONDARY_TEXT">
          {t('profile:sendCoins:modal:inputLabel')}
        </Box>
        <InputGroup color="TEXT.PRIMARY" w="100%">
          <NumberInput w="100%" value={value} onChange={onChange} min={0}>
            <NumberInputField variant="flushed" fontSize="20px" maxLength={9} />
            <InputRightElement>
              <Drop variant="darkGrey" />
            </InputRightElement>
          </NumberInput>
        </InputGroup>
        {error && (
          <Box color="ERROR" fontSize="14px">
            {error}
          </Box>
        )}
      </Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" variant="transparent" onClick={onClose} disabled={loading}>
            {t('common:cancel')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" onClick={onGenerateCouponHandler} disabled={!value} loading={loading}>
            {t('profile:sendCoins:modal:generateBtn')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </>
  );
};

export default GMModalSendCoins;
