import React, { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { Box, Flex, Heading } from '@chakra-ui/core';
import i18n from 'i18n';
import moment from 'moment';
import GMContainer from 'components/GMContainer';
import GMDonationSummary from 'components/GMDonationSummary';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMTransactionSummarySidebar from 'components/GMTransactionSummarySidebar';
import customApolloClient from 'apollo';
import { useProfile } from 'context/profile';

const SINGLE_TRANSACTION = gql`
  query donationSubscriptionByTransactionId($languageCode: String!, $transactionId: String!) {
    donationSubscriptionByTransactionId(languageCode: $languageCode, transactionId: $transactionId) {
      donation {
        slug
        name
        price
        thankYouNote
        expirationDate
      }
      subscribedOn
      transactionState
      confirmedOn
    }
  }
`;

const TransactionSummaryDonation: FC = () => {
  const { t } = useTranslation();
  const { id: transactionId } = useParams();
  const { token } = useProfile();

  const currentLocale = i18n.language;

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const client = useMemo(() => customApolloClient({ clientType: 'promosClient', token }), [token]);

  const { loading, error, data } = useQuery(SINGLE_TRANSACTION, {
    variables: { languageCode: currentLocale, transactionId },
    client,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const { donationSubscriptionByTransactionId } = data;
  const { donation, subscribedOn, confirmedOn, transactionState } = donationSubscriptionByTransactionId || {};
  const { slug, name, price, thankYouNote, expirationDate } = donation || {};

  return (
    <GMContainer>
      <Helmet>
        <title>{t('donations:singleDonation:summary:title')}</title>
        <meta name="description" content={t('donations:singleDonation:summary:title')} />
      </Helmet>

      <Flex px={{ md: '52px' }} py="32px" maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
        <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
          <Heading
            pb={{ base: '24px', xl: '48px' }}
            px={{ base: '16px', lg: '0' }}
            color="PRIMARY"
            fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
          >
            {t('donations:singleDonation:summary:title')}
          </Heading>

          <Box maxW="600px">
            <GMDonationSummary
              slug={slug}
              name={name}
              expirationDate={expirationDate}
              subscribedOn={subscribedOn}
              price={price}
              thankYouNote={thankYouNote}
              state={transactionState}
              confirmedOn={confirmedOn}
            />
          </Box>
        </Box>
        <GMTransactionSummarySidebar context="donations" />
      </Flex>
    </GMContainer>
  );
};

export default TransactionSummaryDonation;
