import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TextEllipsis from 'react-text-ellipsis';
import { Box, Flex } from '@chakra-ui/core';
import map from 'lodash/map';
import moment from 'moment';
import GMTransactionStateLabel from 'components/GMTransactionStateLabel';
import Drop from 'assets/svg-components/drop';
import GreenKm from 'assets/svg-components/greenKm';
import { useProfile } from 'context/profile';

interface IGMTransactionsCalendar {
  transactions?: ITransaction[];
  variant?: 'short';
}

interface ITransaction {
  node: {
    id: string;
    amount: number;
    lastEventDate: Date;
    currency: {
      code: string;
    };
    motive: string;
    sender: string;
    transactionType: string;
    state: string;
  };
}

interface ISingleTransaction {
  amount: number;
  sender: string;
  transactionType: string;
  motive: string;
  currency: { code: string };
  state: string;
  lastEventDate: Date;
  variant?: 'short';
}

const SingleTransaction: FC<ISingleTransaction> = ({
  amount,
  sender,
  transactionType,
  motive,
  currency,
  state,
  lastEventDate,
  variant,
}) => {
  const { userInfo } = useProfile();
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  moment.locale(currentLocale);
  const date = moment(lastEventDate).format('DD MMM YYYY HH:mm');

  const { id: userId } = userInfo?.myData;
  const isSender = userId === sender;

  const amountSignType = (amount && Math.sign(amount) > 0) || Math.sign(amount) === 0;
  const notPreSigned =
    amount && Math.sign(amount) < 0
      ? `${amount?.toLocaleString(currentLocale)}`
      : `+${amount?.toLocaleString(currentLocale)}`;
  const signedAmount = amountSignType && isSender ? `-${amount?.toLocaleString(currentLocale)}` : notPreSigned;

  const isTransactionOutgoing = amountSignType && isSender;

  const transactionName = {
    coupon: isSender
      ? 'profile:transactions:transactionName:couponSent'
      : 'profile:transactions:transactionName:couponReceived',
  };

  const showLabel = state !== 'CONFIRMED' || variant !== 'short';
  const showAmount = state === 'CONFIRMED' || variant !== 'short';

  return (
    <Flex justify="space-between" direction={{ base: variant === 'short' ? 'row' : 'column', lg: 'row' }}>
      <Flex direction="column" mr="5px" color="SECONDARY">
        {transactionType && <Box>{t(transactionName[transactionType])}</Box>}

        {motive && variant === 'short' ? (
          <TextEllipsis lines={1} tag={'div'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
            {t(motive)}
          </TextEllipsis>
        ) : (
          <Box>{t(motive)}</Box>
        )}
        {variant !== 'short' && (
          <Box fontSize="12px" color="PAGE.SECONDARY_TEXT" mt={{ base: '4px', lg: 'auto' }}>
            {date}
          </Box>
        )}
      </Flex>
      <Flex
        direction={{ base: 'row', lg: 'column' }}
        justify="space-between"
        align={{ base: 'center', lg: 'flex-end' }}
        mt={{ base: variant === 'short' ? '0' : '8px', lg: '0' }}
      >
        {showLabel && <GMTransactionStateLabel state={state} />}
        {showAmount && (
          <Flex
            align="center"
            justify="flex-end"
            fontWeight="bold"
            lineHeight="18px"
            mt={variant === 'short' ? '0' : '5px'}
            color={isTransactionOutgoing ? 'PAGE.SECONDARY_TEXT' : 'PRIMARY'}
          >
            {signedAmount}
            {currency?.code === 'GCN' && <Drop variant={isTransactionOutgoing ? 'mediumGrey' : 'blue'} size={20} />}
            {currency?.code === 'GKM' && <GreenKm variant={isTransactionOutgoing ? 'mediumGrey' : 'blue'} size={20} />}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

const GMTransactionsCalendar: FC<IGMTransactionsCalendar> = ({ transactions = [], variant }) => {
  return (
    <>
      <Box mb="24px">
        {map(transactions, (transaction, index) => {
          const singleTransaction = transaction?.node;
          const { id, transactionType, amount, sender, motive, currency, state, lastEventDate } = singleTransaction;

          const urlSlug = {
            'promo subscription': 'promo',
            'donation subscription': 'donation',
          };

          const isLink = transactionType && urlSlug[transactionType];

          return (
            <Box
              fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
              lineHeight={{ base: '17px', lg: '19px', xl: '22px' }}
              key={id}
              bg={Number(index) % 2 === 0 ? 'LIGHT' : 'SUPER_LIGHT_GRAY'}
              p={{ base: '12px', lg: '12px 14px', xl: '12px 17px' }}
            >
              {isLink ? (
                <Link to={`/profile/transactions/${urlSlug[transactionType]}/${id}`}>
                  <SingleTransaction
                    amount={amount}
                    sender={sender}
                    transactionType={transactionType}
                    motive={motive}
                    currency={currency}
                    state={state}
                    lastEventDate={lastEventDate}
                    variant={variant}
                  />
                </Link>
              ) : (
                <SingleTransaction
                  amount={amount}
                  sender={sender}
                  transactionType={transactionType}
                  motive={motive}
                  currency={currency}
                  state={state}
                  lastEventDate={lastEventDate}
                  variant={variant}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default GMTransactionsCalendar;
