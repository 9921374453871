import React, { FC, useEffect } from 'react';
import TextEllipsis from 'react-text-ellipsis';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@chakra-ui/core';
import moment from 'moment';
import map from 'lodash/map';
import GMPhoto from 'components/GMPhoto';
import useBreakpoint from 'hooks/useBreakpoint';
import { localizedCategories, categories } from 'constants/categories';

export interface GMArticleCardProps {
  title?: string;
  desc?: string;
  imageUrl?: string;
  thumbnailImageUrl?: string;
  showDesc?: boolean;
  type?: 'horizontalWithDescription' | 'horizontalOnlyTitle';
  titleSize?: 'xsmall' | 'small' | 'medium' | 'big';
  publishedAt?: Date;
  categoryIds?: string[];
}

export const GMArticleCard: FC<GMArticleCardProps> = ({
  type,
  title,
  desc,
  publishedAt,
  imageUrl = '',
  thumbnailImageUrl = '',
  showDesc = false,
  categoryIds,
  titleSize = 'small',
}) => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const breakpoint = useBreakpoint();
  const getColorFromCategory = (category: string): string => categories[category];

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const date = moment(publishedAt).format('DD MMM YYYY');

  // serach results base-sm-md
  if (type === 'horizontalOnlyTitle') {
    return (
      <Flex pb="24px">
        <Box pr="16px" w="33%" h="96px" minW="33%">
          <GMPhoto
            w="100%"
            h="100%"
            textSize="xsmall"
            thumbnailImageUrl={thumbnailImageUrl}
            imageUrl={imageUrl}
            borderColor="SEARCH.PHOTO_BORDER"
            noGradient
          />
        </Box>
        <Text
          maxH="96px"
          color="ARTICLE_CARD.TITLE"
          overflow="hidden"
          fontSize="md"
          lineHeight="19px"
          fontWeight="bold"
        >
          {title}
        </Text>
      </Flex>
    );
  }
  // search page lg and xl
  if (type === 'horizontalWithDescription') {
    const xl = breakpoint === 'xl';

    return (
      <Flex pb="24px">
        <Box pr="16px">
          <GMPhoto
            w={xl ? '288px' : '215px'}
            h={xl ? '156px' : '117px'}
            textSize="xsmall"
            thumbnailImageUrl={thumbnailImageUrl}
            imageUrl={imageUrl}
            borderColor="SEARCH.PHOTO_BORDER"
            noGradient
          />
        </Box>
        <Box>
          <Box
            maxH="96px"
            color="ARTICLE_CARD.TITLE"
            overflow="hidden"
            fontSize={{ lg: 'lg', xl: 'xl' }}
            lineHeight={{ lg: '22px', xl: '24px' }}
            fontWeight="bold"
            pb={{ lg: '4px', xl: '6px' }}
            h={{ lg: '52px', xl: '54px' }}
          >
            <TextEllipsis lines={2} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
              {title}
            </TextEllipsis>
          </Box>
          <Flex flexShrink={0}>
            <Box
              fontSize={{ lg: 'xs', xl: 'sm' }}
              lineHeight={{ lg: '15px', xl: '17px' }}
              fontWeight={{ lg: 'light', xl: 'regular' }}
              color={xl ? 'ARTICLE_CARD.DATE_LIGHT' : 'ARTICLE_CARD.DATE'}
              pb={{ lg: '12px', xl: '16px' }}
              whiteSpace="nowrap"
            >
              {date}
            </Box>
            <Box
              fontSize={{ lg: 'xs', xl: 'sm' }}
              lineHeight={{ lg: '15px', xl: '17px' }}
              pl={{ lg: '18px', xl: '24px' }}
              fontWeight="bold"
              color="PAGE.SECONDARY_TEXT"
            >
              <TextEllipsis lines={1} tag={'span'} ellipsisChars={' ...'} debounceTimeoutOnResize={200}>
                {map(categoryIds, (catId, index) => (
                  <Box
                    as="span"
                    key={catId ? catId : index}
                    mr="20px"
                    color={getColorFromCategory(catId) || 'SEARCH.DEFAULT_CATEGORY_COLOR'}
                  >
                    {catId?.toUpperCase() ?? ''} {catId ? `- ${t(localizedCategories[catId])}` : ''}
                  </Box>
                ))}
              </TextEllipsis>
            </Box>
          </Flex>
          <Box
            fontSize={{ lg: 'sm', xl: 'md' }}
            lineHeight={{ lg: '17px', xl: '19px' }}
            color="ARTICLE_CARD.SECONDARY_TEXT"
          >
            <TextEllipsis lines={xl ? 3 : 2} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
              {desc}
            </TextEllipsis>
          </Box>
        </Box>
      </Flex>
    );
  }

  // chosen for you, base-sm-md-lg-xl
  return (
    <Flex w="100%" direction="column" h="100%">
      <GMPhoto
        imageUrl={imageUrl}
        thumbnailImageUrl={thumbnailImageUrl}
        textSize={titleSize}
        text={title}
        w={`100%`}
        h="100%"
      />
      <Box
        fontSize="lg"
        color="ARTICLE_CARD.ABSTRACT"
        bg="white"
        display={showDesc ? 'block' : 'none'}
        p={{ base: '12px', xl: '16px' }}
      >
        <Text
          maxH={{ xs: '51px', xl: '66px' }}
          overflow="hidden"
          lineHeight={{ xl: '22px', xs: '17px' }}
          fontSize={{ xl: 'lg', xs: 'sm' }}
        >
          <TextEllipsis lines={3} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
            {desc}
          </TextEllipsis>
        </Text>
      </Box>
    </Flex>
  );
};

export default GMArticleCard;
