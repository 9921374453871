import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@chakra-ui/core';
import GMButtonGreen from 'components/GMButtonGreen';
import GMPromoSummary from 'components/GMPromoSummary';

interface IGMSinglePromoModalSummaryContent {
  name?: string;
  subscribedOn?: string;
  price?: number;
  instructions?: string;
  details?: string;
  promoCode: string;
  onClose: () => void;
  promoCodeType?: string;
  loading?: boolean;
}

const GMSinglePromoModalSummaryContent: FC<IGMSinglePromoModalSummaryContent> = ({
  name,
  subscribedOn,
  price,
  instructions,
  details,
  promoCode,
  promoCodeType,
  onClose,
  loading,
}) => {
  const { t } = useTranslation();
  const state = 'CONFIRMED';

  return (
    <Box>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('promos:singlePromo:summary:title')}
      </Heading>

      <GMPromoSummary
        name={name}
        subscribedOn={subscribedOn}
        price={price}
        instructions={instructions}
        details={details}
        promoCode={promoCode}
        promoCodeType={promoCodeType}
        variant="modal"
        state={state}
      />

      <GMButtonGreen size="medium" onClick={onClose} loading={loading}>
        {t('common:close')}
      </GMButtonGreen>
    </Box>
  );
};

export default GMSinglePromoModalSummaryContent;
