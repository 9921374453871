import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import GMArticleCard from 'components/GMArticleCard';
import useBreakpoint from 'hooks/useBreakpoint';

interface SearchResultArticlesProps {
  articles?: IPost[];
}

interface IPost {
  node: {
    id: string;
    slug?: string;
    title: string;
    landscapeBannerId?: string;
    publishedAt: Date;
    abstract?: string;
    categoryIds?: string[];
  };
}

const SearchResultArticles: FC<SearchResultArticlesProps> = ({ articles = [] }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  return (
    <>
      {map(articles, (article, index) => {
        const singleArticle = article?.node;
        const titleSlug = article?.node?.slug;

        return (
          <Link to={`/${currentLocale}/article/${titleSlug}`} key={singleArticle.id || index}>
            <GMArticleCard
              categoryIds={singleArticle.categoryIds}
              type={lg || xl ? 'horizontalWithDescription' : 'horizontalOnlyTitle'}
              publishedAt={singleArticle.publishedAt}
              thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${singleArticle.landscapeBannerId}`}
              imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${singleArticle.landscapeBannerId}`}
              desc={singleArticle.abstract}
              title={singleArticle.title}
            />
          </Link>
        );
      })}
    </>
  );
};

export default SearchResultArticles;
