import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMSectionContainer from 'components/GMSectionContainer';
import GMSingleCategoryMesh from 'components/GMSingleCategoryMesh';
import GMSingleCategorySidebar from 'components/GMSingleCategorySidebar';
import GMLoader from 'components/GMLoader';
import GMLoading from 'components/GMLoading';
import GMSearchBar from 'components/GMSearchBar';
import { AppContext } from 'context';
import { itemsPerPage } from 'constants/index';
import { categories, localizedCategories } from 'constants/categories';
import { navigateByLocale } from 'utils';

interface EdgesProps {
  id: string;
  slug: string;
  portraitBannerId: string;
  landscapeBannerId: string;
  publishedAt: Date;
  categoryIds: string[];
  title: string;
  abstract: string;
  content: string;
  node: {
    id: string;
    slug: string;
    portraitBannerId: string;
    landscapeBannerId: string;
    publishedAt: Date;
    categoryIds: string[];
    title: string;
    abstract: string;
    content: string;
  };
}

const GET_POSTS_IN_CATEGORY = gql`
  query postsInCategoryList($opts: SearchPostOptions, $currentLocale: String!) {
    posts(languageCode: $currentLocale, opts: $opts) {
      totalCount
      edges {
        node {
          id
          portraitBannerId
          landscapeBannerId
          publishedAt
          categoryIds
          title
          abstract
          content
          isHighlight
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const Category: FC = () => {
  const { t, i18n } = useTranslation();
  const [articles, setArticles] = useState([{}] as EdgesProps[]);
  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const { id } = useParams();
  const currentLocale = i18n.language;
  const { isDivInView } = useContext(AppContext);
  const history = useHistory();

  const searchOptions = {
    byAnyCategoryIds: id ? [id] : undefined,
    itemsPerPage,
  };

  const { loading, error, data, networkStatus, fetchMore, refetch } = useQuery(GET_POSTS_IN_CATEGORY, {
    variables: {
      currentLocale,
      opts: searchOptions,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  });

  useEffect(() => {
    refetch && refetch();
  }, [currentLocale]);

  useEffect(() => {
    navigateByLocale(history, currentLocale, 'category', id);
  }, [currentLocale, id]);

  const pageInfo = data?.posts?.pageInfo;
  const edges = data?.posts?.edges;

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');

    return () => addClass;
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setArticles(edges);
    }
  }, [data, edges, pageInfo]);

  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: {
          opts: {
            after: pageInfoState.endCursor,
            itemsPerPage,
          },
        },
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'posts.edges', []);
          const newPageInfo = get(fetchMoreResult, 'posts.pageInfo', {});

          setPageInfo(newPageInfo);
          setArticles(uniqBy([...articles, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const cleanedArticles = filter(articles, (val) => keys(val).length !== 0);

  return (
    <GMContainer>
      <Helmet>
        <title>{id ? t(localizedCategories[id]) : ''}</title>
        <meta name="description" content={id ? t(localizedCategories[id]) : ''} />
      </Helmet>
      <GMSearchBar path="/search/articles" category={id} />

      <GMSectionContainer>
        <Flex>
          <Box maxW={{ base: '536px', lg: 'calc(100% - 312px)' }} mx="auto" w="100%">
            <Heading
              pb={{ base: '24px', lg: '32px', xl: '48px' }}
              color={id ? categories[id] : 'PRIMARY'}
              fontSize={{ base: '20px', lg: '28px', xl: '32px' }}
            >
              {`${id?.toUpperCase() ?? ''} ${id ? t(localizedCategories[id]) : t('category:allArticlesTitle')}`}
            </Heading>

            <InfiniteScroll
              dataLength={cleanedArticles.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState.hasNext && (
                  <Box margin="0 auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0.5}
              style={{ overflowY: 'hidden' }}
            >
              <GMSingleCategoryMesh category={id} articles={cleanedArticles} />
            </InfiniteScroll>
          </Box>
          <GMSingleCategorySidebar category={id} />
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Category;
