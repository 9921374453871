import React, { FC, ReactNode } from 'react';
import { Select } from '@chakra-ui/core';
import { MdExpandMore } from 'react-icons/md';
import { tag as dimensions } from 'constants/dimensions';

export interface GMSelectProps {
  size?: 'small' | 'medium' | 'big';
  options?: string[];
  value?: string;
  dense?: boolean;
  children?: ReactNode;
  onSelectOption?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
}

export const GMSelect: FC<GMSelectProps> = ({
  children,
  dense = false,
  value = '',
  size = 'small',
  onSelectOption,
  placeholder,
}) => {
  const borderColor = 'TAG.BORDER_ACTIVE';
  const { padding, fontWeight, fontSize, borderRadius, height } = dimensions[size];
  return (
    <Select
      value={value}
      borderRadius={borderRadius}
      placeholder={placeholder}
      fontSize={fontSize}
      borderStyle="solid"
      borderColor={borderColor}
      borderWidth="1px"
      bg="TAG.BACKGROUND_ACTIVE"
      w={dense ? 'auto' : '100%'}
      color="TAG.TEXT_ACTIVE"
      padding={padding}
      fontWeight={fontWeight}
      icon={MdExpandMore}
      h={height}
      onChange={onSelectOption}
      _focus={{
        borderColor: { borderColor },
      }}
      _hover={{
        borderColor: { borderColor },
      }}
    >
      {children}
    </Select>
  );
};

export default GMSelect;
