import React, { FC } from 'react';
import { Box } from '@chakra-ui/core';
import styled from '@emotion/styled';
import GMUpButton from 'components/GMUpButton';
import { useScroll } from 'hooks/useScroll';
import smoothscroll from 'smoothscroll-polyfill';

interface IDiv {
  opacity?: string;
}

const StyledDiv = styled.div<IDiv>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ opacity }) => opacity};
  cursor: pointer;
`;

const GMUpButtonWrapper: FC = () => {
  const scroll = useScroll();

  const opacity = scroll?.y > 200 ? '1' : '0';

  const scrollToTop = (): void => {
    smoothscroll.polyfill();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <StyledDiv opacity={opacity}>
      <Box display={{ base: 'block', lg: 'none' }}>
        <GMUpButton onClick={scrollToTop} position="fixed" bottom="16px" right="16px" />
      </Box>
    </StyledDiv>
  );
};

export default GMUpButtonWrapper;
