/* eslint-disable no-useless-escape */
import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import en from 'i18n-iso-countries/langs/en.json';
import es from 'i18n-iso-countries/langs/es.json';
import fr from 'i18n-iso-countries/langs/fr.json';
import de from 'i18n-iso-countries/langs/de.json';
import moment from 'moment';
import { History } from 'history';

export const slugify = (text): string => {
  return text
    .toString()
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};

export const averageTimeToReadArticle = (article): number => {
  const wordsPerMinute = 200; // Average case.
  let value = 0;

  const textLength = article.split(' ').length; // Split by words
  if (textLength > 0) {
    // convert in milliseconds
    value = Math.ceil(textLength / wordsPerMinute) * 60000;
    // result = `~${value} min read`;
  }
  return value;
};

export const getLocalizedCountries = (lang): countries.LocalizedCountryNames => {
  const langObj = {
    it,
    en,
    es,
    de,
    fr,
  };

  countries.registerLocale(langObj[lang]);
  return countries.getNames(lang);
};

export const urlify = (url: string): string => {
  return url.replace(/(^\w+:|^)\/\//, '');
};

export const convertToLink = (str: string): string => {
  const regexPrefix = /(^\w+:|^)\/\//;
  if (!str.match(regexPrefix)) {
    str = 'http://' + str;
  }
  return str;
};

export const formatXAxisDate = (date: string, filter: string, locale: string): string => {
  switch (filter) {
    case 'week':
    default: {
      return moment(date).locale(locale).format('ddd').substring(0, 1).toUpperCase();
    }
    case 'month': {
      return moment(date).format('D');
    }
    case 'year': {
      return moment(date).locale(locale).format('MMM').substring(0, 1).toUpperCase();
    }
  }
};

export const parseUnderline = (string: string | undefined): string | undefined => {
  if (!string) return;
  const pattern = /[+][+].*[+][+]/g;
  const replace = (str: string): string => {
    const matched = (str.match(pattern) || []).length > 0;
    if (!matched) return str;
    const newString = str.replace('++', '<u>').replace('++', '</u>');
    return replace(newString);
  };

  return replace(string);
};

export const parseMarkdown = (markdownText): string => {
  const htmlText = markdownText
    .replace(/^### (.*$)/gim, '<h3>$1</h3>')
    .replace(/^## (.*$)/gim, '<h2>$1</h2>')
    .replace(/^# (.*$)/gim, '<h1>$1</h1>')
    .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
    .replace(/\_\_(.*)\_\_/gim, '<b>$1</b>')
    .replace(/\*(.*)\*/gim, '<i>$1</i>')
    .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
    .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
    .replace(/\n$/gim, '<br><br/>');

  // @TODO: .replace(/\_/gim, '') WHY?
  return htmlText.trim().replace(/\*/gim, '');
};

export const navigateByLocale = (history: History, locale: string, route: string, slug?: string): void => {
  if (slug) {
    history.replace(`/${locale}/${route}/${slug}`);
  } else {
    history.replace(`/${locale}/${route}`);
  }
};
