import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import { MdKeyboardArrowUp } from 'react-icons/md';

export interface ButtonProps {
  onClick?: () => void;
  props?: {};
  position?:
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'fixed'
    | 'absolute'
    | 'relative'
    | 'static'
    | 'sticky'
    | null
    | undefined;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  margin?: string;
}

const GMUpButton: FC<ButtonProps> = ({ onClick, position, top, right, bottom, left, margin, ...props }) => {
  const w = '15%';
  const maxW = '72px';
  const maxH = maxW;
  const border = '0px';
  const rounded = '50%';
  const bg = 'BUTTON.UP.BACKGROUND';
  const opacity = 0.9;
  const shadow = `0 3px 6px 0 rgba(0,0,0,.25)`;
  return (
    <Box
      maxW={maxW}
      maxH={maxH}
      w={w}
      position={position}
      top={top}
      right={right}
      bottom={bottom}
      left={left}
      margin={margin}
      {...props}
    >
      <Box
        backgroundColor={bg}
        onClick={onClick}
        paddingBottom="100%"
        rounded={rounded}
        shadow={shadow}
        w="100%"
        h="0"
        color="LIGHT"
        border={border}
        opacity={opacity}
        position="relative"
      >
        <Flex align="center" justify="center" h="100%" position="absolute" width="100%">
          <MdKeyboardArrowUp size="50%" />
        </Flex>
      </Box>
    </Box>
  );
};

export default GMUpButton;
