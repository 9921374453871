import React, { FC } from 'react';
import { palette } from 'theme/theme';

interface IDrop {
  variant?: string;
  size?: number;
}

const Drop: FC<IDrop> = ({ variant, size = 36 }): JSX.Element => {
  const colors = {
    green: palette.MEDIUM_GREEN,
    darkGrey: palette.DARK_GREY,
    darkGreen: palette.DARK_GREEN,
    mediumGrey: palette.MEDIUM_GREY,
    lightGrey: palette.LIGHT_GREY,
    blue: palette.BELIZE_HOLE,
  };

  return (
    <svg height={size} width={size} viewBox="0 0 36 36">
      <g id="prefix__icon_goccia_green" data-name="icon goccia green">
        <path
          transform="scale(0.04,-0.04) translate(-65,-900)"
          fill={(variant && colors[variant]) || '#fff'}
          unicode="&#xe900;"
          glyphName="goccia"
          d="M729.344 665.344c-55.604 55.664-132.453 90.098-217.344 90.098-169.662 0-307.2-137.538-307.2-307.2s137.538-307.2 307.2-307.2c169.577 0 307.062 137.401 307.2 306.946v0.013c0 0.072 0 0.157 0 0.242 0 84.771-34.336 161.522-89.859 217.105l0.003-0.003zM512 285.952v0c-1.084-0.040-2.357-0.063-3.635-0.063-56.537 0-102.582 44.92-104.393 101.017l-0.004 0.166c0 70.144 105.216 218.624 108.032 222.464 2.816-3.84 108.032-152.32 108.032-222.464-1.815-56.263-47.86-101.183-104.397-101.183-1.278 0-2.551 0.023-3.818 0.069l0.183-0.005z"
        />
      </g>
    </svg>
  );
};

export default Drop;
