import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import { Box } from '@chakra-ui/core';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import { useProfile } from 'context/profile';

interface IGMPromoCategorySidebar {
  promoCategorySlug?: string;
  context?: 'promo' | 'donation';
}

const GMPromoCategorySidebar: FC<IGMPromoCategorySidebar> = ({ promoCategorySlug, context = 'promo' }) => {
  const { userInfo } = useProfile();

  const urlSlug = {
    promo: 'promos',
    donation: 'donations',
  };

  const descriptionObj = {
    promo: userInfo ? 'promos:greenKmDescriptionAuth' : 'promos:greenKmDescription',
    donation: userInfo ? 'donations:greenCoinDescriptionAuth' : 'donations:greenCoinDescription',
  };

  return (
    <Box
      position="relative"
      w={{ lg: '312px' }}
      pl={{ lg: '96px', xl: '24px' }}
      display={{ base: 'none', lg: 'block' }}
    >
      <Box position="sticky" top="5">
        <Box mb={{ lg: '24px', xl: '48px' }}>
          <GMInputSearchHandler path={`/search/${urlSlug[context]}`} category={promoCategorySlug} />
        </Box>

        <Box color="PAGE.SECONDARY_TEXT" fontSize={{ lg: 'md', xl: 'lg' }}>
          <Trans
            i18nKey={descriptionObj[context]}
            // eslint-disable-next-line react/jsx-key
            components={[<strong className="green"></strong>]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GMPromoCategorySidebar;
