export const EventCategory = {
  AccountApp: 'AccountApp',
  Privacy: 'Privacy',
  SectionSelection: 'SectionSelection',
  UserActivity: 'UserActivity',
  UserConversion: 'UserConversion',
};

export const AccountAppValues = {};
export const PrivacyAppValues = {};
export const SectionoAppValues = {};

export const UseActivityValues = {
  MenuLifestyle: 'lifestyle_menu_clicked',
  MenuShopping: 'shopping_menu_clicked',
  MenuDonations: 'donations_menu_clicked',
  MenuMobility: 'mobility_menu_clicked',
  MenuPromotions: 'promotions_menu_clicked',
  MenuLogin: 'login_menu_clicked',
  MenuRegister: 'register_menu_clicked',
};

export const UserConversionValues = {};
