import React, { FC } from 'react';
import { Box, Flex, Text, Grid } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import TextEllipsis from 'react-text-ellipsis';

import GMPHoto from 'components/GMPhoto';
import useBreakpoint from 'hooks/useBreakpoint';

export interface GMArticleCardAlternativeProps {
  title?: string;
  desc?: string;
  imageUrl?: string;
  thumbnailImageUrl?: string;
  titleSize?: string;
  label?: string;
  truncDescAfterLine?: number;
  truncTitleAfterLine?: number;
  noCardPadding?: boolean;
  bigPicture?: boolean;
  h?: string;
}

export const GMArticleCardAlternative: FC<GMArticleCardAlternativeProps> = ({
  title,
  desc,
  imageUrl = '',
  thumbnailImageUrl = '',
  label,
  truncDescAfterLine = 3,
  truncTitleAfterLine = 2,
  noCardPadding = false,
  bigPicture = false,
  h = '100%',
  titleSize = 'small',
}) => {
  const breakpoint = useBreakpoint();
  const { t } = useTranslation();

  const textSize = breakpoint === 'lg' ? 'small' : 'medium';

  return (
    <Grid
      gridTemplateColumns={bigPicture ? '2fr 1fr' : '1fr 1fr'}
      gridGap={bigPicture ? { lg: '18px', xl: '24px' } : '0'}
      gridTemplateRows={h ? h : 'auto'}
    >
      <Box>
        <GMPHoto
          thumbnailImageUrl={thumbnailImageUrl}
          imageUrl={imageUrl}
          textSize={textSize}
          w="100%"
          h="100%"
          text={breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md' ? title : ''}
        />
      </Box>
      <Flex bg="white" direction="column">
        {label ? (
          <Box py="16px" px={{ base: '18px', xl: '24px' }} bg="ARTICLE_CARD.ONE_ARTICLE_HEAD_BACKGROUD">
            <Text fontSize="20px" lineHeight="24px" fontWeight="Bold" color="ARTICLE_CARD.ONE_ARTICLE_HEAD_TITLE">
              {label}
            </Text>
          </Box>
        ) : null}
        <Box py={noCardPadding ? '0' : '16px'} px={noCardPadding ? '0' : { base: '18px', xl: '24px' }}>
          <Text
            fontSize={{ xs: '2xl', xl: titleSize === 'small' ? '24px' : '32px' }}
            lineHeight={{ xs: '29px', xl: titleSize === 'small' ? '29px' : '39px' }}
            fontWeight="black"
            paddingBottom="16px"
            color="ARTICLE_CARD.ONE_ARTICLE_TITLE"
          >
            <TextEllipsis lines={truncTitleAfterLine} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
              {title}
            </TextEllipsis>
          </Text>
          <Box
            color="ARTICLE_CARD.ABSTRACT"
            fontSize={{ base: '16px', xl: '18px' }}
            lineHeight={{ base: '19px', xl: '22px' }}
          >
            <TextEllipsis lines={truncDescAfterLine} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
              {desc}
            </TextEllipsis>
          </Box>
          <Text
            fontSize="xl"
            lineHeight="24px"
            fontWeight="bold"
            color="ARTICLE_CARD.READ_MORE"
            paddingTop={{ xs: '8px', lg: bigPicture ? '24px' : '8px', xl: '24px' }}
          >
            {t('lifestyle:chosenForYou:readMore')}
          </Text>
        </Box>
      </Flex>
    </Grid>
  );
};

export default GMArticleCardAlternative;
