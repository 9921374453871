import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import { customApolloClient } from 'apollo';
import isEmpty from 'lodash/isEmpty';
import { useProfile } from 'context/profile';
import GMContainer from 'components/GMContainer';
import GMCategoriesList from 'components/GMCategoriesList';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMShopPartnersList from 'components/GMShopPartnersList';
import GMSearchBar from 'components/GMSearchBar';
import GMInputSearchHandler from 'components/GMInputSearchHandler';
import theme from 'theme/theme';

const GET_ALL_STORES = gql`
  query allStores(
    $currentLocale: String!
    $limit: Int!
    $isHighlight: Boolean!
    $hideAppOnly: Boolean
    $countries: [String!]
  ) {
    stores(
      languageCode: $currentLocale
      limit: $limit
      highlight: $isHighlight
      hideAppOnly: $hideAppOnly
      countries: $countries
    ) {
      id
      landscapeBannerId
      name
      slug
    }
  }
`;

const GET_HIGHLIGHTED_STORES = gql`
  query allHighlighterStores($currentLocale: String!, $limit: Int!, $isHighlight: Boolean!, $hideAppOnly: Boolean) {
    stores(languageCode: $currentLocale, limit: $limit, highlight: $isHighlight, hideAppOnly: $hideAppOnly) {
      id
      landscapeBannerId
      name
      slug
    }
  }
`;

const GET_CATEGORIES = gql`
  query categories($languageCode: String!, $entityType: EntityType) {
    categories(languageCode: $languageCode, entityType: $entityType) {
      id
      landscapeBannerId
      name
      slug
    }
  }
`;

const client = customApolloClient({ clientType: 'shopClient' });
const categoriesClient = customApolloClient({ clientType: 'categoriesClient' });

const Shopping: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { userInfo } = useProfile();

  const { data: dataPartnersHighlights } = useQuery(GET_HIGHLIGHTED_STORES, {
    variables: {
      currentLocale: currentLocale,
      limit: 6,
      isHighlight: true,
      hideAppOnly: true,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  const { loading, error, data: dataPartners } = useQuery(GET_ALL_STORES, {
    variables: {
      currentLocale: currentLocale,
      limit: 6,
      isHighlight: false,
      hideAppOnly: true,
      countries: userInfo?.myData?.preferredCountry || undefined,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  const { data: dataCategories } = useQuery(GET_CATEGORIES, {
    variables: {
      languageCode: currentLocale,
      entityType: 'STORE',
    },
    client: categoriesClient,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <GMLoading />;
  if (error || !dataPartners) return <GMError />;

  const { stores: highlightedStores } = dataPartnersHighlights || [];
  const { stores } = dataPartners || [];
  const { categories } = dataCategories || [];

  const description = (
    <Trans
      i18nKey={userInfo ? 'shopping:greenCoinDescriptionAuth' : 'shopping:greenCoinDescription'}
      // eslint-disable-next-line react/jsx-key
      components={[<strong className="green" />]}
    />
  );
  const search = (
    <GMInputSearchHandler
      path="/search/partners"
      placeholder={t('shopping:search')}
      border="2px solid"
      borderColor={theme.colors.PRIMARY}
      iconColor={theme.colors.PRIMARY}
      cursor="pointer"
      shouldRedirectOnClick
      readOnly
    />
  );

  return (
    <GMContainer>
      <Helmet>
        <title>{t('shopping:home:pageTitle')}</title>
        <meta name="description" content={t('shopping:home:pageTitle')} />
      </Helmet>
      <GMSearchBar path="/search/partners" />

      <GMCategoriesList context="store" categories={categories} description={description} search={search} />

      {!isEmpty(highlightedStores) && (
        <GMShopPartnersList
          partners={highlightedStores}
          title={t('shopping:home:highlightTitle')}
          onlyHightlight={!isEmpty(stores)}
        />
      )}

      <GMShopPartnersList title={t('shopping:home:partnersTitle')} partners={stores} readMore />
    </GMContainer>
  );
};

export default Shopping;
