import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, SimpleGrid } from '@chakra-ui/core';
import map from 'lodash/map';
import moment from 'moment';
import GMPromoCard from 'components/GMPromoCard';
import { useProfile } from 'context/profile';

interface IGMPromoCategoryMesh {
  src?: IPromo[];
  categoryName?: string;
  categorySlug?: string;
  context?: 'donation' | 'promo';
}

interface IPromo {
  node: {
    expirationDate: Date;
    id: string;
    portraitBannerId: string;
    partnerName: string;
    name: string;
    price: number;
    public: boolean;
    slug: string;
  };
}

const GMPromoCategoryMesh: FC<IGMPromoCategoryMesh> = ({ src = [], categoryName, categorySlug, context = 'promo' }) => {
  const { userBalance } = useProfile();
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <SimpleGrid columns={{ lg: 2, xl: 3 }} spacing={{ base: '17px', xl: '24px' }} overflowX="hidden">
      {map(src, (item, index) => {
        const singleItem = item?.node;

        const { expirationDate, price, portraitBannerId, name, partnerName, id, slug } = singleItem;

        const isSoldOut = moment().isAfter(expirationDate);
        const disabledKm = price > userBalance?.km || isSoldOut;
        const disabledCoin = price > userBalance?.coins || isSoldOut;

        return (
          <Box position="relative" h={{ base: '160px', lg: '156px' }} key={id || Number(index)}>
            <Link
              to={{
                pathname: `/${currentLocale}/${context}/${slug}`,
                state: { categoryName: categoryName, categorySlug: categorySlug },
              }}
            >
              <GMPromoCard
                disabled={context === 'promo' ? disabledKm : disabledCoin}
                image={portraitBannerId}
                name={name}
                partnerName={partnerName}
                price={price}
                type={context}
              />
            </Link>
          </Box>
        );
      })}
    </SimpleGrid>
  );
};

export default GMPromoCategoryMesh;
