import React, { FC, useMemo, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import InView from 'react-intersection-observer';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isEmpty, filter, keys, get, uniqBy, debounce } from 'lodash';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import { MdClose } from 'react-icons/md';
import moment from 'moment';
import findKey from 'lodash/findKey';
import GMContainer from 'components/GMContainer';
import GMSectionContainer from 'components/GMSectionContainer';
import GMTransactionsCalendar from 'components/GMTransactionsCalendar';
import GMTransactionsSidebar from 'components/GMTransactionsSidebar';
import GMTransactionsHeader from 'components/GMTransactionsHeader';
import GMSearchInput from 'components/GMSearchInput';
import GMLoader from 'components/GMLoader';
import customApolloClient from 'apollo';
import { AppContext } from 'context';
import { useProfile } from 'context/profile';
import { transactionsPerPage } from 'constants/index';
import useBreakpoint from 'hooks/useBreakpoint';

interface EdgesProps {
  id: string;
  lastEventDate: Date;
  currency: { code: string };
  amount: number;
  motive: string;
  sender: string;
  externalReference: string;
  transactionType: string;
  state: string;
  node: {
    id: string;
    lastEventDate: Date;
    currency: { code: string };
    amount: number;
    motive: string;
    sender: string;
    externalReference: string;
    transactionType: string;
    state: string;
  };
}

interface IFilters {
  byCurrency?: string;
  byState?: string;
  byMovement?: string;
  byQuery?: string;
  byDateInterval?: { startDate: Date; endDate: Date };
}

const GET_TRANSACTIONS = gql`
  query transactions($filter: FilterInput!) {
    transactions(filter: $filter) {
      edges {
        node {
          id
          amount
          lastEventDate
          currency {
            code
          }
          motive
          sender
          transactionType
          state
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const Transactions: FC = () => {
  const { token } = useProfile();
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';
  const [transactions, setTransactions] = useState([{}] as EdgesProps[]);
  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const { isDivInView, setIsDivInView } = useContext(AppContext);

  const client = useMemo(() => customApolloClient({ clientType: 'walletClient', token }), [token]);

  const fullTextSearch = Object(t('transactions:Mobility', { returnObjects: true }));

  const filters = {
    byCurrency: undefined,
    byState: undefined,
    byMovement: undefined,
    byQuery: undefined,
    byDateInterval: undefined,
  };

  const [activeFilters, setActiveFilters] = useState<IFilters>(filters);

  const transactionsFilter = {
    after: '',
    limit: transactionsPerPage,
    currencyCode: activeFilters.byCurrency,
    state: activeFilters.byState,
    movements: activeFilters.byMovement,
    //@TODO: Refactor ASAP....but on-the-run now is working
    term: findKey(fullTextSearch, (o) => o?.toLowerCase()?.includes(activeFilters?.byQuery?.toLowerCase()))
      ? activeFilters.byQuery &&
        findKey(fullTextSearch, (o) => o?.toLowerCase()?.includes(activeFilters?.byQuery?.toLowerCase()))
      : !activeFilters.byQuery
      ? undefined
      : activeFilters.byQuery,
    dateInterval: activeFilters.byDateInterval,
  };

  const { data, loading, networkStatus, fetchMore } = useQuery(GET_TRANSACTIONS, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      filter: transactionsFilter,
    },
  });

  const pageInfo = data?.transactions?.pageInfo;
  const edges = data?.transactions?.edges;

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');

    return () => addClass;
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setTransactions(edges);
    }
  }, [data, edges, pageInfo]);

  const setFilter = ({ filter, value }): void => {
    const filters = { ...activeFilters };
    filters[filter] = value;
    setActiveFilters(filters);
  };

  const startSearchAfterMs = 1000;
  const startSearch = debounce((newQuery: string) => {
    if (newQuery.length > 0 && newQuery.length < 3) return;
    setFilter({ filter: 'byQuery', value: newQuery });
  }, startSearchAfterMs);

  const onChangeCurrency = (newValue: string): void => {
    setFilter({ filter: 'byCurrency', value: newValue.toUpperCase() || undefined });
  };

  const onChangeState = (newValue: string): void => {
    setFilter({ filter: 'byState', value: newValue.toUpperCase() || undefined });
  };

  const onChangeMovement = (newValue: string): void => {
    setFilter({ filter: 'byMovement', value: newValue.toUpperCase() || undefined });
  };

  const onChangeQuery = (newQuery: string): void => {
    startSearch(newQuery);
  };

  const onChangeDateInterval = (startDate: Date, endDate: Date): void => {
    setFilter({
      filter: 'byDateInterval',
      value: startDate && endDate ? { startDate, endDate } : undefined,
    });
  };

  const onResetQuery = (): void => {
    setFilter({ filter: 'byQuery', value: undefined });
  };

  const onResetAlLFilters = (): void => {
    setActiveFilters({
      byCurrency: undefined,
      byState: undefined,
      byMovement: undefined,
      byQuery: undefined,
      byDateInterval: undefined,
    });
  };

  const onResetDateInterval = (): void => {
    setFilter({
      filter: 'byDateInterval',
      value: undefined,
    });
  };

  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: {
          filter: {
            ...transactionsFilter,
            after: pageInfoState.endCursor,
          },
        },
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'transactions.edges', []);
          const newPageInfo = get(fetchMoreResult, 'transactions.pageInfo', {});

          setPageInfo(newPageInfo);
          setTransactions(uniqBy([...transactions, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    if (
      !isEmpty(pageInfoState) &&
      pageInfoState.hasNext &&
      networkStatus === NetworkStatus.ready &&
      !isEmpty(isDivInView) &&
      isDivInView.inView
    ) {
      onEndReached();
    }
  }, [isDivInView]);

  const cleanedTransactions = filter(transactions, (val) => keys(val).length !== 0);
  const isFound = cleanedTransactions.length;

  const startDateFormatted = moment(activeFilters?.byDateInterval?.startDate).format('DD/MM/YYYY');
  const endDateFormatted = moment(activeFilters?.byDateInterval?.endDate).format('DD/MM/YYYY');

  // useEffect(() => {
  //   const pippo = findKey(fullTextSearch, (o) => o?.includes(activeFilters?.byQuery));
  //   if (pippo) {
  //     // startSearch(pippo);
  //     setFilter({ filter: 'byQuery', value: pippo });
  //   }
  // }, [activeFilters?.byQuery]);

  return (
    <GMContainer>
      <Helmet htmlAttributes>
        <title>{t('profile:transactions:title')}</title>
        <meta name={t('profile:transactions:title')} content={t('profile:transactions:title')} />
      </Helmet>

      <GMSectionContainer>
        <Flex>
          <Box w="100%" maxW={{ base: '100%', lg: `calc(100% - 234px)`, xl: `calc(100% - 312px)` }} mx="auto">
            <Heading
              color="PRIMARY"
              fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
              pb={{ base: '24px', lg: '32px' }}
            >
              {t('profile:transactions:title')}
            </Heading>
            <GMSearchInput onChangeQuery={onChangeQuery} onResetQuery={onResetQuery} query={activeFilters?.byQuery} />
            {!lg && !xl && (
              <GMTransactionsHeader
                onChangeCurrency={onChangeCurrency}
                onChangeState={onChangeState}
                onChangeMovement={onChangeMovement}
                onChangeDateInterval={onChangeDateInterval}
                activeFilters={activeFilters}
              />
            )}
            {activeFilters?.byDateInterval && (
              <Flex mb={{ base: '16px', lg: '24px' }} w="auto" onClick={onResetDateInterval} cursor="pointer">
                <Flex
                  bg="WHITE"
                  borderRadius="16px"
                  border="1px solid"
                  borderColor="PRIMARY"
                  pl="16px"
                  pr="10px"
                  color="PRIMARY"
                  fontSize={{ base: '10px', lg: '12px' }}
                  lineHeight="15px"
                  fontWeight="bold"
                  h={{ base: '28px', lg: '32px' }}
                  justify="space-between"
                  align="center"
                >
                  <Box>{`${startDateFormatted} - ${endDateFormatted}`}</Box>
                  <MdClose size={15} />
                </Flex>
              </Flex>
            )}
            {loading ? (
              <Box>
                <GMLoader size={30} />
              </Box>
            ) : (
              <InfiniteScroll
                dataLength={cleanedTransactions.length} //This is important field to render the next data
                next={
                  !isEmpty(pageInfoState) &&
                  pageInfoState.hasNext &&
                  networkStatus === NetworkStatus.ready &&
                  !isEmpty(isDivInView) &&
                  isDivInView.inView
                    ? onEndReached
                    : () => null
                }
                hasMore={true}
                loader={
                  pageInfoState.hasNext && (
                    <Box margin="10px auto">
                      <GMLoader size={30} />
                    </Box>
                  )
                }
                scrollThreshold={0.5}
                style={{ overflowY: 'hidden' }}
              >
                {!isFound && (
                  <Box>
                    <Box
                      color="PAGE.SECONDARY_TEXT"
                      fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
                      lineHeight={{ base: '17px', lg: '19px', xl: '22px' }}
                      mb={{ base: '16px', lg: '24px', xl: '32px' }}
                    >
                      {t('search:noResults')}
                    </Box>
                    <Box onClick={onResetAlLFilters} color="PRIMARY" fontWeight="bold" cursor="pointer">
                      {t('search:resetAllFilters')}
                    </Box>
                  </Box>
                )}
                <GMTransactionsCalendar transactions={cleanedTransactions} />
                <InView as="div" onChange={(inView) => setIsDivInView({ type: 'setDivInView', payload: inView })}>
                  <></>
                </InView>
              </InfiniteScroll>
            )}
          </Box>
          {(lg || xl) && (
            <GMTransactionsSidebar
              onChangeCurrency={onChangeCurrency}
              onChangeState={onChangeState}
              onChangeMovement={onChangeMovement}
              onChangeDateInterval={onChangeDateInterval}
              activeFilters={activeFilters}
            />
          )}
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Transactions;
