import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Box, Flex, Text } from '@chakra-ui/core';
import { AppContext } from 'context';
import GMShopCategoryMesh from 'components/GMShopCategoryMesh';
import SearchResultArticles from './articles';
import GMPromoCategoryMesh from 'components/GMPromoCategoryMesh';

interface IGMSearchResults {
  articles?: IPost[];
  partners?: IStore[];
  promos?: IPromo[];
  donations?: IDonation[];
  loading: boolean;
  context?: string;
  onResetAllFilters?: () => void;
}

interface IPost {
  node: {
    id: string;
    slug?: string;
    title: string;
    landscapeBannerId?: string;
    publishedAt: Date;
    abstract?: string;
    categoryIds?: string[];
  };
}

interface IDonation {
  node: {
    expirationDate: Date;
    id: string;
    portraitBannerId: string;
    partnerName: string;
    name: string;
    price: number;
    public: boolean;
    slug: string;
  };
}

interface IPromo {
  node: {
    expirationDate: Date;
    id: string;
    portraitBannerId: string;
    partnerName: string;
    name: string;
    price: number;
    public: boolean;
    slug: string;
  };
}

interface IStore {
  node: {
    id: string;
    landscapeBannerId: string;
    isGreenDelivery: boolean;
    greenKmReward: number;
    name: string;
    tagline: string;
    abstract: string;
    description: string;
    metaDescription: string;
    metaKeywords: string;
    slug: string;
  };
}

const GMSearchResults: FC<IGMSearchResults> = ({
  loading,
  partners = [],
  articles = [],
  promos = [],
  donations = [],
  context,
  onResetAllFilters,
}) => {
  const { setIsDivInView } = useContext(AppContext);
  const { t } = useTranslation();

  const resultComponentsObj = {
    articles: {
      mesh: <SearchResultArticles articles={articles} />,
      src: articles,
    },
    partners: {
      mesh: <GMShopCategoryMesh partners={partners} />,
      src: partners,
    },
    promos: {
      mesh: <GMPromoCategoryMesh src={promos} />,
      src: promos,
    },
    donations: {
      mesh: <GMPromoCategoryMesh src={donations} context="donation" />,
      src: donations,
    },
  };

  const onClickResetHandler = (): void => {
    if (onResetAllFilters) onResetAllFilters();
  };

  if (loading)
    return (
      <Flex px={{ lg: '52px' }} py={{ md: '32px' }} maxW={{ lg: '1224px' }} mx="auto">
        <Box maxW={{ md: '526px', lg: '912px' }} mx="auto">
          <Text>{t('common:loading')}</Text>
        </Box>
      </Flex>
    );

  const isFound = context && resultComponentsObj[context]?.src?.length;

  return (
    <>
      <Box
        color="PAGE.SECONDARY_TEXT"
        fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
        lineHeight={{ base: '17px', lg: '19px', xl: '22px' }}
        mb={{ base: '16px', lg: '24px', xl: '32px' }}
      >
        {isFound ? t('search:searchResults') : t('search:noResults')}
      </Box>
      {!isFound && (
        <Box onClick={onClickResetHandler} color="PRIMARY" fontWeight="bold" cursor="pointer">
          {t('search:resetAllFilters')}
        </Box>
      )}
      {context && resultComponentsObj[context]?.mesh}
      <InView as="div" onChange={(inView) => setIsDivInView({ type: 'setDivInView', payload: inView })}>
        <></>
      </InView>
    </>
  );
};

export default GMSearchResults;
