import React, { FC, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import moment from 'moment';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMPromoSummary from 'components/GMPromoSummary';
import GMTransactionSummarySidebar from 'components/GMTransactionSummarySidebar';
import { useProfile } from 'context/profile';
import customApolloClient from 'apollo';

const SINGLE_TRANSACTION = gql`
  query promoSubscriptionByTransactionId($languageCode: String!, $transactionId: String!) {
    promoSubscriptionByTransactionId(languageCode: $languageCode, transactionId: $transactionId) {
      promo {
        name
        price
        details
        instructions
        promoCodeType
      }
      subscribedOn
      promoCode
    }
  }
`;

const TransactionSummaryPromo: FC = () => {
  const { t } = useTranslation();
  const { id: transactionId } = useParams();
  const { token } = useProfile();

  const currentLocale = i18n.language;

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const client = useMemo(() => customApolloClient({ clientType: 'promosClient', token }), [token]);

  const { loading, error, data } = useQuery(SINGLE_TRANSACTION, {
    variables: { languageCode: currentLocale, transactionId },
    client,
    fetchPolicy: 'no-cache',
  });

  if (loading) return <GMLoading />;
  if (error || !data) return <GMError />;

  const { promoSubscriptionByTransactionId } = data;
  const { promo, subscribedOn, promoCode } = promoSubscriptionByTransactionId || {};
  const { name, price, promoCodeType, instructions, details } = promo || {};

  const state = 'CONFIRMED';

  return (
    <GMContainer>
      <Helmet>
        <title>{t('promos:singlePromo:summary:title')}</title>
        <meta name="description" content={t('promos:singlePromo:summary:title')} />
      </Helmet>

      <Flex px={{ md: '52px' }} py="32px" maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
        <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
          <Heading
            pb={{ base: '24px', xl: '48px' }}
            px={{ base: '16px', lg: '0' }}
            color="PRIMARY"
            fontSize={{ base: 'xl', lg: '28px', xl: '32px' }}
          >
            {t('promos:singlePromo:summary:title')}
          </Heading>

          <Box maxW="600px">
            <GMPromoSummary
              name={name}
              subscribedOn={subscribedOn}
              price={price}
              instructions={instructions}
              details={details}
              promoCode={promoCode}
              promoCodeType={promoCodeType}
              state={state}
            />
          </Box>
        </Box>
        <GMTransactionSummarySidebar />
      </Flex>
    </GMContainer>
  );
};

export default TransactionSummaryPromo;
