import React, { FC, useState, useCallback } from 'react';
import { Heading, Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import remove from 'lodash/remove';
import GMTag from 'components/GMTag';
import GMButtonGreen from 'components/GMButtonGreen';
import GMSelectCountry from 'components/GMSelectCountry';
import { localizedCategories } from 'constants/categories';
import { useTagSize } from 'hooks/useProfileLayout';
import { useProfile } from 'context/profile';

interface IGMModalPreferences {
  onClose?: () => void;
  setUserData?: (data) => void;
}

const GMModalPreferences: FC<IGMModalPreferences> = ({ onClose, setUserData }) => {
  const { userInfo, isEnterprise } = useProfile();
  const { t } = useTranslation();

  const getTagSize: Function = useTagSize();
  const tagSize = getTagSize() || 'small';

  const { myData } = userInfo || {};

  const { categoryIds, preferredCountry, country, firstName, lastName, ageGroup, companyName, VAT, companyCategory } =
    myData || {};

  const [selectedTags, setSelectedTags] = useState<string[]>(categoryIds || []);

  const [selectedPreferredCountry, setSelectedPreferredCountry] = useState<string>(preferredCountry || '');

  const onClickTagHandler = (id: string): void => {
    const tags: string[] = [...(selectedTags || [])];
    const addCategoryToArray = ({ id }): void => {
      if (selectedTags.length >= 3) return;
      tags.push(id);
    };

    const removeCategoryFromArray = ({ id }): void => {
      remove(tags, (tagId) => tagId === id);
    };

    tags.includes(id) ? removeCategoryFromArray({ id }) : addCategoryToArray({ id });
    setSelectedTags(tags);
  };

  const saveHandler = useCallback((): void => {
    if (!firstName || !lastName || !ageGroup) return;
    if (isEnterprise) {
      if (!companyName || !VAT || !companyCategory) return;
    }

    const requiredFields = {
      firstName,
      lastName,
      ageGroup,
      companyName,
      VAT,
      country,
      companyCategory,
    };

    if (setUserData)
      setUserData({
        ...requiredFields,
        preferredCountry: selectedPreferredCountry,
        categoryIds: selectedTags,
      });
  }, [selectedPreferredCountry, selectedTags, isEnterprise]);

  return (
    <>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:preferences:modal:title')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }}>
        <Box pb="32px">
          <Box
            fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
            color="PAGE.SECONDARY_TEXT"
            lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
            pb="8px"
          >
            {t('profile:preferences:modal:deliveryCountry')}
          </Box>
          <Box position="relative" borderBottom="1px solid" borderColor={'SECONDARY'} pr="20px">
            <GMSelectCountry
              onSelect={(value) => setSelectedPreferredCountry(value)}
              country={selectedPreferredCountry}
              fetchedCountries
            />
          </Box>
        </Box>

        <Box>
          <Box
            fontSize={{ base: 'xs', lg: 'sm', xl: 'md' }}
            color="PAGE.SECONDARY_TEXT"
            lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
            pb="8px"
          >
            {t('profile:preferences:modal:lifestyleCategories')}
          </Box>
          <Flex wrap="wrap">
            {map(localizedCategories, (value, category) => {
              const disabled = selectedTags.length >= 3 && !selectedTags.includes(category);
              return (
                <Box mr="8px" mb="8px" key={category} opacity={disabled ? 0.6 : 1}>
                  <GMTag
                    onClick={() => onClickTagHandler(category)}
                    size={tagSize}
                    active={selectedTags.includes(category)}
                    dense
                    tagName={t(`${value}`)}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>
      </Box>
      <Flex>
        <Box mr="16px" w="50%">
          <GMButtonGreen size="medium" variant="transparent" onClick={onClose}>
            {t('common:cancel')}
          </GMButtonGreen>
        </Box>
        <Box w="50%">
          <GMButtonGreen size="medium" onClick={saveHandler}>
            {t('common:save')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </>
  );
};

export default GMModalPreferences;
