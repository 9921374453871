import React, { memo, useState, useEffect, FC, CSSProperties } from 'react';
import styled from 'styled-components';
import { placeHolder } from './placeholder';

interface GMProgressiveImageProps {
  alt?: string;
  backgroundLoadingColor?: string;
  bordered?: number;
  source: string;
  style: CSSProperties;
  thumbnailSource: string;
  transitionDuration?: number;
  noFoundImage?: string;
}

const Image = styled.img`
  width: 100%;

  &.full {
    filter: blur(0px);
    animation: fadein linear ${({ transitionDuration }) => transitionDuration}ms;
  }

  &.thumb {
    filter: opacity(100%) blur(20px);
    transform: scale(1.1);
  }

  @keyframes fadein {
    0% {
      filter: opacity(100%) blur(20px);
      transform: scale(1.1);
    }
    100% {
      filter: opacity(100%) blur(0px);
      transform: scale(1);
    }
  }

  &.has-error {
    /* fallback to placeholder image on error */
    content: url(${placeHolder});
    filter: opacity(100%) blur(0px);
    transform: scale(1);
  }
`;

const View = styled.div`
  border-radius: ${({ bordered }) => bordered}px;
  width: 100%;
`;

const GMProgressiveImage: FC<GMProgressiveImageProps> = ({
  alt = '',
  backgroundLoadingColor = '#d2d2d2',
  bordered = 0,
  source,
  style,
  thumbnailSource,
  transitionDuration = 300,
}) => {
  const [imageSrc, setImageSrc] = useState(thumbnailSource);
  const [imageRef, setImageRef] = useState();

  const onLoad = (event): void => {
    event.target.classList.add('full');
    event.target.classList.remove('thumb');
  };

  const onError = (event): void => {
    event.target.classList.add('has-error');
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== source) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                setImageSrc(source);
                observer.unobserve(imageRef);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          },
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(source);
      }
    }

    return () => {
      didCancel = true;
      // on component cleanup, we remove the listener
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [source, imageSrc, imageRef]);

  return (
    <View bordered={bordered}>
      <Image
        className="thumb"
        ref={setImageRef}
        src={imageSrc}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
        style={{ ...style, ...{ backgroundColor: backgroundLoadingColor } }}
        transitionDuration={transitionDuration}
        bordered={bordered}
      />
    </View>
  );
};

export default memo(GMProgressiveImage);
