import React, { FC, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Text } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import { InView } from 'react-intersection-observer';
import map from 'lodash/map';
import moment from 'moment';
import 'moment/min/locales';
import { AppContext } from 'context';
import GMPhoto from 'components/GMPhoto';
import useBreakpoint from 'hooks/useBreakpoint';
import Post from 'interfaces/Post';

interface GMSingleCategoryMeshProps {
  category?: string;
  articles?: Post[];
}

interface CardProps {
  id?: string;
  slug?: string;
  title?: string;
  date?: Date;
  imageUrl?: string;
  thumbnailImageUrl?: string;
  category?: string;
}
const Card: FC<CardProps> = ({ slug, title, date, imageUrl, thumbnailImageUrl, category }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const titleSlug = slug;

  useEffect(() => {
    moment.locale(currentLocale);
  });

  const publishedDataString = moment(date).format('DD MMM YYYY');

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  return (
    <Box>
      <Link to={`/${currentLocale}/article/${titleSlug}`}>
        <Box h={{ base: '144px', lg: '160px' }}>
          <GMPhoto
            category={category}
            h="100%"
            text={lg || xl ? '' : title}
            textSize="xsmall"
            imageUrl={imageUrl || ''}
            thumbnailImageUrl={thumbnailImageUrl || ''}
          ></GMPhoto>
        </Box>
        {lg || xl ? (
          <Text
            fontSize={{ lg: 'xl', xl: '2xl' }}
            lineHeight={{ lg: '24px', xl: '29px' }}
            fontWeight="black"
            color="TEXT.PRIMARY"
            py="8px"
          >
            <TextEllipsis lines={3} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
              {title}
            </TextEllipsis>
          </Text>
        ) : null}
      </Link>
      {xl ? (
        <Text fontSize="14px" lineHeight="17px" fontWeight="light">
          {publishedDataString}
        </Text>
      ) : null}
    </Box>
  );
};

const GMSingleCategoryMesh: FC<GMSingleCategoryMeshProps> = ({ category, articles = [] }) => {
  const { setIsDivInView } = useContext(AppContext);

  return (
    <Grid
      templateColumns={{ lg: '294px 294px', xl: '288px 288px 288px' }}
      gap={{ base: '24px', lg: '18px', xl: '24px' }}
      h="100%"
    >
      {map(articles, (article, index) => {
        const singleArticle = article?.node;

        return (
          <Card
            id={singleArticle.id}
            slug={singleArticle.slug}
            category={category}
            key={singleArticle.id ? singleArticle.id : index}
            title={singleArticle.title}
            date={singleArticle.publishedAt}
            imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${singleArticle.landscapeBannerId}`}
            thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${singleArticle.landscapeBannerId}`}
          />
        );
      })}
      <InView as="div" onChange={(inView) => setIsDivInView({ type: 'setDivInView', payload: inView })}>
        <></>
      </InView>
    </Grid>
  );
};

export default GMSingleCategoryMesh;
