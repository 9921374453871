import React, { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Flex, useDisclosure } from '@chakra-ui/core';
import { useQuery, gql, useMutation } from '@apollo/client';
import moment from 'moment';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMModal from 'components/GMModal';
import GMModalErrorContent from 'components/GMModalErrorContent';
import GMPromoContent from 'components/GMSinglePromoContent';
import GMSinglePromoSidebar from 'components/GMSinglePromoSidebar';
import GMSinglePromoModalSummaryContent from 'components/GMSinglePromoModalSummaryContent';
import GMSinglePromoModalConfirmContent from 'components/GMSinglePromoModalConfirmContent';
import { customApolloClient } from 'apollo';
import { useProfile } from 'context/profile';
import { navigateByLocale, parseMarkdown } from 'utils';
import { decode } from 'html-entities';

interface ISinglePromo {
  token?: string;
}

const SINGLE_PROMO_BY_SLUG = gql`
  query promoBySlug($slug: String!) {
    promoBySlug(slug: $slug) {
      id
      expirationDate
      landscapeBannerId
      name
      abstract
      description
      slug
      price
      details
      instructions
      promoCodeType
    }
  }
`;

const SINGLE_PROMO_BY_ID = gql`
  query PromoById($id: String!, $languageCode: String!) {
    promoById(id: $id, languageCode: $languageCode) {
      slug
    }
  }
`;

const SUBSCRIBE_PROMO = gql`
  mutation subscribePromo($languageCode: String!, $promoId: String!) {
    subscribePromo(languageCode: $languageCode, promoId: $promoId) {
      subscribedOn
      promoCode
    }
  }
`;

const SinglePromo: FC<ISinglePromo> = () => {
  const { refetchWallet, token } = useProfile();

  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;

  const { slug } = useParams();
  const { state } = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [modalContent, setModalContent] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [subscribedOn, setSubscribedOn] = useState('');

  const history = useHistory();

  const categoryName = get(state, 'categoryName');
  const categorySlug = get(state, 'categorySlug');

  const client = useMemo(() => customApolloClient({ clientType: 'promosClient', token }), [token]);

  const { loading, error, data: dataPromoBySlug } = useQuery(SINGLE_PROMO_BY_SLUG, {
    variables: { slug },
    client,
    fetchPolicy: 'no-cache',
  });

  const { promoBySlug } = dataPromoBySlug || {};
  const { id } = promoBySlug || {};

  const { loading: dataPromoByIdLoading, data: dataPromoById } = useQuery(SINGLE_PROMO_BY_ID, {
    variables: { id, languageCode: currentLocale },
    client,
    fetchPolicy: 'no-cache',
    skip: isEmpty(id),
  });

  const { promoById } = dataPromoById || {};
  const { slug: newLanguageSlug } = promoById || {};

  useEffect(() => {
    navigateByLocale(history, currentLocale, 'promo', newLanguageSlug || slug);
  }, [newLanguageSlug, currentLocale]);

  const [subscribePromo, { loading: subscribeLoading, error: errorSubscribe }] = useMutation(SUBSCRIBE_PROMO, {
    fetchPolicy: 'no-cache',
    client,
    onCompleted(data) {
      const { subscribePromo } = data || {};
      const { promoCode: redeemedPromoCode, subscribedOn: redeemedSubscribedOn } = subscribePromo;
      setPromoCode(redeemedPromoCode);
      setSubscribedOn(redeemedSubscribedOn);
      setModalContent('summary');
      refetchWallet();
    },
    onError() {
      setModalContent('error');
    },
  });

  if (loading || subscribeLoading || dataPromoByIdLoading) return <GMLoading />;
  if (error || !dataPromoBySlug) return <GMError />;

  const promo = dataPromoBySlug?.promoBySlug || {};
  const {
    expirationDate,
    landscapeBannerId,
    name,
    description,
    abstract,
    price,
    details,
    instructions,
    promoCodeType,
  } = promo;

  if (isEmpty(newLanguageSlug))
    return (
      <GMContainer>
        <Flex justify="center" align="center" py="32px">
          <Box maxW={{ md: '536px', lg: '912px' }} mx="auto">
            <Box textAlign="center" mb="20px">
              {t('promos:singlePromo:notFound')}
            </Box>
            <Box textAlign="center" color="PRIMARY" fontWeight="bold" cursor="pointer">
              <Link to={`/${currentLocale}/promos`}>{t('promos:singlePromo:backToList')}</Link>
            </Box>
          </Box>
        </Flex>
      </GMContainer>
    );

  const buyPromoHandler = (): void => {
    setModalContent('confirm');
    onOpen();
  };

  const onConfirmHandler = (): void => {
    subscribePromo({ variables: { languageCode: currentLocale, promoId: id } });
    onOpen();
  };

  const isSoldOut = moment().isAfter(expirationDate);
  const newContent = parseMarkdown(`<div class="new-content">${decode(description ?? '')}</div>`); //item?.content
  const newContentDetails = parseMarkdown(`<div class="new-content">${decode(details ?? '')}</div>`); //item?.content
  const newContentInstructions = parseMarkdown(`<div class="new-content">${decode(instructions ?? '')}</div>`); //item?.content

  return (
    <>
      <GMContainer>
        <Helmet>
          <title>{promo?.title}</title>
          <meta name="description" content={promo?.title} />
          <meta id="og-title" property="og:title" content={promo?.title} />
          <meta
            id="og-image"
            property="og:image"
            content={
              landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''
            }
          />
          <meta property="og:description" content={abstract} />
          <meta property="og:locale" content={currentLocale} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:description" content={abstract} />
          <meta name="twitter:title" content={promo?.title} />
          <meta name="twitter:site" content="Gremobo" />
          <meta
            name="twitter:image"
            content={
              landscapeBannerId ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}` : ''
            }
          />
        </Helmet>

        {promo && (
          <Flex px={{ md: '52px' }} py={{ md: '32px' }} maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
            <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%">
              <GMPromoContent
                price={price}
                categoryName={categoryName}
                title={name}
                abstract={abstract}
                description={newContent}
                details={newContentDetails}
                instructions={newContentInstructions}
                isSoldOut={isSoldOut}
                imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${landscapeBannerId}`}
                thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${landscapeBannerId}`}
                buyPromoHandler={buyPromoHandler}
              />
            </Box>
            <GMSinglePromoSidebar categorySlug={categorySlug} categoryName={categoryName} />
          </Flex>
        )}
      </GMContainer>
      {isOpen && (
        <GMModal isOpen={isOpen} onClose={onClose}>
          {modalContent === 'error' && <GMModalErrorContent onClose={onClose} error={errorSubscribe?.message} />}

          {modalContent === 'confirm' && (
            <GMSinglePromoModalConfirmContent
              onConfirm={onConfirmHandler}
              onClose={onClose}
              loading={subscribeLoading}
            />
          )}

          {modalContent === 'summary' && (
            <GMSinglePromoModalSummaryContent
              onClose={onClose}
              name={name}
              price={price}
              instructions={instructions}
              details={details}
              promoCode={promoCode}
              promoCodeType={promoCodeType}
              subscribedOn={subscribedOn}
            />
          )}
        </GMModal>
      )}
    </>
  );
};

export default SinglePromo;
