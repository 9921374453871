import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import {
  GMTransactionsFilterCurrency,
  GMTransactionsFilterState,
  GMTransactionsFilterMovement,
  GMTransactionsFilterDateInterval,
} from 'components/GMTransactionsFilters';

interface IGMTransactionsHeader {
  activeFilters?: IFilters;
  onChangeCurrency?: (newValue: string) => void;
  onChangeState?: (newValue: string) => void;
  onChangeMovement?: (newValue: string) => void;
  onChangeDateInterval?: (startDate: Date, endDate: Date) => void;
}

interface IFilters {
  byCurrency?: string;
  byState?: string;
  byMovement?: string;
  byDateInterval?: { startDate: Date; endDate: Date };
}

const GMTransactionsHeader: FC<IGMTransactionsHeader> = ({
  activeFilters,
  onChangeCurrency,
  onChangeState,
  onChangeMovement,
  onChangeDateInterval,
}) => {
  const onSelectState = (newValue: string): void => {
    if (onChangeState) onChangeState(newValue);
  };

  const onSelectCurrency = (newValue: string): void => {
    if (onChangeCurrency) onChangeCurrency(newValue);
  };

  const onSelectMovement = (newValue: string): void => {
    if (onChangeMovement) onChangeMovement(newValue);
  };

  const onSelectDates = (startDate, endDate): void => {
    if (onChangeDateInterval) onChangeDateInterval(startDate, endDate);
  };

  return (
    <Flex wrap="wrap" maxW="100%" mb={{ base: '8px', lg: '16px' }}>
      <Box pr="4px" pb="8px" flexShrink={0} flexGrow={1} w="50%" maxW="50%">
        <GMTransactionsFilterMovement activeFilter={activeFilters?.byMovement} onChangeMovement={onSelectMovement} />
      </Box>

      <Box pl="4px" pb="8px" flexShrink={0} flexGrow={1} w="50%" maxW="50%">
        <GMTransactionsFilterState activeFilter={activeFilters?.byState} onChangeState={onSelectState} />
      </Box>

      <Box pr="4px" pb="8px" flexShrink={0} flexGrow={1} w="50%" maxW="50%">
        <GMTransactionsFilterCurrency activeFilter={activeFilters?.byCurrency} onChangeCurrency={onSelectCurrency} />
      </Box>

      <Box pl="4px" pb="8px" flexShrink={0} flexGrow={1} w="50%" maxW="50%">
        <GMTransactionsFilterDateInterval
          activeFilter={activeFilters?.byDateInterval}
          onChangeDateInterval={onSelectDates}
        />
      </Box>
    </Flex>
  );
};

export default GMTransactionsHeader;
