import React, { FC, useCallback } from 'react';
// import { useAnalytics } from 'use-analytics';
import { Box, Flex, Text } from '@chakra-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import keycloak from 'keycloak';
import GMButtonGreen from 'components/GMButtonGreen';
import GMChartCircle from 'components/GMChartCircle';
import useBreakpoint from 'hooks/useBreakpoint';
import { useProfile } from 'context/profile';
import { useBtnSize } from 'hooks/useProfileLayout';
import { UseActivityValues, EventCategory } from 'constants/analytics';
import { lockedData } from 'constants/charts';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

const BtnGroup: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  // const { track } = useAnalytics();
  const sendDataToGTM = useGTMDispatch();

  const login = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuLogin, value: EventCategory.UserActivity });

    keycloak.login({ locale: currentLanguage });
  }, [keycloak]);

  const register = useCallback(() => {
    sendDataToGTM({ event: UseActivityValues.MenuRegister, value: EventCategory.UserActivity });
    keycloak.register({ locale: currentLanguage });
  }, [keycloak]);

  return (
    <>
      <Box mb="16px">
        <GMButtonGreen size={btnSize} onClick={login}>
          {t('common:login')}
        </GMButtonGreen>
      </Box>
      <Box>
        <GMButtonGreen size={btnSize} variant="transparent" onClick={register}>
          {t('common:createAccount')}
        </GMButtonGreen>
      </Box>
    </>
  );
};

const GMMobilityNotAuth: FC = () => {
  const { userInfo } = useProfile();

  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  return (
    <>
      <Box pb="32px">
        <Text
          fontSize={{ base: 'md', xl: 'lg' }}
          lineHeight={{ base: '19px', xl: '22px' }}
          color="PAGE.SECONDARY_TEXT"
          px={{ base: '16px', lg: '0' }}
        >
          <Trans
            i18nKey={userInfo ? 'mobility:descriptionAuth' : 'mobility:description'}
            // eslint-disable-next-line react/jsx-key
            components={[<strong className="green"></strong>]}
          />
        </Text>
      </Box>

      <Box>
        <Box
          mb="16px"
          fontSize={{ base: '14px', lg: '16px' }}
          bg="SUPER_LIGHT_GRAY"
          border="1px solid"
          borderColor="LIGHT"
          color="PAGE.SECONDARY_TEXT"
        >
          <Flex p="16px">
            <Box minW={{ lg: '300px' }}>
              <Box display="inline-block" w="150px">
                <GMChartCircle locked legend={false} data={lockedData} percent={60} containerH={xl ? 150 : 130} />
              </Box>
              <Box display={{ base: 'none', lg: 'inline-block' }} w="150px">
                <GMChartCircle locked legend={false} data={lockedData} containerH={xl ? 150 : 130} />
              </Box>
            </Box>
            <Box color="PAGE.SECONDARY_TEXT" mx="20px">
              <Trans
                i18nKey="mobility:descriptionNotlogged"
                // eslint-disable-next-line react/jsx-key
                components={[<strong className="green"></strong>]}
              />
            </Box>
            <Box display={{ base: 'none', lg: 'block' }} minW={{ lg: '260px', xl: '360px' }}>
              <BtnGroup />
            </Box>
          </Flex>
        </Box>
        <Box display={{ lg: 'none' }} my="22px">
          <BtnGroup />
        </Box>
      </Box>
    </>
  );
};

export default GMMobilityNotAuth;
