import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Text, Flex } from '@chakra-ui/core';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import GMSocials from 'components/GMSocials';
import GMFooterLinkItem from 'components/GMFooterLinkItem';
import GremoboLogo from 'assets/svg-components/logo';
import { GMLink } from './styled';

const GMFooter: FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();

  return (
    <>
      <Box w="100%" bg="FOOTER.BAR" h="8px"></Box>
      <Box bg="PRIMARY" color="WHITE" className="footer">
        <Flex
          w="100%"
          justify="space-between"
          maxW={{ lg: 'calc(1224px + 106px)' }}
          mx="auto"
          p={{ base: '32px 16px', lg: '40px 53px' }}
        >
          <Box fontSize="12px" w={{ base: '100%', lg: '20%' }}>
            {/* @TODO to import white logo */}
            <Flex justify="space-between" w="140px" pb="26px" className="white-logo">
              <GremoboLogo width="100%" height="100%" />
            </Flex>
            <Flex direction={{ base: 'row', lg: 'column' }}>
              <Box pr={{ base: '24px', lg: '0' }}>
                <Text pb="20px">
                  <Trans
                    i18nKey="footer:credits"
                    // eslint-disable-next-line react/jsx-key
                    components={[<b />]}
                  />
                </Text>

                <Text pb="20px">
                  <Trans
                    i18nKey="footer:address"
                    // eslint-disable-next-line react/jsx-key
                    components={[<br />]}
                  />
                </Text>
              </Box>

              <Box>
                <Text pb="20px">{t('footer:piva')}</Text>
                <Text pb="20px">{t('footer:socialCapital')}</Text>
              </Box>
            </Flex>
          </Box>
          <Box w="20%" display={{ base: 'none', lg: 'block' }}>
            <Box mb="32px">
              <Box fontSize="md" lineHeight="19px" fontWeight="bold" borderBottom="1px solid white" pb="8px" mb="8px">
                {t('footer:people:title')}
              </Box>
              <Box fontSize="sm" lineHeight="17px">
                <GMFooterLinkItem
                  to={{ pathname: `https://www.gremove.com/team?lang=${currentLanguage === 'it' ? 'it' : 'en'}` }}
                >
                  {t('footer:people:whoWeAre')}
                </GMFooterLinkItem>

                <GMFooterLinkItem to="/contacts">{t('footer:people:contacts')}</GMFooterLinkItem>
              </Box>
            </Box>
            <Box mb="32px">
              <Box fontSize="md" lineHeight="19px" fontWeight="bold" borderBottom="1px solid white" pb="8px" mb="8px">
                {t('footer:project:title')}
              </Box>
              <Box fontSize="sm" lineHeight="17px">
                <Box py="8px">
                  {location && location?.pathname === '/' ? (
                    <GMLink to="theVision" hashSpy={true} smooth={true} offset={-140} duration={500}>
                      {t('footer:project:vision')}
                    </GMLink>
                  ) : (
                    <HashLink to="/#theVision">{t('footer:project:vision')}</HashLink>
                  )}
                </Box>
                <Box py="8px">
                  {location && location?.pathname === '/' ? (
                    <GMLink to="howItWorks" hashSpy={true} smooth={true} offset={-140} duration={500}>
                      {t('footer:project:howItWorks')}
                    </GMLink>
                  ) : (
                    <HashLink to="/#howItWorks">{t('footer:project:howItWorks')}</HashLink>
                  )}
                </Box>
                {/* <Box py="8px">{t('footer:project:certifications')}</Box> */}
              </Box>
            </Box>
          </Box>
          <Box w="20%" display={{ base: 'none', lg: 'block' }}>
            <Box fontSize="md" lineHeight="19px" fontWeight="bold" borderBottom="1px solid white" pb="8px" mb="8px">
              {t('footer:legal:title')}
            </Box>
            <Box fontSize="sm" lineHeight="17px">
              <GMFooterLinkItem to={`/terms-and-conditions-private`}>
                {t('footer:legal:termsAndConditions')}
              </GMFooterLinkItem>

              <GMFooterLinkItem to={`/terms-and-conditions-enterprise`}>
                {t('footer:legal:termsAndConditions')} Enterprise
              </GMFooterLinkItem>

              <GMFooterLinkItem to={`/privacy-policy`}>{t('footer:legal:privacy')}</GMFooterLinkItem>

              <GMFooterLinkItem to={`/cookie-policy`}>{t('footer:legal:cookiePolicy')}</GMFooterLinkItem>

              <GMFooterLinkItem to="/faq">{t('footer:legal:FAQ')}</GMFooterLinkItem>
            </Box>
          </Box>
          <Box w="20%" display={{ base: 'none', lg: 'block' }}>
            <Box fontSize="md" lineHeight="19px" fontWeight="bold" borderBottom="1px solid white" pb="8px" mb="8px">
              {t('footer:social:title')}
            </Box>
            <Flex justify="flex-start" align="center" wrap="wrap" maxW="150px">
              <GMSocials />
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default GMFooter;
