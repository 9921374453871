import React, { FC, ReactNode } from 'react';
import { Box } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer /*Tooltip*/ } from 'recharts';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { palette } from 'theme/theme';
import { colors, localizedCharts } from 'constants/charts';
import Lock from 'assets/svg-components/lock';

interface IGMChartCircle {
  data: { x?: string; y: number }[];
  percent?: number;
  size?: number;
  locked?: boolean;
  containerH?: number;
  labelAmount?: 'percent' | 'km';
  legend?: boolean;
  total?: number;
  renderLegend?: ReactNode;
  showAmountInLegend?: boolean;
  fleetStats?: boolean;
}

// interface ICustomTooltip {
//   payload?: { value: number }[];
//   unit?: string;
// }

// const CustomTooltip: FC<ICustomTooltip> = ({ payload, unit }) => {
//   if (payload) {
//     return (
//       <Box bg={palette.WHITE} border="1px solid" borderColor={palette.LIGHT_GREY} p="10px">
//         {payload[0]?.value} {unit}
//       </Box>
//     );
//   }
//   return <></>;
// };

const GMChartCircle: FC<IGMChartCircle> = ({
  containerH = 168,
  data = [],
  labelAmount,
  percent,
  size = 110,
  locked = false,
  legend = true,
  total,
  renderLegend,
  showAmountInLegend = false,
  fleetStats = false,
}) => {
  const { t } = useTranslation();

  const cx = legend && data.length > 0 && '40%';
  const totalSum = sumBy(data, (item) => item?.y);

  const format = (percent: number | undefined): number => {
    if (percent && percent > 100) return 100;
    if (percent && percent < 0) return 0;
    return percent ?? 100;
  };

  const koef = 1 - format(percent) / 100;
  const startAngle = 90 + 360 * koef;

  return (
    <Box w="100%" position="relative" overflowWrap="anywhere">
      {locked ? (
        <Box
          position="absolute"
          top="50%"
          left={cx ? '24%' : '50%'}
          transform="translate(-50%, -50%)"
          bg="LIGHT"
          borderRadius="50%"
          w="60px"
          h="60px"
        >
          <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
            <Lock />
          </Box>
        </Box>
      ) : (
        <>
          {labelAmount === 'km' && (
            <Box
              fontSize={12}
              fill={palette.MEDIUM_GREY}
              position="absolute"
              top="35%"
              left={cx ? '24%' : '50%'}
              transform="translate(-50%, -50%)"
            >
              {t('mobility:charts:label:km')}
            </Box>
          )}
          <Box
            position="absolute"
            top="50%"
            left={cx ? '24%' : '50%'}
            transform="translate(-50%, -50%)"
            fontSize={size > 110 ? 35 : 30}
            fontWeight="bold"
          >
            {total ? Math.round(total) : fleetStats ? totalSum : (totalSum / 1000).toFixed(2)}
            {labelAmount === 'percent' && (
              <Box as="span" fontSize={20} transform="translateY(-50%)">
                {t('mobility:charts:label:percent')}
              </Box>
            )}
          </Box>
          {percent?.toString() && (
            <Box position="absolute" bottom="34%" left={cx ? '24%' : '50%'} transform="translate(-50%, 50%)">
              {percent}%
            </Box>
          )}
        </>
      )}
      <ResponsiveContainer width="100%" height={containerH}>
        {/* rounded arcs solution https://github.com/recharts/recharts/issues/309 */}
        <PieChart>
          {/* <Tooltip cursor={{ fill: 'none' }} content={<CustomTooltip unit={t('mobility:charts:unit')} />} /> */}
          {/* placeholder pie */}
          <Pie
            data={[{ y: 1, stroke: palette.LIGHTEST_GREY }]}
            innerRadius={size / 2}
            outerRadius={size / 2}
            dataKey="y"
            isAnimationActive={false}
            cx={cx}
          >
            <Cell strokeWidth={5} />
          </Pie>
          <Pie
            data={data}
            innerRadius={size / 2}
            outerRadius={size / 2}
            paddingAngle={0}
            dataKey="y"
            startAngle={startAngle}
            endAngle={450}
            isAnimationActive={true}
            cx={cx}
          >
            {map(data, (entry, index) => (
              <Cell
                key={`cell-${entry.x}-${index}`}
                strokeWidth={15}
                stroke={(entry?.x && colors[entry?.x]) || colors[`DELIVERY_${index}`] || palette.MEDIUM_GREEN}
              />
            ))}
          </Pie>
          {/* for colors in legend https://github.com/recharts/recharts/issues/1368 */}
          {!locked && legend && (
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              iconType="circle"
              payload={map(data, (item, index) => ({
                id: item.x,
                type: 'circle',
                value: `${showAmountInLegend ? item.y : ''} ${item?.x && (t(localizedCharts[item?.x]) || item?.x)}`,
                amount: item.y,
                color: (item?.x && colors[item?.x]) || colors[`DELIVERY_${index}`] || palette.MEDIUM_GREY,
              }))}
              wrapperStyle={{
                lineHeight: '35px',
                width: cx,
              }}
              iconSize={12}
              content={renderLegend}
            />
          )}
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default GMChartCircle;
