import React, { FC, useState, useEffect } from 'react';
import { Box, InputGroup, Input, InputRightElement } from '@chakra-ui/core';
import { NestDataObject, FieldError } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

interface IGMModalInput {
  label?: string;
  value?: string;
  name: string;
  register: (Ref, validateRule?) => void;
  errors?: NestDataObject<Record<string, unknown>, FieldError>;
  req?: boolean;
}

const GMModalInput: FC<IGMModalInput> = ({ req = false, errors, name, label, value: initValue, register }) => {
  const error = errors && get(errors, name);
  const [value, setValue] = useState(initValue || '');
  const [focus, setFocus] = useState(!isEmpty(value));

  useEffect(() => {
    setValue(initValue || '');
  }, [initValue]);

  return (
    <Box mb={{ base: '16px', lg: '14px' }}>
      <Box
        fontSize={{ base: '12px', lg: '14px', xl: '16px' }}
        color="PAGE.SECONDARY_TEXT"
        lineHeight={{ base: '15px', lg: '17px', xl: '19px' }}
        pb="2px"
        transform={`translateY(${focus ? 0 : 150}%)`}
        transition="all 0.2s ease"
      >
        {label}
      </Box>
      <InputGroup>
        <Input
          name={name}
          ref={register}
          fontSize={{ base: '18px', lg: '20px', xl: '22px' }}
          lineHeight={{ base: '22px', lg: '24px', xl: '27px' }}
          color="SECONDARY"
          variant="flushed"
          value={value}
          borderBottom="1px solid"
          borderColor={error ? 'ERROR' : 'SECONDARY'}
          p="0 20px 8px 0"
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(!isEmpty(value))}
          _focus={{
            borderColor: `${error ? 'ERROR' : 'SECONDARY'}`,
          }}
          onChange={(e) => setValue(e.target.value)}
        />
        {req && (
          <InputRightElement width={5} pb="8px" zIndex={1}>
            <Box color={error ? 'ERROR' : 'PRIMARY'}>&#8226;</Box>
          </InputRightElement>
        )}
      </InputGroup>
      {error && (
        <Box color="ERROR" fontSize="14px">
          {error.message}
        </Box>
      )}
    </Box>
  );
};

export default GMModalInput;
