import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

interface IuseApollo {
  clientType?: string;
  token?: string;
}

export const customApolloClient = ({
  clientType = 'mainClient',
  ...props
}: IuseApollo): ApolloClient<NormalizedCacheObject> => {
  const getClient = (): string => {
    switch (clientType) {
      default:
      case 'mainClient':
        return `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_GRAPHQL}`;
      case 'shopClient':
        return `${process.env.REACT_APP_BASE_API_URL}/shop/${process.env.REACT_APP_GRAPHQL}`;
      case 'promosClient':
        return `${process.env.REACT_APP_BASE_API_URL}/promos/${process.env.REACT_APP_GRAPHQL}`;
      case 'categoriesClient':
        return `${process.env.REACT_APP_BASE_API_URL}/categories/${process.env.REACT_APP_GRAPHQL}`;
      case 'walletClient':
        return `${process.env.REACT_APP_BASE_API_URL}/wallet/${process.env.REACT_APP_GRAPHQL}`;
      case 'usersClient':
        return `${process.env.REACT_APP_BASE_API_URL}/users/${process.env.REACT_APP_GRAPHQL}`;
    }
  };

  return new ApolloClient<NormalizedCacheObject>({
    link: new HttpLink({
      uri: getClient,
      headers: {
        Authorization: props?.token ? `Bearer ${props?.token}` : '',
      },
    }),
    cache: new InMemoryCache(),
  });
};

export default customApolloClient;
