import React, { FC, ReactNode, useRef, useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { tag as dimensions } from 'constants/dimensions';

export interface IGMTag {
  tagName?: string;
  active?: boolean;
  size?: 'small' | 'medium' | 'big';
  dense?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

export const GMTag: FC<IGMTag> = ({ dense = false, tagName = '', active = false, size = 'small', onClick, icon }) => {
  const tagRef = useRef<HTMLDivElement>(null);
  const { padding, fontSize, fontWeight, height, lineHeight, borderRadius } = dimensions[size];

  useEffect(() => {
    if (active && tagRef.current) {
      tagRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [active]);

  return (
    <Box
      ref={tagRef}
      borderRadius={borderRadius}
      borderColor={active ? 'TAG.BORDER_ACTIVE' : 'TAG.BORDER'}
      borderStyle="solid"
      borderWidth="1px"
      bg={active ? 'TAG.BACKGROUND_ACTIVE' : 'TAG.BACKGROUND'}
      padding={padding}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={active ? 'TAG.TEXT_ACTIVE' : 'TAG.TEXT'}
      w={dense ? 'auto' : '100%'}
      h={height}
      lineHeight={lineHeight}
      boxSizing="border-box"
      onClick={onClick}
      cursor="pointer"
      position="relative"
    >
      <Flex align="center" justify="space-between">
        <Box wordBreak="break-all" overflow="hidden" h={lineHeight}>
          {tagName}
        </Box>
      </Flex>
      {icon}
    </Box>
  );
};

export default GMTag;
