import React, { FC } from 'react';

const Bin: FC = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" id="delete-24px" width="24" height="24" viewBox="0 0 24 24">
      <path id="Path_219" fill="none" d="M0 0h24v24H0z" data-name="Path 219" />
      <path
        id="Path_220"
        fill="#fff"
        d="M6 19a2.006 2.006 0 0 0 2 2h8a2.006 2.006 0 0 0 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
        data-name="Path 220"
      />
    </svg>
  );
};

export default Bin;
