import React, { FC, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/core';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import get from 'lodash/get';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import uniqBy from 'lodash/uniqBy';
import InfiniteScroll from 'react-infinite-scroll-component';
import GMContainer from 'components/GMContainer';
import GMSectionContainer from 'components/GMSectionContainer';
import GMSearchResults from 'components/GMSearchResults';
import GMSearchPageSidebar from 'components/GMSearchPageSidebar';
import GMSearchPageHeader from 'components/GMSearchPageHeader';
import GMLoader from 'components/GMLoader';
import useBreakpoint from 'hooks/useBreakpoint';
import usePrevious from 'hooks/usePrevious';
import { AppContext } from 'context';
import { customApolloClient } from 'apollo';
import { itemsPerPage } from 'constants/index';
import { useProfile } from 'context/profile';
import { fetchCountries } from 'api';

interface IEdges {
  id: string;
  categoryName: string;
  node: {
    expirationDate: Date;
    id: string;
    portraitBannerId: string;
    partnerName: string;
    name: string;
    price: number;
    public: boolean;
    slug: string;
  };
}

const GET_PROMOS_IN_CATEGORY = gql`
  query promos($filter: FilterInput!) {
    promos(filter: $filter) {
      totalCount
      edges {
        node {
          expirationDate
          id
          portraitBannerId
          partnerName
          price
          public
          name
          slug
        }
      }
      pageInfo {
        endCursor
        hasNext
      }
    }
  }
`;

const client = customApolloClient({ clientType: 'promosClient' });

const Search: FC = () => {
  const context = 'promos';
  const { userBalance, userInfo } = useProfile();
  const { isDivInView } = useContext(AppContext);
  const [promos, setPromos] = useState([{}] as IEdges[]);
  const [countries, setCountries] = useState([]);
  const [pageInfoState, setPageInfo] = useState({ endCursor: '', hasNext: true });
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const history = useHistory();
  const { state } = history?.location;
  const category = get(state, 'category', null);
  const query = get(state, 'query', null);
  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';
  const previousLocale = usePrevious(currentLocale);

  useEffect(() => {
    history.replace({ pathname: history.location.pathname, state: null });
  }, []);

  const filters = {
    byCategory: category ? [category] : undefined,
    byPrice: undefined,
    byQuery: query || undefined,
    byCountry: userInfo?.myData?.preferredCountry || undefined,
  };

  const [activeFilters, setActiveFilters] = useState(filters);

  const promosFilter = {
    languageCode: currentLocale,
    after: '',
    limit: itemsPerPage,
    priceLessThan: activeFilters.byPrice,
    term: activeFilters.byQuery,
    categorySlugs: activeFilters.byCategory,
    countries: activeFilters.byCountry,
  };

  const { loading, data, networkStatus, fetchMore } = useQuery(GET_PROMOS_IN_CATEGORY, {
    variables: {
      filter: promosFilter,
    },
    fetchPolicy: 'no-cache',
    client,
  });

  const setFilter = ({ filter, value }): void => {
    const filters = { ...activeFilters };
    filters[filter] = value;
    setActiveFilters(filters);
  };

  const startSearchAfterMs = 1000;
  const startSearch = debounce((newQuery: string) => {
    if (newQuery.length > 0 && newQuery.length < 3) return;
    setFilter({ filter: 'byQuery', value: newQuery });
  }, startSearchAfterMs);

  const onChangeQueryHandler = (newQuery: string): void => {
    startSearch(newQuery);
  };

  const onResetQueryHandler = (): void => {
    setFilter({ filter: 'byQuery', value: undefined });
  };

  const onChangeCategoryHandler = (slug: string): void => {
    const selectedCategories: string[] = [...(activeFilters.byCategory || [])];
    const addCategoryToArray = ({ slug }): void => {
      selectedCategories.push(slug);
    };

    const removeCategoryFromArray = ({ slug }): void => {
      remove(selectedCategories, (catSlug) => catSlug === slug);
    };

    selectedCategories.includes(slug) ? removeCategoryFromArray({ slug }) : addCategoryToArray({ slug });
    setFilter({ filter: 'byCategory', value: selectedCategories.length === 0 ? undefined : selectedCategories });
  };

  const onChangePriceOptionHandler = (newValue: string): void => {
    setFilter({ filter: 'byPrice', value: newValue === 'canBePurchased' ? userBalance?.km : undefined });
  };

  const onChangeCountryHandler = (newValue: string): void => {
    setFilter({ filter: 'byCountry', value: newValue || undefined });
  };

  const onResetAllFiltersHandler = (): void => {
    setActiveFilters({
      byCategory: undefined,
      byPrice: undefined,
      byQuery: undefined,
      byCountry: undefined,
    });
  };

  const fetchVars = {
    filter: {
      ...promosFilter,
      after: pageInfoState.endCursor,
    },
  };

  const onEndReached = (): void => {
    fetchMore &&
      fetchMore({
        variables: fetchVars,
        updateQuery: (_previousResult, { fetchMoreResult }) => {
          const newEdges = get(fetchMoreResult, 'promos.edges', []);
          const newPageInfo = get(fetchMoreResult, 'promos.pageInfo', {});

          setPageInfo(newPageInfo);
          setPromos(uniqBy([...promos, ...newEdges], 'node.id'));
        },
      });
  };

  useEffect(() => {
    fetchCountries()
      .then((res) => res.json())
      .then((result) => {
        setCountries(result);
      })
      .catch(() => {
        setCountries([]);
      });
  }, []);

  useEffect(() => {
    if (previousLocale && previousLocale !== currentLocale) {
      setFilter({ filter: 'byCategory', value: undefined });
    }
  }, [currentLocale]);

  useEffect(() => {
    const addClass = document.body.classList.add('infinite');
    return () => addClass;
  });

  const pageInfo = data?.promos?.pageInfo;
  const edges = data?.promos?.edges;

  useEffect(() => {
    if (!isEmpty(data)) {
      setPageInfo(pageInfo);
      setPromos(edges);
    }
  }, [data, edges, pageInfo]);

  const cleanedSrc = filter(promos, (val) => keys(val).length !== 0);

  return (
    <GMContainer>
      <Helmet htmlAttributes>
        <title>{t('search:promosSearchTitle')}</title>
        <meta name="description" content={t('search:promosSearchDescription')} />
      </Helmet>

      <GMSectionContainer>
        <Flex>
          <Box w="100%" maxW={{ base: '100%', lg: `calc(100% - 234px)`, xl: `calc(100% - 312px)` }} mx="auto">
            <GMSearchPageHeader
              onChangeCategory={onChangeCategoryHandler}
              onChangeQuery={onChangeQueryHandler}
              onResetQuery={onResetQueryHandler}
              onChangePriceOption={onChangePriceOptionHandler}
              activeFilters={activeFilters}
              context={context}
              onChangeCountry={onChangeCountryHandler}
              countries={countries}
            />
            <InfiniteScroll
              dataLength={cleanedSrc.length} //This is important field to render the next data
              next={
                !isEmpty(pageInfoState) &&
                pageInfoState.hasNext &&
                networkStatus === NetworkStatus.ready &&
                !isEmpty(isDivInView) &&
                isDivInView.inView
                  ? onEndReached
                  : () => null
              }
              hasMore={true}
              loader={
                pageInfoState?.hasNext && (
                  <Box margin="10px auto">
                    <GMLoader size={30} />
                  </Box>
                )
              }
              scrollThreshold={0}
              style={{ overflowY: 'hidden' }}
            >
              <GMSearchResults
                context={context}
                loading={loading}
                promos={cleanedSrc}
                onResetAllFilters={onResetAllFiltersHandler}
              />
            </InfiniteScroll>
          </Box>
          {(lg || xl) && (
            <GMSearchPageSidebar
              onChangePriceFilter={onChangePriceOptionHandler}
              activeFilters={activeFilters}
              context={context}
              countries={countries}
              onChangeCountry={onChangeCountryHandler}
            />
          )}
        </Flex>
      </GMSectionContainer>
    </GMContainer>
  );
};

export default Search;
