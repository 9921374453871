import React, { FC, useState, useMemo, useCallback } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/core';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useForm, FormContext } from 'react-hook-form';
import { gql, useQuery, useMutation } from '@apollo/client';
import map from 'lodash/map';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import GMButtonGreen from 'components/GMButtonGreen';
import GMContainer from 'components/GMContainer';
import GMError from 'components/GMError';
import GMLoading from 'components/GMLoading';
import GMVehicleCard from 'components/GMVehicleCard';
import { useProfile } from 'context/profile';
import { useBtnSize } from 'hooks/useProfileLayout';
import useBreakpoint from 'hooks/useBreakpoint';
import customApolloClient from 'apollo';

const GET_FLEET = gql`
  query myFleet {
    myFleet {
      id
      name
      model
      licensePlate
      engineType
      registrationDate
      co2Emissions
      kmDistance
      kmDistanceMeasuredAt
    }
  }
`;

const UPDATE_DISTANCES = gql`
  mutation UpdateKmDistances($updatedDistances: [KmDistanceInput!]!) {
    updateKmDistances(updatedDistances: $updatedDistances) {
      id
    }
  }
`;

interface IBtnGroup {
  onClickSubmit?: () => void;
  onCancel?: () => void;
  loading?: boolean;
}

const BtnGroup: FC<IBtnGroup> = ({ onClickSubmit, onCancel, loading }) => {
  const { t } = useTranslation();

  const getBtnSize: Function = useBtnSize();
  const btnSize = getBtnSize() || 'small';

  const breakpoint = useBreakpoint();
  const lg = breakpoint === 'lg';
  const xl = breakpoint === 'xl';

  return (
    <Flex wrap="wrap" maxW="536px" mx="auto">
      <Box w={{ base: '50%', lg: '100%' }} pr={{ base: '14px', lg: '0' }} pb={{ lg: '16px' }}>
        <GMButtonGreen onClick={onClickSubmit} size={btnSize} loading={loading}>
          {lg || xl ? t('common:saveUpdates') : t('common:save')}
        </GMButtonGreen>
      </Box>
      <Box w={{ base: '50%', lg: '100%' }} pb={{ lg: '16px' }}>
        <GMButtonGreen size={btnSize} onClick={onCancel} variant="transparent">
          {lg || xl ? t('common:cancelAndExit') : t('common:cancel')}
        </GMButtonGreen>
      </Box>
    </Flex>
  );
};

const MobilityEnterpriseUpdateStats: FC = () => {
  const { t } = useTranslation();

  const { token, isEnterprise } = useProfile();
  if (!isEnterprise) return <Redirect to="/mobility/" />;

  const history = useHistory();
  const methods = useForm({ reValidateMode: 'onSubmit' });
  const client = useMemo(() => customApolloClient({ clientType: 'usersClient', token }), [token]);

  const [loading, setLoading] = useState(false);

  const redirectToHomeMobility = useCallback((): void => {
    const redirectPath = '/mobility/enterprise';
    history?.location?.pathname !== redirectPath && history.push({ pathname: redirectPath });
  }, []);

  const { loading: loadingFleet, error, data: myFleetData } = useQuery(GET_FLEET, {
    fetchPolicy: 'no-cache',
    client,
  });

  const [UpdateDistances] = useMutation(UPDATE_DISTANCES, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      redirectToHomeMobility();
    },
    onError() {
      setLoading(false);
    },
    client,
  });

  const updateDistances = useCallback(async (updatedDistances) => {
    setLoading(true);
    return await UpdateDistances({
      variables: {
        updatedDistances,
      },
    });
  }, []);

  const onSubmit = useCallback((values): void => {
    const { data } = values;
    const vehiclesData = map(data, (formValues) => {
      return { ...formValues, kmDistance: Number(formValues.kmDistance) };
    });
    remove(vehiclesData, (vehicle) => vehicle.kmDistance === 0);
    if (!isEmpty(vehiclesData)) updateDistances(vehiclesData);
  }, []);

  if (loadingFleet) return <GMLoading />;
  if (error || !myFleetData) return <GMError />;

  const { myFleet } = myFleetData || {};

  return (
    <GMContainer>
      <Helmet>
        <title>{t('mobility:pageTitle')}</title>
        <meta name="mobility" content={t('mobility:pageTitle')} />
      </Helmet>

      <Flex wrap="wrap" pt="32px" px={{ lg: '56px' }}>
        <FormContext {...methods}>
          <Box w="100%" maxW={{ base: '100%', lg: `calc(100% - 326px)`, xl: `calc(100% - 416px)` }} mx="auto" pb="16px">
            <Box>
              <Heading
                pb={{ base: '0px', lg: '24px' }}
                px={{ base: '16px', lg: '0' }}
                color="PRIMARY"
                fontSize={{ base: '20px', lg: '28px', xl: '32px' }}
              >
                <Box maxW={{ base: '536px', lg: '100%' }} mx="auto">
                  {t('mobility:enterprise:updateDistances:title')}
                </Box>
              </Heading>
              <Box
                display={{ lg: 'none' }}
                bg="WHITE"
                py="24px"
                position="sticky"
                top="56px"
                zIndex={2}
                borderBottom="0px solid"
                borderColor="LIGHT"
                px={{ base: '16px', lg: '0' }}
              >
                <BtnGroup
                  loading={loading}
                  onClickSubmit={methods.handleSubmit(onSubmit)}
                  onCancel={redirectToHomeMobility}
                />
              </Box>

              {isEmpty(myFleet) && (
                <Box px={{ base: '16px', lg: '0' }}>
                  <Box maxW={{ base: '536px', lg: '100%' }} mx="auto">
                    {t('mobility:enterprise:myFleet:noVehicles')}
                  </Box>
                </Box>
              )}

              {map(myFleet, (vehicle, index) => (
                <GMVehicleCard vehicle={vehicle} key={vehicle?.id} index={Number(index)} />
              ))}
            </Box>
          </Box>
          <Box
            position="relative"
            w={{ lg: '326px', xl: '416px' }}
            pl={{ lg: '32px', xl: '24px' }}
            display={{ base: 'none', lg: 'block' }}
          >
            <Box position="sticky" top="5px">
              <BtnGroup
                loading={loading}
                onClickSubmit={methods.handleSubmit(onSubmit)}
                onCancel={redirectToHomeMobility}
              />
            </Box>
          </Box>
        </FormContext>
      </Flex>
    </GMContainer>
  );
};

export default MobilityEnterpriseUpdateStats;
