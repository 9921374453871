import React, { useState, useCallback, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import GMReadMoreLink from 'components/GMReadMoreLink';

interface IGMShowMore {
  children: ReactNode;
}

const GMShowMore: FC<IGMShowMore> = ({ children }) => {
  const { t } = useTranslation();
  const [textShown, setTextShown] = useState(false); // To show the remaining Text

  const toggleNumberOfLines = useCallback(() => {
    setTextShown(!textShown); // To toggle the show text or hide it
  }, [textShown]);

  return (
    <Box>
      <TextEllipsis lines={textShown ? null : 4} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
        {children}
      </TextEllipsis>

      <Box onClick={toggleNumberOfLines} mt="10px">
        <GMReadMoreLink
          text={textShown ? t('common:readLess') : t('common:readMore')}
          iconType={textShown ? 'dash' : 'plus'}
        />
      </Box>
    </Box>
  );
};

export default GMShowMore;
