import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useEffect } from 'react';
// import Analytics from 'analytics';
// import { useAnalytics } from 'use-analytics';
// import doNotTrack from 'analytics-plugin-do-not-track';
// import googleAnalytics from '@analytics/google-analytics';
import { useLocation } from 'react-router-dom';

// const dontTrack = process.env.NODE_ENV === 'development' && doNotTrack();

// const analyticsConf = Analytics({
//   app: 'app-name',
//   plugins: [
//     dontTrack,
//     googleAnalytics({
//       trackingId: 'UA-171396271-1',
//     }),
//   ],
// });

export const trackPage = (): void => {
  const location = useLocation();
  // const analytics = useAnalytics();
  const sendDataToGTM = useGTMDispatch();

  useEffect(() => {
    sendDataToGTM({
      event: 'PageView',
      value: location.pathname,
    });
  }, [location]);
};

// export default analyticsConf;
