import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/core';
import isEmpty from 'lodash/isEmpty';
import GMPhoto from 'components/GMPhoto';
import Post from 'interfaces/Post';

interface GMSingleArticleFooterProps {
  relatedArticles: Post[];
}
interface RelatedArticlesProps {
  relatedArticles: Post[];
}

const RelatedArticles: FC<RelatedArticlesProps> = ({ relatedArticles }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <>
      {relatedArticles.map((relatedArticle) => {
        const titleSlug = relatedArticle.slug;

        return (
          <Box pb="24px" key={relatedArticle.id}>
            <Link to={`/${currentLocale}/article/${titleSlug}`}>
              <Flex>
                <Box pr="16px">
                  <GMPhoto
                    w="96px"
                    h="96px"
                    textSize="xsmall"
                    imageUrl={
                      relatedArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/900x450/${relatedArticle.landscapeBannerId}`
                        : ''
                    }
                    thumbnailImageUrl={
                      relatedArticle?.landscapeBannerId
                        ? `${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${relatedArticle.landscapeBannerId}`
                        : ''
                    }
                    borderColor="SINGLE_ARTICLE.RELATED_ARTICLE_BORDER"
                    noGradient
                  ></GMPhoto>
                </Box>
                <Text
                  maxH="96px"
                  color="SINGLE_ARTICLE.RELATED_ARTICLE_TITLE"
                  overflow="hidden"
                  fontSize="md"
                  lineHeight="19px"
                  fontWeight="bold"
                >
                  {relatedArticle.title}
                </Text>
              </Flex>
            </Link>
          </Box>
        );
      })}
    </>
  );
};

const GMSingleArticleFooter: FC<GMSingleArticleFooterProps> = ({ relatedArticles }) => {
  const { t } = useTranslation();
  return (
    <>
      {!isEmpty(relatedArticles) ? (
        <Box
          display={{ lg: 'none' }}
          bg="SINGLE_ARTICLE.RELATED_POST_BACKGROUND"
          p="24px 32px"
          mt={{ xs: '24px', lg: '0' }}
        >
          <Text pb="24px" fontSize="lg" color="PAGE.SECONDARY_TEXT" lineHeight="22px">
            {t('singleArticle:relatedArticles')}
          </Text>
          <RelatedArticles relatedArticles={relatedArticles}></RelatedArticles>
        </Box>
      ) : null}
    </>
  );
};

export default GMSingleArticleFooter;
