import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@chakra-ui/core';
import GMButtonGreen from 'components/GMButtonGreen';
import GMDonationSummary from 'components/GMDonationSummary';

interface IGMSingleDonationModalSummaryContent {
  name?: string;
  subscribedOn?: string;
  price?: number;
  thankYouNote?: string;
  onClose: () => void;
}

const GMSingleDonationModalSummaryContent: FC<IGMSingleDonationModalSummaryContent> = ({
  name,
  subscribedOn,
  price,
  thankYouNote,
  onClose,
}) => {
  const { t } = useTranslation();
  const state = 'PENDING';

  return (
    <Box>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('donations:singleDonation:summary:title')}
      </Heading>

      <GMDonationSummary
        name={name}
        subscribedOn={subscribedOn}
        price={price}
        state={state}
        thankYouNote={thankYouNote}
        variant="modal"
      />

      <GMButtonGreen size="medium" onClick={onClose}>
        {t('common:close')}
      </GMButtonGreen>
    </Box>
  );
};

export default GMSingleDonationModalSummaryContent;
