import React, { FC, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Heading, Flex, Box, Image, Text } from '@chakra-ui/core';
import { Link as CLink } from '@chakra-ui/core';
import {
  GoogleIt,
  GoogleEn,
  GoogleDe,
  GoogleEs,
  GoogleFr,
  AppleDe,
  AppleEn,
  AppleEs,
  AppleFr,
  AppleIt,
} from 'assets/svg-components/mobile-badges';
import GMButtonGreen from 'components/GMButtonGreen';
import GMContainer from 'components/GMContainer';
import divider from 'assets/landing/divider.png';
import infinite from 'assets/landing/infinite.png';
import mobility from 'assets/landing/mobility.png';
import shopping from 'assets/landing/shopping.png';
import top from 'assets/landing/top.png';
import lifestyle from 'assets/landing/lifestyle.jpg';
import GreenKm from 'assets/svg-components/greenKm';
import Drop from 'assets/svg-components/drop';

interface IDescriptionListItem {
  descKey?: string;
  title?: string;
}

interface IParagraph {
  descKey?: string;
}

const DividerHome: FC = () => {
  return (
    <Box my="26px">
      <Image src={divider} />
    </Box>
  );
};

const DescriptionListItem: FC<IDescriptionListItem> = ({ descKey = '', title }) => {
  return (
    <Box
      w={{ base: '100%', sm: 'calc(100% / 2 - 8px)', lg: 'calc(100% / 4 - 24px)' }}
      fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}
    >
      <Box color="PRIMARY" fontWeight="bold">
        {title}
      </Box>
      <Paragraph descKey={descKey} />
    </Box>
  );
};

const Paragraph: FC<IParagraph> = ({ descKey = '' }) => {
  return (
    <Text pb="16px" color="PAGE.SECONDARY_TEXT">
      <Trans
        as="span"
        i18nKey={descKey}
        // eslint-disable-next-line react/jsx-key
        components={[<strong className="green" />]}
      />
    </Text>
  );
};

const Homepage: FC = () => {
  const { t, i18n } = useTranslation();
  const elementRef = useRef<null | HTMLElement>();
  const currentLanguage = i18n.language;

  const googleBadges = {
    it: <GoogleIt width="190" height="80" />,
    en: <GoogleEn width="190" height="80" />,
    es: <GoogleEs width="190" height="80" />,
    de: <GoogleDe width="190" height="80" />,
    fr: <GoogleFr width="190" height="80" />,
  };
  const appleBadges = {
    it: <AppleIt width="165" height="80" />,
    en: <AppleEn width="165" height="80" />,
    es: <AppleEs width="165" height="80" />,
    de: <AppleDe width="165" height="80" />,
    fr: <AppleFr width="165" height="80" />,
  };

  const getBadgeLang = (): { google: object; apple: object } => {
    return { google: googleBadges[i18n.language], apple: appleBadges[i18n.language] };
  };

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash && elementRef.current) {
      elementRef?.current?.scrollIntoView(true);
    }
  }, []);

  return (
    <GMContainer>
      <Helmet>
        <title>{t('homepage:title')}</title>
        <meta name="description" content={t('homepage:topDescription')} />
      </Helmet>

      <Flex align="flex-end" px={{ base: '16px', lg: '56px' }} py={{ base: '16px', lg: '32px' }}>
        <Box maxW={{ base: '536px', lg: '1224px' }} w="100%" mx="auto">
          <Flex
            align="center"
            direction={{ base: 'column', lg: 'row' }}
            h={{ base: 'auto', lg: '520px', xl: '600px' }}
            flexDirection={{ base: 'column', lg: 'row' }}
          >
            <Box pt={{ base: '32px', lg: '0' }} w={{ base: '100%', lg: '50%' }}>
              <Heading
                color="PRIMARY"
                fontSize={{ base: '4xl', lg: '5xl', xl: '6xl' }}
                fontWeight="black"
                pb={{ base: '20px', lg: '32px', xl: '48px' }}
              >
                {t('homepage:pageTitle')}
              </Heading>
              <Box fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }} w={{ base: '100%', lg: '340px', xl: '400px' }}>
                <Box pb="16px" fontWeight="bold" color="TEXT.PRIMARY" fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}>
                  {t('homepage:topDescriptionTitle')}
                </Box>
                <Paragraph descKey="homepage:topDescription" />
              </Box>
            </Box>
            <Box display={{ base: 'block' }} w={{ base: '100%', lg: '50%' }}>
              <Image src={top} />
            </Box>
          </Flex>

          <Box className="howItWorks" id="howItWorks" />
          <DividerHome />

          <Flex align="center" pb="48px" flexDirection={{ base: 'column', lg: 'row' }}>
            <Box w={{ base: '100%', lg: '50%' }}>
              <Box pr={{ lg: '24px' }} display={{ base: 'block' }} pt={{ base: '0px', lg: '94px' }}>
                <Image src={mobility} width={{ base: '100%', md: '80%' }} m="0 auto" />
              </Box>
            </Box>

            <Box w={{ base: '100%', lg: '50%' }} ref={elementRef}>
              <Heading
                id="mobileSection"
                color="PRIMARY"
                fontSize={{ base: '4xl', lg: '5xl', xl: '6xl' }}
                fontWeight="black"
                pb={{ base: '20px', lg: '32px', xl: '48px' }}
              >
                {t('homepage:howItWorksTitle')}
              </Heading>

              <Flex pb="16px">
                <Heading as="h2" color="PRIMARY" fontSize={{ base: '2xl', lg: '3xl' }} fontWeight="black">
                  {t('homepage:mobilitytitle')}
                </Heading>
                <GreenKm size={40} variant="green" />
              </Flex>
              <Box fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }} w={{ base: '100%', lg: '400px' }}>
                <Paragraph descKey="homepage:mobilityDescriptionParagraph1" />
                <Paragraph descKey="homepage:mobilityDescriptionParagraph2" />
                <Paragraph descKey="homepage:mobilityDescriptionParagraph3" />
                <Box
                  pt="8px"
                  display="flex"
                  height={{ xs: '120px', sm: '140px' }}
                  width="100%"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ base: 'flex-start' }}
                >
                  <Box flexDirection="column" mb={{ base: '20px' }}>
                    <CLink
                      href={`https://apps.apple.com/${currentLanguage}/app/gremobo/id1540994263`}
                      boxShadow="none"
                      isExternal
                    >
                      {getBadgeLang().apple}
                    </CLink>
                  </Box>
                  <Box flexDirection="column">
                    <CLink href="https://play.google.com/store/apps/details?id=com.gremobo" boxShadow="none" isExternal>
                      {getBadgeLang().google}
                    </CLink>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex align="center" flexDirection={{ base: 'column', lg: 'row' }} pb="90px">
            <Box w={{ base: '100%', lg: '50%' }}>
              <Box pr={{ lg: '24px' }} display={{ base: 'block' }}>
                <Image src={shopping} width={{ base: '100%', md: '80%' }} m="0 auto" />
              </Box>
            </Box>

            <Box w={{ base: '100%', lg: '50%' }}>
              <Flex alignItems="center" pb="16px">
                <Heading as="h2" color="PRIMARY" fontSize={{ base: '2xl', lg: '3xl' }} fontWeight="black">
                  {t('homepage:shoppingTitle')}
                </Heading>
                <Drop size={40} variant="blue" />
              </Flex>
              <Box fontSize={{ base: '14px', lg: '16px', xl: '18px' }} w={{ base: '100%', lg: '400px' }}>
                <Paragraph descKey="homepage:shoppingDescriptionParagraph1" />
                <Paragraph descKey="homepage:shoppingDescriptionParagraph2" />

                <Box pt="8px">
                  <Link to="/shopping">
                    <GMButtonGreen>{t('homepage:shoppingButton')}</GMButtonGreen>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Flex>

          <Flex align="center" flexDirection={{ base: 'column', lg: 'row' }} pb="50px">
            <Box w={{ base: '100%', lg: '50%' }} mb={{ xs: '20px' }}>
              <Box pr={{ lg: '24px' }} display={{ base: 'block' }}>
                <Image src={lifestyle} width={{ base: '100%', md: '80%' }} m="0 auto" />
              </Box>
            </Box>

            <Box w={{ base: '100%', lg: '50%' }}>
              <Heading as="h2" color="PRIMARY" fontSize={{ base: '2xl', lg: '3xl' }} fontWeight="black" pb="16px">
                {t('homepage:lifestyleTitle')}
              </Heading>
              <Box fontSize={{ base: '14px', lg: '16px', xl: '18px' }} w={{ base: '100%', lg: '400px' }}>
                <Paragraph descKey="homepage:lifestyleDescriptionParagraph1" />
              </Box>
            </Box>
          </Flex>

          <Box className="theVision" id="theVision" />
          <DividerHome />

          <Box>
            <Box w={{ base: '100%' }}>
              <Heading
                color="PRIMARY"
                fontSize={{ base: '4xl', lg: '5xl', xl: '6xl' }}
                fontWeight="black"
                pb={{ base: '20px', lg: '32px', xl: '48px' }}
              >
                {t('homepage:aboutProjectTitle')}
              </Heading>
              <Box fontSize={{ base: 'sm', lg: 'md', xl: 'lg' }}>
                <Box pb="16px" fontWeight="bold" color="TEXT.PRIMARY" fontSize={{ base: 'xl', lg: '2xl', xl: '3xl' }}>
                  {t('homepage:aboutProjectDescriptionTitle')}
                </Box>
              </Box>
            </Box>
            <Image src={infinite} py="16px" width={{ base: '100%', md: '90%' }} m="0 auto" />
            <Flex wrap="wrap" justify="space-between">
              <DescriptionListItem title="1." descKey="homepage:listDescriptionItem1" />
              <DescriptionListItem title="2." descKey="homepage:listDescriptionItem2" />
              <DescriptionListItem title="3." descKey="homepage:listDescriptionItem3" />
              <DescriptionListItem title="4." descKey="homepage:listDescriptionItem4" />
              <DescriptionListItem title="5." descKey="homepage:listDescriptionItem5" />
              <DescriptionListItem title="6." descKey="homepage:listDescriptionItem6" />
              <DescriptionListItem title="7." descKey="homepage:listDescriptionItem7" />
              <DescriptionListItem title="8." descKey="homepage:listDescriptionItem8" />
            </Flex>
          </Box>
        </Box>
      </Flex>
    </GMContainer>
  );
};

export default Homepage;
