import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import GMLink from 'components/GMLink';
import useBreakpoint from 'hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

interface IGMTransactionSummarySidebar {
  context?: 'donations' | 'promos';
}

const GMTransactionSummarySidebar: FC<IGMTransactionSummarySidebar> = ({ context = 'promos' }) => {
  const breakpoint = useBreakpoint();
  const xl = breakpoint === 'xl';

  const { t } = useTranslation();

  const urlSlug = {
    promos: 'promo',
    donations: 'donations',
  };

  const backToWalletText = {
    promos: 'promos:singlePromo:summary:backToWallet',
    donations: 'donations:singleDonation:summary:backToWallet',
  };

  const backToPageText = {
    promos: 'promos:singlePromo:summary:backToPage',
    donations: 'donations:singleDonation:summary:backToPage',
  };

  return (
    <Box position="relative" w={{ lg: '312px' }} pl="24px" display={{ base: 'none', lg: 'block' }}>
      <Box position="sticky" top="5">
        <Box pb={{ lg: '34px', xl: '32px' }}>
          <Link to={`/${urlSlug[context]}`}>
            <GMLink text={t(backToPageText[context])} size={xl ? 'medium' : 'small'} />
          </Link>
        </Box>
        <Box pb={{ lg: '34px', xl: '32px' }}>
          <Link to={`/profile/transactions`}>
            <GMLink text={t(backToWalletText[context])} size={xl ? 'medium' : 'small'} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default GMTransactionSummarySidebar;
