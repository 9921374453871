import React, { FC } from 'react';
import { Heading, Box, Flex } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import GMButtonGreen from 'components/GMButtonGreen';
import { EmailShareButton, WhatsappShareButton } from 'react-share';

interface IGMModalGeneratedCoupon {
  onClose?: () => void;
  coupon?: string;
}

const GMModalGeneratedCoupon: FC<IGMModalGeneratedCoupon> = ({ onClose, coupon }) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading color="PRIMARY" fontSize={{ base: 'xl', lg: '28px', xl: '32px' }} pb={{ base: '24px', lg: '32px' }}>
        {t('profile:generatedCoupon:modal:title')}
      </Heading>
      <Box mb={{ base: '24px', lg: '32px' }}>
        <Box color="PAGE.SECONDARY_TEXT" mb="8px">
          {t('profile:generatedCoupon:modal:label')}
        </Box>
        <Box
          borderRadius="28px"
          bg="LIGHT"
          fontSize="28px"
          color="SECONDARY"
          padding="11px"
          w="100%"
          fontWeight="bold"
          textAlign="center"
        >
          {coupon}
        </Box>
      </Box>
      <Flex justifyContent="space-between" flex="1" marginBottom="20px">
        <EmailShareButton
          url={coupon ?? ''}
          subject={`${t('profile:generatedCoupon:modal:shareCodeGocceSubject')} - ${coupon}`}
          body={t('profile:generatedCoupon:modal:shareCodeGocce')}
        >
          <Box color="PAGE.SECONDARY_TEXT" w="100%" alignItems="flex-start" fontWeight="bold" textAlign="center">
            {t('profile:generatedCoupon:modal:shareEmail')}
          </Box>
        </EmailShareButton>
        <WhatsappShareButton url={coupon ?? ''} title={t('profile:generatedCoupon:modal:shareCodeGocce')}>
          <Box color="PAGE.SECONDARY_TEXT" w="100%" alignItems="flex-end" fontWeight="bold" textAlign="center">
            {t('profile:generatedCoupon:modal:shareWhatsApp')}
          </Box>
        </WhatsappShareButton>
      </Flex>
      <Flex>
        <Box w="100%">
          <GMButtonGreen size="medium" onClick={onClose}>
            {t('profile:generatedCoupon:modal:close')}
          </GMButtonGreen>
        </Box>
      </Flex>
    </>
  );
};

export default GMModalGeneratedCoupon;
