import * as React from 'react';
import { FC, memo, useCallback, useState } from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { SizeMe } from 'react-sizeme';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import GMContainer from 'components/GMContainer';

interface IPDFPage {
  pdf: unknown;
}

const GMPDFViewer: FC<IPDFPage> = (props) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); //setting 1 to show fisrt page

  const onDocumentLoadSuccess = useCallback(({ numPages }): void => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  const changePage = useCallback((offset): void => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  const previousPage = useCallback((): void => {
    changePage(-1);
  }, []);

  const nextPage = useCallback((): void => {
    changePage(1);
  }, []);

  const { pdf } = props;

  return (
    <GMContainer>
      <Flex px={{ md: '52px' }} py={{ md: '32px' }} maxW={{ lg: 'calc(1224px + 106px)' }} mx="auto">
        <Box maxW={{ base: '536px', lg: '912px' }} mx="auto" w="100%" id="pdf-container">
          <Flex justifyContent="center">
            <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              <BsArrowLeft />
            </button>
            <div>
              {pageNumber || (numPages ? 1 : '--')} - {numPages || '--'}
            </div>
            <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              <BsArrowRight />
            </button>
          </Flex>
          <SizeMe>
            {({ size }) => (
              <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} width={size.width || 500} />
              </Document>
            )}
          </SizeMe>
          <Flex justifyContent="center">
            <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
              <BsArrowLeft />
            </button>
            <div>
              {pageNumber || (numPages ? 1 : '--')} - {numPages || '--'}
            </div>
            <button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
              <BsArrowRight />
            </button>
          </Flex>
        </Box>
      </Flex>
    </GMContainer>
  );
};

export default memo(GMPDFViewer);
