import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import { useTranslation } from 'react-i18next';
import GMPhoto from 'components/GMPhoto';
import GMPriceTag from 'components/GMPriceTag';
import GreenKm from 'assets/svg-components/greenKm';
import Drop from 'assets/svg-components/drop';

export interface IGMPromoCard {
  image?: string;
  name?: string;
  partnerName?: string;
  price?: number;
  type?: 'promo' | 'donation';
  category?: string;
  disabled?: boolean;
}

const GMPromoCard: FC<IGMPromoCard> = ({ disabled, image, name, partnerName, price, category, type = 'promo' }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;

  return (
    <Flex h="100%">
      <Box w="40%" minW="40%">
        <GMPhoto
          h="100%"
          w="100%"
          thumbnailImageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/200x130/${image}`}
          imageUrl={`${process.env.REACT_APP_ASSET_STORE}/scaled/360x432/${image}`}
          category={category}
        />
      </Box>
      <Flex direction="column" justify="space-between" pl="18px" w="100%">
        <Box fontSize={{ base: 'md', lg: 'lg' }} lineHeight="22px" fontWeight="bold" color="TEXT.PRIMARY">
          <TextEllipsis
            as="span"
            lines={4}
            ellipsisChars={'...'}
            debounceTimeoutOnResize={200}
            tagClass={currentLocale === 'de' && 'donationTitle'}
          >
            {name}
          </TextEllipsis>
        </Box>
        <Box>
          <Box fontSize={{ base: 'sm', lg: 'md' }} pb="12px" lineHeight="19px" color="PAGE.SECONDARY_TEXT">
            {partnerName}
          </Box>
          <GMPriceTag variant={disabled ? 'grey' : 'gradient'} textAlign="flex-start">
            {price} {type === 'promo' && <GreenKm size={22} />} {type === 'donation' && <Drop size={22} />}
          </GMPriceTag>
        </Box>
      </Flex>
    </Flex>
  );
};

export default GMPromoCard;
