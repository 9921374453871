import React, { FC, ReactNode } from 'react';
import { Box, Text } from '@chakra-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import GMProgressiveImage from 'components/GMProgressiveImage';
import useFontWeight from 'hooks/useGMPhotoFontWeight';
import { categories } from 'constants/categories';
import { gradients, palette } from 'theme/theme';

export interface PhotoProps {
  children?: ReactNode;
  imageUrl: string;
  thumbnailImageUrl: string;
  text?: string;
  borderColor?: string;
  textSize?: 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'big';
  w?: string;
  h?: string;
  noGradient?: boolean;
  category?: string;
}

const GMPhoto: FC<PhotoProps> = ({
  noGradient,
  imageUrl,
  thumbnailImageUrl,
  text,
  borderColor,
  textSize = 'big',
  w = '100%',
  h = '100%',
  category,
}) => {
  const getFontWeight: Function = useFontWeight();
  const fontWeight = getFontWeight() || 'bold';

  const borderColorCustom = borderColor;
  const getBorderColorFromCategory = (category: string): string => categories[category] || borderColorCustom;
  const borderColorCategory = category ? getBorderColorFromCategory(category) : borderColorCustom;
  const border = borderColorCustom ? borderColorCustom : borderColorCategory;

  const measures = {
    big: {
      fontSize: '32px',
      lineHeight: '39px',
      textPadding: '32px',
      fontWeight: 'bold',
    },

    medium: {
      fontSize: '24px',
      lineHeight: '29px',
      textPadding: '16px',
      fontWeight: fontWeight, // base, sm, md: bold; lg, xl: black
    },

    small: {
      fontSize: '20px',
      lineHeight: '24px',
      textPadding: '16px',
      fontWeight: 'black',
    },

    xsmall: {
      fontSize: '18px',
      lineHeight: '22px',
      textPadding: '12px',
      fontWeight: 'black',
    },

    xxsmall: {
      fontSize: '16px',
      lineHeight: '19px',
      textPadding: '12px',
      fontWeight: 'black',
    },
  };

  return (
    <Box display="flex" position="relative" w={w} h={h} overflow="hidden">
      {/* <Image src={imageUrl} w="100%" h="auto" objectFit="cover" objectPosition="50% 50%" /> */}
      <GMProgressiveImage
        thumbnailSource={thumbnailImageUrl}
        source={imageUrl}
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          objectFit: 'cover',
          objectPosition: '50% 50%',
        }}
        backgroundLoadingColor={palette.LIGHTEST_GREY}
        transitionDuration={300} //ms
      />
      <Box
        pos="absolute"
        left={measures[textSize]?.textPadding || measures['medium'].textPadding}
        right={measures[textSize]?.textPadding || measures['medium'].textPadding}
        bottom={measures[textSize]?.textPadding || measures['medium'].textPadding}
        margin="0"
        textShadow="outline"
        zIndex={1}
      >
        <Text
          color="TITLE_LINK.LIGHT_TEXT"
          fontSize={measures[textSize]?.fontSize || measures['medium'].fontSize}
          lineHeight={measures[textSize]?.lineHeight || measures['medium'].lineHeight}
          fontWeight={measures[textSize]?.fontWeight || measures['medium'].fontWeight}
          textShadow="outline"
          fontFamily="inherit"
        >
          <TextEllipsis lines={3} tag={'span'} ellipsisChars={'...'} debounceTimeoutOnResize={200}>
            {text}
          </TextEllipsis>
        </Text>
      </Box>
      <Box
        borderLeft="4px"
        borderColor={border}
        borderLeftWidth={border ? 4 : 0}
        borderLeftStyle={border ? 'solid' : 'none'}
        position="absolute"
        bottom="0"
        left="0"
        w="100%"
        h="100%"
        pointerEvents="none"
        backgroundImage={noGradient ? null : gradients.PHOTO_GRADIENT}
      />
    </Box>
  );
};

export default GMPhoto;
